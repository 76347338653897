import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {
    BaseResponse,
    ErrorResponse,
    OnArrayResponse,
    OnErrorResponse,
    OnNumberResponse,
    OnObjectResponse
} from "./Reponses";
import {Logger} from "./Environments";
import {Dates} from "./Dates";
import {UserModal, UserSessionModal} from "../modal/Users";
import {UserSessionIO} from "../io/Users";
import {ErrorMessage} from "../ui/Common";

const json = require('json-bigint')({
    useNativeBigInt: true
});

const client = axios.create();

client.interceptors.response.use(response => {
    Dates.parseObjectDates(response.data);

    return response;
});

function getValidResponse<T = any, D = any>(
    response: AxiosResponse<T, D>,
): T | null {
    if (response.status >= 200 && response.status < 300) {
        const data = response.data;
        return (!data) ? null : data;
    } else {
        return null;
    }
}

function createErrorMessage(
    caller: string,
    response: AxiosResponse
): string {
    return `${caller}: status=${response.status}, statusText=${response.statusText}`
}

function interceptRequestConfig<D = any>(config?: AxiosRequestConfig<D>): AxiosRequestConfig<D> {
    config = (config) ? config : {};
    const sessionId = UserSessionModal.id()?.toString();
    if (sessionId) {
        if (config.headers) {
            config.headers["x-vowing-session-id"] = sessionId;
        } else {
            config.headers = { "x-vowing-session-id": sessionId };
        }
    } else {
        Logger.log('sessionId is null.');
    }

    const transformResponse = (response?: any) => json.parse(response);
    config.transformResponse = [transformResponse];

    return config;
}

function onResponse<T, R = object>(
    value: AxiosResponse,
    caller: string,
    constructor: (o: R) => T,
    onReady: (o: T) => void,
    onError: (e: string) => void
): T | string {
    const response = getValidResponse(value);
    if (response !== null) {
        const o = constructor(response);
        onReady(o);

        return o;
    } else {
        const message = createErrorMessage(caller, value);
        Logger.error(message);
        onError(message);

        return message;
    }
}

function onArrayResponse<T, R>(
    value: AxiosResponse,
    caller: string,
    constructor: (o: R) => T,
    onReady: OnArrayResponse<T>,
    onError: OnErrorResponse
): T[] | string {
    const response = getValidResponse(value);
    if (response !== null) {
        const array = response.result.map((o: R) => constructor(o));
        onReady(array);

        return array
    } else {
        const message = createErrorMessage(caller, value);
        Logger.error(message);
        onError(message);

        return message
    }
}

function onErrorResponse<D = any>(
    error: AxiosError<object, D>,
    caller: string,
    onError: (e: string) => void,
    retry: () => void
) {
    if (error.response) {
        const response = new ErrorResponse(error.response.data);
        if (response.message === "USER_SESSION_EXPIRED") {
            UserSessionIO.refresh(userSession => {
                userSession.saveStorage();
                retry();
            }, onError);
            return
        }
        if (response.message === "USER_SESSION_NOT_FOUND") {
            UserModal.clearStorage()
            UserSessionModal.clearStorage()
            document.location = '/signIn'
            return
        }

        const message = `${caller} ${response.path}: status=${response.status}, error=${response.error}, message=${response.message}`;
        Logger.error(message);
        onError(message);
    } else {
        const message = `${caller} error: ${error}`;
        Logger.error(caller, error);
        onError(message);
    }
}

export type SequentialPostResponse<T> = { index: number, response: T }

export type SequentialPostError = { index: number, error: ErrorMessage }

export class Requests {
    static post<T, R = BaseResponse, D = any>(
        caller: string,
        url: string,
        constructor: (o: R) => T,
        onReady: (o: T) => void,
        onError: (error: string) => void,
        config?: AxiosRequestConfig
    ) {
        this.postObject<T, R, D>(caller, url, constructor, onReady, onError, undefined, config);
    }

    static postObject<T, R = BaseResponse, D = any>(
        caller: string,
        url: string,
        constructor: (o: R) => T,
        onReady: (o: T) => void,
        onError: (error: string) => void,
        data?: D,
        config?: AxiosRequestConfig
    ) {
        client.post(
            url,
            data,
            interceptRequestConfig(config)
        ).then(value => onResponse(
            value,
            caller,
            constructor,
            onReady,
            onError
        )).catch((error: AxiosError<object>) => onErrorResponse(
            error,
            caller,
            onError,
            () => { this.postObject(caller, url, constructor, onReady, onError, data, config); }
        ));
    }

    static postArray<T, R, D>(
        caller: string,
        url: string,
        constructor: (o: R) => T,
        onReady: OnArrayResponse<T>,
        onError: OnErrorResponse,
        data?: D[],
        config?: AxiosRequestConfig
    ) {
        client.post(url, data, interceptRequestConfig(config))
            .then(value => onArrayResponse(value, caller, constructor, onReady, onError))
            .catch(error => onErrorResponse(error, caller, onError, () => this.postArray(caller, url, constructor, onReady, onError, data, config)))
    }

    static getObject<T, R = BaseResponse>(
        caller: string,
        url: string,
        constructor: (o: R & BaseResponse) => T,
        onReady: (o: T) => void,
        onError: (error: string) => void,
        config?: AxiosRequestConfig
    ) {
        client.get(
            url,
            interceptRequestConfig(config)
        ).then(value => onResponse(value,
            caller,
            constructor,
            onReady,
            onError
        )).catch((error: AxiosError<object>) => onErrorResponse(
            error,
            caller,
            onError,
            () => { this.getObject(caller, url, constructor, onReady, onError, config); }
        ));
    }

    static async getObjectSync<T, R = BaseResponse>(
        caller: string,
        url: string,
        constructor: (o: R) => T,
        config?: AxiosRequestConfig
    ): Promise<T> {
        const response = await client.get(url, interceptRequestConfig(config))
        const data = response.data

        return constructor(data);
    }

    static getCount(
        caller: string,
        url: string,
        onReady: OnNumberResponse,
        onError: (error: string) => void,
        config?: AxiosRequestConfig
    ) {
        this.getObject<number, { count: number }>(
            caller,
            url,
            o => o.count,
            onReady,
            onError,
            config
        );
    }

    static getArray<T, R = BaseResponse>(
        caller: string,
        url: string,
        constructor: (o: R) => T,
        onArrayReady: (a: T[]) => void,
        onError: (e: string) => void,
        config?: AxiosRequestConfig
    ) {
        client.get(
            url,
            interceptRequestConfig(config)
        ).then(value => {
            const response = getValidResponse(value);
            if (response !== null) {
                const array = response.result.map((o: R) => constructor(o));
                onArrayReady(array);
            } else {
                const message = createErrorMessage(caller, value);
                Logger.error(message);
                onError(message);
            }
        }).catch((error: AxiosError<object>) => onErrorResponse(
            error,
            caller,
            onError,
            () => this.getArray(caller, url, constructor, onArrayReady, onError, config)
        ));
    }

    static patch<T, R = BaseResponse, D = any>(
        caller: string,
        url: string,
        constructor: (o: R) => T,
        onReady: (o: T) => void,
        onError: (error: string) => void,
        config?: AxiosRequestConfig
    ) {
        this.patchObject<T, R, D>(caller, url, constructor, onReady, onError, undefined, config);
    }

    static patchObject<T, R = BaseResponse, D = any>(
        caller: string,
        url: string,
        constructor: (o: R) => T,
        onReady: (o: T) => void,
        onError: (error: string) => void,
        data?: D,
        config?: AxiosRequestConfig
    ) {
        client.patch(
            url,
            data,
            interceptRequestConfig(config)
        ).then(value => onResponse(value,
            caller,
            constructor,
            onReady,
            onError
        )).catch((error: AxiosError<object>) => onErrorResponse(
            error,
            caller,
            onError,
            () => { this.patchObject(caller, url, constructor, onReady, onError, data, config); }
        ));
    }

    static putObject<T, R = BaseResponse, D = any>(
        caller: string,
        url: string,
        constructor: (o: R) => T,
        onReady: (o: T) => void,
        onError: (error: string) => void,
        data?: D,
        config?: AxiosRequestConfig
    ) {
        client.put(
            url,
            data,
            interceptRequestConfig(config)
        ).then(value => onResponse(value,
            caller,
            constructor,
            onReady,
            onError
        )).catch((error: AxiosError<object>) => onErrorResponse(
            error,
            caller,
            onError,
            () => { this.patchObject(caller, url, constructor, onReady, onError, data, config); }
        ));
    }

    static delete<T, R = BaseResponse>(
        caller: string,
        url: string,
        constructor: (o: R) => T,
        onReady: (o: T) => void,
        onError: (error: string) => void,
        config?: AxiosRequestConfig
    ) {
        client.delete(
            url,
            interceptRequestConfig(config)
        ).then(value => onResponse(value,
            caller,
            constructor,
            onReady,
            onError
        )).catch((error: AxiosError<object>) => onErrorResponse(
            error,
            caller,
            onError,
            () => { this.delete(caller, url, constructor, onReady, onError, config); }
        ));
    }
}
