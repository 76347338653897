import React from "react";
import {withIndex} from "../util/Iterators";
import {Optional, StatePair, StateWrapper, unwrapStateOrNull} from "../util/Types";
import "../common.css";
import {StringBuilder} from "../util/StringBuilder";

export type NavBarItemAction = (() => void) | string;

type NavBarItemProps = {
    text: string;
    action?: NavBarItemAction;
};

export function NavBarItem(props: NavBarItemProps) {
    let href: string | undefined = undefined;
    let onClick: (() => void) | undefined = undefined;
    if (props.action) {
        if (typeof props.action === 'string') {
            href = props.action;
        } else {
            onClick = props.action;
        }
    }

    return <li><a href={href} onClick={onClick}>{props.text}</a></li>;
}

export type NavTabItemProps = {
    index?: number;
    text: string;
    href?: string;
    className?: string;
    pageState?: StatePair<number>;
};

export function NavTabItem(props: NavTabItemProps) {
    const onClick = () => {
        const pageState = props.pageState;
        if (!pageState) {
            return;
        }

        const [, setPage] = pageState;
        const index = props.index;
        if (index === null || index === undefined) {
            return;
        }

        setPage(index);
    };

    return <li className="tab"><a className={props.className} href={props.href} onClick={onClick}>{props.text}</a></li>;
}

type NavProps = {
    title?: string;
    titleHref?: string;
    titleIcon?: string;
    titleImage?: string;
    titleImageAlt?: string;
    titleOnClick?: () => void;

    barItems?: ReadonlyArray<[string, NavBarItemAction]>;
    showHomeBar?: boolean;

    tabItems?: ReadonlyArray<NavTabItemProps>;

    pageState?: StatePair<number>;

    excludeErrorModal?: boolean;
    errorMessagePair?: StatePair<Optional<ErrorMessage>>;
    errorMessageState?: StateWrapper<Optional<ErrorMessage>>;
    errorRecoverPair?: StatePair<Optional<ErrorMessage>>;
    errorRecoverState?: StateWrapper<Optional<ErrorMessage>>;
    onErrorDismiss?: () => void;
    onErrorDismissHref?: string;

    children?: JSX.Element | JSX.Element[];
};

export function Nav(props: NavProps) {
    let titleIcon: JSX.Element;
    if (props.titleIcon) {
        titleIcon = <i className="material-icons">{props.titleIcon}</i>;
    } else if (props.titleImage) {
        titleIcon = <img src={`/res/${props.titleImage}`} alt={props.titleImageAlt} style={{ height: "1.5rem" }}/>;
    } else {
        titleIcon = <></>;
    }

    let navHomeBar = <></>;
    if (props.showHomeBar !== false) {
        navHomeBar = <NavBarItem key={-1} text="홈" action="/"/>;
    }

    let navBarContainer = <></>;
    if (props.barItems) {
        const navBars = props.barItems.map(([text, action]: [string, NavBarItemAction], index: number) =>
            <NavBarItem
                key={index}
                text={text}
                action={action ?? undefined}/>
        );
        navBars.insert(navHomeBar, 0);
        navBarContainer = <ul className="right hide-on-med-and-down">{navBars}</ul>;
    } else if (props.showHomeBar !== false) {
        navBarContainer = <ul className="right hide-on-med-and-down">{navHomeBar}</ul>;
    }

    const pageState = props.pageState;
    let navTabContainer: JSX.Element;
    if (props.tabItems) {
        const navTabs = props.tabItems.map((item: NavTabItemProps, index: number) =>
            <NavTabItem
                key={index}
                index={index}
                text={item.text}
                href={item.href}
                pageState={pageState} />
        );

        navTabContainer = <>
            <div className="nav-content">
                <ul className="tabs tabs-transparent" style={{ display: "flex" }}>
                    {navTabs}
                </ul>
            </div>
        </>;
    } else {
        navTabContainer = <></>;
    }

    let errorModal: JSX.Element;
    if (props.excludeErrorModal === true) {
        errorModal = <></>;
    } else {
        errorModal = <>
            <ErrorModal
                errorMessagePair={props.errorMessagePair}
                errorMessageState={props.errorMessageState}
                errorRecoverPair={props.errorRecoverPair}
                errorRecoverState={props.errorRecoverState}
                onDismiss={props.onErrorDismiss}
                onDismissHref={props.onErrorDismissHref} />
        </>;
    }

    return <>
        <nav className="nav-extended primary">
            <div className="nav-wrapper">
                <a href={props.titleHref} className="brand-logo clickable" onClick={props.titleOnClick}>{titleIcon}{props.title}</a>
                {navBarContainer}
            </div>
            {navTabContainer}
        </nav>
        {errorModal}
        {props.children}
    </>;
}

export type ErrorMessage = string | string[];

type ErrorModalProps = {
    errorMessagePair?: StatePair<Optional<ErrorMessage>>;
    errorMessageState?: StateWrapper<Optional<ErrorMessage>>;
    errorRecoverPair?: StatePair<Optional<ErrorMessage>>;
    errorRecoverState?: StateWrapper<Optional<ErrorMessage>>;

    onDismiss?: () => void;
    onDismissHref?: string;
};

export function ErrorModal(props: ErrorModalProps) {
    const [messages, setMessages] = props.errorMessagePair ?? unwrapStateOrNull(props.errorMessageState) ?? [undefined, undefined];
    const [recovers, setRecovers] = props.errorRecoverPair ?? unwrapStateOrNull(props.errorRecoverState) ?? [undefined, undefined];
    const apply = (paragraphs: JSX.Element[], key: number, message: string) => {
        if (message.startsWith("http")) {
            paragraphs.push(<a key={key} href={message}>{message}</a>);
        } else {
            paragraphs.push(<p key={key}>{message}</p>);
        }
    };

    const messageParagraphs = [];
    if (messages) {
        messageParagraphs.push(<h5 key={-1}>오류가 발생했습니다: </h5>);
        if (Array.isArray(messages)) {
            for (const [index, message] of withIndex(messages)) {
                apply(messageParagraphs, index, message);
            }
        } else {
            apply(messageParagraphs, 0, messages);
        }
    } else {
        messageParagraphs.push(<p key={0}>오류가 발생했습니다.</p>);
    }

    const recoverParagraphs = [];
    if (recovers) {
        recoverParagraphs.push(<h5 key={-1}>다음을 시도하십시오: </h5>);
        if (Array.isArray(recovers)) {
            for (const [index, recover] of withIndex(recovers)) {
                apply(recoverParagraphs, index, recover);
            }
        } else {
            apply(recoverParagraphs, 0, recovers);
        }
    }

    const onConfirmClicked = () => {
        setMessages?.(null);
        setRecovers?.(null);
        props.onDismiss?.();
    };

    return <div id="error_modal" className="modal">
        <div className="modal-content">
            <h4>오류</h4>
            {messageParagraphs}
            {recoverParagraphs}
        </div>
        <div className="modal-footer">
            <a
                href={props.onDismissHref}
                className="modal-close waves-effect btn-flat black-text"
                onClick={onConfirmClicked}>
                확인
            </a>
        </div>
    </div>;
}

type BottomProgressProps = {
    hasMoreContents: boolean;
};

export function BottomProgress(props: BottomProgressProps) {
    if (props.hasMoreContents) {
        return <div className="preloader-wrapper small active" style={{ marginTop: "1rem", left: "50%", transition: "transform(-50%)" }}>
            <div className="spinner-layer spinner-red-only">
                <div className="circle-clipper left"><div className="circle" /></div>
                <div className="gap-patch"><div className="circle" /></div>
                <div className="circle-clipper right"><div className="circle" /></div>
            </div>
        </div>;
    } else {
        return <></>;
    }
}

type ProgressBarProps = {
    hide: boolean;
};

export function ProgressBar(props: ProgressBarProps) {
    let hide: string;
    if (props.hide) {
        hide = "hide";
    } else {
        hide = "";
    }

    return <div className={`progress ${hide}`}><div className="indeterminate" /></div>;
}

type ReviewStarProps = {
    value: number;
};

export function ReviewStar(props: ReviewStarProps) {
    //
    //
    //
    // const stars: JSX.Element[] = [];
    // let value = props.value / 2;
    // let mod = value % 1;
    // if (mod !== 0) {
    //     value -= mod;
    // }
    // while (value > 0) {
    //     stars.push(<Full key={value--} />);
    // }
    // if (mod !== 0) {
    //     stars.push(<Half key={-1} />);
    // }
    const stars: string[] = [];
    let value = props.value / 2;
    const mod = value % 1;
    if (mod !== 0) {
        value -= mod;
    }
    while (value > 0) {
        stars.push('star');
        value--;
    }
    if (mod !== 0) {
        stars.push('star_half');
    }

    return <span className="material-symbols-rounded review-star small">{StringBuilder.joinToString(stars, " ")}</span>;
}

type DetailCardProps = {
    title: string;
    value?: Optional<string>;
    href?: string;
    smallBody?: boolean;
    columnWidth?: number;
    columnOffset?: number;
};

export function DetailCard(props: DetailCardProps) {
    if (!props.value) {
        return <></>;
    }

    let className = "col";
    if (props.columnWidth !== undefined) {
        className += ` s${props.columnWidth}`;
    } else {
        className += ' s8';
    }
    if (props.columnOffset !== undefined) {
        className += ` offset-s${props.columnOffset}`;
    } else {
        className += ' offset-s2';
    }

    let anchor: JSX.Element;
    if (props.href) {
        anchor = <a href={props.href}><i className="material-icons right grey-text">open_in_new</i></a>;
    } else {
        anchor = <></>;
    }

    return <div className={className}>
        <div className="card">
            <div className="card-content">
                <p>{props.title}{anchor}</p>
                <span className={(props.smallBody === true) ? "" : "card-title"}>{props.value}</span>
            </div>
        </div>
    </div>;
}