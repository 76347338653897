import {ErrorMessage, Nav} from "../../../Common";
import {MaterialModal} from "../../../Materials";
import {Link, useParams} from "react-router-dom";
import {ProductOptionItemModal} from "../../../../modal/Products";
import {useEffect, useState} from "react";
import {Optional} from "../../../../util/Types";
import {ProductOptionItemIO} from "../../../../io/Products";

type Params = {
    productOptionId?: string;
};

export default function List() {
    const params = useParams<Params>();
    const productOptionId = params.productOptionId?.toIntOrNull() ?? null;

    const [contents, setContents] = useState<Optional<ProductOptionItemModal[]>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
        MaterialModal.init();
    });

    useEffect(() => {
        if (productOptionId === null) {
            return;
        }

        ProductOptionItemIO.listByProductOptionId(productOptionId, setContents, setErrorMessage);
    }, [productOptionId]);

    if (errorMessage !== null) {
        window.alert('오류가 발생했습니다.');
        return <Link to="/" />
    } else if (productOptionId === null) {
        window.alert('잘못된 요청입니다.');
        return <Link to="/" />
    } else {
        return <PageWrapper productOptionId={productOptionId} contents={contents} />;
    }
}

type PageWrapperProps = {
    productOptionId: number;
    contents: Optional<ProductOptionItemModal[]>;
};

function PageWrapper(props: PageWrapperProps) {
    const contents = props.contents;
    const rows = (contents ?? []).map(content =>
        <Row
            key={content.id.toString()}
            content={content} />
    );

    return <>
        <Nav
            title="상품 옵션 항목"
            titleHref="/"
            titleIcon="chevron_left"
            excludeErrorModal={true} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>이름</th>
                <th>표시 순서</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="fixed-action-btn">
            <a className="btn-floating btn-large secondary" href={`/product/option/${props.productOptionId}/item/post`}>
                <i className="large material-icons">add</i>
            </a>
        </div>
    </>;
}

type RowProps = {
    content: ProductOptionItemModal;
};

function Row(props: RowProps) {
    return <tr>
        <td>{props.content.id}</td>
        <td>{props.content.name}</td>
        <td>{props.content.sortOrder}</td>
        <td>
            <a href={`/product/option/${props.content.productOptionId}/item/${props.content.id}/edit`} className="clickable">
                <i className="material-icons black-text">edit</i>
            </a>
            <a onClick={() => OnDeleteClicked(props.content)} className="clickable">
                <i className="material-icons black-text">clear</i>
            </a>
        </td>
    </tr>;
}

function OnDeleteClicked(content: ProductOptionItemModal) {
    if (!window.confirm('삭제하시겠습니까?')) {
        return;
    }

    const onDeleted = () => {
        window.alert('삭제되었습니다.');
        window.location.reload();
    };

    const onDeleteError = () => {
        window.alert('삭제를 실패했습니다.');
    };

    ProductOptionItemIO.delete(
        content.id,
        onDeleted,
        onDeleteError
    );
}