import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../../util/Types";
import {ApplicationVersionModal, ApplicationVersionType} from "../../../../modal/Miscs";
import {ErrorMessage, Nav, NavBarItemAction} from "../../../Common";
import {MiscApplicationVersionIO} from "../../../../io/Miscs";
import {
    Component,
    MaterialComponent,
    MaterialDatepicker,
    MaterialInput,
    MaterialModal,
    MaterialSelectWrapper, MaterialTimepicker
} from "../../../Materials";

const pageId = 'application_version_edit';
const nameInputId = `${pageId}_name`;
const typeSelectId = `${pageId}_type_select`;
const typeWrapperId = `${pageId}_type_wrapper`;
const platformInputId = `${pageId}_platform`;
const expireDateId = `${pageId}_expire_date`;
const expireTimeId = `${pageId}_expire_time`;

export default function Edit() {
    const {applicationVersionId} = useParams() as { applicationVersionId?: number };
    const [content, setContent] = useState<Optional<ApplicationVersionModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        MaterialSelectWrapper.init();
        MaterialDatepicker.init();
        MaterialTimepicker.init();
    });

    useEffect(() => {
        if (applicationVersionId) {
            MiscApplicationVersionIO.get(applicationVersionId, setContent, setErrorMessage);
        }
    }, [applicationVersionId]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${nameInputId}`),
            MaterialSelectWrapper.getOrNull(`#${typeWrapperId}`),
            MaterialInput.getOrNull(`#${platformInputId}`),
            MaterialDatepicker.getOrNull(`#${expireDateId}`),
            MaterialTimepicker.getOrNull(`#${expireTimeId}`),
        ];
        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled]);

    useEffect(() => {
        if (content) {
            MaterialInput.getOrNull(`#${nameInputId}`)?.setValue(content.name);
            MaterialSelectWrapper.getOrNull(`#${typeWrapperId}`)?.setValue(ApplicationVersionType.toString(content.type));
            MaterialInput.getOrNull(`#${platformInputId}`)?.setValue(content.platform);
            if (content.expiredAt) {
                MaterialDatepicker.getOrNull(`#${expireDateId}`)?.setValue(content.expiredAt.toDatePartString());
                MaterialTimepicker.getOrNull(`#${expireTimeId}`)?.setValue(content.expiredAt.toTimePartString(false, false));
            }
        }
    }, [content]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contentState={[content, setContent]}
            errorMessageState={[errorMessage, setErrorMessage]}
            setRootEnabled={setRootEnabled} />
    </>;
}

type PageWrapperProps = {
    contentState: StatePair<Optional<ApplicationVersionModal>>;

    errorMessageState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [content] = props.contentState;
    const [, setErrorMessage] = props.errorMessageState;
    const onSaveClicked = () => OnSaveClicked(content, setErrorMessage, props.setRootEnabled);
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", () => document.location = "/misc/application/version/list"]
    ];

    return <>
        <Nav
            title={"애플리케이션 버전 " + ((content) ? "수정" : "추가")}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <Editor />
    </>;
}

function OnSaveClicked(
    content: Optional<ApplicationVersionModal>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const name = MaterialInput.get(`#${nameInputId}`).getValue();
    if (name.length.notIn(1, 21)) {
        setErrorMessage('이름을 확인해주세요.');
        return;
    }

    const type = MaterialSelectWrapper.get(`#${typeWrapperId}`).getFirstSelectionAs(ApplicationVersionType.ordinal);
    if (type === null) {
        setErrorMessage('구분을 확인해주세요.');
        return;
    }

    const platform = MaterialInput.get(`#${platformInputId}`).getValue();
    if (platform.length.notIn(1, 11)) {
        setErrorMessage('플랫폼을 확인해주세요.');
        return;
    }

    const datePart = MaterialDatepicker.get(`#${expireDateId}`).getDatePartOrNull();
    const timePart = MaterialTimepicker.get(`#${expireTimeId}`).getTimePartOrNull();
    let expiredAt: Optional<Date> = null;
    if (datePart && timePart) {
        const {year, month, date} = datePart;
        const {hour, minute} = timePart;
        expiredAt = new Date(year, month, date, hour, minute);
    }

    const onReady = () => {
        window.alert('저장되었습니다.');
        document.location = `/misc/application/version/list`;
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    if (!content) {
        setRootEnabled(false);
        MiscApplicationVersionIO.post(name, type, platform, expiredAt, onReady, onError);
    } else {
        if (content &&
            content.name === name &&
            content.type === type &&
            content.platform === platform &&
            content.expiredAt === expiredAt) {
            onReady();
            return;
        }

        setRootEnabled(false);
        MiscApplicationVersionIO.update(content.id, name, type, platform, expiredAt, onReady, onError);
    }
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={nameInputId}
                inputClasses="validate"
                inputPlaceHolder="20자 이내"
                inputDataLength={20}
                label="이름" />
        </div>
        <div className="row cascade">
            <Component.Select
                values={ApplicationVersionType.values()}
                wrapperId={typeWrapperId}
                wrapperClasses="col s4 offset-s2"
                selectId={typeSelectId}
                label="구분" />
            <Component.Input
                formClasses="col s4"
                inputId={platformInputId}
                inputClasses="validate"
                inputPlaceHolder="20자 이내"
                inputDataLength={20}
                label="플랫폼(대소문자 구분)" />
        </div>
        <div className="row cascade">
            <Component.Datepicker
                formClasses="col s4 offset-s2"
                inputId={expireDateId}
                label="만료 날짜" />
            <Component.Timepicker
                formClasses="col s4"
                inputId={expireTimeId}
                label="만료 시각" />
        </div>
    </>;
}