import {Route, Routes} from "react-router-dom";
import Detail from "./Detail";
import List from "./List";
import {AssertSignIn} from "../../App";
import Edit from "./Edit";
import SignInRouter from "./signIn/SignInRouter";
import BlockRouter from "./block/BlockRouter";
import DormantRouter from "./dormant/DormantRouter";
import WithdrawnRouter from "./withdrawn/WithdrawnRouter";
import NotificationRouter from "./notification/NotificationRouter";
import {PerfectRouter} from "./perfect/PerfectRouter";

export default function UserRouter() {
    return <Routes>
        <Route path="/list" element={<AssertSignIn><List /></AssertSignIn>} />
        <Route path="/:userId" element={<AssertSignIn><Detail /></AssertSignIn>} />
        <Route path="/:userId/edit" element={<AssertSignIn><Edit /></AssertSignIn>} />

        <Route path="/block/*" element={<AssertSignIn><BlockRouter /></AssertSignIn>} />
        <Route path="/dormant/*" element={<AssertSignIn><DormantRouter /></AssertSignIn>} />
        <Route path="/perfect/*" element={<AssertSignIn><PerfectRouter /></AssertSignIn>} />
        <Route path="/signIn/*" element={<AssertSignIn><SignInRouter /></AssertSignIn>} />
        <Route path="/withdrawn/*" element={<AssertSignIn><WithdrawnRouter /></AssertSignIn>} />
        <Route path="/notification/*" element={<AssertSignIn><NotificationRouter /></AssertSignIn>} />
    </Routes>;
}