import {StateWrapper} from "../../util/Types";
import {TextField} from "./TextField";
import React from "react";

export type SearchFieldProps = {
    query: StateWrapper<string>
}

export function SearchField(props: SearchFieldProps) {
    return <>
        <div className={'row'}>
            <form className="col s6 offset-s3">
                <div className="input-field">
                    <TextField
                        label={'검색'}
                        iconProps={{ iconName: 'clear', onClick: () => props.query.set('') }}
                        inputProps={{ value: props.query.value }}
                        onValueChange={(event, value) => props.query.set(value)} />
                </div>
            </form>
        </div>
    </>
}