import {Route, Routes} from "react-router-dom";
import {List} from "./List";
import {Detail} from "./Detail";

export function ParticipationRouter() {
    return <>
        <Routes>
            <Route path={'/list'} element={<List />} />
            <Route path={'/:id'} element={<Detail />} />
        </Routes>
    </>
}