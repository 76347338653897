import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../util/Types";
import {ProductModal} from "../../modal/Products";
import {ErrorMessage, Nav, NavBarItemAction} from "../Common";
import {Component, MaterialComponent, MaterialInput, MaterialModal} from "../Materials";
import {ProductIO} from "../../io/Products";
import {fold, IntRange, map} from "../../util/Iterators";
import {OnErrorResponse, OnObjectResponse} from "../../util/Reponses";
import S3 from "aws-sdk/clients/s3";
import {StorageIO} from "../../io/Services";
import {StringBuilder} from "../../util/StringBuilder";

const pageId = 'product_edit';
const productBrandInputId = `${pageId}_product_brand`;
const nameInputId = `${pageId}_name`;
const priceInputId = `${pageId}_price`;
const headerImageInputId = `${pageId}_header_image`;
const descriptionImageInputId = `${pageId}_description_image`;

export default function Edit() {
    const {productId} = useParams() as { productId?: number };
    const [content, setContent] = useState<Optional<ProductModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [errorRecover, setErrorRecover] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        MaterialModal.init();
        MaterialInput.init();
    });

    useEffect(() => {
        if (productId) {
            ProductIO.get(productId, setContent, setErrorMessage);
        }
    }, [productId]);

    useEffect(() => {
        if (content) {
            MaterialInput.getOrNull(`#${productBrandInputId}`)?.setValue(content.productBrandId.toString());
            MaterialInput.getOrNull(`#${nameInputId}`)?.setValue(content.name);
            MaterialInput.getOrNull(`#${priceInputId}`)?.setValue(content.price.toString());
        }
    }, [content]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${productBrandInputId}`),
            MaterialInput.getOrNull(`#${nameInputId}`),
            MaterialInput.getOrNull(`#${priceInputId}`)
        ];

        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contentState={[content, setContent]}
            errorMessageState={[errorMessage, setErrorMessage]}
            errorRecoverState={[errorRecover, setErrorRecover]}
            setRootEnabled={setRootEnabled} />
    </>;
}

type PageWrapperProps = {
    contentState: StatePair<Optional<ProductModal>>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
    errorRecoverState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [content] = props.contentState;
    const [, setErrorMessage] = props.errorMessageState;
    const [, setErrorRecover] = props.errorRecoverState;
    const onSaveClicked = () => OnSaveClicked(
        content,
        setErrorMessage,
        setErrorRecover,
        props.setRootEnabled
    );
    const onCancelClicked = () => window.history.back();
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", onCancelClicked]
    ];

    return <>
        <Nav
            title={"상품 " + ((content) ? "수정" : "추가")}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState}
            errorRecoverPair={props.errorRecoverState} />
        <Editor
            contentState={props.contentState}
            setErrorMessage={setErrorMessage}
            setErrorRecover={setErrorRecover}
            setRootEnabled={props.setRootEnabled} />
    </>;
}

function OnSaveClicked(
    content: Optional<ProductModal>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const productBrandId = MaterialInput.get(`#${productBrandInputId}`).getValue().toIntOrNull();
    if (productBrandId === null) {
        setErrorMessage("상품 브랜드 ID를 확인해주세요.");
        return;
    }

    const name = MaterialInput.get(`#${nameInputId}`).getValue();
    if (name.length.notIn(0, 101)) {
        setErrorMessage("이름을 확인해주세요.");
        return;
    }

    const price = MaterialInput.get(`#${priceInputId}`).getValue().toIntOrNull();
    if (price === null) {
        setErrorMessage("가격을 확인해주세요.");
        return;
    }

    const headerImageInput = MaterialInput.get(`#${headerImageInputId}`);
    const headerImageCount = headerImageInput.getFileLength();
    if (!content && headerImageCount === 0) {
        setErrorMessage("상단 이미지를 선택해주세요.");
        return;
    } else if (content && content.headerImageCount === 0 && headerImageCount === 0) {
        setErrorMessage("상단 이미지를 선택해주세요.");
        return;
    }

    const descriptionImageInput = MaterialInput.get(`#${descriptionImageInputId}`);
    const descriptionImageCount = descriptionImageInput.getFileLength();
    if (!content && descriptionImageCount === 0) {
        setErrorMessage("설명 이미지를 선택해주세요.");
        return;
    } else if (content && content.descriptionImageCount === 0 && descriptionImageCount === 0) {
        setErrorMessage("설명 이미지를 선택해주세요.");
        return;
    }

    const onSucceed = () => {
        window.alert('저장되었습니다.');
        document.location = '/product/list';
    };

    const onUploadError = (content: ProductModal, succeeded: string[], error: string) => {
        MaterialModal.getOrNull('#error_modal')?.setOnDismiss(() => document.location = "/product/list");
        const succeededLine = (succeeded.isNotEmpty()) ? StringBuilder.joinToString(succeeded, ", ", "성공한 항목: ") : "";
        setErrorRecover([
            'AWS에 접속하여 오류가 발생한 이미지 또는 비디오를 추가해야 합니다. 필요하다면 폴더를 생성해야 할 수 있습니다.',
            succeededLine,
            StorageIO.createPath(`product/${content.id}`)
        ]);
        setErrorMessage(error);
    };

    const onReady = (content: ProductModal) => {
        StorageIO.uploadMaterialFiles(content, [
            { input: headerImageInput, path: ProductModal.headerImagePath, onSucceedText: '상단 이미지' },
            { input: descriptionImageInput, path: ProductModal.descriptionImagePath, onSucceedText: '설명 이미지' }
        ], onSucceed, (succeeded, error) => onUploadError(content, succeeded, error));
    };

    const onError = (error: string) => {
        setErrorRecover(error);
        setRootEnabled(true);
    };

    if (content) {
        const isUpdating = update(
            content, productBrandId, name, price, content.headerImageCount + headerImageCount,
            content.descriptionImageCount + descriptionImageCount, onReady, onError
        );
        setRootEnabled(!isUpdating);
    } else {
        ProductIO.post(productBrandId, name, price, headerImageCount, descriptionImageCount, onReady, onError);
        setRootEnabled(false);
    }
}

function update(
    content: ProductModal,
    productBrandId: number,
    name: string,
    price: number,
    headerImageCount: number,
    descriptionImageCount: number,
    onReady: OnObjectResponse<ProductModal>,
    onError: OnErrorResponse
): boolean {
    if (content.productBrandId === productBrandId &&
        content.name === name &&
        content.price === price) {
        onReady(content);
        return false;
    }

    ProductIO.update(
        content.id,
        productBrandId,
        name,
        price,
        headerImageCount,
        descriptionImageCount,
        onReady,
        onError,
    );
    return true;
}

type EditorProps = {
    contentState: StatePair<Optional<ProductModal>>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function Editor(props: EditorProps) {
    const [content] = props.contentState;

    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={productBrandInputId}
                inputType="number"
                inputClasses="validate"
                label="상품 브랜드 ID" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={nameInputId}
                inputClasses="validate"
                inputPlaceHolder="100자 이내"
                inputDataLength={100}
                label="이름" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={priceInputId}
                inputType="number"
                inputClasses="validate"
                label="가격" />
        </div>
        <div className="row cascade">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                inputId={headerImageInputId}
                inputLabel="상단 이미지 선택"
                multiple={true}
                accept="image/png" />
        </div>
        <div className="row cascade">
            <ImageWrapper
                contentState={props.contentState}
                setErrorMessage={props.setErrorMessage}
                setErrorRecover={props.setErrorRecover}
                setRootEnabled={props.setRootEnabled}
                imageCount={content?.headerImageCount}
                type="header"
                path={ProductModal.headerImagePath}
                onDeleteClicked={OnHeaderImageDeleteClicked} />
        </div>
        <div className="row cascade">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                inputId={descriptionImageInputId}
                inputLabel="설명 이미지 선택"
                multiple={true}
                accept="image/png" />
        </div>
        <div className="row cascade">
            <ImageWrapper
                contentState={props.contentState}
                setErrorMessage={props.setErrorMessage}
                setErrorRecover={props.setErrorRecover}
                setRootEnabled={props.setRootEnabled}
                imageCount={content?.descriptionImageCount}
                type="description"
                path={ProductModal.descriptionImagePath}
                onDeleteClicked={OnDescriptionImageDeleteClicked} />
        </div>
    </>;
}

type ImageWrapperProps = {
    contentState: StatePair<Optional<ProductModal>>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;

    imageCount?: number;
    type: string;
    path: (content: ProductModal, index: number) => string;
    onDeleteClicked: (
        contentState: StatePair<Optional<ProductModal>>,
        index: number,
        setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
        setErrorRecover: React.Dispatch<Optional<ErrorMessage>>,
        setRootEnabled: React.Dispatch<boolean>
    ) => void;
};

function ImageWrapper(props: ImageWrapperProps) {
    const [content] = props.contentState;
    let imageWrapper: JSX.Element;
    if (content && props.imageCount !== undefined) {
        const images = map(new IntRange(0, props.imageCount), index => {
            const onDeleteClick = () => props.onDeleteClicked(
                props.contentState,
                index,
                props.setErrorMessage,
                props.setErrorRecover,
                props.setRootEnabled
            );

            return <a key={index} className="collection-item">
                <a href={`/product/${content.id}/${props.type}/${index}.png`}>
                    {props.path(content, index)}
                </a>
                <a className="secondary-content clickable" onClick={onDeleteClick}>
                    <i className="material-icons">delete</i>
                </a>
            </a>;
        });
        imageWrapper = <div className="col s8 offset-s2 collection">{images}</div>;
    } else {
        imageWrapper = <></>;
    }

    return imageWrapper;
}

function OnHeaderImageDeleteClicked(
    contentState: StatePair<Optional<ProductModal>>,
    index: number,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const [content, setContent] = contentState;
    if (!content) {
        return;
    }

    const onUpdateResponse = (content: ProductModal) => {
        setContent(null);
        setContent(content);
        setRootEnabled(true);
    };

    const onUpdateError = (error: string) => {
        setErrorRecover([
            '데이터베이스에 다음 SQL을 실행해야 합니다: ',
            `UPDATE product SET header_image_count=${content.headerImageCount - 1} WHERE _id=${content.id}`
        ]);
        setErrorMessage([
            error,
            "AWS에서 파일 삭제를 성공했으나 데이터베이스 수정을 실패했습니다."
        ]);
        setRootEnabled(true);
    };

    const onCopyResponse = (responses: S3.CopyObjectOutput[]) => update(
        content,
        content.productBrandId,
        content.name,
        content.price,
        content.headerImageCount - 1,
        content.descriptionImageCount,
        onUpdateResponse,
        onUpdateError
    );

    const onCopyError = (error: string) => {
        setErrorRecover([
            'AWS에 접속하여 오류가 발생한 이미지를 수정해야 합니다. 필요하다면 폴더를 생성해야 할 수 있습니다.',
            StorageIO.createPath(ProductModal.headerImagePath(content))
        ]);
        setErrorMessage(error);
        setRootEnabled(true);
    };

    setRootEnabled(false);
    const map = fold(new IntRange(index, content.headerImageCount - 1), new Map<string, string>(), (acc, element) => {
        acc.set(ProductModal.headerImagePath(content, element + 1), ProductModal.headerImagePath(content, element));
        return acc;
    });

    StorageIO.copyObjects(map, onCopyResponse, onCopyError);
}

function OnDescriptionImageDeleteClicked(
    contentState: StatePair<Optional<ProductModal>>,
    index: number,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const [content, setContent] = contentState;
    if (!content) {
        return;
    }

    const onUpdateResponse = (content: ProductModal) => {
        setContent(null);
        setContent(content);
        setRootEnabled(true);
    };

    const onUpdateError = (error: string) => {
        setErrorRecover([
            '데이터베이스에 다음 SQL을 실행해야 합니다: ',
            `UPDATE product SET description_image_count=${content.descriptionImageCount - 1} WHERE _id=${content.id}`
        ]);
        setErrorMessage([
            error,
            "AWS에서 파일 삭제를 성공했으나 데이터베이스 수정을 실패했습니다."
        ]);
        setRootEnabled(true);
    };

    const onCopyResponse = (responses: S3.CopyObjectOutput[]) => update(
        content,
        content.productBrandId,
        content.name,
        content.price,
        content.headerImageCount,
        content.descriptionImageCount - 1,
        onUpdateResponse,
        onUpdateError
    );

    const onCopyError = (error: string) => {
        setErrorRecover([
            'AWS에 접속하여 오류가 발생한 이미지를 수정해야 합니다. 필요하다면 폴더를 생성해야 할 수 있습니다.',
            StorageIO.createPath(ProductModal.descriptionImagePath(content))
        ]);
        setErrorMessage(error);
        setRootEnabled(true);
    };

    setRootEnabled(false);
    const map = fold(new IntRange(index, content.descriptionImageCount - 1), new Map<string, string>(), (acc, element) => {
        acc.set(ProductModal.descriptionImagePath(content, element + 1), ProductModal.descriptionImagePath(content, element));
        return acc;
    });

    StorageIO.copyObjects(map, onCopyResponse, onCopyError);
}