import qs from "qs";
import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair, useBoolean} from "../../../util/Types";
import {UserSignInModal} from "../../../modal/Users";
import {BottomProgress, ErrorMessage, Nav} from "../../Common";
import {Dates} from "../../../util/Dates";
import {MaterialInput} from "../../Materials";
import {UserSignInIO} from "../../../io/Users";
import {getMaxLong} from "../../../util/Environments";

const searchInputId = 'user_sign_in_list_search';

type PageParams = {
    query?: string;
};

export default function List() {
    // TODO 검색창 기본값
    const {search} = useLocation();
    const parsed = qs.parse(search, { ignoreQueryPrefix: true }) as PageParams;

    const [userSignIns, setUserSignIns] = useState<Optional<UserSignInModal[]>>(null);
    const [query, setQuery] = useState<Optional<string>>(parsed.query ?? null);
    const [hasMoreContents, setMoreContents] = useState<boolean>(true);
    const isUpdating = useBoolean(false)
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    const onWindowScroll = () => {
        if (!isUpdating.value && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            PrepareUserSignIns(
                [userSignIns, setUserSignIns],
                query,
                [hasMoreContents, setMoreContents],
                [errorMessage, setErrorMessage]
            )
        }
    }

    useEffect(() => M.AutoInit());

    useEffect(() => PrepareUserSignIns(
        [userSignIns, setUserSignIns],
        query,
        [hasMoreContents, setMoreContents],
        [errorMessage, setErrorMessage]
    ), [query]);

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    })

    return <>
        <PageWrapper
            userSignInsState={[userSignIns, setUserSignIns]}
            queryState={[query, setQuery]}
            moreContentsState={[hasMoreContents, setMoreContents]}
            errorMessageState={[errorMessage, setErrorMessage]} />
    </>;
}

function PrepareUserSignIns(
    userSignInsState: StatePair<Optional<UserSignInModal[]>>,
    query: Optional<string>,
    moreContentsState: StatePair<boolean>,
    errorMessageState: StatePair<Optional<ErrorMessage>>
) {
    if (query) {
        PrepareByUserId(BigInt(query), userSignInsState, moreContentsState, errorMessageState);
    } else {
        PrepareAll(userSignInsState, moreContentsState, errorMessageState);
    }
}

function PrepareAll(
    userSignInsState: StatePair<Optional<UserSignInModal[]>>,
    moreContentsState: StatePair<boolean>,
    errorMessageState: StatePair<Optional<ErrorMessage>>
) {
    const [present, setUserSignIns] = userSignInsState;
    const [, setErrorMessage] = errorMessageState;
    const [, setMoreContents] = moreContentsState;
    const lastId = present?.lastOrNull()?.id ?? getMaxLong();

    const onResponse = (userSignIns: UserSignInModal[]) => {
        const appended: UserSignInModal[] = [];
        appended.push(...(present ?? []));
        appended.push(...userSignIns);
        setUserSignIns(appended);
        setMoreContents(userSignIns.length === 20);
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setMoreContents(false);
    };

    UserSignInIO.listAll(lastId, onResponse, onError);
}

function PrepareByUserId(
    userId: bigint,
    userSignInsState: StatePair<Optional<UserSignInModal[]>>,
    moreContentsState: StatePair<boolean>,
    errorMessageState: StatePair<Optional<ErrorMessage>>
) {
    const [present, setUserSignIns] = userSignInsState;
    const [, setErrorMessage] = errorMessageState;
    const [, setMoreContents] = moreContentsState;
    const lastId = present?.lastOrNull()?.id ?? getMaxLong();

    const onResponse = (userSignIns: UserSignInModal[]) => {
        const appended: UserSignInModal[] = [];
        appended.push(...(present ?? []));
        appended.push(...userSignIns);
        setUserSignIns(appended);
        setMoreContents(userSignIns.length === 20);
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setMoreContents(false);
    };

    UserSignInIO.listByUserId(userId, lastId, onResponse, onError);
}

type PageWrapperProps = {
    userSignInsState: StatePair<Optional<UserSignInModal[]>>;
    queryState: StatePair<Optional<string>>;
    moreContentsState: StatePair<boolean>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const [contents, setContents] = props.userSignInsState;
    const [hasMoreContents] = props.moreContentsState;
    const rows = (contents ?? []).map(userSignIn => <Row key={userSignIn.id.toString()} userSignIn={userSignIn} />);

    return <>
        <Nav
            title={"로그인 기록"}
            titleHref={"/"}
            titleIcon={"chevron_left"}
            excludeErrorModal={true} />
        <SearchWrapper
            setContents={setContents}
            queryStates={props.queryState} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>사용자 ID</th>
                <th>IP</th>
                <th>애플리케이션 버전 ID</th>
                <th>일시</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents} />
        </div>
    </>;
}

type SearchWrapperProps = {
    setContents: React.Dispatch<Optional<UserSignInModal[]>>;
    queryStates: StatePair<Optional<string>>;
};

function SearchWrapper(props: SearchWrapperProps) {
    const [query, setQuery] = props.queryStates;

    let searchIcon: JSX.Element;
    if (query) {
        const onClicked = () => OnSearchClearClicked(props.setContents, setQuery);
        searchIcon = <i className="material-icons prefix" style={{ cursor: "pointer" }} onClick={onClicked}>clear</i>;
    } else {
        const onClicked = () => OnSearchClicked(props.setContents, setQuery);
        searchIcon = <i className="material-icons prefix" style={{ cursor: "pointer" }} onClick={onClicked}>search</i>;
    }

    return <div className="row">
        <form className="col s6 offset-s3">
            <div className="input-field">
                {searchIcon}
                <input id={searchInputId} type="text" className="validate" />
                <label htmlFor={searchInputId}>사용자 ID 검색</label>
            </div>
        </form>
    </div>;
}

function OnSearchClearClicked(
    setContents: React.Dispatch<Optional<UserSignInModal[]>>,
    setQuery: React.Dispatch<Optional<string>>
) {
    setQuery(null);
    setContents(null);
    MaterialInput.get(`#${searchInputId}`).clearValue();
}

function OnSearchClicked(
    setContents: React.Dispatch<Optional<UserSignInModal[]>>,
    setQuery: React.Dispatch<Optional<string>>
) {
    setContents(null);
    setQuery(MaterialInput.get(`#${searchInputId}`).getValue());
}

type RowProps = {
    userSignIn: UserSignInModal;
};

function Row(props: RowProps) {
    const userId = props.userSignIn.userId.toString();
    const applicationVersionId = props.userSignIn.applicationVersionId;
    return <tr>
        <td>{props.userSignIn.id.toString()}</td>
        <td><a href={`/user/${userId}`}>{userId}</a></td>
        <td>{props.userSignIn.remoteAddress}</td>
        <td><a href={`/misc/application/version/list`}>{applicationVersionId}</a></td>
        <td>{props.userSignIn.signedInAt.toRowFormat(true, true, true)}</td>
    </tr>;
}