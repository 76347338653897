import {IntrinsicProperties} from "../../util/Properties";

type _CheckboxProps = {
    labelProps?: IntrinsicProperties.label
    inputProps?: IntrinsicProperties.input
    spanProps?: IntrinsicProperties.span

    title: string
    checked?: boolean
    onChange?: (value: boolean) => void
    disabled?: boolean
}

function _Checkbox(props: _CheckboxProps) {
    const title = (props.title !== undefined) ? <>
        <span>{props.title}</span>
    </> : <></>

    return <>
        <label {...props.labelProps}>
            <input
                type={'checkbox'}
                checked={props.checked}
                onChange={event => props.onChange?.(event.target.checked)}
                disabled={props.disabled} />
            {title}
        </label>
    </>
}

export type CheckboxProps = {
    wrapperProps?: IntrinsicProperties.div
    formProps?: IntrinsicProperties.form
} & _CheckboxProps

export function Checkbox(props: CheckboxProps) {
    const { wrapperProps, formProps, ...checkBoxProps } = props

    return <>
        <div {...wrapperProps}>
            <form {...formProps}>
                <_Checkbox {...checkBoxProps} />
            </form>
        </div>
    </>
}