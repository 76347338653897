import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {BankModal} from "../../../modal/Miscs";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {MiscBankIO} from "../../../io/Miscs";
import {Component, MaterialComponent, MaterialInput, MaterialModal} from "../../Materials";
import {StorageIO} from "../../../io/Services";
import {GifticonModal} from "../../../modal/Gifticons";
import {StringBuilder} from "../../../util/StringBuilder";

const pageId = 'bank_edit';
const nameInputId = `${pageId}_name`;
const iconInputId = `${pageId}_icon`;

export default function Edit() {
    const {bankId} = useParams() as { bankId?: number };
    const [content, setContent] = useState<Optional<BankModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        if (bankId) {
            MiscBankIO.get(bankId, setContent, setErrorMessage);
        }
    }, [bankId]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${nameInputId}`),
        ];
        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled]);

    useEffect(() => {
        if (content) {
            MaterialInput.getOrNull(`#${nameInputId}`)?.setValue(content.name);
        }
    }, [content]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contentState={[content, setContent]}
            errorMessageState={[errorMessage, setErrorMessage]}
            setRootEnabled={setRootEnabled} />
    </>;
}

type PageWrapperProps = {
    contentState: StatePair<Optional<BankModal>>;

    errorMessageState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [content] = props.contentState;
    const [, setErrorMessage] = props.errorMessageState;
    const onSaveClicked = () => OnSaveClicked(content, setErrorMessage, props.setRootEnabled);
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", () => document.location = "/misc/bank/list"]
    ];

    return <>
        <Nav
            title={"은행 " + ((content) ? "수정" : "추가")}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <Editor />
    </>;
}

function OnSaveClicked(
    content: Optional<BankModal>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const name = MaterialInput.get(`#${nameInputId}`).getValue();
    if (name.length.notIn(1, 41)) {
        setErrorMessage('이름을 확인해주세요.');
        return;
    }

    const iconInput = MaterialInput.get(`#${iconInputId}`);
    const iconCount = iconInput.getFileLength();
    if (!content && iconCount === 0) {
        setErrorMessage('아이콘을 선택해주세요.');
        return;
    }

    const onSucceed = () => {
        window.alert('저장되었습니다.');
        document.location = `/misc/bank/list`;
    };

    const onUploadError = (error: ErrorMessage) => {
        setRootEnabled(true);
        setErrorMessage([
            'AWS에 접속하여 오류가 발생한 이미지 또는 비디오를 추가해야 합니다.',
            `오류: ${error}`,
            StorageIO.createPath('misc/bank')
        ]);
    }

    const onImageReady = (buffer: ArrayBuffer, content: BankModal) => {
        StorageIO.upload(
            BankModal.iconKey(content.id),
            buffer,
            onSucceed,
            onUploadError
        );
    };

    const onReady = (content: BankModal) => {
        if (iconCount === 0) {
            onSucceed();
        } else {
            iconInput.readFileBuffer(
                buffer => onImageReady(buffer, content),
                onUploadError
            );
        }
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    if (!content) {
        setRootEnabled(false);
        MiscBankIO.post(name, onReady, onError);
    } else {
        if (content && content.name === name) {
            onReady(content);
            return;
        }

        setRootEnabled(false);
        MiscBankIO.update(content.id, name, onReady, onError);
    }
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={nameInputId}
                inputClasses="validate"
                inputPlaceHolder="40자 이내"
                inputDataLength={40}
                label="이름" />
        </div>
        <div className="row cascade">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                inputId={iconInputId}
                inputLabel="아이콘 선택"
                multiple={false}
                accept="image/png" />
        </div>
    </>;
}