import {Optional, useBoolean, useWrapper} from "../../../util/Types";
import {OnArrayResponse, OnErrorResponse} from "../../../util/Reponses";
import {UserPerfectModal} from "../../../modal/Admins";
import {UserPerfectIO} from "../../../io/Admins";
import {BottomProgress, ErrorMessage, Nav} from "../../Common";
import {getMaxLong} from "../../../util/Environments";
import React, {useEffect} from "react";

type Fetch = (
    lastUserId: bigint,
    lastOrderCount: bigint,
    accuracy: number,
    onReady: OnArrayResponse<UserPerfectModal>,
    onError: OnErrorResponse
) => void
type FetchContent = {
    cancel: boolean,
    invoke(): void
}
type LastOrderCountSelector = (content: UserPerfectModal) => bigint

enum SortBy {
    adAll, adMonth, m10nAll, m10nMonth, totalAll, totalMonth
}

enum OrderBy {
    ascending, descending
}

function fetch(sort: SortBy, order: OrderBy): Fetch {
    switch (order) {
        case OrderBy.ascending:
            switch (sort) {
                case SortBy.adAll: return UserPerfectIO.listAscendingByAdAll
                case SortBy.adMonth: return UserPerfectIO.listAscendingByAdMonth
                case SortBy.m10nAll: return UserPerfectIO.listAscendingByM10nAll
                case SortBy.m10nMonth: return UserPerfectIO.listAscendingByM10nMonth
                case SortBy.totalAll: return UserPerfectIO.listAscendingByTotalAll
                case SortBy.totalMonth: return UserPerfectIO.listAscendingByTotalMonth
            }
            break
        case OrderBy.descending:
            switch (sort) {
                case SortBy.adAll: return UserPerfectIO.listDescendingByAdAll
                case SortBy.adMonth: return UserPerfectIO.listDescendingByAdMonth
                case SortBy.m10nAll: return UserPerfectIO.listDescendingByM10nAll
                case SortBy.m10nMonth: return UserPerfectIO.listDescendingByM10nMonth
                case SortBy.totalAll: return UserPerfectIO.listDescendingByTotalAll
                case SortBy.totalMonth: return UserPerfectIO.listDescendingByTotalMonth
            }
            break
    }
}

function lastOrderCountSelector(sort: SortBy): LastOrderCountSelector {
    switch (sort) {
        case SortBy.adAll: return (content: UserPerfectModal) => content.adAll
        case SortBy.adMonth: return (content: UserPerfectModal) => content.adMonth
        case SortBy.m10nAll: return (content: UserPerfectModal) => content.m10nAll
        case SortBy.m10nMonth: return (content: UserPerfectModal) => content.m10nMonth
        case SortBy.totalAll: return (content: UserPerfectModal) => content.totalAll
        case SortBy.totalMonth: return (content: UserPerfectModal) => content.totalMonth
    }
}

export function List() {
    const sortBy = useWrapper<SortBy>(SortBy.adAll)
    const orderBy = useWrapper<OrderBy>(OrderBy.descending)
    const contents = useWrapper<UserPerfectModal[]>([])
    const currentFetch = useWrapper<Optional<FetchContent>>(null)
    const hasMoreContents = useBoolean(true)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)
    useEffect(() => M.AutoInit())
    useEffect(() => {
        contents.set([])
        fetchContents().invoke()
    }, [sortBy.value, orderBy.value])
    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    })

    const fetchContents = () => {
        return {
            cancel: false,
            invoke() {
                if (currentFetch.value !== null) {
                    currentFetch.value.cancel = true
                }

                currentFetch.set(this)
                const last = contents.value.lastOrNull()
                const lastUserId = last?.user.id ?? 0n
                const lastOrderCount = (last === null)
                    ? (orderBy.value === OrderBy.ascending) ? 0n : getMaxLong()
                    : lastOrderCountSelector(sortBy.value)(last)
                fetch(sortBy.value, orderBy.value)(
                    lastUserId,
                    lastOrderCount,
                    99,
                    response => {
                        if (this.cancel) {
                            return
                        }

                        contents.set(contents.value.appended(response))
                        hasMoreContents.set(response.length >= 20)
                        currentFetch.set(null)
                    },
                    error => {
                        if (this.cancel) {
                            return
                        }

                        hasMoreContents.setFalse()
                        errorMessage.set(error)
                        currentFetch.set(null)
                    }
                )
            }
        }
    }

    const onWindowScroll = () => {
        if (currentFetch.value === null && hasMoreContents.value && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            fetchContents().invoke()
        }
    }

    const rows = contents.value.map(value => <>
        <tr>
            <td><a href={`/user/${value.user.id}`}>{value.user.id.toString()}</a></td>
            <td>{value.adAll.toString()}</td>
            <td>{value.adMonth.toString()}</td>
            <td>{value.m10nAll.toString()}</td>
            <td>{value.m10nMonth.toString()}</td>
            <td>{value.totalAll.toString()}</td>
            <td>{value.totalMonth.toString()}</td>
        </tr>
    </>)

    type TableHeaderProps = {
        title: string
        sort: SortBy
    }

    function TableHeader(props: TableHeaderProps) {
        const arrow = (props.sort === sortBy.value)
            ? (orderBy.value === OrderBy.ascending) ? ' ↑' : ' ↓'
            : ''
        const onClick = () => {
            if (sortBy.value === props.sort) {
                switch (orderBy.value) {
                    case OrderBy.ascending:
                        orderBy.set(OrderBy.descending)
                        break
                    case OrderBy.descending:
                        orderBy.set(OrderBy.ascending)
                        break
                }
            } else {
                sortBy.set(props.sort)
                orderBy.set(OrderBy.descending)
            }
            contents.set([])
            fetchContents().invoke()
        }

        return <>
            <th><a onClick={onClick}>{props.title + " " + arrow}</a></th>
        </>
    }

    const currentMonth = new Date().getMonth() + 1

    return <>
        <Nav
            title={'발음왕'}
            titleHref={'/'}
            titleIcon={'chevron_left'} />
        <table className={'centered highlight'}>
            <thead>
            <tr>
                <th>사용자 ID</th>
                <TableHeader title={'광고(전체)'} sort={SortBy.adAll} />
                <TableHeader title={`광고(${currentMonth}월)`} sort={SortBy.adMonth} />
                <TableHeader title={'암기플러스(전체)'} sort={SortBy.m10nAll} />
                <TableHeader title={`암기플러스(${currentMonth}월)`} sort={SortBy.m10nMonth} />
                <TableHeader title={'합계(전체)'} sort={SortBy.totalAll} />
                <TableHeader title={`합계(${currentMonth}월)`} sort={SortBy.totalMonth} />
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents.value} />
        </div>
    </>
}

