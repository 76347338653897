import {Route, Routes} from "react-router-dom";
import {List} from "./List";
import {PostSheet} from "./PostSheet";
import {Edit} from "./Edit";
import {Post} from "./Post";
import {Detail} from "./Detail";
import {ParticipationRouter} from "./participation/ParticipationRouter";
import {ReviewRouter} from "./review/ReviewRouter";

export function M10nRouter() {
    return <>
        <Routes>
            <Route path={'/post'} element={<Post />} />
            <Route path={'/post/sheet'} element={<PostSheet />} />
            <Route path={'/list'} element={<List />} />
            <Route path={'/:id'} element={<Detail />} />
            <Route path={'/:id/edit'} element={<Edit />} />
            <Route path={'/participation/*'} element={<ParticipationRouter />} />
            <Route path={'/review/*'} element={<ReviewRouter />} />
        </Routes>
    </>
}