import {useParams} from "react-router-dom";
import {Optional, parseIntOrNull, StateWrapper, wrapState} from "../../../util/Types";
import React, {useEffect, useState} from "react";
import {GifticonBrandModal} from "../../../modal/Gifticons";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {Component, MaterialComponent, MaterialInput, MaterialModal} from "../../Materials";
import {GifticonBrandIO} from "../../../io/Gifticons";

const pageId = 'gifticon_brand_edit';
const nameInputId = `${pageId}_title`;

type Params = {
    id?: string;
};

export default function Edit() {
    const params = useParams<Params>();
    const contentState = wrapState(useState<Optional<GifticonBrandModal>>(null));
    const errorMessageState = wrapState(useState<Optional<ErrorMessage>>(null));
    const errorRecoverState = wrapState(useState<Optional<ErrorMessage>>(null));
    const rootEnabledState = wrapState(useState<boolean>(true));

    useEffect(() => {
        MaterialModal.init();
        MaterialInput.init();
    });

    useEffect(() => {
        const id = PrepareContentId(params.id);
        if (id === null) {
            return;
        }

        PrepareContent(id, contentState.set, errorMessageState.set);
    }, [params.id]);

    useEffect(() => {
        const content = contentState.value;
        if (content === null) {
            return;
        }

        MaterialInput.getOrNull(`#${nameInputId}`)?.setValue(content.name.toString());
    }, [contentState.value]);

    useEffect(() => {
        const isRootEnabled = rootEnabledState.value;
        const inputs = [MaterialInput.getOrNull(`#${nameInputId}`)];

        if (isRootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [contentState.value, rootEnabledState.value]);

    useEffect(() => {
        if (errorMessageState.value) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessageState.value]);

    return <PageWrapper
        content={contentState.value}
        errorMessageState={errorMessageState}
        errorRecoverState={errorRecoverState}
        setRootEnabled={rootEnabledState.set} />;
}

function PrepareContentId(rawId: string | undefined): Optional<number> {
    if (rawId === undefined) {
        return null;
    }

    const id = parseIntOrNull(rawId);
    if (id === null) {
        window.alert('잘못된 접근입니다.');
        window.history.back();
        return null;
    } else {
        return id;
    }
}

function PrepareContent(
    id: number,
    setContent: React.Dispatch<Optional<GifticonBrandModal>>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    GifticonBrandIO.get(id, setContent, setErrorMessage);
}

type PageWrapperProps = {
    content: Optional<GifticonBrandModal>,

    errorMessageState: StateWrapper<Optional<ErrorMessage>>,
    errorRecoverState: StateWrapper<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
};

function PageWrapper(props: PageWrapperProps) {
    const onSaveClicked = () => OnSaveClicked(props);
    const onCancelClicked = () => OnCancelClick();
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", onCancelClicked]
    ];

    return <>
        <Nav
            titleHref='/gifticon/brand/list'
            titleIcon="chevron_left"
            title={"기프티콘 " + ((props.content) ? "수정" : "추가")}
            barItems={barItems}
            errorMessageState={props.errorMessageState}
            errorRecoverState={props.errorRecoverState}
            onErrorDismiss={() => document.location = '/gifticon/brand/list'} />
        <Editor />
    </>;
}

function OnSaveClicked(props: PageWrapperProps) {
    const content = props.content;

    const name = MaterialInput.get(`#${nameInputId}`).getValue();
    if (name.isEmpty()) {
        props.errorMessageState.set("이름을 입력해주세요.");
        return;
    }

    const onReady = () => {
        window.alert('저장되었습니다.');
        document.location = '/gifticon/brand/list';
    };

    const onError = (error: string) => {
        props.errorMessageState.set(error);
        props.setRootEnabled(true);
    };

    if (content && content.name === name) {
        onReady();
        return;
    }

    if (content) {
        GifticonBrandIO.update(content.id, name, onReady, onError);
    } else {
        GifticonBrandIO.post(name, onReady, onError);
    }
}

function OnCancelClick() {
    document.location = '/gifticon/brand/list';
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={nameInputId}
                inputClasses="validate"
                inputPlaceHolder="100자 이내"
                inputDataLength={100}
                label="제목" />
        </div>
    </>;
}












