import {useParams} from "react-router-dom";
import {Optional, StateWrapper, unwrap, useBoolean, useWrapper} from "../../../../util/Types";
import {HomeBestModal} from "../../../../modal/Miscs";
import {ErrorMessage, Nav, NavBarItemAction} from "../../../Common";
import {useEffect} from "react";
import {useEffectOptional} from "../../../../util/Hooks";
import {MiscHomeBestIO} from "../../../../io/Miscs";
import {MaterialModal} from "../../../Materials";
import {StorageIO} from "../../../../io/Services";
import {TextField} from "../../../common/TextField";
import {FileField} from "../../../common/FileField";

export function Edit() {
    const { id } = useParams<{ id?: string }>()
    const content = useWrapper<Optional<HomeBestModal>>(null)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)

    useEffect(() => M.AutoInit())
    useEffectOptional(id => MiscHomeBestIO.getById(Number(id), content.set, errorMessage.set), id)
    useEffect(() => {
        if (errorMessage.value) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage.value]);

    return (content.value && <>
        <Editor
            content={content.value}
            errorMessage={errorMessage} />
    </>)
}

type EditorProps = {
    content: HomeBestModal
    errorMessage: StateWrapper<Optional<ErrorMessage>>
}

function Editor(props: EditorProps) {
    const adId = useWrapper<Optional<number>>(props.content.adId)
    const m10nId = useWrapper<Optional<number>>(props.content.m10nId)
    const noticeId = useWrapper<Optional<number>>(props.content.noticeId)
    const sortOrder = useWrapper<number>(props.content.sortOrder)
    const image = useWrapper<Optional<File>>(null)
    const disabled = useBoolean(false)

    const getId = (state: StateWrapper<Optional<number>>) => (state.value === null || state.value === 0) ? null : state.value

    const onSaveClick = () => {
        let enteredIds = 0
        enteredIds += (getId(adId) === null) ? 0 : 1
        enteredIds += (getId(m10nId) === null) ? 0 : 1
        enteredIds += (getId(noticeId) === null) ? 0 : 1
        if (enteredIds >= 2) {
            props.errorMessage.set('대상 ID는 모두 입력하지 않거나 하나만 입력해주세요.')
            return
        }
        if (sortOrder.value < 1 || sortOrder.value > 3) {
            props.errorMessage.set('표시 순서를 확인해주세요.')
            return
        }

        disabled.setTrue()
        put()
    }

    const put = () => MiscHomeBestIO.put(props.content.id, {
        adId: getId(adId),
        m10nId: getId(m10nId),
        noticeId: getId(noticeId),
        sortOrder: sortOrder.value
    }, onPut, onPutError)

    const onPut = (response: HomeBestModal) => {
        if (image.value === null) {
            onUploaded()
            return
        }

        StorageIO.uploadFileSync(HomeBestModal.imagePath(response), image.value)
            .then(onUploaded)
            .catch(onUploadError)
    }

    const onPutError = (error: ErrorMessage) => {
        disabled.setFalse()
        props.errorMessage.set(error)
    }

    const onUploaded = () => {
        alert('수정되었습니다.')
        document.location = `/misc/home/best/${props.content.id}`
    }

    const onUploadError = (error: ErrorMessage) => {
        disabled.setFalse()
        props.errorMessage.set(error)
    }

    const onCancelClick = () => document.location = `/misc/home/best/${props.content.id}`

    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClick],
        ["취소", onCancelClick]
    ];

    return <>
        <Nav
            title={'홈 베스트 수정'}
            titleIcon={'chevron_left'}
            titleHref={`/misc/home/best/list`}
            barItems={barItems}
            errorMessageState={props.errorMessage} />
        <div className={'row cascade'} style={{ marginTop: "2rem" }}>
            <TextField
                label={'광고 ID 또는 0을 입력하여 무시'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: adId.value?.toString() ?? '', disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => adId.set(Number(value))} />
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'암기플러스 ID 또는 0을 입력하여 무시'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: m10nId.value?.toString() ?? '', disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => m10nId.set(Number(value))} />
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'공지사항 ID 또는 0을 입력하여 무시'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: noticeId.value?.toString() ?? '', disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => noticeId.set(Number(value))} />
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'표시 순서(1이상, 3이하)'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: sortOrder.value.toString() ?? '', disabled: disabled.value, type: 'number', min: 1, max: 3 }}
                onValueChange={(event, value) => sortOrder.set(Number(value))} />
        </div>
        <div className={'row cascade'}>
            <FileField
                formProps={{ className: 'col s8 offset-s2' }}
                title={'이미지'}
                accept={'image/png'}
                multiple={false}
                onChange={files => unwrap(files, files => unwrap(files?.item(0), image.set))} />
        </div>
        <div className={'row cascade'}>
            <StorageIO.StorageImage
                className={'col offset-s2'}
                style={{ maxWidth: '100%' }}
                objectKey={HomeBestModal.imagePath(props.content)} />
        </div>
    </>
}