import {Route, Routes} from "react-router-dom";
import {AssertSignIn} from "../../App";
import List from "./List";
import Edit from "./Edit";
import Detail from "./Detail";
import BrandRouter from "./brand/BrandRouter";
import Image from "./Image";
import DescriptionRouter from "./description/DescriptionRouter";
import OptionRouter from "./option/OptionRouter";
import DeliveryRouter from "./delivery/DeliveryRouter";
import ReviewRouter from "./review/ReviewRouter";

export default function ProductRouter() {
    return <Routes>
        <Route path="/list" element={<AssertSignIn><List /></AssertSignIn>} />
        <Route path="/post" element={<AssertSignIn><Edit /></AssertSignIn>} />
        <Route path="/:productId" element={<AssertSignIn><Detail /></AssertSignIn>} />
        <Route path="/:productId/edit" element={<AssertSignIn><Edit /></AssertSignIn>} />
        <Route path="/:productId/:imageType/:index.png" element={<AssertSignIn><Image /></AssertSignIn>} />
        <Route path="/:productId/:imageType/:index.png" element={<AssertSignIn><Image /></AssertSignIn>} />

        <Route path="/brand/*" element={<AssertSignIn><BrandRouter /></AssertSignIn>} />
        <Route path="/delivery/*" element={<AssertSignIn><DeliveryRouter /></AssertSignIn>} />
        <Route path="/:productId/delivery/*" element={<AssertSignIn><DeliveryRouter /></AssertSignIn>} />
        <Route path="/description/*" element={<AssertSignIn><DescriptionRouter /></AssertSignIn>} />
        <Route path="/:productId/description/*" element={<AssertSignIn><DescriptionRouter /></AssertSignIn>} />
        <Route path="/option/*" element={<AssertSignIn><OptionRouter /></AssertSignIn>} />
        <Route path="/:productId/option/*" element={<AssertSignIn><OptionRouter /></AssertSignIn>} />
        <Route path="/review/*" element={<AssertSignIn><ReviewRouter /></AssertSignIn>} />
    </Routes>;
}