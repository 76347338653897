import qs from "qs";
import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {UserBlockModal} from "../../../modal/Users";
import {BottomProgress, ErrorMessage, Nav} from "../../Common";
import {MaterialInput, MaterialModal} from "../../Materials";
import {UserBlockIO} from "../../../io/Users";
import {getMaxInt} from "../../../util/Environments";

const pageId = 'user_block_list'
const searchInputId = `${pageId}_search`;
const deleteModalId = `${pageId}_delete`;

type PageParams = {
    query?: string;
};

export default function List() {
    // TODO 검색창 기본값
    const {search} = useLocation();
    const parsed = qs.parse(search, { ignoreQueryPrefix: true }) as PageParams;

    const [lock, setLock] = useState<number>(0);
    const [userBlocks, setUserBlocks] = useState<Optional<UserBlockModal[]>>(null);
    const [selectedUser, selectUser] = useState<Optional<UserBlockModal>>(null);
    const [query, setQuery] = useState<Optional<string>>(parsed.query ?? null);
    const [hasMoreContents, setMoreContents] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
        MaterialModal.init();
    });

    useEffect(() => PrepareUserBlocks(
        [userBlocks, setUserBlocks],
        query,
        [hasMoreContents, setMoreContents],
        [errorMessage, setErrorMessage]
    ), [lock, query]);

    return <>
        <PageWrapper
            lockState={[lock, setLock]}
            userBlocksState={[userBlocks, setUserBlocks]}
            selectedUserState={[selectedUser, selectUser]}
            queryState={[query, setQuery]}
            moreContentsState={[hasMoreContents, setMoreContents]}
            errorMessageState={[errorMessage, setErrorMessage]} />
    </>;
}

function PrepareUserBlocks(
    userBlocksState: StatePair<Optional<UserBlockModal[]>>,
    query: Optional<string>,
    moreContentsState: StatePair<boolean>,
    errorMessageState: StatePair<Optional<ErrorMessage>>
) {
    const [present, setUserBlocks] = userBlocksState;
    const [, setErrorMessage] = errorMessageState;
    const [, setMoreContents] = moreContentsState;

    const onResponse = (userBlocks: UserBlockModal[]) => {
        const appended: UserBlockModal[] = [];
        appended.push(...(present ?? []));
        appended.push(...userBlocks);
        setUserBlocks(appended);
        setMoreContents(userBlocks.length === 20);
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setMoreContents(false);
    };

    if (query) {
        const userId = BigInt(query);
        UserBlockIO.listByUserId(BigInt(userId), onResponse, onError);
    } else {
        const lastId = present?.lastOrNull()?.id ?? getMaxInt();
        UserBlockIO.listDescending(lastId, onResponse, onError);
    }
}

type PageWrapperProps = {
    lockState: StatePair<number>,
    userBlocksState: StatePair<Optional<UserBlockModal[]>>;
    selectedUserState: StatePair<Optional<UserBlockModal>>;
    queryState: StatePair<Optional<string>>;
    moreContentsState: StatePair<boolean>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const [contents, setContents] = props.userBlocksState;
    const [selectedContent, selectContent] = props.selectedUserState;
    const [, setQuery] = props.queryState;
    const [hasMoreContents] = props.moreContentsState;
    const [, setErrorMessage] = props.errorMessageState;
    const rows = (contents ?? []).map(userBlock =>
        <Row
            key={userBlock.id.toString()}
            content={userBlock}
            selectContent={selectContent} />
    );

    return <>
        <Nav
            title="이용 정지"
            titleHref="/"
            titleIcon="chevron_left"
            excludeErrorModal={true} />
        <SearchWrapper
            setContents={setContents}
            queryStates={props.queryState} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>사용자 ID</th>
                <th>사유 ID</th>
                <th>정지 일시</th>
                <th>해제 일시</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="fixed-action-btn">
            <a className="btn-floating btn-large secondary" href='/user/block/post'><i className="large material-icons">add</i></a>
        </div>
        <BottomProgress hasMoreContents={hasMoreContents} />
        <DeleteModal
            setContents={setContents}
            selectedContent={selectedContent}
            lockState={props.lockState}
            setQuery={setQuery}
            setErrorMessage={setErrorMessage} />
    </>;
}

type SearchWrapperProps = {
    setContents: React.Dispatch<Optional<UserBlockModal[]>>;
    queryStates: StatePair<Optional<string>>;
};

function SearchWrapper(props: SearchWrapperProps) {
    const [query, setQuery] = props.queryStates;

    let searchIcon: JSX.Element;
    if (query) {
        const onClicked = () => OnSearchClearClicked(props.setContents, setQuery);
        searchIcon = <i className="material-icons prefix" style={{ cursor: "pointer" }} onClick={onClicked}>clear</i>;
    } else {
        const onClicked = () => OnSearchClicked(props.setContents, setQuery);
        searchIcon = <i className="material-icons prefix" style={{ cursor: "pointer" }} onClick={onClicked}>search</i>;
    }

    return <div className="row">
        <form className="col s6 offset-s3">
            <div className="input-field">
                {searchIcon}
                <input id={searchInputId} type="text" className="validate" />
                <label htmlFor={searchInputId}>사용자 ID 검색</label>
            </div>
        </form>
    </div>;
}

function OnSearchClearClicked(
    setContents: React.Dispatch<Optional<UserBlockModal[]>>,
    setQuery: React.Dispatch<Optional<string>>
) {
    setQuery(null);
    setContents(null);
    MaterialInput.get(`#${searchInputId}`).clearValue();
}

function OnSearchClicked(
    setContents: React.Dispatch<Optional<UserBlockModal[]>>,
    setQuery: React.Dispatch<Optional<string>>
) {
    setContents(null);
    setQuery(MaterialInput.get(`#${searchInputId}`).getValue());
}

type RowProps = {
    content: UserBlockModal;
    selectContent: React.Dispatch<Optional<UserBlockModal>>;
};

function Row(props: RowProps) {
    const userId = props.content.userId.toString();
    const causeId = props.content.causeId;
    const blockedUntil = (props.content.blockedUntil) ? props.content.blockedUntil.toRowFormat(true) : "영구 정지";
    const onDeleteClicked = () => props.selectContent(props.content);
    return <tr>
        <td>{props.content.id.toString()}</td>
        <td><a href={`/user/${userId}`}>{userId}</a></td>
        <td><a href={`/misc/user/block/cause/${causeId}`}>{causeId}</a></td>
        <td>{props.content.blockedAt.toRowFormat(true)}</td>
        <td>{blockedUntil}</td>
        <td>
            <a href={`/user/block/${props.content.id}/edit`} className="modal-trigger clickable" onClick={onDeleteClicked}>
                <i className="material-icons black-text">edit</i>
            </a>
            <a href={`#${deleteModalId}`} className="modal-trigger clickable" onClick={onDeleteClicked}>
                <i className="material-icons black-text">clear</i>
            </a>
        </td>
    </tr>;
}

type DeleteModalProps = {
    setContents: React.Dispatch<Optional<UserBlockModal[]>>;
    selectedContent: Optional<UserBlockModal>;
    lockState: StatePair<number>;
    setQuery: React.Dispatch<Optional<string>>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function DeleteModal(props: DeleteModalProps) {
    const onDeleteClicked = () => {
        if (props.selectedContent) {
            OnDeleteConfirmClicked(props.setContents, props.selectedContent, props.lockState, props.setQuery, props.setErrorMessage);
        }
    };

    return <>
        <div id={deleteModalId} className="modal">
            <div className="modal-content">
                <h4>해제</h4>
                <p>이용 정지를 해제하시겠습니까?</p>
            </div>
            <div className="modal-footer">
                <a className="waves-effect btn-flat primary-text" onClick={onDeleteClicked}>예</a>
                <a className="modal-close waves-effect btn-flat primary-text">아니오</a>
            </div>
        </div>
    </>;
}

function OnDeleteConfirmClicked(
    setContents: React.Dispatch<Optional<UserBlockModal[]>>,
    content: UserBlockModal,
    lockState: StatePair<number>,
    setQuery: React.Dispatch<Optional<string>>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const [lock, setLock] = lockState;
    const modal = MaterialModal.get(`#${deleteModalId}`)
    const onReady = () => {
        modal.setCancelable(true);
        modal.close();
        setContents(null);
        setQuery(null);
        setLock(lock + 1);
    };
    const onError = (error: string) => {
        setErrorMessage(error);
        modal.setCancelable(true);
        modal.close();
    };

    modal.setCancelable(false);
    UserBlockIO.delete(content.userId, content.id, onReady, onError);
}
