import {ErrorMessage, Nav, NavBarItemAction} from "../Common";
import {Optional, useBoolean, useWrapper} from "../../util/Types";
import {useEffect, useId} from "react";
import {AwsKeyManager, Logger} from "../../util/Environments";
import * as XLSX from 'xlsx';
import {M10nModal} from "../../modal/M10ns";
import {M10nIO} from "../../io/M10n";
import S3 from "aws-sdk/clients/s3";
import {StorageIO} from "../../io/Services";
import {SequentialPostError, SequentialPostResponse} from "../../util/Requests";
import {AWSError} from "aws-sdk";

type PostResponse = SequentialPostResponse<M10nModal & { hashTagIndex: number }>
type CopyResponse = PostResponse & { output: S3.PutObjectOutput }
type CopyError = PostResponse & { error: AWSError, copyType: (0 | 1 | 2 | 3) }

export function PostSheet() {
    const fileInputId = useId()
    const file = useWrapper<Optional<File>>(null)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)
    const disabled = useBoolean(false)

    useEffect(() => {
        AwsKeyManager.getKeyPair((accessKeyId, secretAccessKey) => console.log(`${accessKeyId}, ${secretAccessKey}`))
    }, [])

    const onSaveClick = () => {
        const fileValue = file.value
        if (fileValue === null) {
            return
        }

        disabled.setTrue()
        fileValue.arrayBuffer()
            .then(onBufferReady)
            .catch(onBufferError)
    }

    const onCancelClick = () => document.location = '/m10n/list'

    const onBufferReady = (arrayBuffer: ArrayBuffer) => {
        const fileInformation = XLSX.read(arrayBuffer, {
            type: 'buffer',
            cellText: false,
            cellDates: true
        })
        const sheetName = fileInformation.SheetNames[0]
        const sheet = fileInformation.Sheets[sheetName]
        const json = XLSX.utils.sheet_to_json(sheet)
        post(json)
    }

    const onBufferError = (reason: any) => {
        Logger.error(reason)
        disabled.setFalse()
        errorMessage.set('파일 읽기를 실패했습니다.')
    }

    const post = (json: any[]) => {
        if (json.isEmpty()) {
            disabled.setFalse()
            errorMessage.set('시트에 입력된 행이 없습니다.')
            return
        }

        M10nIO.postSheet(
            json,
            onPosted
        )
    }

    const onPosted = (
        postResponses: PostResponse[],
        postErrors: SequentialPostError[]
    ) => {
        if (postResponses.isEmpty()) {
            onPostError(postErrors)
            return
        }

        const thumbnails: { index: number, output: S3.GetObjectOutput }[] = []
        const headers: { index: number, output: S3.GetObjectOutput }[] = []
        const descriptions: { index: number, output: S3.GetObjectOutput }[] = []
        const videos: { index: number, output: S3.GetObjectOutput }[] = []
        const onProgress = () => {
            if (thumbnails.length === 19 && headers.length === 19 && descriptions.length === 19 && videos.length === 19) {
                upload(
                    postResponses,
                    thumbnails.sort((a, b) => a.index - b.index).map(value => value.output),
                    headers.sort((a, b) => a.index - b.index).map(value => value.output),
                    descriptions.sort((a, b) => a.index - b.index).map(value => value.output),
                    videos.sort((a, b) => a.index - b.index).map(value => value.output)
                )
            }
        }

        for (let i = 1; i < 20; i++) {
            StorageIO.getObject(`m10n/hashTag/thumbnail/${i}.png`, (error, data) => {
                thumbnails.push({ index: i, output: data })
                onProgress()
            })
            StorageIO.getObject(`m10n/hashTag/header/${i}.png`, (error, data) => {
                headers.push({ index: i, output: data })
                onProgress()
            })
            StorageIO.getObject(`m10n/hashTag/description/${i}.png`, (error, data) => {
                descriptions.push({ index: i, output: data })
                onProgress()
            })
            StorageIO.getObject(`m10n/hashTag/content/${i}.mp4`, (error, data) => {
                videos.push({ index: i, output: data })
                onProgress()
            })
        }
    }

    const onPostError = (postErrors: SequentialPostError[]) => {
        disabled.setFalse()
        Logger.error('오류', ...postErrors)
        errorMessage.set('추가를 실패했습니다.')
    }

    const upload = (
        postResponses: PostResponse[],
        thumbnails: S3.GetObjectOutput[],
        headers: S3.GetObjectOutput[],
        descriptions: S3.GetObjectOutput[],
        videos: S3.GetObjectOutput[],
    ) => {
        const copyResponses: CopyResponse[] = []
        const copyErrors: CopyError[] = []
        const onProgress = () => {
            if ((copyResponses.length + copyErrors.length) === postResponses.length * 4) {
                onUploaded()
            }
        }

        for (const response of postResponses) {
            StorageIO.putObject(
                response.response.thumbnailImageKey(),
                thumbnails[response.response.hashTagIndex - 1].Body!,
                output => { copyResponses.push({ ...response, output }); onProgress() },
                error => { copyErrors.push({ ...response, error, copyType: 0 }); onProgress() }
            )
            StorageIO.putObject(
                response.response.headerImageKey(0),
                headers[response.response.hashTagIndex - 1].Body!,
                output => { copyResponses.push({ ...response, output }); onProgress() },
                error => { copyErrors.push({ ...response, error, copyType: 1 }); onProgress() }
            )
            StorageIO.putObject(
                response.response.descriptionImageKey(0),
                descriptions[response.response.hashTagIndex - 1].Body!,
                output => { copyResponses.push({ ...response, output }); onProgress() },
                error => { copyErrors.push({ ...response, error, copyType: 2 }); onProgress() }
            )
            StorageIO.putObject(
                response.response.contentVideoKey(),
                videos[response.response.hashTagIndex - 1].Body!,
                output => { copyResponses.push({ ...response, output }); onProgress() },
                error => { copyErrors.push({ ...response, error, copyType: 3 }); onProgress() }
            )
        }
    }

    const onUploaded = () => {
        alert('추가되었습니다.')
        document.location = '/m10n/list'
    }

    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClick],
        ["취소", onCancelClick]
    ]

    return <>
        <Nav
            titleHref={'/m10n/list'}
            titleIcon={'chevron_left'}
            title={'암기플러스 추가(스프레드시트)'}
            barItems={barItems}
            errorMessageState={errorMessage} />
        <div className={'row cascade'}>
            <form className={'col s8 offset-s2'}>
                <div className={'file-field input-field'}>
                    <div className={'btn'}>
                        <span>파일 선택</span>
                        <input
                            id={fileInputId}
                            type={'file'}
                            accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                            disabled={disabled.value}
                            multiple={false}
                            onChange={event => file.set(event.target.files?.item(0) ?? null)}/>
                    </div>
                    <div className={'file-path-wrapper'}>
                        <input
                            className={'file-path'}
                            type={'text'}
                            disabled={disabled.value} />
                    </div>
                </div>
            </form>
        </div>
    </>
}