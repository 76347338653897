import {useParams} from "react-router-dom";
import {Optional, parseIntOrNull, StateWrapper, wrapState} from "../../../util/Types";
import React, {useEffect, useState} from "react";
import {GifticonDescriptionModal} from "../../../modal/Gifticons";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {Component, MaterialComponent, MaterialInput, MaterialModal, MaterialTextarea} from "../../Materials";
import {GifticonDescriptionIO} from "../../../io/Gifticons";

const pageId = 'gifticon_description_edit';
const descriptionInputId = `${pageId}_title`;
const sortOrderInputId = `${pageId}_sort_order`;

type Params = {
    gifticonId?: string;
    id?: string;
};

export default function Edit() {
    const params = useParams<Params>();
    const gifticonId = params.gifticonId?.toIntOrNull() ?? null;
    const contentId = params.id?.toIntOrNull() ?? null

    const contentState = wrapState(useState<Optional<GifticonDescriptionModal>>(null));
    const errorMessageState = wrapState(useState<Optional<ErrorMessage>>(null));
    const errorRecoverState = wrapState(useState<Optional<ErrorMessage>>(null));
    const rootEnabledState = wrapState(useState<boolean>(true));

    useEffect(() => {
        MaterialModal.init();
        MaterialInput.init();
    });

    useEffect(() => {
        const id = PrepareContentId(params.id);
        if (id === null) {
            return;
        }

        PrepareContent(id, contentState.set, errorMessageState.set);
    }, [params.id]);

    useEffect(() => {
        const content = contentState.value;
        if (content === null) {
            return;
        }

        MaterialTextarea.getOrNull(`#${descriptionInputId}`)?.setValue(content.description.toString());
        MaterialInput.getOrNull(`#${sortOrderInputId}`)?.setValue(content.sortOrder.toString());
    }, [contentState.value]);

    useEffect(() => {
        const isRootEnabled = rootEnabledState.value;
        const inputs = [
            MaterialTextarea.getOrNull(`#${descriptionInputId}`),
            MaterialInput.getOrNull(`#${sortOrderInputId}`)
        ];

        if (isRootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [contentState.value, rootEnabledState.value]);

    useEffect(() => {
        if (errorMessageState.value) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessageState.value]);

    if (gifticonId === null && contentId === null) {
        window.alert('잘못된 접근입니다.');
        return <></>;
    }

    return <PageWrapper
        gifticonId={gifticonId}
        content={contentState.value}
        errorMessageState={errorMessageState}
        errorRecoverState={errorRecoverState}
        setRootEnabled={rootEnabledState.set} />;
}

function PrepareContentId(rawId: string | undefined): Optional<number> {
    if (rawId === undefined) {
        return null;
    }

    const id = parseIntOrNull(rawId);
    if (id === null) {
        window.alert('잘못된 접근입니다.');
        window.history.back();
        return null;
    } else {
        return id;
    }
}

function PrepareContent(
    id: number,
    setContent: React.Dispatch<Optional<GifticonDescriptionModal>>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    GifticonDescriptionIO.get(id, setContent, setErrorMessage);
}

type PageWrapperProps = {
    gifticonId: Optional<number>,
    content: Optional<GifticonDescriptionModal>,

    errorMessageState: StateWrapper<Optional<ErrorMessage>>,
    errorRecoverState: StateWrapper<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
};

function PageWrapper(props: PageWrapperProps) {
    const gifticonId = props.gifticonId ?? props.content?.gifticonId ?? null;
    const content = props.content;
    const onSaveClicked = () => OnSaveClicked(props);
    const onCancelClicked = () => OnCancelClick(gifticonId);
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", onCancelClicked]
    ];

    if (content !== null) {
        barItems.insert(["삭제", () => OnDeleteClick(gifticonId, content.id, props.setRootEnabled)], 1);
    }

    return <>
        <Nav
            titleHref='/gifticon/description/list'
            titleIcon="chevron_left"
            title={"기프티콘 " + ((props.content) ? "수정" : "추가")}
            barItems={barItems}
            errorMessageState={props.errorMessageState}
            errorRecoverState={props.errorRecoverState}
            onErrorDismiss={() => document.location = '/gifticon/description/list'} />
        <Editor />
    </>;
}

function OnSaveClicked(props: PageWrapperProps) {
    const content = props.content;

    const description = MaterialTextarea.get(`#${descriptionInputId}`).getValue();
    if (description.isEmpty()) {
        props.errorMessageState.set("이름을 입력해주세요.");
        return;
    }

    const sortOrder = MaterialInput.get(`#${sortOrderInputId}`).getValue().toIntOrNull();
    if (sortOrder === null) {
        props.errorMessageState.set("정렬 순서를 확인해주세요.");
        return;
    }

    const onReady = () => {
        window.alert('저장되었습니다.');
        document.location = `/gifticon/${props.gifticonId ?? content?.gifticonId}/description/list`;
    };

    const onError = (error: string) => {
        props.errorMessageState.set(error);
        props.setRootEnabled(true);
    };

    if (content && content.description === description) {
        onReady();
        return;
    }

    if (content) {
        GifticonDescriptionIO.update(content.id, description, sortOrder, onReady, onError);
    } else {
        GifticonDescriptionIO.post(props.gifticonId!!, description, sortOrder, onReady, onError);
    }
}

function OnDeleteClick(
    gifticonId: Optional<number>,
    contentId: number,
    setRootEnabled: React.Dispatch<boolean>
) {
    if (!window.confirm('삭제하시겠습니까?')) {
        return;
    }

    const onReady = () => {
        window.alert('삭제되었습니다.');
        document.location = `/gifticon/${gifticonId}/description/list`;
    };

    const onError = () => {
        window.alert('삭제를 실패했습니다.');
        setRootEnabled(true);
    };

    setRootEnabled(false);
    GifticonDescriptionIO.delete(contentId, onReady, onError);
}

function OnCancelClick(gifticonId: Optional<number>) {
    document.location = `/gifticon/${gifticonId}/description/list`;
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Textarea
                formClasses="col s8 offset-s2"
                textareaId={descriptionInputId}
                textareaClasses="validate"
                textareaPlaceHolder="1000자 이내"
                textareaDataLength={1000}
                label="설명" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={sortOrderInputId}
                inputType="number"
                inputClasses="validate"
                label="정렬 순서" />
        </div>
    </>;
}












