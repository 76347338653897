import {ManagedUpload} from "aws-sdk/clients/s3";
import {useEffect} from "react";
import {Optional, unwrap, useBoolean, useWrapper} from "../../util/Types";
import {M10nModal} from "../../modal/M10ns";
import {ErrorMessage, Nav, NavBarItemAction} from "../Common";
import {TextField} from "../common/TextField";
import {TextAreaField} from "../common/TextAreaField";
import {Select} from "../common/Select";
import {Checkbox} from "../common/Checkbox";
import {FileField} from "../common/FileField";
import {StorageIO} from "../../io/Services";
import {M10nIO} from "../../io/M10n";
import {MaterialModal} from "../Materials";

type UploadResponse = { type: (0 | 1 | 2 | 3), key: string, index: number }
type UploadSuccess = UploadResponse & { value: ManagedUpload.SendData }
type UploadFailure = UploadResponse & { reason: any }

export function Post() {
    const title = useWrapper('')
    const description = useWrapper('')
    const script = useWrapper('')
    const detailUrl = useWrapper('')
    const detailClipboard = useWrapper<string | null>(null)
    const externalUrl = useWrapper('')
    const gradingMessage = useWrapper<string | null>(null)
    const amount = useWrapper(0)
    const point = useWrapper(0)
    const minimumAccuracy = useWrapper(0)
    const pointAdditionalHighAccuracy = useWrapper(0)
    const difficulty = useWrapper(M10nModal.Difficulty.VERY_EASY)
    const externalUrlWing = useWrapper(false)
    const externalUrlNaver = useWrapper(true)
    const detailUrlNaver = useWrapper(true)
    const startAt = useWrapper(new Date())
    const endAt = useWrapper(new Date())
    const exposedAt = useWrapper(new Date())
    const thumbnailImage = useWrapper<Optional<File>>(null)
    const headerImages = useWrapper<Optional<FileList>>(null)
    const descriptionImages = useWrapper<Optional<FileList>>(null)
    const contentVideo = useWrapper<Optional<File>>(null)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)
    const disabled = useBoolean(false)

    useEffect(() => M.AutoInit())

    useEffect(() => {
        if (errorMessage.value) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage.value]);

    const onSaveClick = () => {
        if (title.value.isEmpty()) {
            errorMessage.set('제목을 입력해주세요.')
            return
        }
        if (description.value.isEmpty()) {
            errorMessage.set('설명을 입력해주세요.')
            return
        }
        if (script.value.isEmpty()) {
            errorMessage.set('본문을 입력해주세요.')
            return
        }
        if (detailUrl.value.isEmpty()) {
            errorMessage.set('더 알아보기 URL을 입력해주세요.')
            return
        }
        if (externalUrl.value.isEmpty()) {
            errorMessage.set('외부 연결 URL을 입력해주세요.')
            return
        }
        if (thumbnailImage.value === null) {
            errorMessage.set('썸네일 이미지를 선택해주세요.')
            return
        }
        if (headerImages.value === null || headerImages.value.isEmpty()) {
            errorMessage.set('상단 이미지를 선택해주세요.')
            return
        }
        if (descriptionImages.value === null || descriptionImages.value.isEmpty()) {
            errorMessage.set('설명 이미지를 선택해주세요.')
            return
        }
        if (contentVideo.value === null) {
            errorMessage.set('내용 비디오를 선택해주세요.')
            return
        }

        disabled.setTrue()
        post()
    }

    const post = () => M10nIO.post({
        title: title.value,
        description: description.value,
        script: script.value,
        detailUrl: detailUrl.value,
        detailClipboard: detailClipboard.value,
        externalUrl: externalUrl.value,
        gradingMessage: gradingMessage.value,
        amount: amount.value,
        point: point.value,
        pointAdditionalHighAccuracy: pointAdditionalHighAccuracy.value,
        headerImageCount: headerImages.value!.length,
        descriptionImageCount: descriptionImages.value!.length,
        difficulty: difficulty.value,
        minimumAccuracy: minimumAccuracy.value,
        externalUrlWing: externalUrlWing.value,
        externalUrlNaver: externalUrlNaver.value,
        detailUrlNaver: detailUrlNaver.value,
        startAt: startAt.value,
        endAt: endAt.value,
        exposedAt: exposedAt.value
    }, onPost, onPostError)

    const onPost = (response: M10nModal) => {
        const uploadSize = 1 + headerImages.value!.length + descriptionImages.value!.length + 1
        const success: UploadSuccess[] = []
        const failure: UploadFailure[] = []
        const onProgress = () => {
            if (success.length + failure.length === uploadSize) {
                onUploaded(response, success, failure)
            }
        }

        StorageIO.uploadFileSync(response.thumbnailImageKey(), thumbnailImage.value!)
            .then(value => {
                success.push({ type: 0, key: response.thumbnailImageKey(), index: 0, value })
                onProgress()
            })
            .catch(reason => {
                failure.push({ type: 0, key: response.thumbnailImageKey(), index: 0, reason })
                onProgress()
            })

        for (let index = 0; index < response.headerImageCount; index++) {
            const key = response.headerImageKey(index)
            StorageIO.uploadFileSync(response.headerImageKey(index), headerImages.value![index])
                .then(value => {
                    success.push({ type: 1, key, index, value })
                    onProgress()
                })
                .catch(reason => {
                    failure.push({ type: 1, key, index, reason })
                    onProgress()
                })
        }

        for (let index = 0; index < response.descriptionImageCount; index++) {
            const key = response.descriptionImageKey(index)
            StorageIO.uploadFileSync(response.descriptionImageKey(index), descriptionImages.value![index])
                .then(value => {
                    success.push({ type: 2, key, index, value })
                    onProgress()
                })
                .catch(reason => {
                    failure.push({ type: 2, key, index, reason })
                    onProgress()
                })
        }

        StorageIO.uploadFileSync(response.contentVideoKey(), contentVideo.value!)
            .then(value => {
                success.push({ type: 3, key: response.contentVideoKey(), index: 0, value })
                onProgress()
            })
            .catch(reason => {
                failure.push({ type: 3, key: response.contentVideoKey(), index: 0, reason })
                onProgress()
            })
    }

    const onPostError = (error: ErrorMessage) => {
        disabled.setFalse()
        errorMessage.set(error)
    }

    const onUploaded = (
        m10n: M10nModal,
        successes: UploadSuccess[],
        failures: UploadFailure[]
    ) => {
        if (failures.isEmpty()) {
            alert('추가되었습니다.')
            document.location = `/m10n/${m10n.id}`
            return
        }

        failures.sort((a, b) => {
            const c1 = a.type.compareTo(b.type)
            if (c1 !== 0) {
                return c1
            } else {
                return a.index.compareTo(b.index)
            }
        })

        disabled.setTrue()
        errorMessage.set(createErrorMessages(successes, failures))
    }

    const createErrorMessages = (
        successes: UploadSuccess[],
        failures: UploadFailure[]
    ): string => {
        const errorMessages: string[] = ['일부 이미지 또는 비디오를 업로드하지 못 했습니다.']
        if (successes.isNotEmpty()) {
            let errorMessage = '다음 데이터가 업로드되었습니다: '
            errorMessage += successes.map(toString).join(', ')
            errorMessages.push(errorMessage)
        }
        let errorMessage = '다음 데이터가 업로드되지 않았습니다: '
        errorMessage += failures.map(toString).join(', ')

        errorMessages.push(errorMessage)

        return errorMessage
    }

    const toString = (response: UploadResponse): string => {
        switch (response.type) {
            case 0: return '썸네일 이미지'
            case 1: return `상단 이미지의 ${response.index}번째`
            case 2: return `설명 이미지의 ${response.index}번째`
            case 3: return '내용 비디오'
        }
    };

    const onCancelClick = () => document.location = `/m10n/list`

    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClick],
        ["취소", onCancelClick]
    ];

    return <>
        <Nav
            title={'암기플러스 추가'}
            titleIcon={'chevron_left'}
            titleHref={`/m10n/list`}
            barItems={barItems}
            errorMessageState={errorMessage} />
        <div className={'row cascade'} style={{ marginTop: "2rem" }}>
            <TextField
                label={'제목'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: title.value, disabled: disabled.value, maxLength: 100 }}
                onValueChange={(event, value) => title.set(value)}/>
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'설명'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: description.value, disabled: disabled.value, maxLength: 100 }}
                onValueChange={(event, value) => description.set(value)}/>
        </div>
        <div className={'row cascade'}>
            <TextAreaField
                label={'본문'}
                formProps={{ className: 'col s8 offset-s2' }}
                textAreaProps={{ value: script.value, disabled: disabled.value, maxLength: 1000 }}
                onValueChange={(event, value) => script.set(value)}/>
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'채점 중 표시할 텍스트'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: gradingMessage.value ?? '', disabled: disabled.value, maxLength: 400 }}
                onValueChange={(event, value) => gradingMessage.set(value)}/>
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'더 알아보기 URL'}
                formProps={{ className: 'col s4 offset-s2' }}
                inputProps={{ value: detailUrl.value, disabled: disabled.value, maxLength: 1000 }}
                onValueChange={(event, value) => detailUrl.set(value)}/>
            <TextField
                label={'더 알아보기 클립보드'}
                formProps={{ className: 'col s4' }}
                inputProps={{
                    value: detailClipboard.value ?? '',
                    disabled: disabled.value,
                    maxLength: 100
                }}
                onValueChange={(event, value) => {
                    if (value.isEmpty()) {
                        detailClipboard.set(null)
                    } else {
                        detailClipboard.set(value)
                    }
                }}
            />
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'외부 연결 URL'}
                formProps={{ className: 'col s4 offset-s2' }}
                inputProps={{ value: externalUrl.value, disabled: disabled.value, maxLength: 1000 }}
                onValueChange={(event, value) => externalUrl.set(value)}/>
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'수량'}
                formProps={{ className: 'col s4 offset-s2' }}
                inputProps={{ value: amount.value.toString(), disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => amount.set(Number(value))}/>
            <TextField
                label={'합격 최소 일치율(%)'}
                formProps={{ className: 'col s4' }}
                inputProps={{ value: minimumAccuracy.value.toString(), disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => minimumAccuracy.set(Number(value))}/>
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'포인트'}
                formProps={{ className: 'col s4 offset-s2' }}
                inputProps={{ value: point.value.toString(), disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => point.set(Number(value))}/>
            <TextField
                label={'높은 일치율 추가 포인트'}
                formProps={{ className: 'col s4' }}
                inputProps={{ value: pointAdditionalHighAccuracy.value.toString(), disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => pointAdditionalHighAccuracy.set(Number(value))}/>
        </div>
        <div className={'row cascade'} style={{ display: 'flex', alignItems: 'center' }}>
            <Select
                wrapperProps={{ className: 'col s4 offset-s2' }}
                options={M10nModal.Difficulty.values().map(value => ({ key: value, data: value }))}
                optionIndex={difficulty.value}
                onOptionSelected={index => difficulty.set(index)}
                placeholder={'선택'}
                label={'난이도'} />
            <Checkbox
                title={'외부 연결 URL 클릭 시 날개 지급'}
                wrapperProps={{ className: 'col s4', style: { marginLeft: '0px' }}}
                checked={externalUrlWing.value}
                onChange={externalUrlWing.set} />
        </div>
        <div className={'row cascade'}>
            <Checkbox
                title={'외부 연결 URL 네이버에서 열기'}
                wrapperProps={{ className: 'col s4 offset-s2' }}
                checked={externalUrlNaver.value}
                onChange={externalUrlNaver.set} />
            <Checkbox
                title={'더 알아보기 URL 네이버에서 열기'}
                formProps={{ className: 'col s4' }}
                checked={detailUrlNaver.value}
                onChange={detailUrlNaver.set} />
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'시작일'}
                formProps={{ className: 'col s4 offset-s2' }}
                inputProps={{ value: startAt.value.toKSTString().substring(0, 16), disabled: disabled.value, type: 'datetime-local' }}
                onValueChange={(event, value) => startAt.set(new Date(value))}/>
            <TextField
                label={'종료일'}
                formProps={{ className: 'col s4' }}
                inputProps={{ value: endAt.value.toKSTString().substring(0, 16), disabled: disabled.value, type: 'datetime-local' }}
                onValueChange={(event, value) => endAt.set(new Date(value))}/>
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'노출 시작일'}
                formProps={{ className: 'col s4 offset-s2' }}
                inputProps={{ value: exposedAt.value.toKSTString().substring(0, 16), disabled: disabled.value, type: 'datetime-local' }}
                onValueChange={(event, value) => exposedAt.set(new Date(value))}/>
        </div>
        <div className={'row cascade'}>
            <FileField
                formProps={{ className: 'col s8 offset-s2' }}
                title={'썸네일 이미지'}
                accept={'image/png'}
                multiple={false}
                onChange={files => unwrap(files, files => unwrap(files?.item(0), thumbnailImage.set))} />
        </div>
        <div className={'row cascade'}>
            <FileField
                formProps={{ className: 'col s8 offset-s2' }}
                title={'상단 이미지'}
                accept={'image/png'}
                multiple
                onChange={files => unwrap(files, headerImages.set)} />
        </div>
        <div className={'row cascade'}>
            <FileField
                formProps={{ className: 'col s8 offset-s2' }}
                title={'설명 이미지'}
                accept={'image/png'}
                multiple
                onChange={files => unwrap(files, descriptionImages.set)} />
        </div>
        <div className={'row cascade'}>
            <FileField
                formProps={{ className: 'col s8 offset-s2' }}
                title={'내용 비디오'}
                accept={'video/mp4'}
                multiple={false}
                onChange={files => unwrap(files, files => unwrap(files?.item(0), contentVideo.set))} />
        </div>
    </>
}