import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {AdParticipationModal} from "../../../modal/Ads";
import {Optional, StatePair} from "../../../util/Types";
import {ErrorMessage, Nav} from "../../Common";
import {AdParticipationIO} from "../../../io/Ads";

export default function Detail() {
    const params = useParams();
    const adParticipationId = params.adParticipationId?.toBigIntOrNull();
    const [content, setContent] = useState<Optional<AdParticipationModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
    });

    useEffect(() => {
        if (adParticipationId) {
            AdParticipationIO.get(adParticipationId, setContent, setErrorMessage);
        }
    }, [adParticipationId]);

    return <>
        <PageWrapper
            content={content}
            errorMessageState={[errorMessage, setErrorMessage]} />
    </>;
}

type PageWrapperProps = {
    content: Optional<AdParticipationModal>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    if (!props.content) {
        return <>
            <Nav
                title="광고 참여"
                titleIcon="chevron_left"
                errorMessagePair={props.errorMessageState} />
        </>;
    }

    return <>
        <Nav
            title="광고 참여"
            titleIcon="chevron_left"
            errorMessagePair={props.errorMessageState} />
        <div className="row cascade first">
            <Card title="ID" value={props.content.id.toString()} />
        </div>
        <div className="row cascade">
            <Card title="광고 ID" value={props.content.adId.toString()} href={`/ad/${props.content.adId}`} columnWidth={4} />
            <Card title="사용자 ID" value={props.content.userId.toString()} href={`/user/${props.content.userId}`} columnWidth={4} columnOffset={0} />
        </div>
        <div className="row cascade">
            <Card title="텍스트" value={props.content.recordedText} href={AdParticipationModal.recordPath(props.content)} smallBody={true} />
        </div>
        <div className="row cascade">
            <Card title="정확도" value={props.content.accuracy.toString()} columnWidth={4} />
            <Card title="합격" value={props.content.isPassed ? "예" : "아니오"} columnWidth={4} columnOffset={0} />
        </div>
        <div className="row cascade">
            <Card title="참여 일시" value={props.content.participatedAt.toRowFormat(true, true, true, true)} />
        </div>
    </>;
}

type CardProps = {
    title: string;
    value?: string | null;
    href?: string;
    smallBody?: boolean;
    columnWidth?: number;
    columnOffset?: number;
};

function Card(props: CardProps) {
    if (!props.value) {
        return <></>;
    }

    let className = "col";
    if (props.columnWidth !== undefined) {
        className += ` s${props.columnWidth}`;
    } else {
        className += ' s8';
    }
    if (props.columnOffset !== undefined) {
        className += ` offset-s${props.columnOffset}`;
    } else {
        className += ' offset-s2';
    }

    let anchor: JSX.Element;
    if (props.href) {
        anchor = <a href={props.href} target="_blank"><i className="material-icons right grey-text">open_in_new</i></a>;
    } else {
        anchor = <></>;
    }

    return <div className={className}>
        <div className="card">
            <div className="card-content">
                <p>{props.title}{anchor}</p>
                <span className={(props.smallBody === true) ? "" : "card-title"}>{props.value}</span>
            </div>
        </div>
    </div>;
}