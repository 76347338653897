import {LateInit, Optional} from "../util/Types";
import {BitMaskFlag} from "../util/BitMask";
import {DEBUG} from "../util/Environments";
import {UniqueContent} from "./Modal";

export enum AgreementType {
    SERVICE,
    LOCATION,
    PRIVACY,
    COMMERCIAL_NOTIFICATION
}

export namespace AgreementType {
    const VALUE_SERVICE = "서비스 이용약관";
    const VALUE_LOCATION = "위치정보서비스약관";
    const VALUE_PRIVACY = "개인정보처리방침";
    const VALUE_COMMERCIAL_NOTIFICATION = "이벤트 및 혜택 알림";

    export function values(): string[] {
        return [
            toString(AgreementType.SERVICE),
            toString(AgreementType.LOCATION),
            toString(AgreementType.PRIVACY),
            toString(AgreementType.COMMERCIAL_NOTIFICATION),
        ];
    }

    export function toString(type: AgreementType): string {
        switch (type) {
            case AgreementType.SERVICE: return VALUE_SERVICE;
            case AgreementType.LOCATION: return VALUE_LOCATION;
            case AgreementType.PRIVACY: return VALUE_PRIVACY;
            case AgreementType.COMMERCIAL_NOTIFICATION: return VALUE_COMMERCIAL_NOTIFICATION;
        }
    }

    export function fromValue(value: string): AgreementType | null {
        switch (value) {
            case VALUE_SERVICE: return AgreementType.SERVICE;
            case VALUE_LOCATION: return AgreementType.LOCATION;
            case VALUE_PRIVACY: return AgreementType.PRIVACY;
            case VALUE_COMMERCIAL_NOTIFICATION: return AgreementType.COMMERCIAL_NOTIFICATION;
            default: return null;
        }
    }

    export function ordinal(s: string): number {
        return values().indexOf(s);
    }
}

export enum ApplicationVersionType {
    TEST, RELEASE
}

export namespace ApplicationVersionType {
    export function toString(type: ApplicationVersionType): string {
        switch (type) {
            case ApplicationVersionType.TEST: return "테스트";
            case ApplicationVersionType.RELEASE: return "배포"
        }
    }

    export function values(): string[] {
        return [
            toString(ApplicationVersionType.TEST),
            toString(ApplicationVersionType.RELEASE)
        ];
    }

    export function ordinal(s: string): number {
        return values().indexOf(s);
    }

    export function current(): ApplicationVersionType {
        if (DEBUG) {
            return ApplicationVersionType.TEST;
        } else {
            return ApplicationVersionType.RELEASE;
        }
    }
}

export class ApplicationVersionStatus extends BitMaskFlag {
    static readonly EXPIRED = Object.freeze(new ApplicationVersionStatus("만료", 1 << 0));
    static readonly values: ReadonlyArray<ApplicationVersionStatus> = [
        ApplicationVersionStatus.EXPIRED
    ];
}

export class AgeRangeModal implements UniqueContent<number> {
    id!: number;
    fromInclusive!: number;
    toExclusive!: number;
    name!: string;
    creatorId!: bigint;

    constructor(json: object) {
        Object.assign(this, json);
    }

    toString(short?: boolean): string {
        const single = this.fromInclusive === this.toExclusive - 1;
        if (short === true) {
            if (single) {
                return this.fromInclusive.toString();
            } else {
                return `${this.fromInclusive} ~ ${this.toExclusive - 1}`;
            }
        } else {
            if (single) {
                return `${this.fromInclusive}세`;
            } else {
                return `${this.fromInclusive}세 이상, ${this.toExclusive}세 미만`
            }
        }
    }
}

export class AgreementModal {
    id!: number;
    url!: string;
    type!: AgreementType;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class ApplicationVersionModal {
    static readonly instance = new LateInit<ApplicationVersionModal>();

    id!: number;
    name!: string;
    type!: ApplicationVersionType;
    platform!: string;
    createdAt!: Date;
    expiredAt!: Optional<Date>;
    statusFlags!: number;

    constructor(o: object) {
        Object.assign(this, o);
    }
}

export class BankModal {
    static iconKey(id: number): string {
        return `misc/bank/${id}.png`
    }

    id!: number;
    name!: string;
    creatorId!: bigint;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class ConstructionModal {
    startAt!: Optional<Date>;
    endAt!: Optional<Date>;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class EventModal {
    static descriptionImagePath(content: EventModal, index: number): string {
        return `misc/event/${content.id}/description/${index}.png`;
    }

    id!: number;
    title!: string;
    body!: string;
    descriptionImageCount!: number;
    startAt!: Date;
    endAt!: Date;
    creatorId!: bigint;

    constructor(json: object) {
        Object.assign(this, json);
    }

    imagePath(index: number): string {
        return `misc/event/${this.id}/description/${index}.png`;
    }
}

export class FaqCategoryModal {
    id!: number;
    name!: string;
    parentId!: Optional<number>;
    creatorId!: bigint;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class FaqModal {
    id!: number;
    title!: string;
    body!: string;
    categoryId!: number;
    creatorId!: bigint;

    constructor(json: object) {
        Object.assign(this, json);
    }

    imagePath(): string {
        return `misc/faq/${this.categoryId}/${this.id}.png`;
    }
}

export class HomeBannerModal {
    static imagePath(content: HomeBannerModal | number): string {
        if (content instanceof HomeBannerModal) {
            return content.imagePath();
        } else {
            return `misc/home/banner/${content}.png`;
        }
    }

    id!: number;
    adId!: Optional<number>
    m10nId!: Optional<number>
    noticeId!: Optional<number>
    sortOrder!: number;
    createdAt!: Date;
    startAt!: Date;
    endAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }

    imagePath(): string {
        return `misc/home/banner/${this.id}.png`;
    }
}

export class HomeBestModal {
    static imagePath(content: HomeBestModal | number): string {
        if (content instanceof HomeBestModal) {
            return this.imagePath(content.id)
        } else {
            return `misc/home/best/${content}.png`
        }
    }

    id!: number
    adId!: Optional<number>
    m10nId!: Optional<number>
    noticeId!: Optional<number>
    sortOrder!: number
    createdAt!: Date

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export namespace HomeBestModal {
    export type Post = {
        adId: Optional<number>
        m10nId: Optional<number>
        noticeId: Optional<number>
        sortOrder: number
    }
}

export class LanguageModal {
    id!: number;
    name!: string;
    creatorId!: bigint;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class NoticeCategoryModal {
    id!: number;
    name!: string;
    parentId!: Optional<number>;
    creatorId!: bigint;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class NoticeModal {
    static descriptionImagePath(content: NoticeModal): string {
        return `misc/notice/${content.categoryId}/${content.id}.png`;
    }

    id!: number;
    title!: string;
    body!: string;
    categoryId!: number;
    creatorId!: bigint;
    createdAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }

    imagePath(): string {
        return `misc/notice/${this.categoryId}/${this.id}.png`;
    }
}

export class RegionModal {
    id!: number;
    name!: string;

    constructor(json: object) {
        Object.assign(this, json);
    }
}