import React, {useEffect, useState} from "react";
import {Optional, wrapState} from "../../../util/Types";
import {ErrorMessage, Nav} from "../../Common";
import {MaterialModal} from "../../Materials";
import {GifticonDescriptionModal} from "../../../modal/Gifticons";
import {GifticonDescriptionIO} from "../../../io/Gifticons";
import {useParams} from "react-router-dom";

type Params = {
    gifticonId?: string;
};

export default function List() {
    const params = useParams<Params>();
    const gifticonId = params.gifticonId?.toIntOrNull() ?? null;

    const lockPair = wrapState(useState<number>(0));
    const contentsPair = wrapState(useState<Optional<GifticonDescriptionModal[]>>(null));
    const errorMessagePair = wrapState(useState<Optional<ErrorMessage>>(null));

    useEffect(() => {
        M.AutoInit();
        MaterialModal.init();
    });

    useEffect(() => PrepareContents(
        gifticonId,
        contentsPair.set,
        errorMessagePair.set
    ), [gifticonId, lockPair.value]);

    if (gifticonId === null) {
        window.alert('잘못된 접근입니다.');
        document.location = '/';
        return <></>;
    }

    return <PageWrapper gifticonId={gifticonId} contents={contentsPair.value} />;
}

function PrepareContents(
    gifticonId: Optional<number>,
    setContents: React.Dispatch<Optional<GifticonDescriptionModal[]>>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    if (gifticonId === null) {
        return;
    }

    GifticonDescriptionIO.listByGifticonId(
        gifticonId,
        setContents,
        setErrorMessage
    );
}

type PageWrapperProps = {
    gifticonId: number,
    contents: Optional<GifticonDescriptionModal[]>,
};

function PageWrapper(props: PageWrapperProps) {
    const rows = (props.contents ?? []).map(content => <Row key={content.id} content={content} />);
    return <>
        <Nav
            title="기프티콘 설명"
            titleIcon="chevron_left"
            titleHref="/" />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>설명</th>
                <th>정렬 순서</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="fixed-action-btn">
            <a className="btn-floating btn-large secondary" href={`/gifticon/${props.gifticonId}/description/post`}><i className="large material-icons">add</i></a>
        </div>
    </>;
}

type RowProps = {
    content: GifticonDescriptionModal
};

function Row(props: RowProps) {
    return <tr>
        <td>{props.content.id}</td>
        <td>{props.content.description}</td>
        <td>{props.content.sortOrder}</td>
        <td>
            <a href={`/gifticon/description/${props.content.id}/edit`}><i className="material-icons">edit</i></a>
        </td>
    </tr>;
}