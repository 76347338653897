import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {UserModal, UserSessionModal} from "./modal/Users";
import {MiscApplicationVersionIO} from "./io/Miscs";
import {ApplicationVersionModal, ApplicationVersionStatus} from "./modal/Miscs";
import {useEffect} from "react";
import {Home} from "./ui/Home";
import {SignIn} from "./ui/SignIn";
import MiscRouter from "./ui/misc/MiscRouter";
import UserRouter from "./ui/user/UserRouter";
import AdRouter from "./ui/ad/AdRouter";
import ProductRouter from "./ui/product/ProductRouter";
import PointRouter from "./ui/point/PointRouter";
import WingRouter from "./ui/wing/WingRouter";
import GifticonRouter from "./ui/gifticon/GifticonRouter";
import {_util_array} from "./util/Arrays";
import {M10nRouter} from "./ui/m10n/M10nRouter";
import {_util_file_list} from "./util/FileList";

function App() {
    _util_array();
    _util_file_list()
    useEffect(() => initializeApplicationVersion(), []);

    return <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
            <Route path="/" element={<AssertSignIn><Home/></AssertSignIn>}/>
            <Route path="/signIn" element={<SignIn/>}/>
            <Route path="/user/*" element={<UserRouter/>}/>
            <Route path="/ad/*" element={<AdRouter/>}/>
            <Route path="/m10n/*" element={<M10nRouter />} />
            <Route path="/point/*" element={<PointRouter/>}/>
            <Route path="/product/*" element={<ProductRouter/>}/>
            <Route path="/gifticon/*" element={<GifticonRouter/>}/>
            <Route path="/wing/*" element={<WingRouter/>}/>
            <Route path="/misc/*" element={<MiscRouter/>}/>
        </Routes>
    </BrowserRouter>;
}

function initializeApplicationVersion() {
    MiscApplicationVersionIO.getByProperties(
        applicationVersion => {
            if (ApplicationVersionStatus.EXPIRED.isEnabled(applicationVersion.statusFlags)) {
                window.alert('애플리케이션 업데이트가 필요합니다.');
                window.history.back();
                return;
            }

            ApplicationVersionModal.instance.value = applicationVersion;
        },
        error => { throw new Error(error); }
    );
}

function isSignedIn(): bigint | null {
    const user = UserModal.loadFromStorage();
    const userSession = UserSessionModal.loadFromStorage();
    if (user && userSession) {
        if (user.id === userSession.userId && !userSession.isExpired()) {
            return userSession.id;
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export function AssertSignIn({children}: {children?: JSX.Element | JSX.Element[]}) {
    if (isSignedIn()) {
        return <>{children}</>;
    } else {
        return <Navigate to="/signIn" />;
    }
}

export default App;