import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {ProductBrandModal} from "../../../modal/Products";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {
    Component,
    MaterialComponent,
    MaterialInput,
    MaterialModal
} from "../../Materials";
import {ProductBrandIO} from "../../../io/Products";

const pageId = 'product_brand_edit';
const nameInputId = `${pageId}_name`;

export default function Edit() {
    const {productBrandId} = useParams() as { productBrandId?: number };
    const [content, setContent] = useState<Optional<ProductBrandModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        MaterialModal.init();
        MaterialInput.init();
    });

    useEffect(() => {
        if (productBrandId) {
            ProductBrandIO.get(productBrandId, setContent, setErrorMessage);
        }
    }, [productBrandId]);

    useEffect(() => {
        if (content) {
            MaterialInput.getOrNull(`#${nameInputId}`)?.setValue(content.name);
        }
    }, [content]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${nameInputId}`)
        ];

        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            content={content}
            errorMessageState={[errorMessage, setErrorMessage]}
            setRootEnabled={setRootEnabled} />
    </>;
}

type PageWrapperProps = {
    content: Optional<ProductBrandModal>;

    errorMessageState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [, setErrorMessage] = props.errorMessageState;
    const barItems: [string, NavBarItemAction][] = [
        ["저장", () => OnSaveClicked(props.content, setErrorMessage, props.setRootEnabled)],
        ["취소", () => OnCancelClick()]
    ];

    return <>
        <Nav
            title={"상품 " + ((props.content) ? "수정" : "추가")}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <Editor />
    </>;
}

function OnSaveClicked(
    content: Optional<ProductBrandModal>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const name = MaterialInput.get(`#${nameInputId}`).getValue();
    if (name.isEmpty()) {
        setErrorMessage("제목을 입력해주세요.");
        return;
    }

    if (name.length.notIn(0, 101)) {
        setErrorMessage("제목을 확인해주세요.");
        return;
    }

    const onReady = () => {
        window.alert('저장되었습니다.');
        window.history.back();
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    if (content && content.name === name) {
        onReady();
        return;
    }

    if (content) {
        ProductBrandIO.update(content.id, name, onReady, onError);
    } else {
        ProductBrandIO.post(name, onReady, onError);
    }
}

function OnCancelClick() {
    window.history.back();
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={nameInputId}
                inputClasses="validate"
                inputPlaceHolder="100자 이내"
                inputDataLength={100}
                label="이름" />
        </div>
    </>;
}