import {StringBuilder} from "./StringBuilder";

export class BitMaskFlag {
    readonly name: string;
    readonly value: number;

    constructor(name: string, value: number) {
        this.name = name;
        this.value = value;
    }

    toString(): string {
        return this.name;
    }

    get index(): number {
        let value = this.value;
        let index = 0;
        while ((value >>= 1) !== 0) {
            index++;
        }

        return index;
    }

    isEnabled(value: number): boolean {
        return (this.value & value) !== 0;
    }

    isDisabled(value: number): boolean {
        return !this.isEnabled(value)
    }
}

export class BitMask<E extends BitMaskFlag> {
    readonly value: number;
    protected readonly values: ReadonlyArray<E>

    constructor(initialValue: number, values: ReadonlyArray<E>) {
        this.value = initialValue;
        this.values = values;
    }

    isEnabled(flag: E): boolean {
        return (this.value & flag.value) !== 0;
    }

    isDisabled(flag: E): boolean {
        return (this.value & flag.value) === 0;
    }

    forEachEnabled(action: (enabled: E) => void) {
        for (const element of this.values) {
            if ((element.value & this.value) !== 0) {
                action(element);
            }
        }
    }

    mapEnabled<R>(transform: (enabled: E) => R): R[] {
        const dest: R[] = [];
        this.forEachEnabled((enabled: E) => {
            dest.push(transform(enabled));
        });

        return dest;
    }

    toString(): string {
        return StringBuilder.joinToString(this.mapEnabled(element => element.name));
    }

    indices(): number[] {
        return this.mapEnabled(enabled => enabled.index);
    }
}