import {BitMaskFlag} from "../util/BitMask";
import {Optional, unwrap} from "../util/Types";
import {StorageIO} from "../io/Services";

export type M10nModal = {
    id: number
    title: string
    description: string
    script: string
    detailUrl: string
    detailClipboard: Optional<string>
    externalUrl: string
    gradingMessage: Optional<string>
    amount: number
    point: number
    pointAdditionalHighAccuracy: number
    headerImageCount: number
    descriptionImageCount: number
    difficulty: M10nModal.Difficulty
    minimumAccuracy: number
    statusFlags: number
    createdAt: Date
    startAt: Date
    endAt: Date
    exposedAt: Date
    thumbnailImageKey(): string
    headerImageKey(index: number): string
    headerImageKeys(): string[]
    descriptionImageKey(index: number): string
    descriptionImageKeys(): string[]
    contentVideoKey(): string
}

export namespace M10nModal {
    export function construct(o: any): M10nModal {
        const modal = o as M10nModal
        return {
            ...modal,
            thumbnailImageKey: () => thumbnailImageKey(modal),
            headerImageKey: index => headerImageKey(modal, index),
            headerImageKeys: () => headerImageKeys(modal),
            descriptionImageKey: index => descriptionImageKey(modal, index),
            descriptionImageKeys: () => descriptionImageKeys(modal),
            contentVideoKey: () => contentVideoPath(modal)
        }
    }

    export enum Difficulty {
        VERY_EASY, EASY, NORMAL, HARD, EXPERT
    }

    export namespace Difficulty {
        export function toString(o: M10nModal.Difficulty): string {
            switch (o) {
                case M10nModal.Difficulty.VERY_EASY: return "매우 쉬움";
                case M10nModal.Difficulty.EASY: return "쉬움";
                case M10nModal.Difficulty.NORMAL: return "보통";
                case M10nModal.Difficulty.HARD: return "어려움";
                case M10nModal.Difficulty.EXPERT: return "매우 어려움";
            }
        }

        export function ordinal(s: string): number {
            return values().indexOf(s);
        }

        export function values(): string[] {
            return [
                toString(M10nModal.Difficulty.VERY_EASY),
                toString(M10nModal.Difficulty.EASY),
                toString(M10nModal.Difficulty.NORMAL),
                toString(M10nModal.Difficulty.HARD),
                toString(M10nModal.Difficulty.EXPERT)
            ]
        }
    }

    export class Status extends BitMaskFlag {
        static readonly HIDDEN = Object.freeze(new Status("숨김", 1 << 0));
        static readonly EXTERNAL_URL_WING = Object.freeze(new Status("외부 연결 URL 클릭 시 날개 지급", 1 << 1));
        static readonly EXTERNAL_URL_NAVER = Object.freeze(new Status("외부 연결 URL 네이버에서 열기", 1 << 2));
        static readonly DETAIL_URL_NAVER = Object.freeze(new Status("더 알아보기 URL 네이버에서 열기", 1 << 2));
        static readonly values: ReadonlyArray<Status> = [
            Status.HIDDEN,
            Status.EXTERNAL_URL_WING,
            Status.EXTERNAL_URL_NAVER,
            Status.DETAIL_URL_NAVER
        ];
    }

    export class SearchFilter extends BitMaskFlag {
        static readonly ID_ASCENDING = Object.freeze(new SearchFilter("ID 오름차순", 1 << 0));
        static readonly ID_DESCENDING = Object.freeze(new SearchFilter("ID 내림차순", 1 << 1));
        static readonly POINT_ASCENDING = Object.freeze(new SearchFilter("포인트 오름차순", 1 << 2));
        static readonly POINT_DESCENDING = Object.freeze(new SearchFilter("포인트 내림차순", 1 << 3));
        static readonly END_ASCENDING = Object.freeze(new SearchFilter("종료 오름차순", 1 << 4));
        static readonly END_DESCENDING = Object.freeze(new SearchFilter("종료 내림차순", 1 << 5));
        static readonly PARTICIPATION_COUNT_ASCENDING = Object.freeze(new SearchFilter("참여횟수 오름차순", 1 << 6));
        static readonly PARTICIPATION_COUNT_DESCENDING = Object.freeze(new SearchFilter("참여횟수 내림차순", 1 << 7));
        static readonly AVAILABLE = Object.freeze(new SearchFilter("진행 중인 광고", 1 << 8));
        static readonly FINISHED = Object.freeze(new SearchFilter("마감된 광고", 1 << 9));
        static readonly PARTICIPATED = Object.freeze(new SearchFilter("참여한 광고", 1 << 10));
        static readonly NOT_PARTICIPATED = Object.freeze(new SearchFilter("참여하지 않은 광고", 1 << 11));
        static readonly PASSED = Object.freeze(new SearchFilter("합격한 광고", 1 << 12));
        static readonly NOT_PASSED = Object.freeze(new SearchFilter("합격하지 않은 광고", 1 << 13));
        static readonly values: ReadonlyArray<SearchFilter> = [
            SearchFilter.ID_ASCENDING,
            SearchFilter.ID_DESCENDING,
            SearchFilter.POINT_ASCENDING,
            SearchFilter.POINT_DESCENDING,
            SearchFilter.END_ASCENDING,
            SearchFilter.END_DESCENDING,
            SearchFilter.PARTICIPATION_COUNT_ASCENDING,
            SearchFilter.PARTICIPATION_COUNT_DESCENDING,
            SearchFilter.AVAILABLE,
            SearchFilter.FINISHED,
            SearchFilter.PARTICIPATED,
            SearchFilter.NOT_PARTICIPATED,
            SearchFilter.PASSED,
            SearchFilter.NOT_PASSED
        ];
    }

    export type Post = {
        title: string
        description: string
        script: string
        detailUrl: string
        detailClipboard: Optional<string>
        externalUrl: string
        gradingMessage: Optional<string>
        amount: number
        point: number
        pointAdditionalHighAccuracy: number
        headerImageCount: number
        descriptionImageCount: number
        difficulty: Difficulty
        minimumAccuracy: number
        externalUrlWing: boolean
        externalUrlNaver: boolean
        detailUrlNaver: boolean
        startAt: Date
        endAt: Date
        exposedAt: Date
    }

    export namespace Post {
        export function construct(o: any): Post {
            return {
                title: String(o.title),
                description: String(o.description),
                script: String(o.script),
                detailUrl: String(o.detailUrl),
                detailClipboard: unwrap(o.detailClipboard, String) ?? null,
                externalUrl: String(o.externalUrl),
                gradingMessage: unwrap(o.gradingMessage, String) ?? null,
                amount: Number(o.amount),
                point: Number(o.point),
                pointAdditionalHighAccuracy: Number(o.pointAdditionalHighAccuracy),
                headerImageCount: unwrap(o.headerImageCount, Number) ?? 1,
                descriptionImageCount: unwrap(o.descriptionImageCount, Number) ?? 1,
                difficulty: Number(o.difficulty),
                minimumAccuracy: Number(o.minimumAccuracy),
                externalUrlWing: Boolean(o.externalUrlWing),
                externalUrlNaver: Boolean(o.externalUrlNaver),
                detailUrlNaver: Boolean(o.detailUrlNaver),
                startAt: new Date(o.startAt),
                endAt: new Date(o.endAt),
                exposedAt: new Date(o.exposedAt)
            }
        }
    }

    export type PostSheet = Post & {
        hashTagIndex: number
    }

    export function thumbnailImageKey(content: M10nModal): string {
        return `m10n/${content.id}/thumbnail.png`
    }

    export function headerImageKey(content: M10nModal, index: number): string {
        return `m10n/${content.id}/header/${index}.png`
    }

    export function headerImageKeys(content: M10nModal): string[] {
        const keys: string[] = []
        for (let index = 0; index < content.headerImageCount; index++) {
            keys.push(headerImageKey(content, index))
        }

        return keys
    }

    export function descriptionImageKey(content: M10nModal, index: number): string {
        return `m10n/${content.id}/description/${index}.png`
    }

    export function descriptionImageKeys(content: M10nModal): string[] {
        const keys: string[] = []
        for (let index = 0; index < content.descriptionImageCount; index++) {
            keys.push(descriptionImageKey(content, index))
        }

        return keys
    }

    export function contentVideoPath(content: M10nModal): string {
        return `m10n/${content.id}/content/video.mp4`
    }
}

export type M10nParticipationModal = {
    id: bigint
    m10nId: number
    userId: bigint
    audioId: bigint | null
    recordedText: string
    accuracy: number
    isPassed: boolean
    participatedAt: Date
}

export namespace M10nParticipationModal {
    export function construct(o: any): M10nParticipationModal {
        return o as M10nParticipationModal
    }

    export function audioPath(m10nParticipation: M10nParticipationModal): string | undefined {
        if (m10nParticipation.audioId !== null) {
            return StorageIO.createPath(`audio/${m10nParticipation.audioId}.aac`, 'vowing-1.3.0')
        } else if (m10nParticipation.accuracy >= 80) {
            return StorageIO.createPath(`m10n/${m10nParticipation.m10nId}/recorded/${m10nParticipation.id}.wav`)
        }
    }

    export type Post = {
        m10nId: number
        userId: bigint
        recordedText: string
        accuracy: number
    }
}

export type M10nReviewModal = {
    id: bigint
    m10nId: number
    userId: bigint
    body: string
    statusFlags: number
    writtenAt: Date
    modifiedAt: Optional<Date>
    deletedAt: Optional<Date>
}

export namespace M10nReviewModal {
    export function construct(o: any): M10nReviewModal {
        return o as M10nReviewModal
    }

    export class Status extends BitMaskFlag {
        static readonly MODIFIED = Object.freeze(new Status("수정됨", 1 << 0));
        static readonly DELETED = Object.freeze(new Status("삭제됨", 1 << 1));
        static readonly values: ReadonlyArray<Status> = [
            Status.MODIFIED,
            Status.DELETED
        ];
    }

    export type Post = {
        userId: bigint
        body: string
    }
}

export type M10nReviewLikeModal = {
    m10nReviewId: bigint
    userId: bigint
    likedAt: Date
}

export namespace M10nReviewLikeModal {
    export function construct(o: any): M10nReviewLikeModal {
        return o as M10nReviewLikeModal
    }
}

export type M10nReviewReportModal = {
    m10nReviewId: bigint
    userId: bigint
    causeId: number
    reportedAt: Date
}

export namespace M10nReviewReportModal {
    export function construct(o: any): M10nReviewReportModal {
        return o as M10nReviewReportModal
    }
}

export type M10nReviewReportCauseModal = {
    id: number
    description: string
    sortOrder: number
}

export namespace M10nReviewReportCauseModal {
    export function construct(o: any): M10nReviewReportCauseModal {
        return o as M10nReviewReportCauseModal
    }

    export type Post = {
        description: string
        sortOrder: number
    }
}