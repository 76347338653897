import React, {useState} from "react";
import {StringBuilder} from "./StringBuilder";
import {BitMask, BitMaskFlag} from "./BitMask";
import {getMaxDate, getMaxInt, getMaxLong, getMinDate, getMinInt, getMinLong, Logger} from "./Environments";
import {OnArrayResponse, OnErrorResponse} from "./Reponses";

const locale = 'ko-KR';

const formatGenerator: (length: number) => Intl.NumberFormatOptions = (length: number) => ({useGrouping: false, minimumIntegerDigits: length});

export type Optional<T> = T | null;

export function unwrap<T, R>(o: T |  undefined, transform: (o: T) => R): R | undefined {
    if (o === undefined) {
        return undefined
    } else {
        return transform(o)
    }
}

export type StatePair<S> = [S, React.Dispatch<S>];

export type StateWrapper<S> = {
    value: S;
    set: React.Dispatch<S>;
};

export type BooleanWrapper = StateWrapper<boolean> & {
    setTrue(): void
    setFalse(): void
    toggle(): void
}

export function wrapState<S>(pair: StatePair<S>): StateWrapper<S> {
    const [value, set] = pair;
    return {value, set};
}

export function useWrapper<S>(defaultValue: S): StateWrapper<S> {
    return wrapState(useState(defaultValue))
}

export function useBoolean(defaultValue: boolean): BooleanWrapper {
    const state = useWrapper<boolean>(defaultValue)
    return {
        ...state,
        setTrue() {
            state.set(true)
        },
        setFalse() {
            state.set(false)
        },
        toggle() {
            state.set(!state.value)
        }
    }
}

export function unwrapState<S>(pair: StateWrapper<S>): StatePair<S> {
    return [pair.value, pair.set];
}

export function unwrapStateOrNull<S>(pair: StateWrapper<S> | undefined): Optional<StatePair<S>> {
    return (pair) ? unwrapState(pair) : null;
}

export type StringConvertable = {
    toString(): string;
};

export type Comparator<T> = (o1: T, o2: T) => number;

export type Comparable<T> = { compareTo: (other: T) => number; };

export type ConditionalSupplier<U, T, K> = (
    condition: U,
    lastKey: K,
    onReady: OnArrayResponse<T>,
    onError: OnErrorResponse
) => void;

export type ConditionalInfiniteContent<U, T, K> = {
    contentCount: StateWrapper<Optional<number>>;
    contents: StateWrapper<Optional<T[]>>;
    contentAscending: StateWrapper<boolean>;
    hasMoreContents: StateWrapper<boolean>;
    isUpdating: StateWrapper<boolean>;

    supplierAscending: ConditionalSupplier<U, T, K>;
    defaultLastKeyOnAscending: K;
    supplierDescending: ConditionalSupplier<U, T, K>;
    defaultLastKeyOnDescending: K;
};

export function useConditionalInfiniteContent<U, T, K>(
    supplierAscending: ConditionalSupplier<U, T, K>,
    defaultLastKeyOnAscending: K,
    supplierDescending: ConditionalSupplier<U, T, K>,
    defaultLastKeyOnDescending: K,
): ConditionalInfiniteContent<U, T, K> {
    return {
        contentCount: wrapState(useState<Optional<number>>(null)),
        contents: wrapState(useState<Optional<T[]>>(null)),
        contentAscending: wrapState(useState<boolean>(false)),
        hasMoreContents: wrapState(useState<boolean>(true)),
        isUpdating: wrapState(useState<boolean>(false)),
        supplierAscending,
        defaultLastKeyOnAscending,
        supplierDescending,
        defaultLastKeyOnDescending
    };
}

export function useConditionalIntContent<U, T>(
    supplierAscending: ConditionalSupplier<U, T, number>,
    supplierDescending: ConditionalSupplier<U, T, number>,
): ConditionalInfiniteContent<U, T, number> {
    return useConditionalInfiniteContent(
        supplierAscending, getMinInt(),
        supplierDescending, getMaxInt()
    );
}

export function useConditionalLongContent<U, T>(
    supplierAscending: ConditionalSupplier<U, T, bigint>,
    supplierDescending: ConditionalSupplier<U, T, bigint>,
): ConditionalInfiniteContent<U, T, bigint> {
    return useConditionalInfiniteContent(
        supplierAscending, getMinLong(),
        supplierDescending, getMaxLong()
    );
}

export function useConditionalDateContent<U, T>(
    supplierAscending: ConditionalSupplier<U, T, Date>,
    supplierDescending: ConditionalSupplier<U, T, Date>,
): ConditionalInfiniteContent<U, T, Date> {
    return useConditionalInfiniteContent(
        supplierAscending, getMinDate(),
        supplierDescending, getMaxDate()
    );
}

declare global {
    interface Number {
        compareTo(this: number, other: number): number;

        toLengthString(this: number, length: number): string;

        repeat(action: (index: number) => void): void;
        map<R>(transform: (index: number) => R): R[];
        fold<R>(initial: R, operation: (acc: R, index: number) => R): R;
    }

    interface BigInt {
        compareTo(this: bigint, other: bigint): number;
    }

    interface String extends Comparable<string> {
        compareTo(this:string, other: string): number;
    }
}

Number.prototype.compareTo = function (this: number, other: number): number {
    const self: number | undefined = this;
    if (self === undefined) {
        throw new Error(`Cannot compare from undefined. other=${other}`);
    }

    return self - other;
}

Number.prototype.toLengthString = function (this: number, length: number): string {
    return this.toLocaleString('ko-KR', formatGenerator(length));
}

Number.prototype.repeat = function (this: number, action: (index: number) => void) {
    if (!Number.isSafeInteger(this)) {
        Logger.warn('number is not safe', this);
    }

    for (let i = 0; i < this; i++) {
        action(i);
    }
}

Number.prototype.map = function<R> (this: number, transform: (index: number) => R): R[] {
    const array: R[] = [];
    this.repeat(index => array.push(transform(index)));

    return array;
}

Number.prototype.fold = function<R> (this: number, initial: R, operation: (acc: R, index: number) => R): R {
    let accumulate = initial;
    this.repeat(index => accumulate = operation(accumulate, index));

    return accumulate;
}

BigInt.prototype.compareTo = function (this: bigint, other: bigint): number {
    const self: bigint | undefined = this;
    if (self === undefined) {
        throw new Error(`Cannot compare from undefined. other=${other}`);
    }

    const diff = self - other;
    if (diff < 0) {
        return -1;
    } else if (diff > 0) {
        return 1;
    } else {
        return 0;
    }
}

String.prototype.compareTo = function (this: string, other: string): number {
    const self: string | undefined = this;
    if (self === undefined) {
        throw new Error(`Cannot compare from undefined. other=${other}`);
    }

    if (self < other) {
        return -1;
    } else if (self > other) {
        return 1;
    } else {
        return 0;
    }
}

export function parseIntOrNull(s: string): Optional<number> {
    const i = parseInt(s);
    if (isNaN(i)) {
        return null;
    } else {
        return i;
    }
}

export interface Valuable<T> {
    get value(): T;
    set value(value: T);
}

export class LateInit<T> implements Valuable<T> {
    private _value: Optional<T>;

    constructor() {
        this._value = null;
    }

    get initialized(): boolean {
        return this._value !== null;
    }

    get value(): T {
        const value = this._value;
        if (value === null) {
            throw new Error(`Never initialized.`);
        }

        return value;
    }

    set value(value: T) {
        if (this._value !== null) {
            throw new Error(`Already initialized: ${this._value}`);
        }

        this._value = value;
    }
}

export class LazyInit<T> implements Valuable<T> {
    private readonly lateInit: LateInit<T> = new LateInit<T>();
    private readonly init: () => T;

    constructor(init: () => T) {
        this.init = init;
    }

    get value(): T {
        if (!this.lateInit.initialized) {
            this.lateInit.value = this.init();
        }

        return this.lateInit.value;
    }

    set value(value: T) {
        throw new Error(`Value of LazyInit cannot be re-assigned: present=${this.lateInit}, parameter=${value}`)
    }
}

declare global {
    interface Number {
        in(start: number, end?: number): boolean;
        notIn(start: number, end?: number): boolean;

        toBitMask<E extends BitMaskFlag>(values: ReadonlyArray<E>): BitMask<E>;
    }
}

Number.prototype.in = function (start: number, end?: number): boolean {
    if (end) {
        return start <= this && this < end;
    } else {
        return start <= this;
    }
}

Number.prototype.notIn = function (start: number, end?: number): boolean {
    return !this.in(start, end);
}

Number.prototype.toBitMask = function<E extends BitMaskFlag> (values: ReadonlyArray<E>): BitMask<E> {
    return new BitMask<E>(this.valueOf(), values);
}

declare global {
    interface String {
        isEmpty(): boolean;
        isNotEmpty(): boolean;

        toInt(): number;
        toIntOrNull(): Optional<number>;
        toIntOrElse(defaultValue: () => number): number;

        toBigInt(): bigint;
        toBigIntOrNull(): Optional<bigint>;
        toBigIntOrElse(defaultValue: () => bigint): bigint;
    }
}

String.prototype.isEmpty = function (): boolean {
    return this.length === 0;
}

String.prototype.isNotEmpty = function (): boolean {
    return !this.isEmpty();
}

String.prototype.toInt = function (): number {
    return this.toIntOrNull()!
}

String.prototype.toIntOrNull = function (): Optional<number> {
    const asInt = parseInt(this.toString());
    if (isNaN(asInt)) {
        return null;
    } else {
        return asInt;
    }
}

String.prototype.toIntOrElse = function (defaultValue: () => number): number {
    return this.toIntOrNull() ?? defaultValue();
}

String.prototype.toBigInt = function (): bigint {
    return this.toBigIntOrNull()!;
}

String.prototype.toBigIntOrNull = function (): Optional<bigint> {
    try {
        return BigInt(this.toString());
    } catch (e) {
        return null;
    }
}

String.prototype.toBigIntOrElse = function (defaultValue: () => bigint): bigint {
    return this.toBigIntOrNull() ?? defaultValue();
}

export type DatePart = {
    year: number;
    month: number;
    date: number;
};

export type TimePart = {
    hour: number;
    minute: number;
    second: number;
    millis: number;
}

declare global {
    interface Date {
        splitDatePart(requireDecreasedMonth?: boolean): DatePart;
        splitDatePartOrNull(requireDecreasedMonth?: boolean): Optional<DatePart>;
        splitTimePart(): TimePart;

        toDatePartString(): string;
        toTimePartString(includeSecond?: boolean, includeMillis?: boolean): string;

        toRowFormat(includeDate?: boolean, includeTime?: boolean, includeSecond?: boolean, includeMillis?: boolean): string;
        toKSTString(): string;

        apply(datePart?: DatePart, timePart?: TimePart): Date;
    }
}

Date.prototype.splitDatePart = function (requireDecreasedMonth?: boolean): DatePart {
    return this.splitDatePartOrNull(requireDecreasedMonth)!;
}

Date.prototype.splitDatePartOrNull = function (requireDecreasedMonth?: boolean): Optional<DatePart> {
    const year = this.getFullYear();
    const month = this.getMonth() + ((requireDecreasedMonth) ? 0 : 1);
    const date = this.getDate();
    if (isNaN(year) || isNaN(month) || isNaN(date)) {
        return null;
    } else {
        return { year, month, date };
    }
}

Date.prototype.splitTimePart = function (): TimePart {
    const hour = this.getHours();
    const minute = this.getMinutes();
    const second = this.getSeconds();
    const millis = this.getMilliseconds();

    return { hour, minute, second, millis };
}

Date.prototype.toDatePartString = function (): string {
    const {year, month, date} = this.splitDatePart();
    return `${year}-${month.toLengthString(2)}-${date.toLengthString(2)}`;
}

Date.prototype.toTimePartString = function (includeSecond?: boolean, includeMillis?: boolean): string {
    const {hour, minute, second, millis} = this.splitTimePart();
    let builder = `${hour.toLengthString(2)}:${minute.toLengthString(2)}`;
    if (includeSecond === true) {
        builder += `:${second.toLengthString(2)}`;
    }
    if (includeMillis === true) {
        builder += `.${millis.toLengthString(3)}`;
    }

    return builder;
}

Date.prototype.toRowFormat = function (
    includeDate?: boolean,
    includeTime?: boolean,
    includeSecond?: boolean,
    includeMillis?: boolean
): string {
    const {year, month, date} = this.splitDatePart();
    const {hour, minute, second, millis} = this.splitTimePart();
    const builder: string[] = [];
    if (includeDate === true) {
        builder.push(`${year}년`);
        builder.push(`${month}월`);
        builder.push(`${date}일`);
    }
    if (includeTime === true) {
        builder.push(`${hour}시`);
        builder.push(`${minute}분`);
    }
    if (includeSecond === true) {
        builder.push(`${second}초`);
    }
    if (includeMillis === true) {
        builder.push(millis.toString());
    }

    return StringBuilder.joinToString(builder, ' ');
}

Date.prototype.apply = function (datePart?: DatePart, timePart?: TimePart): Date {
    if (datePart) {
        const {year, month, date} = datePart;
        this.setFullYear(year, month - 1, date);
    }
    if (timePart) {
        const {hour, minute, second, millis} = timePart;
        this.setHours(hour, minute, second, millis);
    }

    return this;
}

Date.prototype.toKSTString = function (): string {
    return new Date(this.getTime() - this.getTimezoneOffset() * 60000).toISOString();
}