import {ErrorMessage, Nav, NavBarItemAction} from "../../../Common";
import {Component, MaterialModal, MaterialSelectWrapper} from "../../../Materials";
import React, {useEffect, useState} from "react";
import {AdTargetGenderIO} from "../../../../io/Ads";
import {useParams} from "react-router-dom";
import {Optional, wrapState} from "../../../../util/Types";
import {UserGender} from "../../../../modal/Users";

const pageId = 'ad_target_gender_post';
const genderSelectId = `${pageId}_difficulty_select`;
const genderWrapperId = `${pageId}_difficulty_wrapper`;

export default function Post() {
    const {adId} = useParams() as { adId?: null };
    const errorMessageState = wrapState(useState<Optional<ErrorMessage>>(null));

    useEffect(() => {
        MaterialModal.init();
        MaterialSelectWrapper.init();
    });

    useEffect(() => {
        if (errorMessageState.value) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    });

    if (adId === null || adId === undefined) {
        window.alert('잘못된 요청입니다.');
        return <></>;
    }

    const onSaveClick = () => {
        const gender = MaterialSelectWrapper.get(`#${genderWrapperId}`).getFirstSelectionAs(UserGender.ordinal);
        if (gender === null || gender === -1) {
            errorMessageState.set('성별을 선택해주세요.');
            return;
        }

        AdTargetGenderIO.post(adId, gender, onPosted, onPostError);
    };

    const onPosted = () => {
        window.alert('저장되었습니다.');
        document.location = `/ad/${adId}#ad_detail_target_gender`;
    };

    const onPostError = (error: ErrorMessage) => {
        if (typeof error === 'string' && error.includes('Duplicate entry')) {
            errorMessageState.set('이미 등록된 성별입니다.');
        } else {
            errorMessageState.set(error);
        }
    };

    const barItems: [string, NavBarItemAction][] = [
        ['저장', onSaveClick],
        ['취소', () => window.history.back()]
    ];

    return <>
        <Nav
            titleHref='/'
            titleIcon='chevron_left'
            title='대상 성별 추가'
            barItems={barItems}
            errorMessageState={errorMessageState} />
        <div className="row cascade first">
            <Component.Select
                values={UserGender.values()}
                wrapperId={genderWrapperId}
                wrapperClasses="input-field col s8 offset-s2"
                selectId={genderSelectId}
                label="성별" />
        </div>
    </>;
}