import {Optional, StatePair} from "../../util/Types";
import {AdDifficulty, AdModal, AdParticipationType, AdRewardType, AdStatus} from "../../modal/Ads";
import React, {ChangeEvent, useEffect, useState} from "react";
import {
    Component, MaterialCheckbox,
    MaterialCheckboxGroup,
    MaterialComponent,
    MaterialDatepicker,
    MaterialInput,
    MaterialModal,
    MaterialSelectWrapper,
    MaterialTextarea,
    MaterialTimepicker
} from "../Materials";
import {StorageIO} from "../../io/Services";
import {ErrorMessage, Nav, NavBarItemAction} from "../Common";
import {useParams} from "react-router-dom";
import {AdIO} from "../../io/Ads";
import {getMaxInt, S3_BUCKET_NAME} from "../../util/Environments";
import {StringBuilder} from "../../util/StringBuilder";
import {BitMask} from "../../util/BitMask";
import {IntRange, map} from "../../util/Iterators";

const pageId = 'ad_edit';
const titleInputId = `${pageId}_title`;
const descriptionInputId = `${pageId}_description`;
const scriptInputId = `${pageId}_script`;
const externalUrlInputId = `${pageId}_external_url`;
const externalUrlWingInputId = `${pageId}_external_url_wing`;
const gradingMessageInputId = `${pageId}_grading_message`
const amountInputId = `${pageId}_amount`;
const pointInputId = `${pageId}_point`;
const pointAdditionalHighAccuracyInputId = `${pageId}_point_additional_high_accuracy`;
const productInputId = `${pageId}_product`;
const participationTypeGroupId = `${pageId}_participation_type`;
const rewardTypeGroupId = `${pageId}_reward_type`;
const thumbnailImageInputId = `${pageId}_thumbnail_image`;
const headerImageInputId = `${pageId}_header_image`;
const descriptionImageInputId = `${pageId}_description_image`;
const contentVideoInputId = `${pageId}_content_video`;
const difficultySelectId = `${pageId}_difficulty_select`;
const difficultyWrapperId = `${pageId}_difficulty_wrapper`;
const minimumAccuracyInputId = `${pageId}_minimum_accuracy`;
const startDateId = `${pageId}_start_date`;
const startTimeId = `${pageId}_start_time`;
const endDateId = `${pageId}_end_date`;
const endTimeId = `${pageId}_end_time`;
const exposedDateId = `${pageId}_exposed_date`;
const exposedTimeId = `${pageId}_exposed_time`;
const announcedDateId = `${pageId}_announced_date`;
const announcedTimeId = `${pageId}_announced_time`;

export default function Edit() {
    const {adId} = useParams();
    const [ad, setAd] = useState<Optional<AdModal>>(null);
    const [isPointChecked, checkPoint] = useState<boolean>(ad?.rewardType?.toBitMask(AdRewardType.values)?.isEnabled(AdRewardType.POINT) ?? false);
    const [isProductChecked, checkProduct] = useState<boolean>(ad?.rewardType?.toBitMask(AdRewardType.values)?.isEnabled(AdRewardType.PRODUCT) ?? false);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [errorRecover, setErrorRecover] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        MaterialSelectWrapper.init();
        MaterialModal.init();
        MaterialInput.init();
        MaterialTextarea.init();
        MaterialDatepicker.init();
        MaterialTimepicker.init();
    });

    useEffect(() => {
        if (adId) {
            PrepareAd(parseInt(adId), setAd, setErrorMessage);
        }
    }, [adId]);

    useEffect(() => {
        if (ad) {
            MaterialInput.getOrNull(`#${titleInputId}`)?.setValue(ad.title);
            MaterialInput.getOrNull(`#${descriptionInputId}`)?.setValue(ad.description);
            MaterialInput.getOrNull(`#${scriptInputId}`)?.setValue(ad.script);
            MaterialInput.getOrNull(`#${externalUrlInputId}`)?.setValue(ad.externalUrl);
            MaterialTextarea.getOrNull(`#${gradingMessageInputId}`)?.setValue(ad.gradingMessage ?? "");
            MaterialInput.getOrNull(`#${amountInputId}`)?.setValue(ad.amount.toString());
            MaterialInput.getOrNull(`#${pointInputId}`)?.setValue(ad.point.toString());
            MaterialInput.getOrNull(`#${pointAdditionalHighAccuracyInputId}`)?.setValue(ad.pointAdditionalHighAccuracy.toString());
            MaterialInput.getOrNull(`#${productInputId}`)?.setValue(ad.productId?.toString() ?? "");
            MaterialCheckbox.getOrNull(`#${externalUrlWingInputId}`)?.setCheck(new BitMask<AdStatus>(ad.statusFlags, AdStatus.values).isEnabled(AdStatus.EXTERNAL_URL_WING));
            MaterialCheckboxGroup.getOrNull(`#${participationTypeGroupId}`)?.setBits(ad.participationType);
            const rewardTypeGroup = MaterialCheckboxGroup.getOrNull(`#${rewardTypeGroupId}`);
            rewardTypeGroup?.setBits(ad.rewardType);
            rewardTypeGroup?.disable();
            MaterialSelectWrapper.getOrNull(`#${difficultyWrapperId}`)?.setValue(AdDifficulty.toString(ad.difficulty));
            MaterialInput.getOrNull(`#${minimumAccuracyInputId}`)?.setValue(ad.minimumAccuracy.toString());
            MaterialDatepicker.getOrNull(`#${startDateId}`)?.setValue(ad.startAt.toDatePartString());
            MaterialTimepicker.getOrNull(`#${startTimeId}`)?.setValue(ad.startAt.toTimePartString(false, false));
            MaterialDatepicker.getOrNull(`#${endDateId}`)?.setValue(ad.endAt.toDatePartString());
            MaterialTimepicker.getOrNull(`#${endTimeId}`)?.setValue(ad.endAt.toTimePartString(false, false));
            MaterialDatepicker.getOrNull(`#${exposedDateId}`)?.setValue(ad.exposedAt.toDatePartString());
            MaterialTimepicker.getOrNull(`#${exposedTimeId}`)?.setValue(ad.exposedAt.toTimePartString(false, false));
            const announcedAt = ad.announcedAt;
            if (announcedAt !== null) {
                MaterialDatepicker.getOrNull(`#${announcedDateId}`)?.setValue(announcedAt.toDatePartString());
                MaterialTimepicker.getOrNull(`#${announcedTimeId}`)?.setValue(announcedAt.toTimePartString(false, false));
            }

            const rewardType = ad.rewardType.toBitMask(AdRewardType.values);
            if (rewardType.isEnabled(AdRewardType.POINT)) {
                checkPoint(true);
            } else if (rewardType.isEnabled(AdRewardType.PRODUCT)) {
                checkProduct(true);
            }
        }
    }, [ad]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${titleInputId}`),
            MaterialInput.getOrNull(`#${descriptionInputId}`),
            MaterialInput.getOrNull(`#${scriptInputId}`),
            MaterialInput.getOrNull(`#${amountInputId}`),
            MaterialInput.getOrNull(`#${pointInputId}`),
            MaterialInput.getOrNull(`#${pointAdditionalHighAccuracyInputId}`),
            MaterialInput.getOrNull(`#${productInputId}`),
            MaterialCheckbox.getOrNull(`#${externalUrlWingInputId}`),
            MaterialCheckboxGroup.getOrNull(`#${participationTypeGroupId}`),
            MaterialCheckboxGroup.getOrNull(`#${rewardTypeGroupId}`),
            MaterialInput.getOrNull(`#${headerImageInputId}`),
            MaterialInput.getOrNull(`#${descriptionImageInputId}`),
            MaterialInput.getOrNull(`#${contentVideoInputId}`),
            MaterialSelectWrapper.getOrNull(`#${difficultyWrapperId}`),
            MaterialInput.getOrNull(`#${minimumAccuracyInputId}`),
            MaterialDatepicker.getOrNull(`#${startDateId}`),
            MaterialTimepicker.getOrNull(`#${startTimeId}`),
            MaterialDatepicker.getOrNull(`#${endDateId}`),
            MaterialTimepicker.getOrNull(`#${endTimeId}`),
            MaterialDatepicker.getOrNull(`#${exposedDateId}`),
            MaterialTimepicker.getOrNull(`#${exposedTimeId}`),
            MaterialDatepicker.getOrNull(`#${announcedDateId}`),
            MaterialTimepicker.getOrNull(`#${announcedTimeId}`),
        ];

        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled]);

    useEffect(() => {
        if (isPointChecked) {
            MaterialInput.getOrNull(`#${pointInputId}`)?.enable();
            MaterialInput.getOrNull(`#${pointAdditionalHighAccuracyInputId}`)?.enable();
        } else {
            MaterialInput.getOrNull(`#${pointInputId}`)?.disable();
            MaterialInput.getOrNull(`#${pointAdditionalHighAccuracyInputId}`)?.disable();
        }
    }, [isPointChecked]);

    useEffect(() => {
        if (isProductChecked) {
            MaterialInput.getOrNull(`#${productInputId}`)?.enable();
            MaterialDatepicker.getOrNull(`#${announcedDateId}`)?.enable();
            MaterialTimepicker.getOrNull(`#${announcedTimeId}`)?.enable();
        } else {
            MaterialInput.getOrNull(`#${productInputId}`)?.disable();
            MaterialDatepicker.getOrNull(`#${announcedDateId}`)?.disable();
            MaterialTimepicker.getOrNull(`#${announcedTimeId}`)?.disable();
        }
    }, [isProductChecked]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage]);

    return <PageWrapper
        ad={ad}
        pointState={[isPointChecked, checkPoint]}
        productState={[isProductChecked, checkProduct]}
        errorMessageState={[errorMessage, setErrorMessage]}
        errorRecoverState={[errorRecover, setErrorRecover]}
        setRootEnabled={setRootEnabled} />
}

function PrepareAd(
    adId: number,
    setAd: React.Dispatch<Optional<AdModal>>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    AdIO.get(adId, setAd, setErrorMessage);
}

type PageWrapperProps = {
    ad: Optional<AdModal>;

    pointState: StatePair<boolean>;
    productState: StatePair<boolean>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
    errorRecoverState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [isPointChecked] = props.pointState;
    const [isProductChecked] = props.productState;
    const [, setErrorMessage] = props.errorMessageState;
    const [, setErrorRecover] = props.errorRecoverState;
    const onSaveClicked = () => OnSaveClicked(
        props.ad,
        isPointChecked,
        isProductChecked,
        setErrorMessage,
        setErrorRecover,
        props.setRootEnabled
    );
    const onCancelClicked = () => OnCancelClick(props.ad);
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", onCancelClicked]
    ];

    return <>
        <Nav
            titleHref={`/ad/${props.ad?.id}`}
            titleIcon="chevron_left"
            title={"광고 " + ((props.ad) ? "수정" : "추가")}
            barItems={barItems}
            errorMessagePair={props.errorMessageState}
            errorRecoverPair={props.errorRecoverState} />
        <Editor
            ad={props.ad}
            pointState={props.pointState}
            productState={props.productState} />
    </>;
}

function OnSaveClicked(
    ad: Optional<AdModal>,
    isPointChecked: boolean,
    isProductChecked: boolean,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const title = MaterialInput.get(`#${titleInputId}`).getValue();
    if (title.isEmpty()) {
        setErrorMessage("제목을 입력해주세요.");
        return;
    }

    const description = MaterialInput.get(`#${descriptionInputId}`).getValue();
    if (description.isEmpty()) {
        setErrorMessage("설명을 입력해주세요.");
        return;
    }

    const script = MaterialInput.get(`#${scriptInputId}`).getValue();
    if (script.isEmpty()) {
        setErrorMessage("본문을 입력해주세요.");
        return;
    }

    const externalUrl = MaterialInput.get(`#${externalUrlInputId}`).getValue();
    if (externalUrl.isEmpty()) {
        setErrorMessage("외부 연결 URL을 입력해주세요.");
        return;
    }

    const externalUrlWing = MaterialCheckbox.get(`#${externalUrlWingInputId}`).isChecked();
    const statusFlags = (externalUrlWing) ? AdStatus.EXTERNAL_URL_WING.value : 0;

    let gradingMessage: Optional<string> = MaterialInput.get(`#${gradingMessageInputId}`).getValue();
    if (gradingMessage === null || gradingMessage.isEmpty()) {
        gradingMessage = null;
    }

    const amount = MaterialInput.get(`#${amountInputId}`).getValue().toIntOrNull();
    if (!amount || amount.notIn(0, getMaxInt())) {
        setErrorMessage("수량을 확인해주세요.");
        return;
    }

    const point = MaterialInput.get(`#${pointInputId}`).getValue().toIntOrElse(() => 0);
    const pointAdditionalHighAccuracy = MaterialInput.get(`#${pointAdditionalHighAccuracyInputId}`).getValue().toIntOrNull();
    if (isPointChecked && (pointAdditionalHighAccuracy === null || pointAdditionalHighAccuracy.notIn(0, getMaxInt()))) {
        setErrorMessage("높은 일치율 추가 포인트를 확인해주세요.");
        return;
    }

    const productId = (isProductChecked)
        ? ad?.productId ?? MaterialInput.get(`#${productInputId}`)?.getValue()?.toInt()
        : null;

    const participationType = MaterialCheckboxGroup.get(`#${participationTypeGroupId}`).getBits().toBitMask(AdParticipationType.values);
    if (participationType.value === 0) {
        setErrorMessage("참여 유형을 선택해주세요.");
        return;
    }

    const rewardType = ad?.rewardType ?? MaterialCheckboxGroup.get(`#${rewardTypeGroupId}`).getBits();
    if (rewardType === 0) {
        setErrorMessage("보상 유형을 선택해주세요.");
        return;
    }

    const thumbnailImageInput = MaterialInput.get(`#${thumbnailImageInputId}`);
    const thumbnailImageCount = thumbnailImageInput.getFileLength();
    if (!ad && thumbnailImageCount === 0) {
        setErrorMessage("썸네일 이미지를 선택해주세요.");
        return;
    }

    const headerImageInput = MaterialInput.get(`#${headerImageInputId}`);
    const headerImageCount = headerImageInput.getFileLength();
    if (!ad && headerImageCount === 0) {
        setErrorMessage("상단 이미지를 선택해주세요.");
        return;
    }

    const descriptionImageInput = MaterialInput.get(`#${descriptionImageInputId}`);
    const descriptionImageCount = descriptionImageInput.getFileLength();
    if (!ad && descriptionImageCount === 0) {
        setErrorMessage("설명 이미지를 선택해주세요.");
        return;
    }

    const contentVideoInput = MaterialInput.get(`#${contentVideoInputId}`);
    const contentVideoCount = contentVideoInput.getFileLength();
    if (!ad && contentVideoCount === 0) {
        setErrorMessage("내용 비디오를 선택해주세요.");
        return;
    }

    const difficulty = MaterialSelectWrapper.get(`#${difficultyWrapperId}`).getFirstSelectionAs(AdDifficulty.ordinal);
    if (difficulty === null) {
        setErrorMessage("난이도를 선택해주세요.");
        return;
    }

    const minimumAccuracy = MaterialInput.get(`#${minimumAccuracyInputId}`).getValue().toInt();
    if (minimumAccuracy.notIn(0, 101)) {
        setErrorMessage("합격 최소 일치율을 확인해주세요.");
        return;
    }

    const [startYear, startMonth, startDate] = MaterialDatepicker.get(`#${startDateId}`).getDatePart();
    const [startHour, startMinute] = MaterialTimepicker.get(`#${startTimeId}`).getTimePart();
    const startAt = new Date(startYear, startMonth, startDate, startHour, startMinute);
    const [endYear, endMonth, endDate] = MaterialDatepicker.get(`#${endDateId}`).getDatePart();
    const [endHour, endMinute] = MaterialTimepicker.get(`#${endTimeId}`).getTimePart();
    const endAt = new Date(endYear, endMonth, endDate, endHour, endMinute);
    const [exposedYear, exposedMonth, exposedDate] = MaterialDatepicker.get(`#${exposedDateId}`).getDatePart();
    const [exposedHour, exposedMinute] = MaterialTimepicker.get(`#${exposedTimeId}`).getTimePart();
    const exposedAt = new Date(exposedYear, exposedMonth, exposedDate, exposedHour, exposedMinute);
    const announcedDatePart = MaterialDatepicker.get(`#${announcedDateId}`).getDatePartOrNull();
    const announcedTimePart = MaterialTimepicker.get(`#${announcedTimeId}`).getTimePartOrNull();
    const announcedAt = (isProductChecked && announcedDatePart && announcedTimePart)
        ? new Date().apply(announcedDatePart, announcedTimePart)
        : null;

    const onSucceed = () => {
        window.alert('저장되었습니다.');
        document.location = `/ad/list`;
    };

    const onUploadError = (ad: AdModal, succeeded: string[], error: string) => {
        MaterialModal.getOrNull('#error_modal')?.setOnDismiss(() => { document.location = "/ad/list"; });
        const succeededLine = (succeeded.isNotEmpty()) ? StringBuilder.joinToString(succeeded, ", ", "성공한 항목: ") : "";
        setErrorRecover([
            'AWS에 접속하여 오류가 발생한 이미지 또는 비디오를 추가해야 합니다. 필요하다면 폴더를 생성해야 할 수 있습니다.',
            succeededLine,
            `https://s3.console.aws.amazon.com/s3/buckets/${S3_BUCKET_NAME}?region=ap-northeast-2&prefix=ad/${ad.id}`
        ]);
        setErrorMessage(error);
    };

    const onReady = (ad: AdModal) => {
        StorageIO.uploadMaterialFiles(ad, [
            { input: thumbnailImageInput, path: AdModal.thumbnailImagePath, onSucceedText: '썸네일 이미지' },
            { input: headerImageInput, path: AdModal.headerImagePath, onSucceedText: '상단 이미지' },
            { input: descriptionImageInput, path: AdModal.descriptionImagePath, onSucceedText: '설명 이미지' },
            { input: contentVideoInput, path: AdModal.contentVideoPath, onSucceedText: '내용 비디오' },
        ], onSucceed, (succeeded, error) => onUploadError(ad, succeeded, error));
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    if (ad &&
        ad.title === title &&
        ad.description === description &&
        ad.script === script &&
        ad.externalUrl === externalUrl &&
        ad.gradingMessage === gradingMessage &&
        ad.amount === amount &&
        ad.point === point &&
        ad.pointAdditionalHighAccuracy === pointAdditionalHighAccuracy &&
        ad.productId === productId &&
        ad.participationType === participationType.value &&
        ad.rewardType === rewardType &&
        ad.difficulty === difficulty &&
        ad.minimumAccuracy === minimumAccuracy &&
        ad.statusFlags === statusFlags &&
        ad.startAt.getTime() === startAt.getTime() &&
        ad.endAt.getTime() === endAt.getTime() &&
        ad.exposedAt.getTime() === exposedAt.getTime() &&
        ad.announcedAt?.getTime() === announcedAt?.getTime()
    ) {
        if (thumbnailImageCount === 0 &&
            headerImageCount === 0 &&
            descriptionImageCount === 0
        ) {
            onSucceed();
        } else {
            onReady(ad);
        }
        return;
    }

    if (ad) {
        const nextHeaderImageCount = (headerImageCount) ? headerImageCount : ad.headerImageCount;
        const nextDescriptionImageCount = (descriptionImageCount) ? descriptionImageCount : ad.descriptionImageCount;
        AdIO.update(
            ad.id, title, description, script, externalUrl, gradingMessage, amount, point, pointAdditionalHighAccuracy ?? 0, productId,
            participationType, new BitMask<AdRewardType>(rewardType, AdRewardType.values), nextHeaderImageCount,
            nextDescriptionImageCount, difficulty, minimumAccuracy, externalUrlWing, startAt, endAt, exposedAt,
            announcedAt, onReady, onError
        );
    } else {
        AdIO.post(
            title, description, script, externalUrl, gradingMessage, amount, point, pointAdditionalHighAccuracy ?? 0, productId,
            participationType, new BitMask<AdRewardType>(rewardType, AdRewardType.values), headerImageCount,
            descriptionImageCount, difficulty, minimumAccuracy, externalUrlWing, startAt, endAt, exposedAt,
            announcedAt, onReady, onError
        );
    }
}

function OnCancelClick(ad: Optional<AdModal>) {
    document.location = (ad) ? `/ad/${ad.id}` : '/ad/list';
}

type EditorProps = {
    ad: Optional<AdModal>,
    pointState: StatePair<boolean>;
    productState: StatePair<boolean>;
};

function Editor(props: EditorProps) {
    const ad = props.ad;
    const [isPointChecked, checkPoint] = props.pointState;
    const [isProductChecked, checkProduct] = props.productState;

    // 신규 등록이어야 하고(이미 등록된 광고는 상품 ID를 수정해서는 안 됨), 보상 유형이 상품일 때
    const isProductIdEnabled = isProductChecked && !ad
    // 보상 유형이 상품일 때에만 활성화
    const isAnnounceEnabled = isProductChecked;

    const MediaWrapper = ({children}: {children: JSX.Element | JSX.Element[]}) =>
        <div className="row">
            <div className="col s8 offset-s2">
                {children}
            </div>
        </div>;

    let thumbnailImageWrapper: JSX.Element;
    if (!ad) {
        thumbnailImageWrapper = <></>;
    } else {
        thumbnailImageWrapper = <MediaWrapper><StorageIO.StorageImage objectKey={AdModal.thumbnailImagePath(ad)} style={{ maxWidth: '100%' }} /></MediaWrapper>;
    }

    let headerImageWrapper: JSX.Element;
    if (!ad || ad.headerImageCount === 0) {
        headerImageWrapper = <></>;
    } else {
        const headerImages = map(new IntRange(0, ad.headerImageCount), index => <StorageIO.StorageImage key={index} objectKey={AdModal.headerImagePath(ad,index)} style={{ maxWidth: '100%' }} />);
        headerImageWrapper = <MediaWrapper>{headerImages}</MediaWrapper>;
    }

    let descriptionImageWrapper: JSX.Element;
    if (!ad || ad.descriptionImageCount === 0) {
        descriptionImageWrapper = <></>;
    } else {
        const descriptionImages = map(new IntRange(0, ad.descriptionImageCount), index => <StorageIO.StorageImage key={index} objectKey={AdModal.descriptionImagePath(ad, index)} style={{ maxWidth: '100%' }} />);
        descriptionImageWrapper = <MediaWrapper>{descriptionImages}</MediaWrapper>;
    }

    let contentVideoWrapper: JSX.Element;
    if (!ad) {
        contentVideoWrapper = <></>;
    } else {
        contentVideoWrapper = <MediaWrapper>
            <StorageIO.StorageVideo
                objectKey={AdModal.contentVideoPath(ad)}
                style={{ maxWidth: '100%' }}
                controls />
        </MediaWrapper>;
    }

    const rewardTypeCheckedIndices = ad?.rewardType?.toBitMask(AdRewardType.values)?.indices() ?? [];
    const onRewardTypeChange = new Map([
        [0, (event: ChangeEvent<HTMLInputElement>) => { checkPoint(event.target.checked); }],
        [1, (event: ChangeEvent<HTMLInputElement>) => { checkProduct(event.target.checked); }]
    ]);

    return <>
        <div className="row" style={{ marginTop: "2rem" }}>
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={titleInputId}
                inputClasses="validate"
                inputPlaceHolder="100자 이내"
                inputDataLength={100}
                label="제목" />
        </div>
        <div className="row">
            <Component.Textarea
                formClasses="col s8 offset-s2"
                textareaId={descriptionInputId}
                textareaClasses="validate"
                textareaPlaceHolder="100자 이내"
                textareaDataLength={100}
                label="설명" />
        </div>
        <div className="row">
            <Component.Textarea
                formClasses="col s8 offset-s2"
                textareaId={scriptInputId}
                textareaClasses="validate"
                textareaPlaceHolder="1000자 이내"
                textareaDataLength={1000}
                label="본문" />
        </div>
        <div className="row" style={{ marginTop: "2rem" }}>
            <Component.Input
                formClasses="col s4 offset-s2"
                inputId={externalUrlInputId}
                inputClasses="validate"
                inputPlaceHolder="1000자 이내"
                inputDataLength={1000}
                inputType="url"
                label="외부 연결 URL" />
            <Component.Checkbox
                formClasses="col s4"
                inputId={externalUrlWingInputId}
                text={"클릭 시 날개 지급"} />
        </div>
        <div className="row">
            <Component.Textarea
                formClasses="col s8 offset-s2"
                textareaId={gradingMessageInputId}
                label="채점 중 표시할 텍스트" />
        </div>
        <div className="row">
            <Component.Input
                formClasses="col s4 offset-s2"
                inputId={amountInputId}
                inputType="number"
                inputClasses="validate"
                label="수량" />
            <Component.Input
                formClasses="col s4"
                inputId={productInputId}
                inputType="number"
                inputClasses="validate"
                enabled={isProductIdEnabled}
                label="상품 ID" />
        </div>
        <div className="row">
            <Component.Input
                formClasses="col s4 offset-s2"
                inputId={pointInputId}
                inputType="number"
                inputClasses="validate"
                enabled={isPointChecked}
                label="포인트" />
            <Component.Input
                formClasses="col s4 offset"
                inputId={pointAdditionalHighAccuracyInputId}
                inputType="number"
                inputClasses="validate"
                enabled={isPointChecked}
                label="높은 일치율 추가 포인트" />
        </div>
        <div className="row">
            <Component.Select
                values={AdDifficulty.values()}
                wrapperId={difficultyWrapperId}
                wrapperClasses="input-field col s4 offset-s2"
                selectId={difficultySelectId}
                label="난이도" />
        </div>
        <div className="row">
            <Component.CheckboxGroup
                formId={participationTypeGroupId}
                formClasses="input-field col s4 offset-s2"
                values={AdParticipationType.values}
            />
            <Component.CheckboxGroup
                formId={rewardTypeGroupId}
                formClasses="input-field col s4"
                values={AdRewardType.values}
                checkedIndices={rewardTypeCheckedIndices}
                onChanges={onRewardTypeChange} />
        </div>
        <div className="row">
            <Component.Input
                formClasses="col s4 offset-s2"
                inputId={minimumAccuracyInputId}
                inputType="number"
                inputClasses="validate"
                inputMax={100}
                label="합격 최소 일치율" />
        </div>
        <div className="row">
            <Component.Datepicker
                formClasses="col s4 offset-s2"
                inputId={startDateId}
                label="시작 날짜" />
            <Component.Timepicker
                formClasses="col s4"
                inputId={startTimeId}
                label="시작 시각" />
        </div>
        <div className="row">
            <Component.Datepicker
                formClasses="col s4 offset-s2"
                inputId={endDateId}
                label="종료 날짜" />
            <Component.Timepicker
                formClasses="col s4"
                inputId={endTimeId}
                label="종료 시각" />
        </div>
        <div className="row">
            <Component.Datepicker
                formClasses="col s4 offset-s2"
                inputId={exposedDateId}
                label="노출 시작 날짜" />
            <Component.Timepicker
                formClasses="col s4"
                inputId={exposedTimeId}
                label="노출 시작 시각" />
        </div>
        <div className="row">
            <Component.Datepicker
                formClasses="col s4 offset-s2"
                inputId={announcedDateId}
                enabled={isAnnounceEnabled}
                label="발표 날짜" />
            <Component.Timepicker
                formClasses="col s4"
                inputId={announcedTimeId}
                enabled={isAnnounceEnabled}
                label="발표 시각" />
        </div>
        <div className="row">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                inputId={thumbnailImageInputId}
                inputLabel="썸네일 이미지 선택"
                multiple={true}
                accept="image/png" />
        </div>
        {thumbnailImageWrapper}
        <div className="row">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                inputId={headerImageInputId}
                inputLabel="상단 이미지 선택"
                multiple={true}
                accept="image/png" />
        </div>
        {headerImageWrapper}
        <div className="row">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                inputId={descriptionImageInputId}
                inputLabel="설명 이미지 선택"
                multiple={true}
                accept="image/png" />
        </div>
        {descriptionImageWrapper}
        <div className="row">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                inputId={contentVideoInputId}
                inputLabel="내용 비디오 선택"
                accept="video/mp4" />
        </div>
        {contentVideoWrapper}
    </>;
}