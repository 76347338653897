import {FaqCategoryModal} from "../../../../modal/Miscs";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../../util/Types";
import {ErrorMessage, Nav} from "../../../Common";
import {MiscFaqCategoryIO} from "../../../../io/Miscs";
import {MaterialModal} from "../../../Materials";
import {BaseResponse} from "../../../../util/Reponses";
import {UserSessionModal} from "../../../../modal/Users";

const pageId = 'faq_category_list';
const deleteModalId = `${pageId}_delete_modal`;

export default function List() {
    const [contents, setContents] = useState<Optional<FaqCategoryModal[]>>(null);
    const [selectedContent, selectContent] = useState<Optional<FaqCategoryModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
    });

    useEffect(() => {
        MiscFaqCategoryIO.listAll(setContents, setErrorMessage);
    }, []);

    useEffect(() => {
        if (selectedContent) {
            MaterialModal.getOrNull(`#${deleteModalId}`)?.open();
        } else {
            MaterialModal.getOrNull(`#${deleteModalId}`)?.close();
        }
    }, [selectedContent]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contents={contents}
            selectedContentState={[selectedContent, selectContent]}
            errorMessageState={[errorMessage, setErrorMessage]} />
    </>;
}

type PageWrapperProps = {
    contents: Optional<FaqCategoryModal[]>;
    selectedContentState: StatePair<Optional<FaqCategoryModal>>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const [selectedContent, selectContent] = props.selectedContentState;
    const [, setErrorMessage] = props.errorMessageState;
    const rows = (props.contents ?? []).map(content => <Row key={content.id} content={content} selectContent={selectContent} />);
    return <>
        <Nav
            title="자주 묻는 질문 카테고리"
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            errorMessagePair={props.errorMessageState} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>이름</th>
                <th>부모 카테고리 ID</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="fixed-action-btn">
            <a className="btn-floating btn-large secondary" href='/misc/faq/category/post'><i className="large material-icons">add</i></a>
        </div>
        <DeleteModal selectedContent={selectedContent} setErrorMessage={setErrorMessage} />
    </>;
}

type RowProps = {
    content: FaqCategoryModal;
    selectContent: React.Dispatch<Optional<FaqCategoryModal>>;
};

function Row(props: RowProps) {
    const content = props.content;
    if (!content) {
        return <tr />;
    }

    let parentIdContainer = <td></td>;
    if (content.parentId) {
        parentIdContainer = <td>
            <a href={`/misc/faq/category/${content.parentId}`}>{content.parentId}</a>
        </td>;
    }

    return <tr>
        <td>{content.id}</td>
        <td>{content.name}</td>
        {parentIdContainer}
        <td>
            <a href={`/misc/faq/category/${content.id}`} className="modal-trigger clickable">
                <i className="material-icons black-text">open_in_new</i>
            </a>
            <a href={`/misc/faq/category/${content.id}/edit`} className="modal-trigger clickable">
                <i className="material-icons black-text">edit</i>
            </a>
            <a onClick={() => props.selectContent(content)} className="modal-trigger clickable">
                <i className="material-icons black-text">clear</i>
            </a>
        </td>
    </tr>;
}

type DeleteModalProps = {
    selectedContent: Optional<FaqCategoryModal>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function DeleteModal(props: DeleteModalProps) {
    const onDeleteClicked = () => {
        if (props.selectedContent) {
            OnDeleteConfirmClicked(props.selectedContent, props.setErrorMessage);
        }
    };

    return <div id={deleteModalId} className="modal">
        <div className="modal-content">
            <h4>삭제</h4>
            <p>이 항목을 삭제하시겠습니까? 이 항목의 ID를 포함하는 모든 항목이 수정 또는 삭제됩니다.</p>
            <ul className="browser-default">
                <li className="browser-default">환경 데이터 &gt; 자주 묻는 질문: 모든 하위 항목이 삭제됩니다.</li>
            </ul>
        </div>
        <div className="modal-footer">
            <a onClick={onDeleteClicked} className="waves-effect waves-red btn-flat red-text">삭제</a>
            <a className="modal-close waves-effect btn-flat black-text">취소</a>
        </div>
    </div>;
}

function OnDeleteConfirmClicked(
    content: FaqCategoryModal,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
) {
    const onReady = (response: BaseResponse) => {
        window.alert('삭제되었습니다.');
        document.location = '/misc/faq/category/list';
    };

    MiscFaqCategoryIO.delete(
        UserSessionModal.loadFromStorage()!.id,
        content.id,
        onReady,
        setErrorMessage
    );
}