import {useParams} from "react-router-dom";
import {Optional, parseIntOrNull, StateWrapper, wrapState} from "../../util/Types";
import React, {useEffect, useState} from "react";
import {GifticonModal} from "../../modal/Gifticons";
import {ErrorMessage, Nav, NavBarItemAction} from "../Common";
import {Component, MaterialComponent, MaterialInput, MaterialModal} from "../Materials";
import {GifticonIO} from "../../io/Gifticons";
import {StringBuilder} from "../../util/StringBuilder";
import {StorageIO} from "../../io/Services";
import {Logger} from "../../util/Environments";

const pageId = 'gifticon_edit';
const brandInputId = `${pageId}_brand`;
const titleInputId = `${pageId}_title`;
const pointInputId = `${pageId}_point`;
const durationInputId = `${pageId}_duration`;
const descriptionImageInputId = `${pageId}_description_image`;
const barcodeImageInputId = `${pageId}_barcode_image`;

type Params = {
    id?: string;
};

export default function Edit() {
    const params = useParams<Params>();
    const contentState = wrapState(useState<Optional<GifticonModal>>(null));
    const errorMessageState = wrapState(useState<Optional<ErrorMessage>>(null));
    const errorRecoverState = wrapState(useState<Optional<ErrorMessage>>(null));
    const rootEnabledState = wrapState(useState<boolean>(true));

    useEffect(() => {
        MaterialModal.init();
        MaterialInput.init();
    });

    useEffect(() => {
        const id = PrepareContentId(params.id);
        if (id === null) {
            return;
        }

        PrepareContent(id, contentState.set, errorMessageState.set);
    }, [params.id]);

    useEffect(() => {
        const content = contentState.value;
        if (content === null) {
            return;
        }

        const brandInput = MaterialInput.getOrNull(`#${brandInputId}`);
        brandInput?.setValue(content.brandId.toString());
        brandInput?.disable();
        MaterialInput.getOrNull(`#${titleInputId}`)?.setValue(content.title.toString());
        MaterialInput.getOrNull(`#${pointInputId}`)?.setValue(content.point.toString());
        MaterialInput.getOrNull(`#${durationInputId}`)?.setValue(content.duration.toString());
    }, [contentState.value]);

    useEffect(() => {
        const isRootEnabled = rootEnabledState.value;
        const inputs = [
            MaterialInput.getOrNull(`#${brandInputId}`),
            MaterialInput.getOrNull(`#${titleInputId}`),
            MaterialInput.getOrNull(`#${pointInputId}`),
            MaterialInput.getOrNull(`#${durationInputId}`)
        ];

        if (isRootEnabled) {
            MaterialComponent.enable(...inputs);
            inputs[0]?.setEnabled(contentState.value === null);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [contentState.value, rootEnabledState.value]);

    useEffect(() => {
        if (errorMessageState.value) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessageState.value]);

    return <PageWrapper
        content={contentState.value}
        errorMessageState={errorMessageState}
        errorRecoverState={errorRecoverState}
        setRootEnabled={rootEnabledState.set} />;
}

function PrepareContentId(rawId: string | undefined): Optional<number> {
    if (rawId === undefined) {
        return null;
    }

    const id = parseIntOrNull(rawId);
    if (id === null) {
        window.alert('잘못된 접근입니다.');
        window.history.back();
        return null;
    } else {
        return id;
    }
}

function PrepareContent(
    id: number,
    setContent: React.Dispatch<Optional<GifticonModal>>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    GifticonIO.get(id, setContent, setErrorMessage);
}

type PageWrapperProps = {
    content: Optional<GifticonModal>,

    errorMessageState: StateWrapper<Optional<ErrorMessage>>,
    errorRecoverState: StateWrapper<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
};

function PageWrapper(props: PageWrapperProps) {
    const onSaveClicked = () => OnSaveClicked(props);
    const onCancelClicked = () => OnCancelClick();
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", onCancelClicked]
    ];

    return <>
        <Nav
            titleHref={`/gifticon/list`}
            titleIcon="chevron_left"
            title={"기프티콘 " + ((props.content) ? "수정" : "추가")}
            barItems={barItems}
            errorMessageState={props.errorMessageState}
            errorRecoverState={props.errorRecoverState}
            onErrorDismiss={() => document.location = '/gifticon/list'} />
        <Editor />
    </>;
}

function OnSaveClicked(props: PageWrapperProps) {
    const present = props.content;

    const brandId = MaterialInput.get(`#${brandInputId}`).getValue().toIntOrNull();
    if (brandId === null) {
        props.errorMessageState.set('브랜드 ID를 확인해주세요.');
        return;
    }

    const title = MaterialInput.get(`#${titleInputId}`).getValue();
    if (title.isEmpty()) {
        props.errorMessageState.set("제목을 입력해주세요.");
        return;
    }

    const point = MaterialInput.get(`#${pointInputId}`).getValue().toIntOrNull();
    if (point === null) {
        props.errorMessageState.set('포인트를 확인해주세요.');
        return;
    }

    const duration = MaterialInput.get(`#${durationInputId}`).getValue().toIntOrNull();
    if (duration === null) {
        props.errorMessageState.set('사용 기간을 확인해주세요.');
        return;
    }

    const descriptionImageInput = MaterialInput.get(`#${descriptionImageInputId}`);
    const descriptionImageCount = descriptionImageInput.getFileLength();
    if (present === null && descriptionImageCount === 0) {
        props.errorMessageState.set('이미지를 선택해주세요.');
        return;
    }

    const barcodeImageInput = MaterialInput.get(`#${barcodeImageInputId}`);
    const barcodeImageCount = barcodeImageInput.getFileLength();
    Logger.log('barcodeImageCount', barcodeImageCount);
    if (present === null && barcodeImageCount === 0) {
        props.errorMessageState.set('바코드를 선택해주세요.');
        return;
    }

    const onSucceed = () => {
        window.alert('저장되었습니다.');
        document.location = '/gifticon/list';
    };

    const onUploadError = (content: GifticonModal, succeeded: string[], error: string) => {
        const succeededLine = (succeeded.isNotEmpty()) ? StringBuilder.joinToString(succeeded, ", ", "성공한 항목: ") : "";
        props.errorRecoverState.set([
            'AWS에 접속하여 오류가 발생한 이미지 또는 비디오를 추가해야 합니다. 필요하다면 폴더를 생성해야 할 수 있습니다.',
            succeededLine,
            StorageIO.createPath(`gifticon/${content.id}`)
        ]);
        props.errorMessageState.set(error);
    }

    const onReady = (content: GifticonModal) => {
        Logger.log('onReady', content);
        StorageIO.uploadMaterialFiles(content, [
            { input: descriptionImageInput, path: GifticonModal.descriptionImageKey, onSucceedText: '이미지' },
            { input: barcodeImageInput, path: GifticonModal.barcodeImageKey, onSucceedText: '바코드', startIndex: present?.amount ?? 0 },
        ], onSucceed, (succeeded, error) => onUploadError(content, succeeded, error));
    };

    const onError = (error: string) => {
        props.errorMessageState.set(error);
        props.setRootEnabled(true);
    };

    if (present &&
        present.title === title &&
        present.point === point &&
        descriptionImageCount === 0 &&
        barcodeImageCount === 0 &&
        present.duration === duration
    ) {
        onSucceed();
        return;
    }

    if (present) {
        GifticonIO.update(present.id, title, point, present.amount + barcodeImageCount, duration, onReady, onError);
    } else {
        GifticonIO.post(brandId, title, point, barcodeImageCount, duration, onReady, onError);
    }
}

function OnCancelClick() {
    document.location = '/gifticon/list';
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={brandInputId}
                inputType="number"
                inputClasses="validate"
                label="브랜드 ID" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={titleInputId}
                inputClasses="validate"
                inputPlaceHolder="100자 이내"
                inputDataLength={100}
                label="제목" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s4 offset-s2"
                inputId={pointInputId}
                inputType="number"
                inputClasses="validate"
                label="포인트 ID" />
            <Component.Input
                formClasses="col s4"
                inputId={durationInputId}
                inputType="number"
                inputClasses="validate"
                label="사용 기간" />
        </div>
        <div className="row cascade">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                inputId={descriptionImageInputId}
                inputLabel="이미지 선택"
                multiple={false}
                accept="image/png" />
        </div>
        <div className="row cascade">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                inputId={barcodeImageInputId}
                inputLabel="바코드 선택"
                multiple={true}
                accept="image/png" />
        </div>
    </>;
}












