import {Optional, useWrapper} from "../../../../util/Types";
import {HomeBestModal} from "../../../../modal/Miscs";
import React, {useEffect} from "react";
import {MiscHomeBestIO} from "../../../../io/Miscs";
import {ErrorMessage, Nav} from "../../../Common";
import {FloatingActionButton} from "../../../common/FloatingActionButton";

export function List() {
    const contents = useWrapper<HomeBestModal[]>([])
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)
    const rows = useWrapper<JSX.Element[]>([])
    useEffect(() => M.AutoInit())
    useEffect(() => MiscHomeBestIO.listAll(
        response => contents.set(response.sort((a, b) => b.id.compareTo(a.id))),
        errorMessage.set
    ), [])
    useEffect(() => rows.set(contents.value.map(content => <Row key={content.id} {...content} />)), [contents.value])

    return <>
        <Nav
            title={'홈 베스트'}
            titleHref={'/'}
            titleIcon={'chevron_left'}
            errorMessageState={errorMessage} />
        <table className={'centered highlight'}>
            <thead>
            <tr>
                <th>ID</th>
                <th>광고 ID</th>
                <th>암기플러스 ID</th>
                <th>공지사항 ID</th>
                <th>표시 순서</th>
                <th>생성 시각</th>
            </tr>
            </thead>
            <tbody>{rows.value}</tbody>
        </table>
        <FloatingActionButton
            href={'/misc/home/best/post'}
            iconProps={{ iconName: 'add' }} />
    </>
}

function Row(content: HomeBestModal) {
    return <>
        <tr>
            <td><a href={`/misc/home/best/${content.id}`}>{content.id.toString()}</a></td>
            <td>{content.adId ? <a href={`/ad/${content.adId}`}>{content.adId.toString()}</a> : '-'}</td>
            <td>{content.m10nId ? <a href={`/m10n/${content.m10nId}`}>{content.m10nId.toString()}</a> : '-'}</td>
            <td>{content.noticeId ? <a href={`/misc/notice/${content.noticeId}`}>{content.noticeId.toString()}</a> : '-'}</td>
            <td>{content.sortOrder}</td>
            <td>{content.createdAt.toRowFormat(true, true)}</td>
        </tr>
    </>
}