import {Optional, parseIntOrNull} from "./Types";

type Consumer<E> = (element: E) => void;

type Function<E, R> = (element: E) => R;

type IndexedFunction<E, R> = (index: number, element: E) => R;

type Predicate<E> = (element: E) => boolean;

type MutableCollection<E> = {
    push: (...elements: E[]) => number;
}

type Destination<E> = Array<E> | MutableCollection<E>;

export function repeat(n: number, action: Consumer<number>) {
    for (const i of new IntRange(0, n)) {
        action(i);
    }
}

export function map<E, R>(from: Iterable<E>, transform: Function<E, R>): R[] {
    return mapTo([], from, transform);
}

export function mapTo<E, R, C extends Destination<R>>(dest: C, from: Iterable<E>, transform: Function<E, R>): C {
    for (const element of from) {
        dest.push(transform(element));
    }

    return dest;
}

export function mapNotNull<E, R>(from: Iterable<E>, transform: Function<E, Optional<R>>): R[] {
    return mapNotNullTo([], from, transform);
}

export function mapNotNullTo<E, R, C extends Array<R> | MutableCollection<R>>(dest: C, from: Iterable<E>, transform: Function<E, Optional<R>>): C {
    for (const element of from) {
        const transformed = transform(element);
        if (transformed) {
            dest.push(transformed);
        }
    }

    return dest;
}

export function mapNotNullIndexed<E, R>(from: Iterable<E>, transform: IndexedFunction<E, Optional<R>>): R[] {
    return mapNotNullIndexedTo([], from, transform);
}

export function mapNotNullIndexedTo<E, R, C extends Destination<R>>(dest: C, from: Iterable<E>, transform: IndexedFunction<E, Optional<R>>): C {
    let index = 0;
    for (const element of from) {
        const transformed = transform(index++, element);
        if (transformed) {
            dest.push(transformed);
        }
    }

    return dest;
}

export function fold<E, R>(from: Iterable<E>, initial: R, operation: (acc: R, element: E) => R): R {
    let accumulate = initial;
    for (const element of from) {
        accumulate = operation(accumulate, element);
    }

    return accumulate;
}

export function foldIndexed<E, R>(from: Iterable<E>, initial: R, operation: (index: number, acc: R, element: E) => R): R {
    let accumulate = initial;
    let index = 0;
    for (const element of from) {
        accumulate = operation(index++, accumulate, element);
    }

    return accumulate;
}

export function firstIndexOf<E>(from: Iterable<E>, what: E): number {
    return firstIndexWith(from, element => element === what);
}

export function firstIndexWith<E>(from: Iterable<E>, predicate: Predicate<E>): number {
    let index = 0;
    for (const element of from) {
        if (predicate(element)) {
            return index;
        }

        index++;
    }

    return -1;
}

export function filter<E>(from: Iterable<E>, predicate: Predicate<E>): E[] {
    return filterTo([], from, predicate);
}

export function filterTo<E, C extends Destination<E>>(dest: C, from: Iterable<E>, predicate: Predicate<E>): C {
    for (const element of from) {
        if (predicate(element)) {
            dest.push(element);
        }
    }

    return dest;
}

export function find<E>(from: Iterable<E>, predicate: Predicate<E>): Optional<E> {
    for (const element of from) {
        if (predicate(element)) {
            return element;
        }
    }

    return null;
}

export function withIndex<E>(from: Iterable<E>): Iterable<[number, E]> {
    return new IndexedIterable(from);
}

export class EnumValueIterator implements Iterable<string> {
    private readonly keys: string[];

    constructor(_enum: object) {
        this.keys = Object.keys(_enum).filter(value => parseIntOrNull(value) === null);
    }

    [Symbol.iterator](): Iterator<string> {
        const iterator = this.keys[Symbol.iterator]();
        return {
            next(...args): IteratorResult<string> {
                return iterator.next();
            }
        }
    }
}

class IntRangeIterator implements Iterator<number> {
    private readonly startInclusive: number;
    private readonly endExclusive: number;
    private cursor: number;

    constructor(startInclusive: number, endExclusive: number) {
        this.startInclusive = startInclusive;
        this.endExclusive = endExclusive;
        this.cursor = startInclusive;
    }

    next(...args: [] | [undefined]): IteratorResult<number, number> {
        return {
            value: this.cursor++,
            done: this.cursor > this.endExclusive
        };
    }
}

export class IntRange implements Iterable<number> {
    private readonly startInclusive: number;
    private readonly endExclusive: number;

    constructor(startInclusive: number, endExclusive: number) {
        this.startInclusive = startInclusive;
        this.endExclusive = endExclusive;
    }

    [Symbol.iterator](): Iterator<number> {
        return new IntRangeIterator(this.startInclusive, this.endExclusive);
    }
}

class IndexedIterable<E> implements Iterable<[number, E]> {
    private readonly iterable: Iterable<E>;

    constructor(iterable: Iterable<E>) {
        this.iterable = iterable;
    }

    [Symbol.iterator](): Iterator<[number, E]> {
        let index = 0;
        const iterator = this.iterable[Symbol.iterator]();
        return {
            next(...args): IteratorResult<[number, E]> {
                const {done, value} = iterator.next();
                return {
                    done: done,
                    value: [index++, value]
                }
            }
        };
    }
}