import {OnArrayResponse, OnErrorResponse} from "../../../../util/Reponses";
import {ProductReviewReportModal} from "../../../../modal/Products";
import {Optional, StateWrapper, wrapState} from "../../../../util/Types";
import {UserGender} from "../../../../modal/Users";
import React, {ChangeEvent, useEffect, useState} from "react";
import {BottomProgress, ErrorMessage, Nav} from "../../../Common";
import {Component, MaterialDatepicker, MaterialInput, MaterialModal, MaterialSelectWrapper} from "../../../Materials";
import {ProductReviewReportIO} from "../../../../io/Products";
import {getMaxDate, Logger} from "../../../../util/Environments";

enum SortOption {
    none = "없음",
    reporterId = "신고자 ID",
    reviewId = "리뷰 ID",
    reportCause = "신고 사유",
    reportedAt = "신고 일자"
}

const pageId = 'product_review_report_list';
const sortOptionModalId = `${pageId}_sort_option`
const sortOptionModalSelectId = `${pageId}_sort_option_select`;
const sortOptionModalSelectWrapperId = `${pageId}_sort_option_select_wrapper`;
const sortOptionModalReporterIdInputId = `${pageId}_sort_option_product_id_input`;
const sortOptionModalReviewIdInputId = `${pageId}_sort_option_user_age_input`;
const sortOptionModalCauseIdInputId = `${pageId}_sort_option_user_gender_select`;
const sortOptionModalUserGenderSelectWrapperId = `${pageId}_sort_option_user_gender_select_wrapper`;
const sortOptionModalRegionIdInputId = `${pageId}_sort_option_region_id_input`;

type Request<T> = (
    filter: T,
    lastReportedAt: Date,
    onReady: OnArrayResponse<ProductReviewReportModal>,
    onError: OnErrorResponse
) => void;

type SortOptionCollection = {
    contentsPair: StateWrapper<Optional<ProductReviewReportModal[]>>;
    sortOptionPair: StateWrapper<SortOption>;
    reporterIdPair: StateWrapper<Optional<bigint>>;
    reviewIdPair: StateWrapper<Optional<bigint>>;
    reportCausePair: StateWrapper<Optional<number>>;
    reportedAtPair: StateWrapper<Optional<string>>;
}

export default function List() {
    const sortOptionPair = wrapState(useState<SortOption>(SortOption.none));
    const reporterIdPair = wrapState(useState<Optional<bigint>>(null));
    const reviewIdPair = wrapState(useState<Optional<bigint>>(null));
    const reportCausePair = wrapState(useState<Optional<number>>(null));
    const reportedAtPair = wrapState(useState<Optional<string>>(null));

    const lockPair = wrapState(useState<number>(0));
    const contentsPair = wrapState(useState<Optional<ProductReviewReportModal[]>>(null));
    const hasMoreContentsPair = wrapState(useState<boolean>(true));
    const updatingPair = wrapState(useState<boolean>(false));
    const errorMessagePair = wrapState(useState<Optional<ErrorMessage>>(null));

    const sortOptionCollection = {
        contentsPair,
        sortOptionPair,
        reporterIdPair,
        reviewIdPair,
        reportCausePair,
        reportedAtPair
    };

    useEffect(() => {
        MaterialModal.init();
        MaterialSelectWrapper.init();
        MaterialDatepicker.init();
    });

    useEffect(() => {
        const onWindowScroll = () => OnWindowScroll(
            sortOptionCollection,
            hasMoreContentsPair,
            updatingPair,
            errorMessagePair.set
        );
        window.addEventListener('scroll', onWindowScroll);
        return function clean() {
            window.removeEventListener('scroll', onWindowScroll);
        }
    });

    useEffect(() => PrepareContents(
        sortOptionCollection,
        hasMoreContentsPair,
        updatingPair,
        errorMessagePair.set
    ), [lockPair.value]);

    return <PageWrapper pairCollection={sortOptionCollection} lockPair={lockPair} hasMoreContentsPair={hasMoreContentsPair} />;
}

function OnWindowScroll(
    collection: SortOptionCollection,
    hasMoreContentsPair: StateWrapper<boolean>,
    updatingPair: StateWrapper<boolean>,
    setErrorMessage: React.Dispatch<ErrorMessage>
) {
    const { value: isUpdating } = updatingPair;
    if (!isUpdating && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // 업데이트중이지 않고 바닥까지 스크롤되었을 때
        PrepareContents(collection, hasMoreContentsPair, updatingPair, setErrorMessage);
    }
}

function PrepareContents(
    collection: SortOptionCollection,
    hasMoreContentsPair: StateWrapper<boolean>,
    updatingPair: StateWrapper<boolean>,
    setErrorMessage: React.Dispatch<ErrorMessage>
) {
    if (updatingPair.value) {
        return;
    }

    updatingPair.set(true);
    const contents = collection.contentsPair.value;
    const lastReportedAt = GetLastReportedAt(contents);
    const sortOption = collection.sortOptionPair.value;
    const onReady = (response: ProductReviewReportModal[]) => {
        collection.contentsPair.set((contents ?? []).appended(response));
        hasMoreContentsPair.set(response.length === 20);
        updatingPair.set(false);
    };
    const prepareByFilter = function<T> (filterPair: StateWrapper<Optional<T>>, request: Request<T>) {
        const filterValue = filterPair.value;
        if (filterValue === null || filterValue === undefined) {
            return;
        }

        request(filterValue, lastReportedAt, onReady, setErrorMessage);
    }

    switch (sortOption) {
        case SortOption.none:
            ProductReviewReportIO.listDescending(lastReportedAt, onReady, setErrorMessage);
            return;
        case SortOption.reporterId:
            prepareByFilter(collection.reporterIdPair, ProductReviewReportIO.listByUserIdDescending);
            break;
        case SortOption.reviewId:
            prepareByFilter(collection.reviewIdPair, ProductReviewReportIO.listByProductReviewIdDescending);
            break;
        case SortOption.reportCause:
            prepareByFilter(collection.reportCausePair, ProductReviewReportIO.listByCauseIdDescending);
            break;
        case SortOption.reportedAt:
            prepareByFilter(collection.reportedAtPair, ProductReviewReportIO.listByReportedAtDescending);
            break;
    }
}

function GetLastReportedAt(contents: Optional<ProductReviewReportModal[]>): Date {
    if (contents === null || contents === undefined) {
        return getMaxDate();
    } else {
        const last = contents.lastOrNull()
        if (last === null || last === undefined) {
            return getMaxDate();
        } else {
            return last.reportedAt;
        }
    }
}

type PageWrapperProps = {
    pairCollection: SortOptionCollection;
    lockPair: StateWrapper<number>;
    hasMoreContentsPair: StateWrapper<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const contents = props.pairCollection.contentsPair.value;
    const hasMoreContents = props.hasMoreContentsPair.value;
    const rows = (contents ?? []).map(content => <Row key={content.reportedAt.getTime()} content={content} />);
    const onSortClicked: (() => void) | string = () => MaterialModal.get(`#${sortOptionModalId}`).open();
    return <>
        <Nav
            title="광고 리뷰 신고"
            titleHref="/"
            titleIcon="chevron_left"
            barItems={[["정렬", onSortClicked]]}
            excludeErrorModal={true} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>상품 리뷰 ID</th>
                <th>신고자 ID</th>
                <th>신고 사유 ID</th>
                <th>신고 시각</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents} />
        </div>
        <SortOptionModal
            setContents={props.pairCollection.contentsPair.set}
            sortOptionPair={props.pairCollection.sortOptionPair}
            reporterIdPair={props.pairCollection.reporterIdPair}
            reviewIdPair={props.pairCollection.reviewIdPair}
            reportCausePair={props.pairCollection.reportCausePair}
            reportedAtPair={props.pairCollection.reportedAtPair}
            lockPair={props.lockPair} />
    </>
}

type RowProps = {
    content: ProductReviewReportModal;
};

function Row(props: RowProps) {
    return <tr>
        <td>{props.content.productReviewId.toString()}</td>
        <td>{props.content.userId.toString()}</td>
        <td>{props.content.causeId}</td>
        <td>{props.content.reportedAt.toRowFormat(true, true, true, true)}</td>
        <td>
            <a href={`/product/review/${props.content.productReviewId}`} style={{ cursor: "pointer" }}>
                <i className="material-icons black-text">open_in_new</i>
            </a>
        </td>
    </tr>;
}

type SortOptionModalProps = {
    setContents: React.Dispatch<Optional<ProductReviewReportModal[]>>;
    sortOptionPair: StateWrapper<SortOption>;
    reporterIdPair: StateWrapper<Optional<bigint>>;
    reviewIdPair: StateWrapper<Optional<bigint>>;
    reportCausePair: StateWrapper<Optional<number>>;
    reportedAtPair: StateWrapper<Optional<string>>;
    lockPair: StateWrapper<number>;
}

function SortOptionModal(props: SortOptionModalProps) {
    const [sortOption, setSortOption] = useState<SortOption>(props.sortOptionPair.value);
    const onSortOptionSelected = (event: ChangeEvent<HTMLSelectElement>) => {
        Logger.log("selectedIndex", event.target.selectedIndex);
        switch (event.target.selectedIndex) {
            case 0: setSortOption(SortOption.none); break;
            case 1: setSortOption(SortOption.reporterId); break;
            case 2: setSortOption(SortOption.reviewId); break;
            case 3: setSortOption(SortOption.reportCause); break;
            case 4: setSortOption(SortOption.reportedAt); break;
        }
    };

    const onApplyClicked = () => {
        switch (sortOption) {
            case SortOption.none:
                if (props.sortOptionPair.value !== SortOption.none) {
                    props.setContents(null);
                    props.sortOptionPair.set(sortOption);
                    props.lockPair.set(props.lockPair.value + 1);
                }
                break;
            case SortOption.reporterId:
                const reporterId = MaterialInput.get(`#${sortOptionModalReporterIdInputId}`).getValue().toBigIntOrElse(() => 0n);
                if (props.reporterIdPair.value !== reporterId) {
                    props.reporterIdPair.set(reporterId);
                    props.setContents(null);
                    props.sortOptionPair.set(sortOption);
                    props.lockPair.set(props.lockPair.value + 1);
                }
                break;
            case SortOption.reviewId:
                const reviewId = MaterialInput.get(`#${sortOptionModalReviewIdInputId}`).getValue().toBigIntOrElse(() => 0n);
                if (props.reviewIdPair.value !== reviewId) {
                    props.reviewIdPair.set(reviewId);
                    props.setContents(null);
                    props.sortOptionPair.set(sortOption);
                    props.lockPair.set(props.lockPair.value + 1);
                }
                break;
            case SortOption.reportCause:
                const userGender = MaterialSelectWrapper.get(`#${sortOptionModalUserGenderSelectWrapperId}`).getFirstSelectionAs(UserGender.ordinal);
                if (props.reportCausePair.value !== userGender) {
                    props.reportCausePair.set(userGender);
                    props.setContents(null);
                    props.sortOptionPair.set(sortOption);
                    props.lockPair.set(props.lockPair.value + 1);
                }
                break;
            case SortOption.reportedAt:
                const reportedAt = MaterialInput.get(`#${sortOptionModalRegionIdInputId}`).getValue();
                if (props.reportedAtPair.value !== reportedAt) {
                    props.reportedAtPair.set(reportedAt);
                    props.setContents(null);
                    props.sortOptionPair.set(sortOption);
                    props.lockPair.set(props.lockPair.value + 1);
                }
                break;
        }
    };

    let detail = <>
        <div className="row" style={{ display: (sortOption === SortOption.reporterId) ? "inherit" : "none" }}>
            <Component.Input
                formClasses="col s8"
                inputId={sortOptionModalReporterIdInputId}
                inputType="number"
                inputClasses="validate"
                label={SortOption.reporterId} />
        </div>
        <div className="row" style={{ display: (sortOption === SortOption.reviewId) ? "inherit" : "none" }}>
            <Component.Input
                formClasses="col s8"
                inputId={sortOptionModalReviewIdInputId}
                inputType="number"
                inputClasses="validate"
                label={SortOption.reviewId} />
        </div>
        <div className="row" style={{ display: (sortOption === SortOption.reportCause) ? "inherit" : "none" }}>
            <Component.Input
                formClasses="col s8"
                inputId={sortOptionModalCauseIdInputId}
                inputType="number"
                inputClasses="validate"
                label={SortOption.reportCause} />
        </div>
        <div className="row" style={{ display: (sortOption === SortOption.reportedAt) ? "inherit" : "none" }}>
            <Component.Datepicker
                formClasses="col s8"
                inputId={sortOptionModalRegionIdInputId}
                label={SortOption.reportedAt} />
        </div>
    </>;

    return <div id={sortOptionModalId} className="modal" style={{ display: "flex" }}>
        <div className="modal-content">
            <h4>정렬</h4>
            <div className="row">
                <Component.Select
                    values={[SortOption.none, SortOption.reporterId, SortOption.reviewId, SortOption.reportCause, SortOption.reportedAt]}
                    wrapperId={sortOptionModalSelectWrapperId}
                    wrapperClasses="col s8"
                    selectId={sortOptionModalSelectId}
                    label="내용 유형"
                    requireDefault={false}
                    onChange={onSortOptionSelected} />
            </div>
            {detail}
        </div>
        <div className="modal-footer" onClick={onApplyClicked}>
            <a className="modal-close waves-effect btn-flat black-text">적용</a>
        </div>
    </div>;
}