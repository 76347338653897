import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {UserBlockCauseModal} from "../../../../../modal/Users";
import {UserBlockCauseIO} from "../../../../../io/Users";
import {ErrorMessage, Nav, NavBarItemAction} from "../../../../Common";
import {Component, MaterialComponent, MaterialInput, MaterialModal} from "../../../../Materials";
import {Optional, StatePair} from "../../../../../util/Types";

const pageId = 'user_block_cause_edit';
const descriptionInputId = `${pageId}_description`;

export default function Edit() {
    const {userBlockCauseId} = useParams() as { userBlockCauseId?: number };
    const [content, setContent] = useState<Optional<UserBlockCauseModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        M.AutoInit();
    });

    useEffect(() => {
        if (userBlockCauseId) {
            UserBlockCauseIO.get(userBlockCauseId, setContent, setErrorMessage);
        }
    }, [userBlockCauseId]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${descriptionInputId}`),
        ];
        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled]);

    useEffect(() => {
        if (content) {
            MaterialInput.getOrNull(`#${descriptionInputId}`)?.setValue(content.description);
        }
    }, [content]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contentState={[content, setContent]}
            errorMessageState={[errorMessage, setErrorMessage]}
            setRootEnabled={setRootEnabled} />
    </>;
}

type PageWrapperProps = {
    contentState: StatePair<Optional<UserBlockCauseModal>>;

    errorMessageState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [content] = props.contentState;
    const [, setErrorMessage] = props.errorMessageState;
    const onSaveClicked = () => OnSaveClicked(content, setErrorMessage, props.setRootEnabled);
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", () => document.location = "/misc/user/block/cause/list"]
    ];

    return <>
        <Nav
            title={"사용자 이용 정지 사유 " + ((content) ? "수정" : "추가")}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <Editor />
    </>;
}

function OnSaveClicked(
    content: Optional<UserBlockCauseModal>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const description = MaterialInput.get(`#${descriptionInputId}`).getValue();
    if (description.length.notIn(1, 41)) {
        setErrorMessage('설명을 확인해주세요.');
        return;
    }

    const onReady = () => {
        window.alert('저장되었습니다.');
        document.location = `/misc/user/block/cause/list`;
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    if (!content) {
        setRootEnabled(false);
        UserBlockCauseIO.post(description, onReady, onError);
    } else {
        if (content &&
            content.description === description) {
            onReady();
            return;
        }

        setRootEnabled(false);
        UserBlockCauseIO.update(content.id, description, onReady, onError);
    }
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={descriptionInputId}
                inputClasses="validate"
                inputPlaceHolder="40자 이내"
                inputDataLength={40}
                label="설명" />
        </div>
    </>;
}