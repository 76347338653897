import React, {useEffect, useState} from "react";
import {Optional, StateWrapper, wrapState} from "../../../util/Types";
import {BottomProgress, ErrorMessage, Nav} from "../../Common";
import {MaterialModal} from "../../Materials";
import {GifticonPurchaseModal} from "../../../modal/Gifticons";
import {getMaxLong} from "../../../util/Environments";
import {GifticonPurchaseIO} from "../../../io/Gifticons";

export default function List() {
    const lockPair = wrapState(useState<number>(0));
    const contentsPair = wrapState(useState<Optional<GifticonPurchaseModal[]>>(null));
    const moreContentsPair = wrapState(useState<boolean>(true));
    const updatingPair = wrapState(useState<boolean>(false));
    const errorMessagePair = wrapState(useState<Optional<ErrorMessage>>(null));

    useEffect(() => {
        M.AutoInit();
        MaterialModal.init();
    });

    useEffect(() => PrepareContents(
        contentsPair,
        moreContentsPair.set,
        updatingPair.set,
        errorMessagePair.set
    ), [lockPair.value]);

    useEffect(() => {
        const onWindowScroll = () => OnWindowScroll(contentsPair, moreContentsPair, updatingPair, errorMessagePair.set);
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    });

    return <PageWrapper contents={contentsPair.value} hasMoreContents={moreContentsPair.value} />;
}

function PrepareContents(
    contentsPair: StateWrapper<Optional<GifticonPurchaseModal[]>>,
    setMoreContents: React.Dispatch<boolean>,
    setUpdating: React.Dispatch<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const { value: present, set: setContents } = contentsPair;

    const onReady = (contents: GifticonPurchaseModal[]) => {
        setContents((present ?? []).appended(contents));
        setMoreContents(contents.length === 20);
        setUpdating(false);
    };

    const onError = (error: ErrorMessage) => {
        setErrorMessage(error);
        setMoreContents(false);
        setUpdating(false);
    };

    const lastId = present?.lastOrNull()?.id ?? getMaxLong();
    setUpdating(true);
    GifticonPurchaseIO.listDescending(
        lastId,
        onReady,
        onError
    );
}

function OnWindowScroll(
    contentsPair: StateWrapper<Optional<GifticonPurchaseModal[]>>,
    moreContentsPair: StateWrapper<boolean>,
    updatingPair: StateWrapper<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const { value: hasMoreContents, set: setMoreContents } = moreContentsPair;
    const { value: isUpdating, set: setUpdating } = updatingPair;
    if (hasMoreContents && !isUpdating && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        PrepareContents(contentsPair, setMoreContents, setUpdating, setErrorMessage);
    }
}

type PageWrapperProps = {
    contents: Optional<GifticonPurchaseModal[]>,
    hasMoreContents: boolean
};

function PageWrapper(props: PageWrapperProps) {
    const rows = (props.contents ?? []).map(content => <Row key={content.id.toString()} content={content} />);
    return <>
        <Nav
            title="기프티콘 구매"
            titleIcon="chevron_left"
            titleHref="/" />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>기프티콘 ID</th>
                <th>사용자 ID</th>
                <th>바코드 색인</th>
                <th>구매 시각</th>
                <th>만료 시각</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={props.hasMoreContents} />
        </div>
    </>;
}

type RowProps = {
    content: GifticonPurchaseModal
};

function Row(props: RowProps) {
    return <tr>
        <td>{props.content.id.toString()}</td>
        <td>{props.content.gifticonId}</td>
        <td>{props.content.userId.toString()}</td>
        <td>{props.content.ordinal}</td>
        <td>{props.content.purchasedAt.toRowFormat(true, true, true, true)}</td>
        <td>{props.content.expiredAt.toRowFormat(true, true, true, true)}</td>
    </tr>;
}