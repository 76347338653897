import {OnArrayResponse, OnBaseResponse, OnErrorResponse, OnObjectResponse} from "../util/Reponses";
import {WingUsageModal, WingUsageType} from "../modal/Wings";
import {AxiosRequestConfig} from "axios";
import {Requests} from "../util/Requests";
import {SERVER_URL} from "../util/Environments";
import {PointUsageModal} from "../modal/Points";

export class WingUsageIO {
    static post(
        userId: bigint,
        amount: number,
        type: WingUsageType,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'WingUsageIO.post',
            `${SERVER_URL}/wing/usage`,
            o => o,
            onReady,
            onError,
            { userId: userId.toString(), amount, type }
        );
    }

    static get(
        id: bigint,
        onReady: OnObjectResponse<WingUsageModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'WingUsageIO.get',
            `${SERVER_URL}/wing/usage/${id}`,
            o => new WingUsageModal(o),
            onReady,
            onError
        );
    }

    static listAscending(
        lastId: bigint,
        onReady: OnArrayResponse<WingUsageModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'WingUsageIO.listAscending',
            `${SERVER_URL}/wing/usage/list`,
            o => new WingUsageModal(o),
            onReady,
            onError,
            { params: { lastId, ascending: "" } }
        );
    }

    static listDescending(
        lastId: bigint,
        onReady: OnArrayResponse<WingUsageModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'WingUsageIO.listDescending',
            `${SERVER_URL}/wing/usage/list`,
            o => new WingUsageModal(o),
            onReady,
            onError,
            { params: { lastId, descending: "" } }
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<WingUsageModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { userId, lastId, ascending: "" }
        };
        Requests.getArray(
            'WingUsageIO.listByUserIdAscending',
            `${SERVER_URL}/wing/usage/list`,
            o => new WingUsageModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<WingUsageModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { userId, lastId, descending: "" }
        };
        Requests.getArray(
            'WingUsageIO.listByUserIdDescending',
            `${SERVER_URL}/wing/usage/list`,
            o => new WingUsageModal(o),
            onReady,
            onError,
            config
        );
    }
}