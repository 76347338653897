import React, {ChangeEvent, useEffect, useState} from "react";
import {Optional, StateWrapper, wrapState} from "../../../../util/Types";
import {AdParticipationModal} from "../../../../modal/Ads";
import {getMaxLong, Logger} from "../../../../util/Environments";
import {AdParticipationIO} from "../../../../io/Ads";
import {OnArrayResponse, OnErrorResponse} from "../../../../util/Reponses";
import {UserGender} from "../../../../modal/Users";
import {BottomProgress, ErrorMessage, Nav} from "../../../Common";
import {Component, MaterialInput, MaterialModal, MaterialSelectWrapper} from "../../../Materials";
import {StorageIO} from "../../../../io/Services";

enum SortOption {
    none = "없음",
    adId = "광고 ID",
    userAge = "참가자 연령",
    userGender = "참가자 성별",
    userRegion = "참가자 지역"
}

const pageId = "ad_participation_record_list";
const sortOptionModalId = `${pageId}_sort_option`
const sortOptionModalSelectId = `${pageId}_sort_option_select`;
const sortOptionModalSelectWrapperId = `${pageId}_sort_option_select_wrapper`;
const sortOptionModalAdIdInputId = `${pageId}_sort_option_ad_id_input`;
const sortOptionModalUserAgeInputId = `${pageId}_sort_option_user_age_input`;
const sortOptionModalUserGenderSelectId = `${pageId}_sort_option_user_gender_select`;
const sortOptionModalUserGenderSelectWrapperId = `${pageId}_sort_option_user_gender_select_wrapper`;
const sortOptionModalRegionIdInputId = `${pageId}_sort_option_region_id_input`;

type ParticipationRequest<T> = (
    filter: T,
    lastId: bigint,
    onReady: OnArrayResponse<AdParticipationModal>,
    onError: OnErrorResponse
) => void;

type SortOptionCollection = {
    contentsPair: StateWrapper<Optional<AdParticipationModal[]>>;
    sortOptionPair: StateWrapper<SortOption>;
    adIdPair: StateWrapper<Optional<number>>;
    userAgePair: StateWrapper<Optional<number>>;
    userGenderPair: StateWrapper<Optional<UserGender>>;
    regionIdPair: StateWrapper<Optional<number>>;
}

export default function List() {
    const sortOptionPair = wrapState(useState<SortOption>(SortOption.none));
    const adIdPair = wrapState(useState<Optional<number>>(null));
    const userAgePair = wrapState(useState<Optional<number>>(null));
    const userGenderPair = wrapState(useState<Optional<UserGender>>(null));
    const regionIdPair = wrapState(useState<Optional<number>>(null));

    const lockPair = wrapState(useState<number>(0));
    const contentsPair = wrapState(useState<Optional<AdParticipationModal[]>>(null));
    const hasMoreContentsPair = wrapState(useState<boolean>(true));
    const updatingPair = wrapState(useState<boolean>(false));
    const errorMessagePair = wrapState(useState<Optional<ErrorMessage>>(null));

    const sortOptionCollection = {
        contentsPair,
        sortOptionPair,
        adIdPair,
        userAgePair,
        userGenderPair,
        regionIdPair
    };

    useEffect(() => {
        M.AutoInit();
        MaterialModal.init();
        MaterialSelectWrapper.init();
    });

    useEffect(() => {
        const onWindowScroll = () => OnWindowScroll(
            sortOptionCollection,
            hasMoreContentsPair,
            updatingPair,
            errorMessagePair.set
        );
        window.addEventListener('scroll', onWindowScroll);
        return function clean() {
            window.removeEventListener('scroll', onWindowScroll);
        }
    });

    useEffect(() => PrepareParticipations(
        sortOptionCollection,
        hasMoreContentsPair,
        updatingPair,
        errorMessagePair.set
    ), [lockPair.value]);

    return <PageWrapper pairCollection={sortOptionCollection} lockPair={lockPair} hasMoreContentsPair={hasMoreContentsPair} />;
}

function OnWindowScroll(
    collection: SortOptionCollection,
    hasMoreContentsPair: StateWrapper<boolean>,
    updatingPair: StateWrapper<boolean>,
    setErrorMessage: React.Dispatch<ErrorMessage>
) {
    const { value: isUpdating } = updatingPair;
    if (!isUpdating && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // 업데이트중이지 않고 바닥까지 스크롤되었을 때
        PrepareParticipations(collection, hasMoreContentsPair, updatingPair, setErrorMessage);
    }
}

function PrepareParticipations(
    collection: SortOptionCollection,
    hasMoreContentsPair: StateWrapper<boolean>,
    updatingPair: StateWrapper<boolean>,
    setErrorMessage: React.Dispatch<ErrorMessage>
) {
    if (updatingPair.value) {
        return;
    }

    updatingPair.set(true);
    const contents = collection.contentsPair.value;
    const lastId = getLastContentId(contents);
    const sortOption = collection.sortOptionPair.value;
    const onReady = (response: AdParticipationModal[]) => {
        collection.contentsPair.set((contents ?? []).appended(response));
        hasMoreContentsPair.set(response.length === 20);
        updatingPair.set(false);
    };
    const prepareByFilter = function<T> (filterPair: StateWrapper<Optional<T>>, request: ParticipationRequest<T>) {
        const filterValue = filterPair.value;
        if (filterValue === null || filterValue === undefined) {
            return;
        }

        request(filterValue, lastId, onReady, setErrorMessage);
    }

    switch (sortOption) {
        case SortOption.none:
            AdParticipationIO.listRecordDescending(lastId, onReady, setErrorMessage);
            return;
        case SortOption.adId:
            prepareByFilter(collection.adIdPair, AdParticipationIO.listRecordByAdIdDescending);
            break;
        case SortOption.userAge:
            prepareByFilter(collection.userAgePair, AdParticipationIO.listRecordByUserAgeDescending);
            break;
        case SortOption.userGender:
            prepareByFilter(collection.userGenderPair, AdParticipationIO.listRecordByUserGenderDescending);
            break;
        case SortOption.userRegion:
            prepareByFilter(collection.regionIdPair, AdParticipationIO.listRecordByUserRegionDescending);
            break;
    }
}

function getLastContentId(contents: Optional<AdParticipationModal[]>): bigint {
    if (contents === null || contents === undefined) {
        return getMaxLong()
    } else {
        const last = contents.lastOrNull()
        if (last === null || last === undefined) {
            return getMaxLong()
        } else {
            return last.id
        }
    }
}

type PageWrapperProps = {
    pairCollection: SortOptionCollection;
    lockPair: StateWrapper<number>;
    hasMoreContentsPair: StateWrapper<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const contents = props.pairCollection.contentsPair.value;
    const hasMoreContents = props.hasMoreContentsPair.value;
    const rows = (contents ?? []).map(content =>
        <Row
            key={content.id.toString()}
            content={content} />
    );
    const onSortClicked: (() => void) | string = () => {
        MaterialModal.get(`#${sortOptionModalId}`).open();
    };

    return <>
        <Nav
            title="녹음된 오디오"
            titleHref="/"
            titleIcon="chevron_left"
            barItems={[["정렬", onSortClicked]]}
            excludeErrorModal={true} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>광고 ID</th>
                <th>사용자 ID</th>
                <th>정확도</th>
                <th>참여 일시</th>
                <th>AWS S3</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents} />
        </div>
        <SortOptionModal
            setContents={props.pairCollection.contentsPair.set}
            sortOptionPair={props.pairCollection.sortOptionPair}
            adIdPair={props.pairCollection.adIdPair}
            userAgePair={props.pairCollection.userAgePair}
            userGenderPair={props.pairCollection.userGenderPair}
            regionIdPair={props.pairCollection.regionIdPair}
            lockPair={props.lockPair} />
    </>
}

type RowProps = {
    content: AdParticipationModal;
};

function Row(props: RowProps) {
    const key = AdParticipationModal.recordPath(props.content);
    if (key === null || key === undefined) {
        return <></>;
    }

    const url = StorageIO.createPath(key);
    return <tr>
        <td>{props.content.id.toString()}</td>
        <td>{props.content.adId}</td>
        <td>{props.content.userId.toString()}</td>
        <td>{props.content.accuracy + "%"}</td>
        <td>{props.content.participatedAt.toRowFormat(true, true, true, true)}</td>
        <td>
            <a href={url} style={{ cursor: "pointer" }}>
                <i className="material-icons black-text">open_in_new</i>
            </a>
        </td>
    </tr>;
}

type SortOptionModalProps = {
    setContents: React.Dispatch<Optional<AdParticipationModal[]>>,
    sortOptionPair: StateWrapper<SortOption>,
    adIdPair: StateWrapper<Optional<number>>,
    userAgePair: StateWrapper<Optional<number>>,
    userGenderPair: StateWrapper<Optional<UserGender>>,
    regionIdPair: StateWrapper<Optional<number>>,
    lockPair: StateWrapper<number>
}

function SortOptionModal(props: SortOptionModalProps) {
    const [sortOption, setSortOption] = useState<SortOption>(props.sortOptionPair.value);
    const onSortOptionSelected = (event: ChangeEvent<HTMLSelectElement>) => {
        Logger.log("selectedIndex", event.target.selectedIndex);
        switch (event.target.selectedIndex) {
            case 0: setSortOption(SortOption.none); break;
            case 1: setSortOption(SortOption.adId); break;
            case 2: setSortOption(SortOption.userAge); break;
            case 3: setSortOption(SortOption.userGender); break;
            case 4: setSortOption(SortOption.userRegion); break;
        }
    };

    const onApplyClicked = () => {
        switch (sortOption) {
            case SortOption.none: break;
            case SortOption.adId:
                const adId = MaterialInput.get(`#${sortOptionModalAdIdInputId}`).getValue().toIntOrElse(() => 0);
                if (props.adIdPair.value !== adId) {
                    props.adIdPair.set(adId);
                    props.setContents(null);
                    props.lockPair.set(props.lockPair.value + 1);
                }
                break;
            case SortOption.userAge:
                const userAge = MaterialInput.get(`#${sortOptionModalUserAgeInputId}`).getValue().toIntOrElse(() => 0);
                if (props.userAgePair.value !== userAge) {
                    props.userAgePair.set(userAge);
                    props.setContents(null);
                    props.lockPair.set(props.lockPair.value + 1);
                }
                break;
            case SortOption.userGender:
                const userGender = MaterialSelectWrapper.get(`#${sortOptionModalUserGenderSelectWrapperId}`).getFirstSelectionAs(UserGender.ordinal);
                if (props.userGenderPair.value !== userGender) {
                    props.userGenderPair.set(userGender);
                    props.setContents(null);
                    props.lockPair.set(props.lockPair.value + 1);
                }
                break;
            case SortOption.userRegion:
                const regionId = MaterialInput.get(`#${sortOptionModalRegionIdInputId}`).getValue().toIntOrElse(() => 0);
                if (props.regionIdPair.value !== regionId) {
                    props.regionIdPair.set(regionId);
                    props.setContents(null);
                    props.lockPair.set(props.lockPair.value + 1);
                }
                break;
        }

        props.sortOptionPair.set(sortOption);
    };

    let detail = <>
        <div className="row" style={{ display: (sortOption === SortOption.adId) ? "inherit" : "none" }}>
            <Component.Input
                formClasses="col s8"
                inputId={sortOptionModalAdIdInputId}
                inputType="number"
                inputClasses="validate"
                label="광고 ID" />
        </div>
        <div className="row" style={{ display: (sortOption === SortOption.userAge) ? "inherit" : "none" }}>
            <Component.Input
                formClasses="col s8"
                inputId={sortOptionModalUserAgeInputId}
                inputType="number"
                inputClasses="validate"
                label="연령" />
        </div>
        <div className="row" style={{ display: (sortOption === SortOption.userGender) ? "inherit" : "none" }}>
            <Component.Select
                values={UserGender.values()}
                wrapperId={sortOptionModalUserGenderSelectWrapperId}
                wrapperClasses="col s8"
                selectId={sortOptionModalUserGenderSelectId}
                label="성별"
                requireDefault={false} />
        </div>
        <div className="row" style={{ display: (sortOption === SortOption.userRegion) ? "inherit" : "none" }}>
            <Component.Input
                formClasses="col s8"
                inputId={sortOptionModalRegionIdInputId}
                inputType="number"
                inputClasses="validate"
                label="지역 ID" />
        </div>
    </>;

    return <div id={sortOptionModalId} className="modal" style={{ height: "75%" }}>
        <div className="modal-content">
            <h4>정렬</h4>
            <div className="row">
                <Component.Select
                    values={[SortOption.none, SortOption.adId, SortOption.userAge, SortOption.userGender, SortOption.userRegion]}
                    wrapperId={sortOptionModalSelectWrapperId}
                    wrapperClasses="col s8"
                    selectId={sortOptionModalSelectId}
                    label="내용 유형"
                    requireDefault={false}
                    onChange={onSortOptionSelected} />
            </div>
            {detail}
        </div>
        <div className="modal-footer" onClick={onApplyClicked}>
            <a className="modal-close waves-effect btn-flat black-text">적용</a>
        </div>
    </div>;
}