import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {ProductOptionModal} from "../../../modal/Products";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {Component, MaterialComponent, MaterialInput, MaterialModal} from "../../Materials";
import {ProductOptionIO} from "../../../io/Products";

const pageId = 'product_option_edit';
const productInputId = `${pageId}_product`;
const titleInputId = `${pageId}_title`;
const sortOrderInputId = `${pageId}_sort_order`;

export default function Edit() {
    const {productId, productOptionId} = useParams() as {
        productId?: number,
        productOptionId?: number
    };

    const [content, setContent] = useState<Optional<ProductOptionModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        MaterialModal.init();
        MaterialInput.init();
    });

    useEffect(() => {
        if (productId && productOptionId) {
            ProductOptionIO.get(productId, productOptionId, setContent, setErrorMessage);
        }
    }, [productId, productOptionId]);

    useEffect(() => {
        if (productId) {
            MaterialInput.getOrNull(`#${productInputId}`)?.setValue(productId.toString());
        }
    }, [productId]);

    useEffect(() => {
        if (content) {
            MaterialInput.getOrNull(`#${productInputId}`)?.setValue(content.productId.toString());
            MaterialInput.getOrNull(`#${titleInputId}`)?.setValue(content.title);
            MaterialInput.getOrNull(`#${sortOrderInputId}`)?.setValue(content.sortOrder.toString());
        }
    }, [content]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${productInputId}`),
            MaterialInput.getOrNull(`#${titleInputId}`),
            MaterialInput.getOrNull(`#${sortOrderInputId}`),
        ];

        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contentState={[content, setContent]}
            errorMessageState={[errorMessage, setErrorMessage]}
            setRootEnabled={setRootEnabled} />
    </>;
}

type PageWrapperProps = {
    contentState: StatePair<Optional<ProductOptionModal>>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [content] = props.contentState;
    const [, setErrorMessage] = props.errorMessageState;
    const onSaveClicked = () => OnSaveClicked(
        content,
        setErrorMessage,
        props.setRootEnabled
    );
    const onCancelClicked = () => window.history.back();
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", onCancelClicked]
    ];

    return <>
        <Nav
            title={"상품 옵션 " + ((content) ? "수정" : "추가")}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <Editor />
    </>;
}

function OnSaveClicked(
    content: Optional<ProductOptionModal>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const productId = MaterialInput.get(`#${productInputId}`).getValue().toIntOrNull();
    if (productId === null) {
        setErrorMessage("상품 ID를 확인해주세요.");
        return;
    }

    const title = MaterialInput.get(`#${titleInputId}`).getValue();
    if (title.length.notIn(0, 101)) {
        setErrorMessage("제목을 확인해주세요.");
        return;
    }

    const sortOrder = MaterialInput.get(`#${sortOrderInputId}`).getValue().toIntOrNull();
    if (sortOrder === null) {
        setErrorMessage("표시 순서를 확인해주세요.");
        return;
    }

    const onReady = () => {
        window.alert('저장되었습니다.');
        window.history.back();
    };

    const onError = (error: string) => {
        setRootEnabled(true);
        setErrorMessage(error);
    };

    if (content) {
        if (content.productId === productId &&
            content.title === title &&
            content.sortOrder === sortOrder) {
            onReady();
            return;
        }
        setRootEnabled(false);
        ProductOptionIO.update(content.productId, content.id, title, sortOrder, onReady, onError);
    } else {
        setRootEnabled(false);
        ProductOptionIO.post(productId, title, sortOrder, onReady, onError);
    }
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={productInputId}
                inputType="number"
                inputClasses="validate"
                label="상품 ID" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={titleInputId}
                inputClasses="validate"
                inputPlaceHolder="100자 이내"
                inputDataLength={100}
                label="제목" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={sortOrderInputId}
                inputType="number"
                inputClasses="validate"
                label="표시 순서" />
        </div>
    </>;
}