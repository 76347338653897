import {AwsKeyManager, DEBUG, Logger, S3_BUCKET_NAME} from "../util/Environments";
import S3, {
    Body,
    CopyObjectRequest,
    DeleteObjectRequest,
    GetObjectOutput,
    GetObjectRequest,
    ManagedUpload,
    PutObjectRequest,
} from 'aws-sdk/clients/s3';

import {OnArrayResponse, OnErrorResponse, OnObjectResponse} from "../util/Reponses";
import {AWSError} from "aws-sdk";
import {useEffect, useState} from "react";
import {MaterialInput} from "../ui/Materials";
import {CredentialsOptions} from "aws-sdk/lib/credentials";
import {Optional} from "../util/Types";
import {
    Action,
    ActionTypeEnum,
    DescribeListenersCommand,
    DescribeListenersCommandInput,
    DescribeListenersCommandOutput,
    ElasticLoadBalancingV2Client,
    ElasticLoadBalancingV2ClientConfig,
    FixedResponseActionConfig,
    Listener,
    ModifyListenerCommand,
    ModifyListenerCommandInput,
    ModifyListenerCommandOutput
} from "@aws-sdk/client-elastic-load-balancing-v2";
import {ConstructionModal} from "../modal/Miscs";
import {Dates} from "../util/Dates";
import {IntrinsicProperties} from "../util/Properties";

const json = require('json-bigint')({
    useNativeBigInt: true
});

export module LoadBalancerIO {
    const listenerArn = (DEBUG)
        ? "arn:aws:elasticloadbalancing:ap-northeast-2:424388665937:listener/app/vowing-backend-test/8479cfc5ffac2ad0/be6ef81bcbe2ec4c"
        : "arn:aws:elasticloadbalancing:ap-northeast-2:424388665937:listener/app/vowing-backend/18767ca196d7756a/7970032ef69f9add"

    function createInstance(onReady: (client: ElasticLoadBalancingV2Client) => void): void {
        const onKeyReady = (accessKeyId: string, secretAccessKey: string): void => {
            const config: ElasticLoadBalancingV2ClientConfig = {
                region: "ap-northeast-2",
                credentials: { accessKeyId, secretAccessKey }
            };

            const client = new ElasticLoadBalancingV2Client(config);

            onReady(client);
        };

        AwsKeyManager.getKeyPair(onKeyReady);
    }

    export function get(
        onReady: OnObjectResponse<ConstructionModal>,
        onError: OnErrorResponse
    ) {
        const input: DescribeListenersCommandInput = {
            ListenerArns: [listenerArn],
            PageSize: 1
        };

        const command = new DescribeListenersCommand(input);

        const onCommandError = (reason: any) => {
            Logger.error("Failed to run command", reason);
            onError(reason);
        };

        const onCommandSucceed = (output: DescribeListenersCommandOutput) => {
            const messageBody = output.Listeners
                ?.firstOrNull()
                ?.DefaultActions
                ?.firstOrNull()
                ?.FixedResponseConfig
                ?.MessageBody;
            if (messageBody === null || messageBody === undefined) {
                onCommandError(`messageBody is not exists: output=${output}`);
            } else {
                const object = json.parse(messageBody);
                Dates.parseObjectDates(object);
                onReady(new ConstructionModal(object));
            }
        };

        const onInstanceReady = (client: ElasticLoadBalancingV2Client) => {
            client.send(command)
                .then(onCommandSucceed)
                .catch(onCommandError);
        };

        createInstance(onInstanceReady);
    }

    export function update(
        startAt: Optional<Date>,
        endAt: Optional<Date>,
        onReady: OnArrayResponse<Listener>,
        onError: OnErrorResponse
    ) {
        const startAtString = (startAt === null)
            ? "null"
            : `${startAt.toDatePartString()} ${startAt.toTimePartString(true, true)}`;
        const endAtString = (endAt === null)
            ? "null"
            : `${endAt.toDatePartString()} ${endAt.toTimePartString(true, true)}`;
        const config: FixedResponseActionConfig = {
            MessageBody: `{"startAt":"${startAtString}","endAt":"${endAtString}","metadata":{"acceptedAt":0,"respondAt":0}}`,
            StatusCode: "503",
            ContentType: "application/json"
        };
        const action: Action = {
            Type: ActionTypeEnum.FIXED_RESPONSE,
            FixedResponseConfig: config
        };
        const input: ModifyListenerCommandInput = {
            Protocol: "HTTPS",
            Port: 443,
            DefaultActions: [action],
            ListenerArn: listenerArn
        };
        const command = new ModifyListenerCommand(input);

        const onCommandError = (reason: any): void => {
            Logger.error("Failed to run command", reason);
            onError(reason);
        };

        const onCommandSucceed = (output: ModifyListenerCommandOutput): void => {
            const listeners = output.Listeners;
            if (listeners === undefined) {
                onCommandError(`listeners is not exists: output=${output}`);
            } else {
                onReady(listeners);
            }
        };

        createInstance(client => {
            client.send(command)
                .then(onCommandSucceed)
                .catch(onCommandError);
        });
    }
}

export module StorageIO {
    export type MaterialImageUpload<T> = {
        input: MaterialInput;
        path: ((content: T) => string) | ((content: T, index: number) => string);
        onSucceedText: string;
        startIndex?: number;
    };

    async function createInstanceSync() {
        const { accessKeyId, secretAccessKey } = await AwsKeyManager.getKeyPairSync()
        const s3 = new S3();
        s3.config.region = "ap-northeast-2"
        s3.config.accessKeyId = accessKeyId;
        s3.config.secretAccessKey = secretAccessKey;
        s3.config.credentials = { accessKeyId, secretAccessKey } as CredentialsOptions;

        return s3
    }

    function createInstance(onConfigReady: (s3: S3) => void) {
        const onKeyReady = (accessKeyId: string, secretAccessKey: string) => {
            const s3 = new S3();
            s3.config.region = "ap-northeast-2"
            s3.config.accessKeyId = accessKeyId;
            s3.config.secretAccessKey = secretAccessKey;
            s3.config.credentials = { accessKeyId, secretAccessKey } as CredentialsOptions;

            onConfigReady(s3);
        };

        AwsKeyManager.getKeyPair(onKeyReady);
    }

    function onResponse<R>(
        error: Error,
        data: R,
        onReady: OnObjectResponse<R>,
        onError: OnErrorResponse
    ) {
        if (data) {
            onReady(data);
        } else if (error) {
            onError(error.message);
            Logger.error(error);
        } else {
            Logger.error("Both responses are null or undefined.");
        }
    }

    function completionHandler<E extends Error, R>(
        data: R | undefined,
        error: E | undefined,
        onReady: (result: R) => void,
        onError: (error: E) => void
    ) {
        if (data !== undefined) {
            onReady(data);
        } else if (error !== undefined) {
            onError(error);
            Logger.error(error);
        } else {
            Logger.error("Both responses are null or undefined.");
        }
    }

    export function createPath(
        key: string,
        bucket: string = S3_BUCKET_NAME,
        region: string = 'ap-northeast-2',
        showVersions: boolean = false
    ) {
        return `https://s3.console.aws.amazon.com/s3/buckets/${bucket}` +
            `?region=${region}` +
            `&prefix=${key}` +
            `&showversions=${showVersions}`;
    }

    export function upload(
        key: string,
        body: ArrayBuffer,
        onReady: OnObjectResponse<ManagedUpload.SendData>,
        onError: OnErrorResponse
    ) {
        if (key.startsWith('/')) {
            key = key.substring(1, key.length);
        }

        const request: PutObjectRequest = {
            Bucket: S3_BUCKET_NAME,
            Key: key,
            Body: body
        };

        createInstance(s3 => s3.upload(request, (err: Error, data: S3.ManagedUpload.SendData) => onResponse(err, data, onReady, onError)));
    }

    export function putObject(
        key: string,
        body: S3.Body,
        onReady: OnObjectResponse<S3.PutObjectOutput>,
        onError: OnObjectResponse<AWSError>
    ) {
        if (key.startsWith('/')) {
            key = key.substring(1, key.length);
        }

        const request: PutObjectRequest = {
            Bucket: S3_BUCKET_NAME,
            Key: key,
            Body: body
        };

        createInstance(s3 => s3.putObject(request, (err, data) => completionHandler(data, err, onReady, onError)))
    }

    export async function uploadSync(
        key: string,
        body: ArrayBuffer
    ) {
        if (key.startsWith('/')) {
            key = key.substring(1, key.length);
        }

        const request: PutObjectRequest = {
            Bucket: S3_BUCKET_NAME,
            Key: key,
            Body: body
        }

        const s3 = await createInstanceSync()

        return await s3.upload(request).promise()
    }

    export async function uploadFileSync(
        key: string,
        file: File
    ) {
        const body = await file.arrayBuffer()

        return await uploadSync(key, body)
    }

    export function uploadAll(
        map: ReadonlyMap<string, ArrayBuffer>,
        onReady: OnArrayResponse<ManagedUpload.SendData>,
        onError: OnErrorResponse
    ) {
        const iterator = map[Symbol.iterator]();
        uploadAllRecursive(iterator, [], onReady, onError);
    }

    export function uploadMaterialFiles<T>(
        content: T,
        uploads: MaterialImageUpload<T>[],
        onSucceed: () => void,
        onError: (succeeded: string[], error: string) => void
    ) {
        uploadMaterialFilesRecursive(content, uploads[Symbol.iterator](), [], [], onSucceed, onError);
    }

    function uploadMaterialFilesRecursive<T>(
        content: T,
        iterator: Iterator<MaterialImageUpload<T>>,
        accumulate: ManagedUpload.SendData[],
        succeeded: string[],
        onSucceed: () => void,
        onError: (succeeded: string[], error: string) => void
    ) {
        const next = iterator.next();
        Logger.log('uploadMaterialFilesRecursive', next);
        if (next.done) {
            onSucceed();
            return;
        }

        const {input, path, onSucceedText, startIndex} = next.value;
        if (input.getFileLength() === 0) {
            uploadMaterialFilesRecursive(content, iterator, accumulate, succeeded, onSucceed, onError);
            return;
        }

        const onUploadResponse = (response: ManagedUpload.SendData | ManagedUpload.SendData[]): void => {
            if (Array.isArray(response)) {
                accumulate.push(...response);
            } else {
                accumulate.push(response);
            }
            succeeded.push(onSucceedText);
            uploadMaterialFilesRecursive(content, iterator, accumulate, succeeded, onSucceed, onError);
        };

        const parameterLength = path.length;
        if (parameterLength === 1) {
            const key = (path as ((content: T) => string))(content);
            const onBufferReady = (buffer: ArrayBuffer) => upload(key, buffer, onUploadResponse, error => onError(succeeded, error));

            input.readFileBuffer(onBufferReady, error => onError(succeeded, error));
        } else {
            const onBuffersReady = (buffers: ArrayBuffer[]) => {
                const map = buffers.foldIndexed(new Map<string, ArrayBuffer>(), (index, acc, element) => {
                    const key = (path as ((content: T, index: number) => string))(content, (startIndex ?? 0) + index);
                    acc.set(key, element);
                    return acc;
                });

                uploadAll(map, onUploadResponse, error => onError(succeeded, error));
            };

            input.readFileBuffers(onBuffersReady, error => onError(succeeded, error));
        }
    }

    function uploadAllRecursive(
        iterator: IterableIterator<[string, ArrayBuffer]>,
        accumulate: ManagedUpload.SendData[],
        onReady: OnArrayResponse<ManagedUpload.SendData>,
        onError: OnErrorResponse
    ) {
        const next = iterator.next();
        if (next.done) {
            onReady(accumulate);
            return;
        }

        const [key, body] = next.value;
        const onEachReady = (response: ManagedUpload.SendData) => {
            accumulate.push(response);
            uploadAllRecursive(iterator, accumulate, onReady, onError);
        };

        upload(key, body, onEachReady, onError);
    }

    export function read(
        key: string,
        onReady: OnObjectResponse<Uint8Array>,
        onError: OnErrorResponse
    ) {
        if (key.startsWith('/')) {
            key = key.substring(1, key.length);
        }

        const request: GetObjectRequest = {
            Bucket: S3_BUCKET_NAME,
            Key: key
        };

        const onObjectReady = (error: AWSError, data: GetObjectOutput) => {
            if (data && data.Body && data.Body instanceof Uint8Array) {
                onReady(data.Body);
            } else {
                Logger.error('Storages.read', 'error', error, 'data', data);
                onError(`응답이 없거나 내용의 형식이 올바르지 않습니다. ${error?.message}`);
            }
        };

        createInstance(s3 => s3.getObject(request, onObjectReady));
    }

    export function retrieveURL(
        key: string,
        onReady: OnObjectResponse<string>,
        onError: OnErrorResponse,
    ) {
        if (key.startsWith('/')) {
            key = key.substring(1, key.length);
        }

        const onArrayReady = (body: Uint8Array) => {
            const blob = new Blob([body], { type: "image/png" });
            const url = URL.createObjectURL(blob);
            onReady(url);
        };

        read(key, onArrayReady, onError);
    }

    type StorageImageProps = IntrinsicProperties.img & {
        objectKey: string;
    };

    export function StorageImage(props: StorageImageProps) {
        const [url, setUrl] = useState<string | null>(null);
        const [error, setError] = useState<string | null>(null);
        useEffect(() => retrieveURL(props.objectKey, setUrl, setError), [props.objectKey]);

        if (!url || error) {
            return <img {...props} />;
        } else {
            return <img {...props} src={url} />;
        }
    }

    type StorageImagesProps = IntrinsicProperties.img & {
        objectKeys: string[];
    };

    export function StorageImages(props: StorageImagesProps) {
        const { objectKeys, ...storageImageProps } = props

        return <div>
            {objectKeys.map(value => <>
                <StorageImage
                    key={value}
                    {...storageImageProps}
                    objectKey={value} />
            </>)}
        </div>
    }

    type StorageVideoProps = IntrinsicProperties.video & {
        objectKey: string;
        onError?: (error: string) => void
    }

    export function StorageVideo(props: StorageVideoProps) {
        const [url, setUrl] = useState<Optional<string>>(null);
        useEffect(() => retrieveURL(
            props.objectKey,
            setUrl,
            error => props.onError?.(error)
        ), [props.objectKey]);

        let source: JSX.Element;
        if (url) {
            source = <source src={url} type="video/mp4" />;
        } else {
            source = <></>;
        }

        return <video {...props}>{source}</video>;
    }

    export function deleteObject(
        key: string,
        onReady: OnObjectResponse<S3.DeleteObjectOutput>,
        onError: OnErrorResponse
    ) {
        if (key.startsWith('/')) {
            key = key.substring(1, key.length);
        }

        const request: DeleteObjectRequest = {
            Bucket: S3_BUCKET_NAME,
            Key: key,
        };

        createInstance(s3 => s3.deleteObject(request, (error, data) => onResponse(error, data, onReady, onError)));
    }

    export function getObject(key: string, completionHandler: (error: AWSError, data: GetObjectOutput) => void) {
        const request: GetObjectRequest = {
            Bucket: S3_BUCKET_NAME,
            Key: key,
            ResponseCacheControl: 'no-cache',
        }

        createInstance(s3 => s3.getObject(request, (err, data) => completionHandler(err, data)));
    }

    export function copyObject(
        from: string,
        to: string,
        onReady: OnObjectResponse<S3.CopyObjectOutput>,
        onError: OnErrorResponse
    ) {
        const request: CopyObjectRequest = {
            Bucket: S3_BUCKET_NAME,
            CopySource: `${S3_BUCKET_NAME}/${from}`,
            Key: to
        };

        createInstance(s3 => s3.copyObject(request, (error, data) => onResponse(error, data, onReady, onError)));
    }

    export function copyObjects(
        map: ReadonlyMap<string, string>,
        onReady: OnArrayResponse<S3.CopyObjectOutput>,
        onError: OnErrorResponse
    ) {
        copyObjectsRecursive(map[Symbol.iterator](), [], onReady, onError);
    }

    function copyObjectsRecursive(
        iterator: IterableIterator<[string, string]>,
        accumulate: S3.CopyObjectOutput[],
        onReady: OnArrayResponse<S3.CopyObjectOutput>,
        onError: OnErrorResponse
    ) {
        const next = iterator.next();
        if (next.done) {
            onReady(accumulate);
            return;
        }

        const onCopyResponse = (response: S3.CopyObjectOutput) => {
            accumulate.push(response);
            copyObjectsRecursive(iterator, accumulate, onReady, onCopyError);
        };

        const onCopyError = (error: string) => {
            Logger.error('from', from, 'to', to, 'prevError', error);
            const message = `${from}을 ${to}로 복사를 실패했습니다.`;
            onError(message);
        };

        const [from, to] = next.value;
        copyObject(
            from,
            to,
            onCopyResponse,
            onCopyError
        );
    }
}