declare global {
    interface FileList {
        isEmpty(): boolean
        isNotEmpty(): boolean
    }
}

FileList.prototype.isEmpty = function (): boolean {
    return this.length === 0
}

FileList.prototype.isNotEmpty = function (): boolean {
    return this.length !== 0
}

export function _util_file_list() {}