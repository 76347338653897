import {Route, Routes} from "react-router-dom";
import List from "./List";
import Detail from "./Detail";
import {AssertSignIn} from "../../../App";
import ReportRouter from "./report/ReportRouter";

export default function ReviewRouter() {
    return <Routes>
        <Route path="/list" element={<AssertSignIn><List /></AssertSignIn>} />
        <Route path="/:adReviewId" element={<AssertSignIn><Detail /></AssertSignIn>} />
        <Route path="/report/*" element={<AssertSignIn><ReportRouter /></AssertSignIn>} />
    </Routes>;
}