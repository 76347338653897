import {BitMaskFlag} from "../util/BitMask";
import {Optional} from "../util/Types";

const json = require('json-bigint')({ useNativeBigInt: true });

export enum UserType {
    ADMIN,
    MANAGER,
    USER
}

export namespace UserType {
    export function toString(type: UserType): string {
        switch (type) {
            case UserType.ADMIN: return "관리자(어드민)";
            case UserType.MANAGER: return "관리자(매니저)";
            case UserType.USER: return "일반 사용자"
        }
    }

    export function values(): string[] {
        return [
            toString(UserType.ADMIN),
            toString(UserType.MANAGER),
            toString(UserType.USER),
        ];
    }
}

export enum UserGender {
    MALE,
    FEMALE
}

export namespace UserGender {
    export function toString(gender: UserGender): string {
        switch (gender) {
            case UserGender.MALE: return "남성";
            case UserGender.FEMALE: return "여성";
        }
    }

    export function ordinal(s: string): number {
        return values().indexOf(s);
    }

    export function values(): string[] {
        return [
            toString(UserGender.MALE),
            toString(UserGender.FEMALE)
        ];
    }
}

export enum UserNotificationType {
    NOTICE,
    EVENT,
    ANNOUNCEMENT,
    PRODUCT_REVIEW,
    POINT_WITHDRAW_COMPLETE,
    POINT_ADMIN
}

export namespace UserNotificationType {
    export function toString(type: UserNotificationType): string {
        switch (type) {
            case UserNotificationType.NOTICE: return "공지사항";
            case UserNotificationType.EVENT: return "이벤트";
            case UserNotificationType.ANNOUNCEMENT: return "당첨자";
            case UserNotificationType.PRODUCT_REVIEW: return "상품 리뷰";
            case UserNotificationType.POINT_WITHDRAW_COMPLETE: return "포인트 인출 완료";
            case UserNotificationType.POINT_ADMIN: return "포인트 지급";
        }
    }

    export function ordinal(s: string): number {
        return values().indexOf(s);
    }

    export function values(): string[] {
        return [
            toString(UserNotificationType.NOTICE),
            toString(UserNotificationType.EVENT),
            toString(UserNotificationType.ANNOUNCEMENT),
            toString(UserNotificationType.PRODUCT_REVIEW),
            toString(UserNotificationType.POINT_WITHDRAW_COMPLETE),
            toString(UserNotificationType.POINT_ADMIN)
        ];
    }
}

export class UserNotificationStatus extends BitMaskFlag {
    static readonly HIDDEN = Object.freeze(new UserNotificationStatus("숨김", 1 << 0));
}

export class UserStatus extends BitMaskFlag {
    static readonly DORMANT = Object.freeze(new UserStatus("휴면", 1 << 0));
    static readonly WITHDRAWN = Object.freeze(new UserStatus("탈퇴", 1 << 1));
}

export class UserModal {
    id!: bigint;
    email!: string;
    password!: string;
    passwordSalt!: string;
    phone!: string;
    nickname!: string;
    accountName!: string;
    recommenderId!: Optional<bigint>;
    birth!: string;
    gender!: UserGender;
    type!: UserType;
    serviceAgreementId!: number;
    locationAgreementId!: number;
    privacyAgreementId!: number;
    commercialNotificationAgreementId!: Optional<number>;
    statusFlags!: number;
    signedUpAt!: Date;
    dormantAt!: Optional<Date>;
    withdrawnAt!: Optional<Date>;
    withdrawCauseId!: Optional<number>;

    constructor(json: object) {
        Object.assign(this, json);
    }

    saveStorage() {
        window.localStorage.setItem('currentUser', json.stringify(this));
    }

    isManager() {
        return this.type === UserType.ADMIN || this.type === UserType.MANAGER;
    }

    profileImagePath(): string {
        return `user/${this.id}/profile.png`;
    }

    static loadFromStorage(): UserModal | null {
        const asJsonString = window.localStorage.getItem('currentUser');
        if (!asJsonString) {
            return null;
        }

        return new UserModal(json.parse(asJsonString));
    }

    static idOrNull(): Optional<bigint> {
        return this.loadFromStorage()?.id ?? null
    }

    static clearStorage() {
        window.localStorage.removeItem('currentUser');
    }
}

export class UserBlockModal {
    id!: number;
    userId!: bigint;
    causeId!: number;
    blockedAt!: Date;
    blockedUntil!: Date | null;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class UserBlockCauseModal {
    id!: number;
    description!: string;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class UserNotificationModal {
    id!: bigint;
    userId!: bigint;
    title!: string;
    body!: string;
    type!: UserNotificationType;
    statusFlags!: number;
    adId!: Optional<number>;
    productId!: Optional<number>;
    createdAt!: Date;
}

export class UserRegionModal {
    userId!: number;
    regionId!: number;
    createdAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class UserSessionModal {
    id!: bigint;
    userId!: bigint;
    createdAt!: Date;
    expiredAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }

    saveStorage() {
        window.localStorage.setItem('currentUserSession', json.stringify(this));
    }

    isExpired(): boolean {
        return this.expiredAt < new Date();
    }

    static loadFromStorage(): Optional<UserSessionModal> {
        const asJsonString = window.localStorage.getItem('currentUserSession');
        if (!asJsonString) {
            return null;
        }

        return new UserSessionModal(json.parse(asJsonString));
    }

    static id(): Optional<bigint> {
        return UserSessionModal.loadFromStorage()?.id ?? null
    }

    static clearStorage() {
        window.localStorage.removeItem('currentUserSession');
    }
}

export class UserSignInModal {
    id!: bigint;
    userId!: bigint;
    remoteAddress!: string;
    messagingToken!: Optional<string>;
    deviceName!: string;
    platformVersion!: string;
    applicationVersionId!: number;
    signedInAt!: Date;

    constructor(o: object) {
        Object.assign(this, o);
    }
}

export class UserWithdrawCauseModal {
    id!: number;
    description!: string;

    constructor(json: object) {
        Object.assign(this, json);
    }
}