import {BitMaskFlag} from "../util/BitMask";

class ProductBrandModal {
    id!: number;
    name!: string;
    creatorId!: bigint;
    createdAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class ProductDeliveryModal {
    id!: bigint;
    adId!: number;
    productId!: number;
    requesterId!: bigint;
    recipientName!: string;
    postcode!: string;
    addressPrefix!: string;
    addressDetail!: string;
    requestMessage!: string;
    requestedAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

class ProductDescriptionModal {
    id!: number;
    productId!: number;
    title!: string;
    body!: string;
    sortOrder!: number;
    creatorId!: bigint;
    createdAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

class ProductModal {
    static headerImagePath(contentOrId: ProductModal | number, index?: number): string {
        const id = (contentOrId instanceof ProductModal) ? contentOrId.id : contentOrId;
        return (index === undefined)
            ? `product/${id}/header`
            : `product/${id}/header/${index}.png`;
    }

    static descriptionImagePath(contentOrId: ProductModal | number, index?: number): string {
        const id = (contentOrId instanceof ProductModal) ? contentOrId.id : contentOrId;
        return (index === undefined)
            ? `product/${id}/description`
            : `product/${id}/description/${index}.png`;
    }

    id!: number;
    productBrandId!: number;
    name!: string;
    price!: number;
    headerImageCount!: number;
    descriptionImageCount!: number;
    creatorId!: bigint;
    createdAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

class ProductOptionItemModal {
    id!: number;
    productOptionId!: number;
    name!: string;
    sortOrder!: number;
    creatorId!: bigint;
    createdAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

class ProductOptionModal {
    id!: number;
    productId!: number;
    title!: string;
    sortOrder!: number;
    creatorId!: bigint;
    createdAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

class ProductReviewModal {
    id!: bigint;
    productId!: number;
    userId!: bigint;
    body!: string;
    rating!: number;
    imageCount!: number;
    statusFlags!: number;
    writtenAt!: Date;
    modifiedAt!: Date | null;
    deletedAt!: Date | null;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

class ProductReviewLikeModal {
    productReviewId!: bigint;
    userId!: bigint;
    likedAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

class ProductReviewReportModal {
    productReviewId!: bigint;
    userId!: bigint;
    causeId!: number;
    reportedAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

class ProductReviewReportCauseModal {
    id!: number;
    description!: string;
    sortOrder!: number;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class ProductReviewStatus extends BitMaskFlag {
    static readonly MODIFIED = Object.freeze(new ProductReviewStatus("수정됨", 1 << 0));
    static readonly DELETED = Object.freeze(new ProductReviewStatus("삭제됨", 1 << 1));
    static readonly values: ReadonlyArray<ProductReviewStatus> = [
        ProductReviewStatus.MODIFIED,
        ProductReviewStatus.DELETED
    ];
}

export {
    ProductBrandModal, ProductDescriptionModal, ProductModal, ProductOptionItemModal, ProductOptionModal,
    ProductReviewLikeModal, ProductReviewModal, ProductReviewReportCauseModal, ProductReviewReportModal
};