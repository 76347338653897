import {Requests} from "../util/Requests";
import {SERVER_URL} from "../util/Environments";
import {OnArrayResponse, OnBaseResponse, OnErrorResponse, OnObjectResponse} from "../util/Reponses";
import {PointUsageModal, PointUsageType, PointWithdrawalModal, PointWithdrawalRejectCauseModal} from "../modal/Points";
import {AxiosRequestConfig} from "axios";

export class PointUsageIO {
    static post(
        userId: bigint,
        amount: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'PointUsageIO.post',
            `${SERVER_URL}/point/usage`,
            o => o,
            onReady,
            onError,
            { userId: userId.toString(), amount, type: PointUsageType.ADMIN }
        );
    }

    static get(
        id: bigint,
        onReady: OnObjectResponse<PointUsageModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'PointUsageIO.get',
            `${SERVER_URL}/point/usage/${id}`,
            o => new PointUsageModal(o),
           onReady,
           onError
        );
    }

    static listAscending(
        lastId: bigint,
        onReady: OnArrayResponse<PointUsageModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'PointUsageIO.listAscending',
            `${SERVER_URL}/point/usage/list`,
            o => new PointUsageModal(o),
            onReady,
            onError,
            { params: { lastId, ascending: "" } }
        );
    }

    static listDescending(
        lastId: bigint,
        onReady: OnArrayResponse<PointUsageModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'PointUsageIO.listDescending',
            `${SERVER_URL}/point/usage/list`,
            o => new PointUsageModal(o),
            onReady,
            onError,
            { params: { lastId, descending: "" } }
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<PointUsageModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { userId, lastId, ascending: "" }
        };
        Requests.getArray(
            'PointUsageIO.listByUserIdAscending',
            `${SERVER_URL}/point/usage/list`,
            o => new PointUsageModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<PointUsageModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { userId, lastId, descending: "" }
        };
        Requests.getArray(
            'PointUsageIO.listByUserIdDescending',
            `${SERVER_URL}/point/usage/list`,
            o => new PointUsageModal(o),
            onReady,
            onError,
            config
        );
    }
}

export class PointWithdrawalIO {
    static withdraw(
        id: bigint,
        onReady: OnObjectResponse<PointWithdrawalModal>,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'PointWithdrawalIO.withdraw',
            `${SERVER_URL}/point/withdrawal/${id}/withdraw`,
            PointWithdrawalModal.construct,
            onReady,
            onError
        )
    }

    static reject(
        id: bigint,
        causeId: number,
        onReady: OnObjectResponse<PointWithdrawalModal>,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'PointWithdrawalIO.withdraw',
            `${SERVER_URL}/point/withdrawal/${id}/reject/${causeId}`,
            PointWithdrawalModal.construct,
            onReady,
            onError
        )
    }

    static listAscending(
        lastId: bigint,
        onReady: OnArrayResponse<PointWithdrawalModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'PointWithdrawalIO.listAscending',
            `${SERVER_URL}/point/withdrawal/list`,
            PointWithdrawalModal.construct,
            onReady,
            onError,
            { params: { lastId, ascending: "" } }
        );
    }

    static listDescending(
        lastId: bigint,
        onReady: OnArrayResponse<PointWithdrawalModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'PointWithdrawalIO.listDescending',
            `${SERVER_URL}/point/withdrawal/list`,
            PointWithdrawalModal.construct,
            onReady,
            onError,
            { params: { lastId, descending: "" } }
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<PointWithdrawalModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'PointWithdrawalIO.listByUserIdAscending',
            `${SERVER_URL}/point/withdrawal/list`,
            PointWithdrawalModal.construct,
            onReady,
            onError,
            { params: { userId, lastId, ascending: "" } }
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<PointWithdrawalModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'PointWithdrawalIO.listByUserIdDescending',
            `${SERVER_URL}/point/withdrawal/list`,
            PointWithdrawalModal.construct,
            onReady,
            onError,
            { params: { userId, lastId, descending: "" } }
        );
    }

    static listQualifiedDescending(
        lastId: bigint,
        onReady: OnArrayResponse<PointWithdrawalModal.Qualified>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'PointWithdrawalIO.listQualifiedByDescending',
            `${SERVER_URL}/point/withdrawal/qualified/list`,
            PointWithdrawalModal.Qualified.construct,
            onReady,
            onError,
            { params: { lastId, descending: '' } }
        )
    }
}

export class PointWithdrawalRejectCauseIO {
    static post(
        description: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'PointWithdrawalRejectCauseIO.post',
            `${SERVER_URL}/misc/point/withdrawal/reject/cause`,
            o => o,
            onReady,
            onError,
            {description}
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<PointWithdrawalRejectCauseModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'PointWithdrawalRejectCauseIO.get',
            `${SERVER_URL}/misc/point/withdrawal/reject/cause/${id}`,
            o => new PointWithdrawalRejectCauseModal(o),
            onReady,
            onError
        );
    }

    static listAll(
        onReady: OnArrayResponse<PointWithdrawalRejectCauseModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'PointWithdrawalRejectCauseIO.listAll',
            `${SERVER_URL}/misc/point/withdrawal/reject/cause/list`,
            o => new PointWithdrawalRejectCauseModal(o),
            onReady,
            onError,
        );
    }

    static update(
        pointWithdrawalRejectCauseId: number,
        description: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'PointWithdrawalRejectCauseIO.update',
            `${SERVER_URL}/misc/point/withdrawal/reject/cause/${pointWithdrawalRejectCauseId}`,
            o => o,
            onReady,
            onError,
            {description}
        );
    }

    static delete(
        pointWithdrawalRejectCauseId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'PointWithdrawalRejectCauseIO.delete',
            `${SERVER_URL}/misc/point/withdrawal/reject/cause/${pointWithdrawalRejectCauseId}`,
            o => o,
            onReady,
            onError
        );
    }
}