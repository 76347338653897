import {useParams} from "react-router-dom";
import {Optional, StateWrapper, useWrapper} from "../../util/Types";
import {M10nModal, M10nParticipationModal, M10nReviewModal} from "../../modal/M10ns";
import {ErrorMessage, Nav, NavBarItemAction, NavTabItemProps} from "../Common";
import React, {useEffect} from "react";
import {useEffectOptional} from "../../util/Hooks";
import {M10nIO, M10nParticipationIO, M10nReviewIO} from "../../io/M10n";
import {CardHorizontal} from "../common/CardHorizontal";

export function Detail() {
    const { id } = useParams<{ id?: string }>()
    const m10n = useWrapper<Optional<M10nModal>>(null)
    const participationCount = useWrapper<Optional<bigint>>(null)
    const passedParticipationCount = useWrapper<Optional<bigint>>(null)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)

    useEffect(() => M.AutoInit())

    useEffectOptional(id => M10nIO.getById(Number(id), m10n.set, errorMessage.set), id)
    useEffectOptional(id => M10nParticipationIO.countByM10nId(Number(id), participationCount.set, errorMessage.set), id)
    useEffectOptional(id => M10nParticipationIO.countPassedByM10nId(Number(id), passedParticipationCount.set, errorMessage.set), id)

    return <>
        {m10n.value && participationCount.value !== null && passedParticipationCount.value !== null && <>
            <PageWrapper
                m10n={m10n.value}
                participationCount={participationCount.value}
                passedParticipationCount={passedParticipationCount.value}
                errorMessage={errorMessage} />
        </>}
    </>
}

const defaultPageId = 'm10n_detail_page_default'
const participationPageId = 'm10n_detail_page_participation'
const reviewPageId = 'm10n_detail_page_review'

type PageWrapperProps = {
    m10n: M10nModal
    participationCount: bigint
    passedParticipationCount: bigint
    errorMessage: StateWrapper<Optional<ErrorMessage>>
}

function PageWrapper(props: PageWrapperProps) {
    const barItems: [string, NavBarItemAction][] = [
        ["수정", () => { document.location = `/m10n/${props.m10n.id}/edit` }]
    ];

    const tabItems: NavTabItemProps[] = [
        { text: '기본', href: `#${defaultPageId}` },
        { text: '참여', href: `#${participationPageId}` },
        { text: '리뷰', href: `#${reviewPageId}` },
    ]

    return <>
        <Nav
            title={'암기플러스'}
            titleIcon={'chevron_left'}
            titleHref={'/m10n/list'}
            barItems={barItems}
            tabItems={tabItems} />
        <DefaultPage {...props} />
        <ParticipationPage {...props} />
        <ReviewPage {...props} />
    </>
}

type DefaultPageProps = PageWrapperProps & {}

function DefaultPage(props: DefaultPageProps) {
    return <>
        <div id={defaultPageId}>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal
                        title={"ID"}
                        value={props.m10n.id.toString()}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal
                        title={"제목"}
                        value={props.m10n.title}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal
                        title={"설명"}
                        value={props.m10n.description} />
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal
                        title={"본문"}
                        value={props.m10n.script}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal
                        title={"더 알아보기 URL"}
                        value={props.m10n.detailUrl}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal
                        title={"더 알아보기 URL 네이버 앱 열기"}
                        value={M10nModal.Status.DETAIL_URL_NAVER.isEnabled(props.m10n.statusFlags) ? "예" : "아니오"}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal
                        title={"더 알아보기 클립보드"}
                        value={props.m10n.detailClipboard ?? "(없음)"}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"외부 연결 URL"} value={props.m10n.externalUrl}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"외부 연결 URL 날개 지급"}
                                    value={M10nModal.Status.EXTERNAL_URL_WING.isEnabled(props.m10n.statusFlags) ? "예" : "아니오"}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"외부 연결 URL 네이버 앱 열기"}
                                    value={M10nModal.Status.EXTERNAL_URL_NAVER.isEnabled(props.m10n.statusFlags) ? "예" : "아니오"}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"채점 중 표시 텍스트"} value={props.m10n.gradingMessage ?? "(없음)"}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"수량"} value={props.m10n.amount.toString()}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"포인트"} value={props.m10n.point.toString()}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"높은 일치율 추가 포인트"} value={props.m10n.pointAdditionalHighAccuracy.toString()}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"난이도"} value={M10nModal.Difficulty.toString(props.m10n.difficulty)}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"최소 합격 일치율"} value={props.m10n.minimumAccuracy.toString() + "%"}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s4 offset-s2'}>
                    <CardHorizontal title={"합격"} value={props.passedParticipationCount.toString()}/>
                </div>
                <div className={'col s4'}>
                    <CardHorizontal title={"참여"} value={props.participationCount.toString()}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"생성 시각"} value={props.m10n.createdAt.toRowFormat(true, true, false, false)}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"시작 시각"} value={props.m10n.startAt.toRowFormat(true, true, false, false)}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"종료 시각"} value={props.m10n.endAt.toRowFormat(true, true, false, false)}/>
                </div>
            </div>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"노출 시각"} value={props.m10n.exposedAt.toRowFormat(true, true, false, false)}/>
                </div>
            </div>
        </div>
    </>
}

type ParticipationPageProps = PageWrapperProps & {}

function ParticipationPage(props: ParticipationPageProps) {
    const participations = useWrapper<M10nParticipationModal[]>([])
    const rows = useWrapper<JSX.Element[]>([])
    useEffect(() => M10nParticipationIO.listAllByM10nId(props.m10n.id, participations.set, props.errorMessage.set), [])
    useEffect(() => {
        rows.set(participations.value.map(participation => <>
            <tr key={participation.id.toString()}>
                <td><a href={`/m10n/participation/${participation.id}`}>{participation.id.toString()}</a></td>
                <td><a href={`/user/${participation.userId}`}>{participation.userId.toString()}</a></td>
                <td>{participation.recordedText}</td>
                <td>{participation.accuracy.toString()}</td>
                <td>{(participation.isPassed) ? 'O' : 'X'}</td>
                <td>{participation.participatedAt.toRowFormat(true, true)}</td>
            </tr>
        </>))
    }, [participations.value])

    return <>
        <div id={participationPageId} className={'row cascade'}>
            <table className={'centered highlight'}>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>사용자 ID</th>
                    <th>녹음된 텍스트</th>
                    <th>일치율</th>
                    <th>합격</th>
                    <th>참여 시각</th>
                </tr>
                </thead>
                <tbody>
                {rows.value}
                </tbody>
            </table>
        </div>
    </>
}

type ReviewPageProps = PageWrapperProps & {}

function ReviewPage(props: ReviewPageProps) {
    const reviews = useWrapper<M10nReviewModal[]>([])
    const rows = useWrapper<JSX.Element[]>([])
    useEffect(() => M10nReviewIO.listAllByM10nId(props.m10n.id, reviews.set, props.errorMessage.set), [])
    useEffect(() => {
        rows.set(reviews.value.map(review => <>
            <tr key={review.id.toString()}>
                <td><a href={`/m10n/review/${review.id}`}>{review.id.toString()}</a></td>
                <td><a href={`/user/${review.userId}`}>{review.userId.toString()}</a></td>
                <td>{review.body}</td>
                <td>{review.writtenAt.toRowFormat(true, true)}</td>
            </tr>
        </>))
    }, [reviews.value])

    return <>
        <div id={reviewPageId} className={'col cascade'}>
            <table className={'centered highlight'}>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>사용자 ID</th>
                    <th>내용</th>
                    <th>작성 시각</th>
                </tr>
                </thead>
            </table>
        </div>
    </>
}