import {Route, Routes} from "react-router-dom";
import {AssertSignIn} from "../../../App";
import BannerRouter from "./banner/BannerRouter";
import BestRouter from "./best/BestRouter";

export default function HomeRouter() {
    return <Routes>
        <Route path="/banner/*" element={<AssertSignIn><BannerRouter /></AssertSignIn>} />
        <Route path="/best/*" element={<AssertSignIn><BestRouter /></AssertSignIn>} />
    </Routes>
}