import {useLocation, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {FaqModal} from "../../../modal/Miscs";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {MiscFaqIO} from "../../../io/Miscs";
import {
    Component,
    MaterialComponent,
    MaterialDatepicker,
    MaterialInput,
    MaterialModal, MaterialTextarea, MaterialTimepicker
} from "../../Materials";
import qs from "qs";

const pageId = 'faq_edit';
const categoryInputId = `${pageId}_category`;
const titleInputId = `${pageId}_title`;
const bodyTextareaId = `${pageId}_body`;

export default function Edit() {
    const {faqId} = useParams() as { faqId?: number };
    const {faqCategoryId} = qs.parse(
        useLocation().search,
        { ignoreQueryPrefix: true }
    ) as { faqCategoryId?: number };

    const [content, setContent] = useState<Optional<FaqModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        MaterialInput.init();
        MaterialDatepicker.init();
        MaterialTimepicker.init();
    });

    useEffect(() => {
        if (faqId) {
            MiscFaqIO.get(faqId, setContent, setErrorMessage);
            MaterialInput.getOrNull(`#${categoryInputId}`)?.disable();
        }
    }, [faqId]);

    useEffect(() => {
        const categoryInput = (faqId) ? null : MaterialInput.getOrNull(`#${categoryInputId}`);
        const inputs = [
            categoryInput,
            MaterialInput.getOrNull(`#${titleInputId}`),
            MaterialTextarea.getOrNull(`#${bodyTextareaId}`),
        ];
        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [faqId, rootEnabled]);

    useEffect(() => {
        if (faqCategoryId) {
            MaterialInput.getOrNull(`#${categoryInputId}`)?.setValue(faqCategoryId.toString());
        }
    }, [faqCategoryId]);

    useEffect(() => {
        if (content) {
            MaterialInput.getOrNull(`#${categoryInputId}`)?.setValue(content.categoryId.toString());
            MaterialInput.getOrNull(`#${titleInputId}`)?.setValue(content.title);
            MaterialTextarea.getOrNull(`#${bodyTextareaId}`)?.setValue(content.body);
        }
    }, [content]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contentState={[content, setContent]}
            errorMessageState={[errorMessage, setErrorMessage]}
            setRootEnabled={setRootEnabled} />
    </>;
}

type PageWrapperProps = {
    contentState: StatePair<Optional<FaqModal>>;

    errorMessageState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [content] = props.contentState;
    const [, setErrorMessage] = props.errorMessageState;
    const onSaveClicked = () => OnSaveClicked(content, setErrorMessage, props.setRootEnabled);
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", window.history.back]
    ];

    return <>
        <Nav
            title={"자주 묻는 질문 " + ((content) ? "수정" : "추가")}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <Editor />
    </>;
}

function OnSaveClicked(
    content: Optional<FaqModal>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const faqCategoryId = MaterialInput.get(`#${categoryInputId}`).getValue().toIntOrNull();
    if (faqCategoryId === null) {
        setErrorMessage('카테고리 ID를 확인해주세요.');
        return;
    }

    const title = MaterialInput.get(`#${titleInputId}`).getValue();
    if (title.length.notIn(1, 101)) {
        setErrorMessage('제목을 확인해주세요.');
        return;
    }

    const body = MaterialTextarea.get(`#${bodyTextareaId}`).getValue();
    if (body.length.notIn(1, 1001)) {
        setErrorMessage('내용을 확인해주세요.');
        return;
    }

    const onReady = () => {
        window.alert('저장되었습니다.');
        document.location = `/misc/faq/category/${faqCategoryId}`;
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    if (!content) {
        setRootEnabled(false);
        MiscFaqIO.post(title, body, faqCategoryId, onReady, onError);
    } else {
        if (content.title === title &&
            content.body === body) {
            onReady();
            return;
        }

        setRootEnabled(false);
        MiscFaqIO.update(content.id, title, body, onReady, onError);
    }
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={categoryInputId}
                inputType="number"
                inputClasses="validate"
                label="카테고리 ID" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={titleInputId}
                inputClasses="validate"
                inputPlaceHolder="100자 이내"
                inputDataLength={100}
                label="제목" />
        </div>
        <div className="row cascade">
            <Component.Textarea
                formClasses="col s8 offset-s2"
                textareaId={bodyTextareaId}
                textareaClasses="validate"
                textareaPlaceHolder="1,000자 이내"
                textareaDataLength={1000}
                label="내용" />
        </div>
    </>;
}