import {M10nParticipationModal} from "../../../modal/M10ns";
import {Optional, useBoolean, useWrapper} from "../../../util/Types";
import {BottomProgress, ErrorMessage, Nav} from "../../Common";
import React, {useEffect} from "react";
import {MaterialModal} from "../../Materials";
import {M10nParticipationIO} from "../../../io/M10n";
import {getMaxLong} from "../../../util/Environments";

export function List() {
    const contents = useWrapper<M10nParticipationModal[]>([])
    const hasMoreContents = useBoolean(true)
    const isFetching = useBoolean(false)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)

    useEffect(() => {
        M.AutoInit()
        MaterialModal.init()
    })

    useEffect(() => fetchContents(), [])

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    })

    const fetchContents = () => {
        isFetching.setTrue()
        M10nParticipationIO.listAllDescending(
            contents.value.lastOrNull()?.id ?? getMaxLong(),
            response => {
                contents.set(contents.value.appended(response))
                hasMoreContents.set(response.length >= 20)
                isFetching.setFalse()
            },
            error => {
                errorMessage.set(error)
                hasMoreContents.setFalse()
            }
        )
    }

    const onWindowScroll = () => {
        if (!isFetching.value && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            fetchContents()
        }
    }

    const rows = contents.value.map(value => <>
        <Row
            key={value.id.toString()}
            content={value} />
    </>)

    return <>
        <Nav
            title={'암기플러스 참여'}
            titleHref={'/'}
            titleIcon={'chevron_left'}
            excludeErrorModal={false} />
        <table className={'centered highlight'}>
            <thead>
            <tr>
                <th>ID</th>
                <th>암기플러스 ID</th>
                <th>사용자 ID</th>
                <th>녹음 본문</th>
                <th>일치율</th>
                <th>합격 여부</th>
                <th>참여 시각</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents.value} />
        </div>
    </>
}

type RowProps = {
    content: M10nParticipationModal
}

function Row(props: RowProps) {
    return <>
        <tr>
            <td><a href={`/m10n/participation/${props.content.id}`}>{props.content.id.toString()}</a></td>
            <td><a href={`/m10n/${props.content.m10nId}`}>{props.content.m10nId.toString()}</a></td>
            <td><a href={`/user/${props.content.userId}`}>{props.content.userId.toString()}</a></td>
            <td>{props.content.recordedText}</td>
            <td>{props.content.accuracy + '%'}</td>
            <td>{(props.content.isPassed) ? 'O' : 'X'}</td>
            <td>{props.content.participatedAt.toRowFormat(true, true)}</td>
        </tr>
    </>
}