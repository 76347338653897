import React, {useEffect, useState} from "react";
import {
    AdDifficulty,
    AdModal,
    AdParticipationModal, AdParticipationType, AdReviewModal, AdRewardType,
    AdTargetAgeModal,
    AdTargetGenderModal
} from "../../modal/Ads";
import {Optional, StatePair} from "../../util/Types";
import {ErrorMessage, Nav, NavBarItemAction, NavTabItemProps, ReviewStar} from "../Common";
import {useParams} from "react-router-dom";
import {ProductModal} from "../../modal/Products";
import {
    AdIO,
    AdParticipationIO, AdReviewIO,
    AdTargetAgeRangeIO,
    AdTargetGenderIO,
    AdTargetRegionIO
} from "../../io/Ads";
import {getMaxLong, getMinLong, Logger} from "../../util/Environments";
import {AgeRangeModal, RegionModal} from "../../modal/Miscs";
import {MiscAgeRangeIO} from "../../io/Miscs";
import {UserGender} from "../../modal/Users";
import {MaterialModal} from "../Materials";
import {ProductIO} from "../../io/Products";
import {OnBaseResponse, OnErrorResponse} from "../../util/Reponses";
import {UniqueContent} from "../../modal/Modal";
import {CardHorizontal} from "../common/CardHorizontal";

const pageId = 'ad_detail';
const defaultPageId = `${pageId}_default`;
const targetAgePageId = `${pageId}_target_age`;
const targetGenderPageId = `${pageId}_target_gender`;
const targetRegionPageId = `${pageId}_target_region`;

export default function Detail() {
    const params = useParams();
    const adId = params.adId?.toIntOrNull();

    const [ad, setAd] = useState<Optional<AdModal>>(null);
    const [product, setProduct] = useState<Optional<ProductModal>>(null);
    const [targetAges, setTargetAges] = useState<Optional<AdTargetAgeModal[]>>(null);
    const [ageRanges, setAgeRanges] = useState<Optional<AgeRangeModal[]>>(null)
    const [targetGenders, setTargetGenders] = useState<Optional<AdTargetGenderModal[]>>(null);
    const [regions, setRegions] = useState<Optional<RegionModal[]>>(null);

    const [hasMoreParticipations, setMoreParticipations] = useState<boolean>(true);
    const [participationLock, setParticipationLock] = useState<number>(0);
    const [participations, setParticipations] = useState<Optional<AdParticipationModal[]>>(null);
    const [participationCount, setParticipationCount] = useState<Optional<bigint>>(null);
    const [participationAscending, setParticipationAscending] = useState<boolean>(true);
    const [passedParticipationCount, setPassedParticipationCount] = useState<Optional<bigint>>(null);

    const [hasMoreReviews, setMoreReviews] = useState<boolean>(true);
    const [reviewLock, setReviewLock] = useState<number>(0);
    const [reviews, setReviews] = useState<Optional<AdReviewModal[]>>(null);
    const [reviewCount, setReviewCount] = useState<Optional<number>>(null);
    const [reviewAscending, setReviewAscending] = useState<boolean>(true);

    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
        M.Tabs.init(document.querySelectorAll('ul.tabs')).forEach(tab => {
            Logger.log(tab);
        });
    });

    useEffect(() => {
        if (adId) {
            AdIO.get(adId, setAd, setErrorMessage);
            AdTargetAgeRangeIO.listRange(adId, setTargetAges, setErrorMessage);
            AdTargetGenderIO.list(adId, setTargetGenders, setErrorMessage);
            AdTargetRegionIO.list(adId, setRegions, setErrorMessage)
            PrepareParticipations(adId, [participations, setParticipations], participationAscending, setMoreParticipations, setErrorMessage);
            AdParticipationIO.countByAdId(adId, setParticipationCount, setErrorMessage);
            AdParticipationIO.countPassedByAdId(adId, setPassedParticipationCount, setErrorMessage);
            PrepareReviews(adId, [reviews, setReviews], reviewAscending, setMoreReviews, setErrorMessage);
            AdReviewIO.countByAdId(adId, setReviewCount, setErrorMessage);
        }
    }, [adId]);

    useEffect(() => {
        if (ad && ad.productId) {
            ProductIO.get(ad.productId, setProduct, setErrorMessage);
        }
    }, [ad]);

    useEffect(() => {
        if (targetAges) {
            MiscAgeRangeIO.listByIds(targetAges.map(age => age.ageRangeId), setAgeRanges, setErrorMessage);
        }
    }, [targetAges]);

    useEffect(() => {
        if (adId) {
            PrepareParticipations(adId, [participations, setParticipations], participationAscending, setMoreParticipations, setErrorMessage);
        }
    }, [adId, participationAscending, participationLock]);

    useEffect(() => {
        if (adId) {
            PrepareReviews(adId, [reviews, setReviews], reviewAscending, setMoreReviews, setErrorMessage);
        }
    }, [adId, reviewAscending, reviewLock]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull(`#error_modal`)?.open();
        }
    }, [errorMessage]);

    if (adId === undefined) {
        window.alert('잘못된 요청입니다.');
        return <></>;
    }

    return <PageWrapper
        adId={adId}
        ad={ad}
        product={product}
        ageRanges={ageRanges}
        targetGenders={targetGenders}
        regions={regions}

        hasMoreParticipations={hasMoreParticipations}
        participationLockState={[participationLock, setParticipationLock]}
        participationsState={[participations, setParticipations]}
        participationCount={Number(participationCount ?? 0n)}
        participationAscendingState={[participationAscending, setParticipationAscending]}
        passedParticipationCount={Number(passedParticipationCount ?? 0n)}

        hasMoreReviews={hasMoreReviews}
        reviewLockState={[reviewLock, setReviewLock]}
        reviewsState={[reviews, setReviews]}
        reviewCount={reviewCount}
        reviewAscendingState={[reviewAscending, setReviewAscending]}

        setErrorMessage={setErrorMessage} />;
}

function PrepareParticipations(
    adId: number,
    participationsState: StatePair<Optional<AdParticipationModal[]>>,
    participationAscending: boolean,
    setMoreContents: React.Dispatch<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const [present, setParticipates] = participationsState;
    const last = present?.lastOrNull()?.id;
    const onReady = (participations: AdParticipationModal[]) => {
        setParticipates((present ?? []).appended(participations));
        setMoreContents(participations.length === 20);
    };
    if (participationAscending) {
        AdParticipationIO.listByAdIdAscending(adId, last ?? getMinLong(), onReady, setErrorMessage);
    } else {
        AdParticipationIO.listByAdIdDescending(adId, last ?? getMaxLong(), setParticipates, setErrorMessage);
    }
}

function PrepareReviews(
    adId: number,
    reviewsState: StatePair<Optional<AdReviewModal[]>>,
    reviewAscending: boolean,
    setMoreContents: React.Dispatch<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const [present, setReviews] = reviewsState;
    const last = present?.lastOrNull()?.id;
    const onReady = (reviews: AdReviewModal[]) => {
        setReviews((present ?? []).appended(reviews));
        setMoreContents(reviews.length === 20);
    };
    if (reviewAscending) {
        AdReviewIO.listByAdIdAscending(adId, last ?? getMinLong(), onReady, setErrorMessage);
    } else {
        AdReviewIO.listByAdIdDescending(adId, last ?? getMaxLong(), setReviews, setErrorMessage);
    }
}

type PageWrapperProps = {
    adId: Optional<number>;
    ad: Optional<AdModal>;
    product: Optional<ProductModal>;
    ageRanges: Optional<AgeRangeModal[]>;
    targetGenders: Optional<AdTargetGenderModal[]>;
    regions: Optional<RegionModal[]>;

    hasMoreParticipations: boolean,
    participationLockState: StatePair<number>;
    participationsState: StatePair<Optional<AdParticipationModal[]>>;
    participationCount: Optional<number>,
    participationAscendingState: StatePair<boolean>;
    passedParticipationCount: Optional<number>

    hasMoreReviews: boolean,
    reviewLockState: StatePair<number>;
    reviewsState: StatePair<Optional<AdReviewModal[]>>;
    reviewCount: Optional<number>,
    reviewAscendingState: StatePair<boolean>;

    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const pageState = useState<number>(0);

    const adId = props.adId;
    if (adId === null) {
        return <></>;
    }

    const barItems: [string, NavBarItemAction][] = [
        ["수정", () => { document.location = `/ad/${props.ad?.id}/edit` }]
    ];

    const tabItems: NavTabItemProps[] = [
        { text: "기본", href: `#${defaultPageId}` },
        { text: "대상 연령대", href: `#${targetAgePageId}` },
        { text: "대상 성별", href: `#${targetGenderPageId}` },
        { text: "대상 지역", href: `#${targetRegionPageId}` },
    ];

    return <>
        <Nav
            title="광고"
            titleIcon="chevron_left"
            titleHref="/ad/list"
            barItems={barItems}
            tabItems={tabItems}
            pageState={pageState} />
        <DefaultPage
            ad={props.ad}
            product={props.product}
            hasMoreParticipations={props.hasMoreParticipations}
            participationLockState={props.participationLockState}
            participationsState={props.participationsState}
            participationCount={props.participationCount}
            participationAscendingState={props.participationAscendingState}
            passedParticipationCount={props.passedParticipationCount}
            hasMoreReviews={props.hasMoreReviews}
            reviewLockState={props.reviewLockState}
            reviewsState={props.reviewsState}
            reviewCount={props.reviewCount}
            reviewAscendingState={props.reviewAscendingState} />
        <ContentsPage
            adId={adId}
            pageId={targetAgePageId}
            tableHeaders={['ID', '이름', '시작 나이(포함)', '종료 나이(제외)', '동작']}
            contents={props.ageRanges}
            rowSelector={content => <>
                <TargetAgeRow
                    adId={adId}
                    content={content}
                    setErrorMessage={props.setErrorMessage} />
            </>} />
        <ContentsPage
            adId={adId}
            pageId={targetGenderPageId}
            tableHeaders={['이름', '동작']}
            contents={props.targetGenders}
            rowSelector={content => <>
                <TargetGenderRow
                    adId={adId}
                    content={content}
                    setErrorMessage={props.setErrorMessage} />
            </>} />
        <ContentsPage
            adId={adId}
            pageId={targetRegionPageId}
            tableHeaders={['ID', '이름', '동작']}
            contents={props.regions}
            rowSelector={content => <>
                <TargetRegionRow
                    adId={adId}
                    content={content}
                    setErrorMessage={props.setErrorMessage} />
            </>} />
    </>;
}

type DefaultPageProps = {
    ad: Optional<AdModal>;
    product: Optional<ProductModal>;
    hasMoreParticipations: boolean;
    participationLockState: StatePair<number>;
    participationsState: StatePair<Optional<AdParticipationModal[]>>;
    participationCount: Optional<number>;
    participationAscendingState: StatePair<boolean>;
    passedParticipationCount: Optional<number>;
    hasMoreReviews: boolean;
    reviewLockState: StatePair<number>;
    reviewsState: StatePair<Optional<AdReviewModal[]>>;
    reviewCount: Optional<number>;
    reviewAscendingState: StatePair<boolean>;
};

function DefaultPage(props: DefaultPageProps) {
    return <div id={defaultPageId}>
        <AdArea ad={props.ad} product={props.product} />
        <ParticipationArea
            hasMoreContents={props.hasMoreParticipations}
            lockState={props.participationLockState}
            contentsState={props.participationsState}
            count={props.participationCount}
            passedCount={props.passedParticipationCount}
            ascendingState={props.participationAscendingState} />
        <ReviewArea
            hasMoreContents={props.hasMoreReviews}
            lockState={props.reviewLockState}
            contentsState={props.reviewsState}
            count={props.reviewCount}
            ascendingState={props.reviewAscendingState} />
    </div>;
}

type AdAreaProps = {
    ad: Optional<AdModal>;
    product: Optional<ProductModal>;
};

function AdArea(props: AdAreaProps) {
    const ad = props.ad;
    if (!ad) {
        return <></>;
    }

    const rewardType = ad.rewardType.toBitMask(AdRewardType.values);

    let pointCards: JSX.Element;
    if (rewardType.isEnabled(AdRewardType.POINT)) {
        pointCards = <>
            <CardHorizontal title="포인트" value={ad.point.toString()} />
            <CardHorizontal title="높은 일치율 추가 포인트" value={ad.pointAdditionalHighAccuracy.toString()} />
        </>;
    } else {
        pointCards = <></>;
    }

    const product = props.product;
    let productCard: JSX.Element;
    if (rewardType.isDisabled(AdRewardType.PRODUCT) || ad.productId === null || !product) {
        productCard = <></>;
    } else {
        productCard = <CardHorizontal title={"상품"} value={product.name} href={`/product/${product.id}`} />;
    }

    const announcedAt = ad.announcedAt;
    let announcedAtCard: JSX.Element;
    if (rewardType.isEnabled(AdRewardType.PRODUCT) && announcedAt) {
        announcedAtCard = <CardHorizontal title="발표 일시" value={announcedAt.toRowFormat(true, true, false, false)} />
    } else {
        announcedAtCard = <></>;
    }

    return <>
        <div className="row" style={{ marginTop: "2rem" }}>
            <div className="col s8 offset-s2">
                <CardHorizontal title="제목" value={ad.title} />
                <CardHorizontal title="설명" value={ad.description} smallBody={true} />
                <CardHorizontal title="본문" value={ad.script} smallBody={true} />
                <CardHorizontal title="외부 연결 URL" value={ad.externalUrl} smallBody={true} />
                <CardHorizontal title="채점 중 표시할 텍스트" value={ad.gradingMessage ?? "(없음)"} smallBody={true} />
                <CardHorizontal title="수량" value={ad.amount.toString()} />
                {pointCards}
                {productCard}
                <CardHorizontal title="참가 유형" value={ad.participationType.toBitMask(AdParticipationType.values).toString()} />
                <CardHorizontal title="보상 유형" value={ad.rewardType.toBitMask(AdRewardType.values).toString()} />
                <CardHorizontal title="난이도" value={AdDifficulty.toString(ad.difficulty)} />
                <CardHorizontal title="합격 최소 일치율" value={ad.minimumAccuracy + "%"} />
                <CardHorizontal title="생성 일시" value={ad.createdAt.toRowFormat(true, true, false, false)} />
                <CardHorizontal title="참여 시작 일시" value={ad.startAt.toRowFormat(true, true, false, false)} />
                <CardHorizontal title="참여 종료 일시" value={ad.endAt.toRowFormat(true, true, false, false)} />
                <CardHorizontal title="노출 시작 일시" value={ad.exposedAt.toRowFormat(true, true, false, false)} />
                {announcedAtCard}
            </div>
        </div>
    </>;
}

type AreaProps<T> = {
    hasMoreContents?: boolean;
    lockState?: StatePair<number>;
    contentsState: StatePair<Optional<T[]>>;
    ascendingState?: StatePair<boolean>;
    count?: Optional<number>;
    passedCount?: Optional<number>;
};

function ParticipationArea(props: AreaProps<AdParticipationModal>) {
    const [contents, setContents] = props.contentsState;
    if (!contents || contents.isEmpty()) {
        return <></>;
    }

    if (!props.ascendingState || !props.lockState || props.count === null || props.count === undefined || props.passedCount === undefined) {
        return <></>;
    }

    const [ascending, setAscending] = props.ascendingState;
    const columns: JSX.Element[][] = [[], [], [], []];
    const Card = ({content}: {content: AdParticipationModal}) => <>
        <div className="card">
            <div className="card-content">
                <a href={`/ad/participation/${content.id}`}>ID: {content.id.toString()}</a><br />
                <a href={`/user/${content.userId}`}>사용자: {content.userId.toString()}</a>
                <p>날짜: {content.participatedAt.toRowFormat(true, false, false, false)}</p>
            </div>
        </div>
    </>;

    contents.forEach((content, index) => columns[index % 4].push(<Card key={index} content={content} />));
    if (props.hasMoreContents) {
        columns[contents.length % 4].push(<CardMore key={contents.length} lockState={props.lockState} />);
    }

    const sortText = (ascending) ? "오름차순" : "내림차순";
    const onSortClicked = () => {
        setContents(null);
        setAscending(!ascending);
    };
    return <>
        <div className="row" style={{ marginBottom: 0 }}>
            <div className="divider col s8 offset-s2" />
            <h5 className="col offset-s2">합격 / 참여 ({props.passedCount} / {props.count})</h5>
            <h5 className="col primary-text" onClick={onSortClicked} style={{ cursor: "pointer" }}>{sortText}</h5>
        </div>
        <div className="row">
            <div className="col s2 offset-s2">{columns[0]}</div>
            <div className="col s2">{columns[1]}</div>
            <div className="col s2">{columns[2]}</div>
            <div className="col s2">{columns[3]}</div>
        </div>
    </>;
}

function ReviewArea(props: AreaProps<AdReviewModal>) {
    const [contents, setContents] = props.contentsState;
    if (!contents || contents.isEmpty()) {
        return <></>;
    }

    if (!props.ascendingState || !props.lockState || props.count === null || props.count === undefined) {
        return <></>;
    }

    const [ascending, setAscending] = props.ascendingState;
    const columns: JSX.Element[][] = [[], [], [], []];
    const Card = ({content}: {content: AdReviewModal}) => <>
        <div className="card">
            <div className="card-content">
                <a href={`/ad/review/${content.id}`}>ID: {content.id.toString()}</a><br />
                <a href={`/user/${content.userId}`}>사용자: {content.userId.toString()}</a>
                <p>날짜: {content.writtenAt.toRowFormat(true, false, false, false)}</p>
                <ReviewStar value={content.rating} />
            </div>
        </div>
    </>;

    contents.forEach((content, index) => columns[index % 4].push(<Card key={index} content={content} />));
    if (props.hasMoreContents) {
        columns[contents.length % 4].push(<CardMore key={contents.length} lockState={props.lockState} />);
    }

    const sortText = (ascending) ? "오름차순" : "내림차순";
    const onSortClicked = () => {
        setContents(null);
        setAscending(!ascending);
    };
    return <>
        <div className="row" style={{ marginBottom: 0 }}>
            <div className="divider col s8 offset-s2" />
            <h5 className="col offset-s2">리뷰 ({props.count})</h5>
            <h5 className="col primary-text" onClick={onSortClicked} style={{ cursor: "pointer" }}>{sortText}</h5>
        </div>
        <div className="row">
            <div className="col s2 offset-s2">{columns[0]}</div>
            <div className="col s2">{columns[1]}</div>
            <div className="col s2">{columns[2]}</div>
            <div className="col s2">{columns[3]}</div>
        </div>
    </>;
}

type CardMoreProps = {
    lockState: StatePair<number>
};

function CardMore(props: CardMoreProps) {
    const [lock, setLock] = props.lockState;
    return <>
        <div className="card">
            <div className="card-content">
                <a>‎</a>
                <p>‎</p>
                <span className="material-symbols-rounded" onClick={() => setLock(lock + 1)} style={{
                    fontSize: "xxx-large",
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)" }}>add</span>
            </div>
        </div>
    </>
}

type ContentsPageProps<T> = {
    adId: number;
    pageId: string;
    tableHeaders: string[];
    contents: Optional<T[]>;
    rowSelector: (content: T) => JSX.Element;
};

function ContentsPage<T>(props: ContentsPageProps<T>) {
    const tableHeaders = props.tableHeaders.map(element => <th key={element}>{element}</th>);
    const rows = (props.contents ?? []).map(props.rowSelector);
    const onAddClick = () => {
        switch (props.pageId) {
            case targetAgePageId: document.location = `/ad/${props.adId}/target/age`; return;
            case targetGenderPageId: document.location = `/ad/${props.adId}/target/gender`; return;
            case targetRegionPageId: document.location = `/ad/${props.adId}/target/region`; return;
        }
    }
    return <>
        <div id={props.pageId}>
            <table className="centered highlight">
                <thead><tr>{tableHeaders}</tr></thead>
                <tbody>{rows}</tbody>
            </table>
            <div className="fixed-action-btn">
                <a className="btn-floating btn-large secondary" onClick={onAddClick}>
                    <i className="large material-icons">add</i>
                </a>
            </div>
        </div>
    </>;
}

type ContentsPageRowProps<T extends UniqueContent<number>> = {
    adId: number;
    content: T;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function TargetAgeRow(props: ContentsPageRowProps<AgeRangeModal>) {
    return <tr>
        <td>{props.content.id}</td>
        <td>{props.content.name}</td>
        <td>{props.content.fromInclusive}</td>
        <td>{props.content.toExclusive}</td>
        <td><ContentsPageRowDeleteColumn rowProps={props} deleteAction={AdTargetAgeRangeIO.delete} /></td>
    </tr>;
}

function TargetGenderRow(props: ContentsPageRowProps<AdTargetGenderModal>) {
    return <tr>
        <td>{UserGender.toString(props.content.gender)}</td>
        <td><ContentsPageRowDeleteColumn rowProps={props} deleteAction={AdTargetGenderIO.delete} /></td>
    </tr>;
}

function TargetRegionRow(props: ContentsPageRowProps<RegionModal>) {
    return <tr>
        <td>{props.content.id}</td>
        <td>{props.content.name}</td>
        <td><ContentsPageRowDeleteColumn rowProps={props} deleteAction={AdTargetRegionIO.delete} /></td>
    </tr>;
}

type ContentsPageRowDeleteAction = (adId: number, contentId: number, onReady: OnBaseResponse, onError: OnErrorResponse) => void;

type ContentsPageDeleteColumnProps<T extends UniqueContent<number>> = {
    rowProps: ContentsPageRowProps<T>;
    deleteAction: ContentsPageRowDeleteAction;
};

function ContentsPageRowDeleteColumn<T extends UniqueContent<number>>(props: ContentsPageDeleteColumnProps<T>,) {
    return <>
        <a className="modal-trigger clickable" onClick={() => onPageRowDeleteClick(props.rowProps, props.deleteAction)}>
            <i className="material-icons black-text">delete</i>
        </a>
    </>;
}

function onPageRowDeleteClick<T extends UniqueContent<number>>(
    props: ContentsPageRowProps<T>,
    action: ContentsPageRowDeleteAction
) {
    action(
        props.adId,
        props.content.id,
        onPageRowDeleted,
        props.setErrorMessage
    );
}

function onPageRowDeleted() {
    document.location.reload();
}