import {SERVER_URL} from "../util/Environments";
import {
    UserBlockCauseModal,
    UserBlockModal,
    UserGender,
    UserModal, UserNotificationType, UserSessionModal,
    UserSignInModal, UserType,
    UserWithdrawCauseModal
} from "../modal/Users";
import {OnArrayResponse, OnBaseResponse, OnErrorResponse, OnObjectResponse} from "../util/Reponses";
import {Requests} from "../util/Requests";
import {detect} from "detect-browser";
import {AxiosRequestConfig} from "axios";
import {Optional} from "../util/Types";
import {map} from "../util/Iterators";

const browser = detect()!;

export class UserIO {
    static get(
        id: bigint,
        onUser: OnObjectResponse<UserModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'UserIO.get',
            `${SERVER_URL}/user/${id}`,
            o => new UserModal(o),
            onUser,
            onError
        );
    }

    static search(
        sessionId: bigint,
        query: string,
        page: number,
        onUsersReady: OnArrayResponse<UserModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserIO.search',
            `${SERVER_URL}/user/search`,
            o => new UserModal(o),
            onUsersReady,
            onError,
            { params: { query, page } }
        );
    }

    static listAscending(
        sessionId: bigint,
        page: number,
        onUsersReady: OnArrayResponse<UserModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            headers: { "x-vowing-session-id": sessionId.toString() },
            params: { page }
        };
        Requests.getArray(
            'UserIO.listAscending',
            `${SERVER_URL}/user/list/ascending`,
            o => new UserModal(o),
            onUsersReady,
            onError,
            config
        );
    }

    static listDormantDescending(
        lastId: bigint,
        onReady: OnArrayResponse<UserModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId }
        };
        Requests.getArray(
            'UserIO.listDormantDescending',
            `${SERVER_URL}/user/dormant/list`,
            o => new UserModal(o),
            onReady,
            onError,
            config
        );
    }

    static listWithdrawnDescending(
        lastId: bigint,
        onReady: OnArrayResponse<UserModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId }
        };
        Requests.getArray(
            'UserIO.listWithdrawnDescending',
            `${SERVER_URL}/user/withdrawn/list`,
            o => new UserModal(o),
            onReady,
            onError,
            config
        );
    }

    static update(
        sessionId: bigint,
        userId: bigint,
        email: string,
        phone: string,
        nickname: string,
        accountName: string,
        birth: string,
        gender: UserGender,
        type: UserType,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const config = {
            headers: { "x-vowing-session-id": sessionId.toString() },
        };
        Requests.patchObject(
            'UserIO.update',
            `${SERVER_URL}/user/${userId}`,
            o => o,
            onReady,
            onError,
            {email, phone, nickname, accountName, birth, gender, type},
            config
        );
    }

    static delete(
        sessionId: bigint,
        userId: bigint,
        withdrawCauseId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const config = {
            headers: { "x-vowing-session-id": sessionId.toString() },
            params: { withdrawCauseId }
        };
        Requests.delete(
            'UserIO.delete',
            `${SERVER_URL}/user/${userId}`,
            o => o,
            onReady,
            onError,
            config
        );
    }
}

export class UserBlockIO {
    static post(
        userId: bigint,
        causeId: number,
        blockedAt: Date,
        blockedUntil: Date | null,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const config = {
        };
        Requests.postObject(
            'UserBlockIO.post',
            `${SERVER_URL}/user/${userId}/block`,
            o => o,
            onReady,
            onError,
            {causeId, blockedAt, blockedUntil},
            config
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<UserBlockModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'UserBlockIO.get',
            `${SERVER_URL}/user/block/${id}`,
            o => new UserBlockModal(o),
            onReady,
            onError
        );
    }

    static listDescending(
        lastId: number,
        onReady: OnArrayResponse<UserBlockModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { lastId }
        };
        Requests.getArray(
            'UserBlockIO.listDescending',
            `${SERVER_URL}/user/block/list`,
            o => new UserBlockModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserId(
        userId: bigint,
        onReady: OnArrayResponse<UserBlockModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserBlockIO.listByUserId',
            `${SERVER_URL}/user/${userId}/block/list`,
            o => new UserBlockModal(o),
            onReady,
            onError
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastId: number,
        onReady: OnArrayResponse<UserBlockModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserBlockIO.listByUserIdAscending',
            `${SERVER_URL}/user/${userId}/block/list`,
            o => new UserBlockModal(o),
            onReady,
            onError,
            { params: { lastId, ascending: "" } }
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastId: number,
        onReady: OnArrayResponse<UserBlockModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserBlockIO.listByUserIdDescending',
            `${SERVER_URL}/user/${userId}/block/list`,
            o => new UserBlockModal(o),
            onReady,
            onError,
            { params: { lastId, descending: "" } }
        );
    }

    static update(
        sessionId: bigint,
        userId: bigint,
        blockId: number,
        causeId: number,
        blockedUntil: Date | null,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const config = {
            headers: { "x-vowing-session-id": sessionId.toString() },
        };
        Requests.patchObject(
            'UserBlockIO.update',
            `${SERVER_URL}/user/${userId}/block/${blockId}`,
            o => o,
            onReady,
            onError,
            {causeId, blockedUntil},
            config
        );
    }

    static delete(
        userId: bigint,
        blockId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const config = {
        };
        Requests.delete(
            'UserBlockIO.delete',
            `${SERVER_URL}/user/${userId}/block/${blockId}`,
            o => o,
            onReady,
            onError,
            config
        );
    }
}

export class UserBlockCauseIO {
    static post(
        description: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.post(
            'UserBlockCauseIO.post',
            `${SERVER_URL}/misc/user/block/cause`,
            o => o,
            onReady,
            onError,
            { params: { description } }
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<UserBlockCauseModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'UserBlockCauseIO.get',
            `${SERVER_URL}/misc/user/block/cause/${id}`,
            o => new UserBlockCauseModal(o),
            onReady,
            onError
        );
    }

    static listAll(
        onReady:OnArrayResponse<UserBlockCauseModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserBlockCauseIO.listAll',
            `${SERVER_URL}/misc/user/block/cause/list`,
            o => new UserBlockCauseModal(o),
            onReady,
            onError
        );
    }

    static update(
        id: number,
        description: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patch(
            'UserBlockCauseIO.update',
            `${SERVER_URL}/misc/user/block/cause/${id}`,
            o => o,
            onReady,
            onError,
            { params: { description } }
        );
    }

    static delete(
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'UserBlockCauseIO.delete',
            `${SERVER_URL}/misc/user/block/cause/${id}`,
            o => o,
            onReady,
            onError,
        )
    }
}

export class UserNotification {
    static postByUserId(
        userId: bigint,
        title: string,
        body: string,
        type: UserNotificationType,
        adId: Optional<number>,
        productId: Optional<number>,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'UserNotification.postByUserId',
            `${SERVER_URL}/user/${userId}/notification`,
            o => o,
            onReady,
            onError,
            { title, body, type, adId, productId }
        );
    }

    static postByUserIds(
        userIds: Set<bigint>,
        title: string,
        body: string,
        type: UserNotificationType,
        adId: Optional<number>,
        productId: Optional<number>,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: new URLSearchParams(map(userIds, userId => ["userIds", userId.toString()]))
        };

        Requests.postObject(
            'UserNotification.postByUserIds',
            `${SERVER_URL}/user/notification`,
            o => o,
            onReady,
            onError,
            { title, body, type, adId, productId },
            config
        );
    }
}

export class UserSessionIO {
    static create(
        userId: bigint,
        password: string,
        nextPassword: string,
        nextPasswordSalt: string,
        onSessionIdReady: OnObjectResponse<bigint>,
        onError: OnErrorResponse
    ) {
        Requests.postObject<bigint, { id: bigint }>(
            'UserSessionIO.create',
            `${SERVER_URL}/user/${userId}/session`,
            o => o.id,
            onSessionIdReady,
            onError,
            {password, nextPassword, nextPasswordSalt}
        );
    }

    static get(
        sessionId: bigint,
        onUserSessionReady: OnObjectResponse<UserSessionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'UserSessionIO.get',
            `${SERVER_URL}/user/session/${sessionId}`,
            o => new UserSessionModal(o),
            onUserSessionReady,
            onError
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<UserSessionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserSessionIO.listByUserIdAscending',
            `${SERVER_URL}/user/${userId}/session/list`,
            o => new UserSessionModal(o),
            onReady,
            onError,
            { params: { lastId, ascending: "" } }
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<UserSessionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserSessionIO.listByUserIdDescending',
            `${SERVER_URL}/user/${userId}/session/list`,
            o => new UserSessionModal(o),
            onReady,
            onError,
            { params: { lastId, descending: "" } }
        );
    }

    static refresh(
        onReady: OnObjectResponse<UserSessionModal>,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'UserSessionIO.refresh',
            `${SERVER_URL}/user/session`,
            o => new UserSessionModal(o),
            onReady,
            onError
        );
    }
}

export class UserSignInIO {
    static getSalt(
        email: string,
        onSalt: OnObjectResponse<string>,
        onError: OnErrorResponse
    ) {
        Requests.getObject<string, { salt: string }>(
            'UserSignInIO.getSalt',
            `${SERVER_URL}/user/salt`,
            o => o.salt,
            onSalt,
            onError,
            {params: {email}}
        );
    }

    static signIn(
        email: string,
        password: string,
        nextPassword: string,
        nextPasswordSalt: string,
        applicationVersionId: number,
        onUser: OnObjectResponse<UserModal>,
        onError: OnErrorResponse
    ) {
        const deviceName = `${browser.os} ${browser.name}`
        const platformVersion = browser.version;
        Requests.postObject(
            'UserSignInIO.signIn',
            `${SERVER_URL}/user/signIn`,
            o => new UserModal(o),
            onUser,
            onError,
            {email, password, nextPassword, nextPasswordSalt, messagingToken: null, deviceName, platformVersion, applicationVersionId}
        );
    }

    static getLastByUserId(
        userId: bigint,
        onReady: OnObjectResponse<UserSignInModal>,
        onError: OnErrorResponse
    ) {
        const config = {
        };
        Requests.getObject(
            'UserSignInIO.getLastByUserId',
            `${SERVER_URL}/user/${userId}/signIn/last`,
            o => new UserSignInModal(o),
            onReady,
            onError,
            config
        );
    }

    static countRecent(
        duration: number,
        onReady: OnObjectResponse<number>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { duration }
        };
        Requests.getObject<number, { count: number; }>(
            'UserSignInIO.countRecent',
            `${SERVER_URL}/user/signIn/count`,
            o => o.count,
            onReady,
            onError,
            config
        );
    }

    static listAll(
        lastId: bigint,
        onReady: OnArrayResponse<UserSignInModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { lastId }
        };
        Requests.getArray(
            'UserSignInIO.listAll',
            `${SERVER_URL}/user/signIn/list`,
            o => new UserSignInModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserId(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<UserSignInModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { lastId, descending: '' }
        };
        Requests.getArray(
            'UserSignInIO.listAll',
            `${SERVER_URL}/user/${userId}/signIn/list`,
            o => new UserSignInModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<UserSignInModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserSignInIO.listByUserIdAscending',
            `${SERVER_URL}/user/${userId}/signIn/list`,
            o => new UserSignInModal(o),
            onReady,
            onError,
            { params: { lastId, ascending: "" } }
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<UserSignInModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserSignInIO.listByUserIdDescending',
            `${SERVER_URL}/user/${userId}/signIn/list`,
            o => new UserSignInModal(o),
            onReady,
            onError,
            { params: { lastId, descending: "" } }
        );
    }
}

export class UserWithdrawCauseIO {
    static post(
        description: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.post(
            'UserWithdrawCauseIO.post',
            `${SERVER_URL}/misc/user/withdraw/cause`,
            o => o,
            onReady,
            onError,
            { params: { description } }
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<UserWithdrawCauseModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'UserWithdrawCauseIO.get',
            `${SERVER_URL}/misc/user/withdraw/cause/${id}`,
            o => new UserWithdrawCauseModal(o),
            onReady,
            onError
        );
    }

    static listAll(
        onReady: OnArrayResponse<UserWithdrawCauseModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserWithdrawCauseIO.listAll',
            `${SERVER_URL}/misc/user/withdraw/cause/list`,
            o => new UserWithdrawCauseModal(o),
            onReady,
            onError
        );
    }

    static update(
        id: number,
        description: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patch(
            'UserWithdrawCauseIO.update',
            `${SERVER_URL}/misc/user/withdraw/cause/${id}`,
            o => o,
            onReady,
            onError,
            { params: { description } }
        );
    }

    static delete(
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'UserWithdrawCauseIO.delete',
            `${SERVER_URL}/misc/user/withdraw/cause/${id}`,
            o => o,
            onReady,
            onError,
        );
    }
}