import {Route, Routes} from "react-router-dom";
import {AssertSignIn} from "../../../App";
import BlockRouter from "./block/BlockRouter";
import WithdrawRouter from "./withdraw/WithdrawRouter";

export default function UserRouter() {
    return <Routes>
        <Route path="/block/*" element={<AssertSignIn><BlockRouter /></AssertSignIn>} />
        <Route path="/withdraw/*" element={<AssertSignIn><WithdrawRouter /></AssertSignIn>} />
    </Routes>
}