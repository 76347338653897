import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {UserModal} from "../../../modal/Users";
import {ErrorMessage, Nav} from "../../Common";
import {UserIO} from "../../../io/Users";
import {getMaxLong} from "../../../util/Environments";

export default function List() {
    const [users, setUsers] = useState<Optional<UserModal[]>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
    });

    useEffect(() => {
        const lastId = users?.lastOrNull()?.id ?? getMaxLong();
        UserIO.listDormantDescending(lastId, setUsers, setErrorMessage);
    }, []);

    return <PageWrapper users={users} errorMessageState={[errorMessage, setErrorMessage]} />
}

type PageWrapperProps = {
    users: Optional<UserModal[]>,
    errorMessageState: StatePair<Optional<ErrorMessage>>
};

function PageWrapper(props: PageWrapperProps) {
    const rows = props?.users?.map(user => <Row key={user.id.toString()} user={user} />) ?? [];

    return <>
        <Nav
            title={"휴면"}
            titleIcon={"chevron_left"}
            titleHref={"/user/list"}
            errorMessagePair={props.errorMessageState} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>이메일</th>
                <th>전화번호</th>
                <th>휴면 시각</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>
    </>;
}

type RowProps = {
    user: UserModal;
};

function Row(props: RowProps) {
    const userId = props.user.id.toString()
    return <tr>
        <td><a href={`/user/${userId}`}>{userId}</a></td>
        <td>{props.user.email}</td>
        <td>{props.user.phone}</td>
        <td>{props.user.dormantAt!.toRowFormat(true, true, true, false)}</td>
        <td>
            <a href={`/user/${props.user.id}`} style={{ cursor: "pointer" }}>
                <i className="material-icons black-text">open_in_new</i>
            </a>
        </td>
    </tr>;
}