import React from "react";
import {IntrinsicProperties} from "../../util/Properties";

export type IconProps = IntrinsicProperties.i & {
    iconName: string
}

export function Icon(props: IconProps) {
    const { iconName, ...iconProps } = props
    const className = (iconProps.className ?? '') + ' material-icons'
    return <>
        <i
            {...iconProps}
            className={className}>
            {props.iconName}
        </i>
    </>
}