export class StringBuilder {
    static joinToString<T extends { toString(): string }>(
        iterable: Iterable<T>,
        separator: string = ", ",
        prefix: string = "",
        postfix: string = "",
        limit: number = -1,
        truncated: string = "...",
        transform: (element: T) => string = (element: T) => { return element.toString(); }
    ) {
        const builder = new StringBuilder(prefix);
        let count = 0;
        for (const element of iterable){
            if (++count > 1) {
                builder.appendString(separator);
            }
            if (limit < 0 || count <= limit) {
                builder.appendString(transform(element));
            } else {
                break;
            }
        }
        if (limit >= 0 && count > limit) {
            builder.appendString(truncated);
        }
        builder.appendString(postfix);

        return builder.toString();
    }

    private readonly buffer: number[];

    constructor(s?: string) {
        if (s !== undefined) {
            this.buffer = StringBuilder.toCodePoints(s);
        } else {
            this.buffer = []
        }
    }

    private static toCodePoints(s: string): number[] {
        const buffer: number[] = [];
        const length = s.length;
        for (let i = 0; i < length; i++) {
            const codePoint = s.codePointAt(i);
            if (!codePoint) {
                throw new Error(`Unknown code point at index ${i}: s=${s}`);
            }

            buffer[i] = codePoint;
        }

        return buffer;
    }

    private static onEachCodePoint(s: string, action: (codePoint: number) => void) {
        const length = s.length;
        for (let i = 0; i < length; i++) {
            const codePoint = s.codePointAt(i);
            if (!codePoint) {
                throw new Error(`Unknown code point at index ${i}: s=${s}`);
            }

            action(codePoint);
        }
    }

    appendString(s: string): StringBuilder {
        StringBuilder.onEachCodePoint(s, codePoint => this.buffer.push(codePoint));
        return this;
    }

    appendNumber(x: number, radix?: number): StringBuilder {
        return this.appendString(x.toString(radix));
    }

    toString(): string {
        return String.fromCodePoint(...this.buffer);
    }
}