import {Logger} from "../util/Environments";

export class GifticonModal {
    static descriptionImageKey(contentOrId: GifticonModal | number): string {
        const id = (contentOrId instanceof GifticonModal) ? contentOrId.id : contentOrId;
        return `gifticon/${id}/description.png`;
    }

    static barcodeImageKey(contentOrId: GifticonModal | number, ordinal: number): string {
        const id = (contentOrId instanceof GifticonModal) ? contentOrId.id : contentOrId;
        Logger.log('barcodeImageKey', contentOrId, id, ordinal);
        return `gifticon/${id}/${ordinal}.png`;
    }

    id!: number;
    brandId!: number;
    title!: string;
    statusFlags!: number;
    point!: number;
    amount!: number;
    duration!: number;
    createdAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class GifticonBrandModal {
    id!: number;
    name!: string;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class GifticonDescriptionModal {
    id!: number;
    gifticonId!: number;
    description!: string;
    sortOrder!: number;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class GifticonPurchaseModal {
    id!: bigint;
    gifticonId!: number;
    userId!: bigint;
    ordinal!: number;
    statusFlags!: number;
    purchasedAt!: Date;
    expiredAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}