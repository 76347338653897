import {useParams} from "react-router-dom";
import {Optional, useWrapper} from "../../../../util/Types";
import {ErrorMessage, Nav, NavBarItemAction} from "../../../Common";
import {useEffect} from "react";
import {HomeBestModal} from "../../../../modal/Miscs";
import {useEffectOptional} from "../../../../util/Hooks";
import {MiscHomeBestIO} from "../../../../io/Miscs";
import {CardHorizontal} from "../../../common/CardHorizontal";
import {MaterialModal} from "../../../Materials";

export function Detail() {
    const { id } = useParams<{ id?: string }>()
    const content = useWrapper<Optional<HomeBestModal>>(null)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)

    useEffect(() => M.AutoInit())
    useEffectOptional(id => MiscHomeBestIO.getById(Number(id), content.set, errorMessage.set), id)
    useEffect(() => {
        if (errorMessage.value) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage.value]);

    const onDeleteClick = () => MiscHomeBestIO.delete(Number(id), onDeleted, errorMessage.set)

    const onDeleted = () => {
        alert('삭제되었습니다.')
        document.location = '/misc/home/best/list'
        return
    }

    const barItems: [string, NavBarItemAction][] = [
        ['수정', () => document.location = `/misc/home/best/${id}/edit`],
        ['삭제', onDeleteClick]
    ]

    return <>
        <Nav
            title={'홈 베스트'}
            titleIcon={'chevron_left'}
            titleHref={'/misc/home/best/list'}
            barItems={barItems} />
        {content.value && <>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={'ID'} value={content.value.id.toString()} />
                    <CardHorizontal title={'광고 ID'} value={content.value.adId?.toString()} href={`/ad/${content.value.adId}`} />
                    <CardHorizontal title={'암기플러스 ID'} value={content.value.m10nId?.toString()} href={`/ad/${content.value.m10nId}`} />
                    <CardHorizontal title={'공지사항 ID'} value={content.value.noticeId?.toString()} href={`/ad/${content.value.noticeId}`} />
                    <CardHorizontal title={'표시 순서'} value={content.value.sortOrder.toString()} />
                    <CardHorizontal title={'생성 시각'} value={content.value.createdAt.toRowFormat(true, true)} />
                </div>
            </div>
        </>}
    </>
}