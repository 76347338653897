import {OnArrayResponse, OnBaseResponse, OnErrorResponse, OnObjectResponse} from "../util/Reponses";
import {Requests} from "../util/Requests";
import {SERVER_URL} from "../util/Environments";
import {GifticonBrandModal, GifticonDescriptionModal, GifticonModal, GifticonPurchaseModal} from "../modal/Gifticons";

export class GifticonIO {
    static post(
        brandId: number,
        title: string,
        point: number,
        amount: number,
        duration: number,
        onReady: OnObjectResponse<GifticonModal>,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'GifticonIO.post',
            `${SERVER_URL}/gifticon`,
            o => new GifticonModal(o),
            onReady,
            onError,
            { brandId, title, point, amount, duration }
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<GifticonModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'GifticonIO.get',
            `${SERVER_URL}/gifticon/${id}`,
            o => new GifticonModal(o),
            onReady,
            onError
        );
    }

    static listDescending(
        lastId: number,
        onReady: OnArrayResponse<GifticonModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'GifticonIO.listDescending',
            `${SERVER_URL}/gifticon/list`,
            o => new GifticonModal(o),
            onReady,
            onError,
            { params: { lastId, descending: "" } }
        );
    }

    static update(
        id: number,
        title: string,
        point: number,
        amount: number,
        duration: number,
        onReady: OnObjectResponse<GifticonModal>,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'GifticonIO.update',
            `${SERVER_URL}/gifticon/${id}`,
            o => new GifticonModal(o),
            onReady,
            onError,
            { title, point, amount, duration }
        );
    }
}

export class GifticonBrandIO {
    static post(
        name: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'GifticonBrandIO.post',
            `${SERVER_URL}/gifticon/brand`,
            o => o,
            onReady,
            onError,
            { name }
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<GifticonBrandModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'GifticonBrandIO.get',
            `${SERVER_URL}/gifticon/brand/${id}`,
            o => new GifticonBrandModal(o),
            onReady,
            onError
        );
    }

    static listDescending(
        lastId: number,
        onReady: OnArrayResponse<GifticonBrandModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'GifticonBrandIO.listDescending',
            `${SERVER_URL}/gifticon/brand/list`,
            o => new GifticonBrandModal(o),
            onReady,
            onError,
            { params: { lastId, descending: "" } }
        )
    }

    static update(
        id: number,
        name: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'GifticonBrandIO.update',
            `${SERVER_URL}/gifticon/brand/${id}`,
            o => o,
            onReady,
            onError,
            { name }
        );
    }
}

export class GifticonDescriptionIO {
    static post(
        gifticonId: number,
        description: string,
        sortOrder: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'GifticonDescriptionIO.post',
            `${SERVER_URL}/gifticon/${gifticonId}/description`,
            o => o,
            onReady,
            onError,
            { description, sortOrder }
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<GifticonDescriptionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'GifticonDescriptionIO.get',
            `${SERVER_URL}/gifticon/description/${id}`,
            o => new GifticonDescriptionModal(o),
            onReady,
            onError
        );
    }

    static listByGifticonId(
        gifticonId: number,
        onReady: OnArrayResponse<GifticonDescriptionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'GifticonDescriptionIO.listByGifticonId',
            `${SERVER_URL}/gifticon/${gifticonId}/description/list`,
            o => new GifticonDescriptionModal(o),
            onReady,
            onError
        );
    }

    static update(
        id: number,
        description: string,
        sortOrder: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'GifticonDescriptionIO.update',
            `${SERVER_URL}/gifticon/description/${id}`,
            o => o,
            onReady,
            onError,
            { description, sortOrder }
        );
    }

    static delete(
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'GifticonDescriptionIO.delete',
            `${SERVER_URL}/gifticon/description/${id}`,
            o => o,
            onReady,
            onError,
        );
    }
}

export class GifticonPurchaseIO {
    static post(
        gifticonId: number,
        userId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'GifticonPurchaseIO.post',
            `${SERVER_URL}/gifticon/${gifticonId}/purchase`,
            o => o,
            onReady,
            onError,
            { userId }
        );
    }

    static listDescending(
        lastId: bigint,
        onReady: OnArrayResponse<GifticonPurchaseModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'GifticonPurchaseIO.post',
            `${SERVER_URL}/gifticon/purchase/list`,
            o => new GifticonPurchaseModal(o),
            onReady,
            onError,
            { params: { lastId: lastId.toString(), descending: "" } }
        );
    }
}