import {Route, Routes} from "react-router-dom";
import Edit from "./Edit";
import List from "./List";
import {AssertSignIn} from "../../../../../../App";

export default function CauseRouter() {
    return <Routes>
        <Route path="/list" element={<AssertSignIn><List /></AssertSignIn>} />
        <Route path="/post" element={<AssertSignIn><Edit /></AssertSignIn>} />
        <Route path="/:pointWithdrawalRejectCauseId/edit" element={<AssertSignIn><Edit /></AssertSignIn>} />
    </Routes>;
}