import {useParams} from "react-router-dom";
import {Optional, StateWrapper, useBoolean, useWrapper} from "../../util/Types";
import {M10nModal} from "../../modal/M10ns";
import {M10nIO} from "../../io/M10n";
import {ErrorMessage, Nav, NavBarItemAction} from "../Common";
import {useEffectOptional} from "../../util/Hooks";
import {TextField} from "../common/TextField";
import {TextAreaField} from "../common/TextAreaField";
import {Select} from "../common/Select";
import {useEffect} from "react";
import {Checkbox} from "../common/Checkbox";
import {FileField} from "../common/FileField";
import {StorageIO} from "../../io/Services";
import {ManagedUpload} from "aws-sdk/clients/s3";

type UploadResponse = { type: (0 | 1 | 2 | 3), key: string, index: number }
type UploadSuccess = UploadResponse & { value: ManagedUpload.SendData }
type UploadFailure = UploadResponse & { reason: any }

export function Edit() {
    const { id } = useParams<{ id?: string }>()
    const m10n = useWrapper<Optional<M10nModal>>(null)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)

    useEffect(() => M.AutoInit())

    useEffectOptional(id => M10nIO.getById(
        Number(id),
        response => m10n.set(response),
        error => errorMessage.set(error)
    ), id)

    return <>
        {m10n.value && <>
            <Editor
                m10n={m10n.value}
                errorMessage={errorMessage} />
        </>}
    </>
}

type EditorProps = {
    m10n: M10nModal
    errorMessage: StateWrapper<Optional<ErrorMessage>>
}

function Editor(props: EditorProps) {
    const id = useWrapper(props.m10n.id)
    const title = useWrapper(props.m10n.title)
    const description = useWrapper(props.m10n.description)
    const script = useWrapper(props.m10n.script)
    const detailUrl = useWrapper(props.m10n.detailUrl)
    const detailClipboard = useWrapper(props.m10n.detailClipboard)
    const externalUrl = useWrapper(props.m10n.externalUrl)
    const gradingMessage = useWrapper(props.m10n.gradingMessage)
    const amount = useWrapper(props.m10n.amount)
    const point = useWrapper(props.m10n.point)
    const minimumAccuracy = useWrapper(props.m10n.minimumAccuracy)
    const pointAdditionalHighAccuracy = useWrapper(props.m10n.pointAdditionalHighAccuracy)
    const difficulty = useWrapper(props.m10n.difficulty)
    const externalUrlWing = useWrapper(M10nModal.Status.EXTERNAL_URL_WING.isEnabled(props.m10n.statusFlags))
    const externalUrlNaver = useWrapper(M10nModal.Status.EXTERNAL_URL_NAVER.isEnabled(props.m10n.statusFlags))
    const detailUrlNaver = useWrapper(M10nModal.Status.EXTERNAL_URL_NAVER.isEnabled(props.m10n.statusFlags))
    const startAt = useWrapper(props.m10n.startAt)
    const endAt = useWrapper(props.m10n.endAt)
    const exposedAt = useWrapper(props.m10n.exposedAt)
    const thumbnailImage = useWrapper<Optional<File>>(null)
    const headerImages = useWrapper<Optional<FileList>>(null)
    const descriptionImages = useWrapper<Optional<FileList>>(null)
    const contentVideo = useWrapper<Optional<File>>(null)
    const disabled = useBoolean(false)

    const onSaveClick = () => {
        if (title.value.isEmpty()) {
            props.errorMessage.set('제목을 입력해주세요.')
            return
        }
        if (description.value.isEmpty()) {
            props.errorMessage.set('설명을 입력해주세요.')
            return
        }
        if (script.value.isEmpty()) {
            props.errorMessage.set('본문을 입력해주세요.')
            return
        }
        if (detailUrl.value.isEmpty()) {
            props.errorMessage.set('더 알아보기 URL을 입력해주세요.')
            return
        }
        if (externalUrl.value.isEmpty()) {
            props.errorMessage.set('외부 연결 URL을 입력해주세요.')
            return
        }

        disabled.setTrue()
        put()
    }

    const onCancelClick = () => document.location = `/m10n/${props.m10n.id}`

    const put = () => M10nIO.patch(
        props.m10n.id,
        {
            title: title.value,
            description: description.value,
            script: script.value,
            detailUrl: detailUrl.value,
            detailClipboard: detailClipboard.value,
            externalUrl: externalUrl.value,
            gradingMessage: gradingMessage.value,
            amount: amount.value,
            point: point.value,
            pointAdditionalHighAccuracy: pointAdditionalHighAccuracy.value,
            headerImageCount: headerImages.value?.length ?? props.m10n.headerImageCount,
            descriptionImageCount: descriptionImages.value?.length ?? props.m10n.descriptionImageCount,
            difficulty: difficulty.value,
            minimumAccuracy: minimumAccuracy.value,
            externalUrlWing: externalUrlWing.value,
            externalUrlNaver: externalUrlNaver.value,
            detailUrlNaver: detailUrlNaver.value,
            startAt: startAt.value,
            endAt: endAt.value,
            exposedAt: exposedAt.value
        },
        onPut,
        onPutError
    )

    const onPut = () => {
        const uploadSize = ((thumbnailImage.value !== null) ? 1 : 0) +
            ((headerImages.value !== null) ? headerImages.value.length : 0) +
            ((descriptionImages.value !== null) ? descriptionImages.value.length : 0) +
            ((contentVideo.value !== null) ? 1 : 0)
        const success: UploadSuccess[] = []
        const failure: UploadFailure[] = []
        const onProgress = () => {
            if (success.length + failure.length === uploadSize) {
                onUploaded(success, failure)
            }
        }

        // 입력이 없을 때 진행
        onProgress()

        if (thumbnailImage.value !== null) {
            const key = props.m10n.thumbnailImageKey()
            StorageIO.uploadFileSync(key, thumbnailImage.value)
                .then(value => {
                    success.push({ type: 0, key, index: 0, value })
                    onProgress()
                })
                .catch(reason => {
                    failure.push({ type: 0, key, index: 0, reason })
                    onProgress()
                })
        }

        if (headerImages.value !== null) {
            for (let index = 0; index < headerImages.value.length; index++) {
                const key = props.m10n.headerImageKey(index)
                StorageIO.uploadFileSync(key, headerImages.value[index])
                    .then(value => {
                        success.push({ type: 1, key, index, value })
                        onProgress()
                    })
                    .catch(reason => {
                        failure.push({ type: 1, key, index, reason })
                        onProgress()
                    })
            }
        }

        if (descriptionImages.value !== null) {
            for (let index = 0; index < descriptionImages.value.length; index++) {
                const key = props.m10n.descriptionImageKey(index)
                StorageIO.uploadFileSync(key, descriptionImages.value[index])
                    .then(value => {
                        success.push({ type: 2, key, index, value })
                        onProgress()
                    })
                    .catch(reason => {
                        failure.push({ type: 2, key, index, reason })
                        onProgress()
                    })
            }
        }

        if (contentVideo.value !== null) {
            const key = props.m10n.contentVideoKey()
            StorageIO.uploadFileSync(key, contentVideo.value)
                .then(value => {
                    success.push({ type: 3, key, index: 0, value })
                    onProgress()
                })
                .catch(reason => {
                    failure.push({ type: 3, key, index: 0, reason })
                    onProgress()
                })
        }
    }

    const onPutError = (error: ErrorMessage) => {
        disabled.setFalse()
        props.errorMessage.set(error)
    }
    const onUploaded = (
        successes: UploadSuccess[],
        failures: UploadFailure[]
    ) => {
        if (failures.isEmpty()) {
            alert('수정되었습니다.')
            document.location = '/m10n/list'
            return
        }

        failures.sort((a, b) => {
            const c1 = a.type.compareTo(b.type)
            if (c1 !== 0) {
                return c1
            } else {
                return a.index.compareTo(b.index)
            }
        })

        disabled.setTrue()
        props.errorMessage.set(createErrorMessages(successes, failures))
    }

    const createErrorMessages = (
        successes: UploadSuccess[],
        failures: UploadFailure[]
    ): string => {
        const errorMessages: string[] = ['일부 이미지 또는 비디오를 업로드하지 못 했습니다.']
        if (successes.isNotEmpty()) {
            let errorMessage = '다음 데이터가 업로드되었습니다: '
            errorMessage += successes.map(toString).join(', ')
            errorMessages.push(errorMessage)
        }
        let errorMessage = '다음 데이터가 업로드되지 않았습니다: '
        errorMessage += failures.map(toString).join(', ')

        errorMessages.push(errorMessage)

        return errorMessage
    }

    const toString = (response: UploadResponse): string => {
        switch (response.type) {
            case 0: return '썸네일 이미지'
            case 1: return `상단 이미지의 ${response.index}번째`
            case 2: return `설명 이미지의 ${response.index}번째`
            case 3: return '내용 비디오'
        }
    };

    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClick],
        ["취소", onCancelClick]
    ];

    return <>
        <Nav
            title={'암기플러스 수정'}
            titleIcon={'chevron_left'}
            titleHref={`/m10n/${props.m10n.id}`}
            barItems={barItems}
            errorMessageState={props.errorMessage} />
        <div className={'row cascade'} style={{ marginTop: "2rem" }}>
            <TextField
                label={'ID'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: id.value.toString(), disabled: true }} />
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'제목'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: title.value, disabled: disabled.value, maxLength: 100 }}
                onValueChange={(event, value) => title.set(value)}/>
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'설명'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: description.value, disabled: disabled.value, maxLength: 100 }}
                onValueChange={(event, value) => description.set(value)}/>
        </div>
        <div className={'row cascade'}>
            <TextAreaField
                label={'본문'}
                formProps={{ className: 'col s8 offset-s2' }}
                textAreaProps={{ value: script.value, disabled: disabled.value, maxLength: 1000 }}
                onValueChange={(event, value) => script.set(value)}/>
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'채점 중 표시할 텍스트'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: gradingMessage.value ?? '', disabled: disabled.value, maxLength: 400 }}
                onValueChange={(event, value) => gradingMessage.set(value)}/>
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'더 알아보기 URL'}
                formProps={{ className: 'col s4 offset-s2' }}
                inputProps={{ value: detailUrl.value, disabled: disabled.value, maxLength: 1000 }}
                onValueChange={(event, value) => detailUrl.set(value)}/>
            <TextField
                label={'더 알아보기 클립보드'}
                formProps={{ className: 'col s4' }}
                inputProps={{
                    value: detailClipboard.value ?? '',
                    disabled: disabled.value,
                    maxLength: 100
                }}
                onValueChange={(event, value) => {
                    if (value.isEmpty()) {
                        detailClipboard.set(null)
                    } else {
                        detailClipboard.set(value)
                    }
                }}
            />
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'외부 연결 URL'}
                formProps={{ className: 'col s4 offset-s2' }}
                inputProps={{ value: externalUrl.value, disabled: disabled.value, maxLength: 1000 }}
                onValueChange={(event, value) => externalUrl.set(value)}/>
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'수량'}
                formProps={{ className: 'col s4 offset-s2' }}
                inputProps={{ value: amount.value.toString(), disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => amount.set(Number(value))}/>
            <TextField
                label={'합격 최소 일치율(%)'}
                formProps={{ className: 'col s4' }}
                inputProps={{ value: minimumAccuracy.value.toString(), disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => minimumAccuracy.set(Number(value))}/>
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'포인트'}
                formProps={{ className: 'col s4 offset-s2' }}
                inputProps={{ value: point.value.toString(), disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => point.set(Number(value))}/>
            <TextField
                label={'높은 일치율 추가 포인트'}
                formProps={{ className: 'col s4' }}
                inputProps={{ value: pointAdditionalHighAccuracy.value.toString(), disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => pointAdditionalHighAccuracy.set(Number(value))}/>
        </div>
        <div className={'row cascade'} style={{ display: 'flex', alignItems: 'center' }}>
            <Select
                wrapperProps={{ className: 'col s4 offset-s2' }}
                options={M10nModal.Difficulty.values().map(value => ({ key: value, data: value }))}
                optionIndex={difficulty.value}
                onOptionSelected={index => difficulty.set(index)}
                placeholder={'선택'}
                label={'난이도'} />
            <Checkbox
                title={'외부 연결 URL 클릭 시 날개 지급'}
                wrapperProps={{ className: 'col s4', style: { marginLeft: '0px' }}}
                checked={externalUrlWing.value}
                onChange={externalUrlWing.set} />
        </div>
        <div className={'row cascade'}>
            <Checkbox
                title={'외부 연결 URL 네이버에서 열기'}
                wrapperProps={{ className: 'col s4 offset-s2' }}
                checked={externalUrlNaver.value}
                onChange={externalUrlNaver.set} />
            <Checkbox
                title={'더 알아보기 URL 네이버에서 열기'}
                formProps={{ className: 'col s4' }}
                checked={detailUrlNaver.value}
                onChange={detailUrlNaver.set} />
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'시작일'}
                formProps={{ className: 'col s4 offset-s2' }}
                inputProps={{ value: startAt.value.toKSTString().substring(0, 16), disabled: disabled.value, type: 'datetime-local' }}
                onValueChange={(event, value) => startAt.set(new Date(value))}/>
            <TextField
                label={'종료일'}
                formProps={{ className: 'col s4' }}
                inputProps={{ value: endAt.value.toKSTString().substring(0, 16), disabled: disabled.value, type: 'datetime-local' }}
                onValueChange={(event, value) => endAt.set(new Date(value))}/>
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'노출 시작일'}
                formProps={{ className: 'col s4 offset-s2' }}
                inputProps={{ value: exposedAt.value.toKSTString().substring(0, 16), disabled: disabled.value, type: 'datetime-local' }}
                onValueChange={(event, value) => exposedAt.set(new Date(value))}/>
        </div>
        <div className={'row cascade'}>
            <FileField
                formProps={{ className: 'col s8 offset-s2' }}
                title={'썸네일 이미지'}
                accept={'image/png'}
                multiple={false} />
        </div>
        <div className={'row cascade'}>
            <StorageIO.StorageImage
                className={'col offset-s2'}
                style={{ maxWidth: '100%' }}
                objectKey={props.m10n.thumbnailImageKey()} />
        </div>
        <div className={'row cascade'}>
            <FileField
                formProps={{ className: 'col s8 offset-s2' }}
                title={'상단 이미지'}
                accept={'image/png'}
                multiple />
        </div>
        <div className={'row cascade'}>
            <StorageIO.StorageImages
                className={'col offset-s2'}
                style={{ maxWidth: '100%' }}
                objectKeys={props.m10n.headerImageKeys()} />
        </div>
        <div className={'row cascade'}>
            <FileField
                formProps={{ className: 'col s8 offset-s2' }}
                title={'설명 이미지'}
                accept={'image/png'}
                multiple />
        </div>
        <div className={'row cascade'}>
            <StorageIO.StorageImages
                className={'col offset-s2'}
                style={{ maxWidth: '100%' }}
                objectKeys={props.m10n.descriptionImageKeys()} />
        </div>
        <div className={'row cascade'}>
            <FileField
                formProps={{ className: 'col s8 offset-s2' }}
                title={'내용 비디오'}
                accept={'image/png'} />
        </div>
        <div className={'row cascade'}>
            <StorageIO.StorageVideo
                className={'col offset-s2'}
                style={{ maxWidth: '100%' }}
                objectKey={props.m10n.contentVideoKey()}
                controls />
        </div>
    </>
}




