import React, {useId} from "react";
import {appendClassName, IntrinsicProperties} from "../../util/Properties";

export type TextAreaFieldProps = {
    label: string
    onValueChange?(event: React.ChangeEvent<HTMLTextAreaElement>, value: string): void

    formProps?: IntrinsicProperties.form
    textAreaProps?: IntrinsicProperties.textarea
    labelProps?: IntrinsicProperties.label
}

export function TextAreaField(props: TextAreaFieldProps) {
    const id = useId()
    const { label, onValueChange, formProps, textAreaProps, labelProps } = props
    return <>
        <form {...formProps}>
            <textarea
                {...textAreaProps}
                id={id}
                onChange={event => onValueChange?.(event, event.target.value)}
                className={appendClassName(textAreaProps, 'input-field materialize-textarea')} />
            <label
                {...labelProps}
                htmlFor={id}>
                {label}
            </label>
        </form>
    </>
}