import {useParams} from "react-router-dom";
import {Optional, wrapState} from "../../../util/Types";
import React, {useEffect, useState} from "react";
import {WingUsageModal, WingUsageType} from "../../../modal/Wings";
import {WingUsageIO} from "../../../io/Wings";
import {DetailCard, ErrorMessage, Nav} from "../../Common";

export default function Detail() {
    const {wingUsageId} = useParams() as { wingUsageId?: bigint };
    const {value: wingUsage, set: setWingUsage} = wrapState(useState<Optional<WingUsageModal>>(null));
    const {value: errorMessage, set: setErrorMessage} = wrapState(useState<Optional<ErrorMessage>>(null));

    useEffect(() => {
        M.AutoInit();
    });
    useEffect(() => {
        if (wingUsageId !== undefined && wingUsageId !== null) {
            WingUsageIO.get(wingUsageId, setWingUsage, setErrorMessage);
        }
    }, [wingUsageId]);

    if (wingUsage === null) {
        return <></>;
    }

    const adParticipationCard = (wingUsage.adParticipationId === null) ? <></> :
        <div className="row cascade">
            <DetailCard title="광고 참여 ID" value={wingUsage.adParticipationId.toString()} href={`/ad/participation/${wingUsage.adParticipationId}`} />
        </div>;
    const wingUsageCard = (wingUsage.pointUsageId === null) ? <></> :
        <div className="row cascade">
            <DetailCard title="포인트 사용 ID" value={wingUsage.pointUsageId.toString()} href={`/wing/usage/${wingUsage.pointUsageId}`} />
        </div>;

    return <>
        <Nav
            title="날개 사용"
            titleIcon="chevron_left"
            errorMessagePair={[errorMessage, setErrorMessage]} />
        <div className="row cascade first">
            <DetailCard title="ID" value={wingUsage.id.toString()} />
        </div>
        <div className="row cascade">
            <DetailCard title="사용자 ID" value={wingUsage.userId.toString()} href={`/user/${wingUsage.userId}`} />
        </div>
        <div className="row cascade">
            <DetailCard title="증감" value={wingUsage.amount.toString()} />
        </div>
        <div className="row cascade">
            <DetailCard title="마지막 보유량" value={wingUsage.lastAmount.toString()} />
        </div>
        <div className="row cascade">
            <DetailCard title="현재 보유량" value={(wingUsage.amount + wingUsage.lastAmount).toString()} />
        </div>
        <div className="row cascade">
            <DetailCard title="유형" value={WingUsageType.toString(wingUsage.type)} />
        </div>
        {adParticipationCard}
        {wingUsageCard}
        <div className="row cascade">
            <DetailCard title="시각" value={wingUsage.createdAt.toRowFormat(true, true, true, true)} />
        </div>
    </>;
}
