import {Route, Routes} from "react-router-dom";
import {AssertSignIn} from "../../../App";
import Edit from "./Edit";
import CategoryRouter from "./category/CategoryRouter";
import Detail from "./Detail";

export default function FaqRouter() {
    return <Routes>
        <Route path="/post" element={<AssertSignIn><Edit /></AssertSignIn>} />
        <Route path="/:faqId" element={<AssertSignIn><Detail /></AssertSignIn>} />
        <Route path="/:faqId/edit" element={<AssertSignIn><Edit /></AssertSignIn>} />

        <Route path="/category/*" element={<AssertSignIn><CategoryRouter /></AssertSignIn>} />
    </Routes>;
}