import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {ProductBrandModal, ProductModal} from "../../../modal/Products";
import {useParams} from "react-router-dom";
import {BottomProgress, ErrorMessage, Nav, NavBarItemAction, NavTabItemProps} from "../../Common";
import {ProductBrandIO, ProductIO} from "../../../io/Products";
import {getMaxInt, getMinInt} from "../../../util/Environments";
import {MaterialModal} from "../../Materials";

const pageId = 'product_brand_detail';
const defaultPageId = `${pageId}_default`;
const productsPageId = `${pageId}_products`;

export default function Detail() {
    const {productBrandId} = useParams() as { productBrandId?: number };
    const [content, setContent] = useState<Optional<ProductBrandModal>>(null);

    const [productCount, setProductCount] = useState<Optional<number>>(null);
    const [products, setProducts] = useState<Optional<ProductModal[]>>(null);
    const [productAscending, setProductAscending] = useState<boolean>(true);
    const [hasMoreProducts, setMoreProducts] = useState<boolean>(true);
    const [isProductsUpdating, setProductsUpdating] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
        M.Tabs.init(document.querySelectorAll('.tabs'));
    });

    useEffect(() => {
        if (productBrandId) {
            ProductBrandIO.get(productBrandId, setContent, setErrorMessage);
        }
    }, [productBrandId]);

    useEffect(() => {
        if (productBrandId) {
            PrepareProducts(productBrandId, [products, setProducts], productAscending, setMoreProducts, [isProductsUpdating, setProductsUpdating], setErrorMessage);
            ProductIO.countByProductBrandId(productBrandId, setProductCount, setErrorMessage);
        }
    }, [productBrandId, productAscending]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull(`#error_modal`)?.open();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            content={content}
            productCount={productCount}
            productsState={[products, setProducts]}
            productAscendingState={[productAscending, setProductAscending]}
            moreProductsState={[hasMoreProducts, setMoreProducts]}
            productsUpdatingState={[isProductsUpdating, setProductsUpdating]}
            setErrorMessage={setErrorMessage} />
    </>;
}

function PrepareProducts(
    productBrandId: number,
    contentsState: StatePair<Optional<ProductModal[]>>,
    contentAscending: boolean,
    setMoreContents: React.Dispatch<boolean>,
    updatingState: StatePair<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const [isUpdating, setUpdating] = updatingState;
    if (isUpdating) {
        return;
    }

    const [present, setContents] = contentsState;
    const onResponse = (contents: ProductModal[]) => {
        setContents((present ?? []).appended(contents));
        setMoreContents(contents.length === 20);
        setUpdating(false);
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setMoreContents(false);
        setUpdating(false);
    };

    setUpdating(true);
    const lastId = present?.lastOrNull()?.id;
    if (contentAscending) {
        ProductIO.listByProductBrandIdAscending(productBrandId, lastId ?? getMinInt(), onResponse, onError);
    } else {
        ProductIO.listByProductBrandIdDescending(productBrandId, lastId ?? getMaxInt(), onResponse, onError);
    }
}

type PageWrapperProps = {
    content: Optional<ProductBrandModal>;
    productCount: Optional<number>;

    productsState: StatePair<Optional<ProductModal[]>>;
    productAscendingState: StatePair<boolean>;
    moreProductsState: StatePair<boolean>;
    productsUpdatingState: StatePair<boolean>;

    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const barItems: [string, NavBarItemAction][] = [
        ["수정", () => { document.location = `/product/brand/${props.content?.id}/edit` }]
    ];
    const tabItems: ReadonlyArray<NavTabItemProps> = [
        { text: "자세히", href: `#${defaultPageId}` },
        { text: "상품", href: `#${productsPageId}` }
    ];

    return <>
        <Nav
            title="상품 브랜드"
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            tabItems={tabItems} />
        <DefaultPage content={props.content} productCount={props.productCount} />
        <ProductsPage
            content={props.content}
            contentsState={props.productsState}
            contentAscendingState={props.productAscendingState}
            moreContentsState={props.moreProductsState}
            updatingState={props.productsUpdatingState}
            setErrorMessage={props.setErrorMessage} />
    </>;
}

type DefaultPageProps = {
    content: Optional<ProductBrandModal>;
    productCount: Optional<number>;
};

function DefaultPage(props: DefaultPageProps) {
    if (!props.content || props.productCount === null) {
        return <></>;
    }

    return <div id={defaultPageId}>
        <div className="row cascade first">
            <Card title="ID" value={props.content.id.toString()} />
        </div>
        <div className="row cascade">
            <Card title="이름" value={props.content.name} />
        </div>
        <div className="row cascade">
            <Card title="하위 상품 수" value={props.productCount.toString()} />
        </div>
    </div>;
}

type CardProps = {
    title: string;
    value?: Optional<string>;
    href?: string;
    smallBody?: boolean;
    columnWidth?: number;
    columnOffset?: number;
};

function Card(props: CardProps) {
    if (!props.value) {
        return <></>;
    }

    let className = "col";
    if (props.columnWidth !== undefined) {
        className += ` s${props.columnWidth}`;
    } else {
        className += ' s8';
    }
    if (props.columnOffset !== undefined) {
        className += ` offset-s${props.columnOffset}`;
    } else {
        className += ' offset-s2';
    }

    let anchor: JSX.Element;
    if (props.href) {
        anchor = <a href={props.href}><i className="material-icons right grey-text">open_in_new</i></a>;
    } else {
        anchor = <></>;
    }

    return <div className={className}>
        <div className="card">
            <div className="card-content">
                <p>{props.title}{anchor}</p>
                <span className={(props.smallBody === true) ? "" : "card-title"}>{props.value}</span>
            </div>
        </div>
    </div>;
}

function OnWindowScroll(
    productBrandId: number,
    contentsState: StatePair<Optional<ProductModal[]>>,
    contentAscending: boolean,
    setMoreContents: React.Dispatch<boolean>,
    updatingState: StatePair<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const [isUpdating] = updatingState;
    if (!isUpdating && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // 업데이트중이지 않고 바닥까지 스크롤되었을 때
        PrepareProducts(productBrandId, contentsState, contentAscending, setMoreContents, updatingState, setErrorMessage);
    }
}

type ProductsPageProps = {
    content: Optional<ProductBrandModal>;
    contentsState: StatePair<Optional<ProductModal[]>>;
    contentAscendingState: StatePair<boolean>;
    moreContentsState: StatePair<boolean>;
    updatingState: StatePair<boolean>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function ProductsPage(props: ProductsPageProps) {
    const [contents] = props.contentsState;
    const [contentAscending, setContentAscending] = props.contentAscendingState;
    const [hasMoreContents, setMoreContents] = props.moreContentsState;

    useEffect(() => {
        const content = props.content;
        if (!content) {
            return () => {};
        }

        const clean = () => window.removeEventListener('scroll', onWindowScroll);
        const onWindowScroll = () => OnWindowScroll(
            content.id,
            props.contentsState,
            contentAscending,
            setMoreContents,
            props.updatingState,
            props.setErrorMessage
        );

        window.addEventListener('scroll', onWindowScroll);
        return clean;
    });

    const rows = (contents ?? []).map(content =>
        <Row
            key={content.id.toString()}
            content={content} />
    );

    return <div id={productsPageId}>
        <table className="centered highlight">
            <thead>
            <tr>
                <th className="clickable" onClick={() => setContentAscending(!contentAscending)}>ID</th>
                <th>이름</th>
                <th>가격</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents} />
        </div>
    </div>;
}

type RowProps = {
    content: ProductModal;
};

function Row(props: RowProps) {
    return <tr>
        <td>{props.content.id}</td>
        <td>{props.content.name}</td>
        <td>{props.content.price + "원"}</td>
        <td>
            <a href={`/product/${props.content.id}`} style={{ cursor: "pointer" }}>
                <i className="material-icons black-text">open_in_new</i>
            </a>
            <a href={`/product/${props.content.id}/edit`} style={{ cursor: "pointer" }}>
                <i className="material-icons black-text">edit</i>
            </a>
        </td>
    </tr>;
}