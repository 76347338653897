import {Optional, wrapState} from "../../../util/Types";
import React, {useEffect, useState} from "react";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {Component, MaterialComponent, MaterialInput, MaterialModal} from "../../Materials";
import {PointUsageIO} from "../../../io/Points";

const pageId = 'point_usage_post';
const userIdInputId = `${pageId}_user`;
const amountInputId = `${pageId}_amount`;

export default function Post() {
    const errorMessagePair = wrapState(useState<Optional<ErrorMessage>>(null));
    const rootEnabledPair = wrapState(useState<boolean>(true));

    useEffect(() => {
        MaterialModal.init();
        MaterialInput.init();
    });

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${userIdInputId}`),
            MaterialInput.getOrNull(`#${amountInputId}`)
        ];

        if (rootEnabledPair.value) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabledPair.value]);

    useEffect(() => {
        if (errorMessagePair.value) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessagePair.value]);

    return <PageWrapper setErrorMessage={errorMessagePair.set} setRootEnabled={rootEnabledPair.set} />;
}

type PageWrapperProps = {
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
};

function PageWrapper(props: PageWrapperProps) {
    const onSaveClicked = () => OnSaveClicked(
        props.setErrorMessage,
        props.setRootEnabled
    );
    const onCancelClicked = () => window.history.back();
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", onCancelClicked]
    ];

    return <>
        <Nav
            titleHref='/'
            titleIcon="chevron_left"
            title="포인트 지급 및 차감"
            barItems={barItems} />
        <Editor />
    </>;
}

function OnSaveClicked(
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const userId = MaterialInput.get(`#${userIdInputId}`).getValue().toBigIntOrNull();
    if (userId === null) {
        setErrorMessage("사용자 ID를 확인해 주세요.");
        return;
    }

    const amount = MaterialInput.get(`#${amountInputId}`).getValue().toIntOrNull();
    if (amount === null) {
        setErrorMessage("수량을 확인해 주세요.");
        return;
    }

    const onError = (error: ErrorMessage) => {
        setRootEnabled(true);
        setErrorMessage(error);
    };

    const onReady = () => {
        window.alert("저장되었습니다.");
        window.history.back();
    };

    setRootEnabled(false);
    PointUsageIO.post(
        userId,
        amount,
        onReady,
        onError
    );
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={userIdInputId}
                inputType="number"
                inputPlaceHolder="사용자 ID"
                label="사용자 ID" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={amountInputId}
                inputType="number"
                inputPlaceHolder="차감을 원하시면 음수를 입력하세요."
                label="수량" />
        </div>
    </>;
}
