import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../../util/Types";
import {ErrorMessage, Nav, NavBarItemAction, NavTabItemProps} from "../../../Common";
import {
    FaqCategoryModal,
    FaqModal
} from "../../../../modal/Miscs";
import {MiscFaqCategoryIO, MiscFaqIO} from "../../../../io/Miscs";
import {MaterialModal} from "../../../Materials";
import {BaseResponse} from "../../../../util/Reponses";

const pageId = 'faq_category_detail';
const defaultPageId = `${pageId}_default`;
const faqPageId = `${pageId}_faq`;
const faqDeleteModalId = `${pageId}_faq_delete`;

export default function Detail() {
    const {faqCategoryId} = useParams() as { faqCategoryId?: number };
    const [content, setContent] = useState<Optional<FaqCategoryModal>>(null);
    const [faqs, setFaqs] = useState<Optional<FaqModal[]>>(null);
    const [selectedFaq, selectFaq] = useState<Optional<FaqModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
        M.Tabs.init(document.querySelectorAll('.tabs'));
        MaterialModal.getOrNull(`#${faqDeleteModalId}`)?.setOnDismiss(() => selectFaq(null));
    });

    useEffect(() => {
        if (faqCategoryId) {
            MiscFaqCategoryIO.get(faqCategoryId, setContent, setErrorMessage);
            MiscFaqIO.listByCategoryId(faqCategoryId, setFaqs, setErrorMessage);
        }
    }, [faqCategoryId]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        }
    }, [errorMessage]);

    useEffect(() => {
        if (selectedFaq) {
            MaterialModal.getOrNull(`#${faqDeleteModalId}`)?.open();
        } else {
            MaterialModal.getOrNull(`#${faqDeleteModalId}`)?.close();
        }
    }, [selectedFaq]);

    return <>
        <PageWrapper
            content={content}
            faqs={faqs}
            selectedFaqState={[selectedFaq, selectFaq]}
            errorMessageState={[errorMessage, setErrorMessage]} />
    </>;
}

type PageWrapperProps = {
    content: Optional<FaqCategoryModal>;
    faqs: Optional<FaqModal[]>;
    selectedFaqState: StatePair<Optional<FaqModal>>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const [, setErrorMessage] = props.errorMessageState;

    const onEditClicked = () => {
        if (props.content) {
            document.location = `/misc/faq/category/${props.content.id}/edit`;
        }
    };

    const barItems: [string, NavBarItemAction][] = [
        ["수정", onEditClicked]
    ];

    const tabItems: ReadonlyArray<NavTabItemProps> = [
        { text: "자세히", href: `#${defaultPageId}` },
        { text: "하위 항목", href: `#${faqPageId}` },
    ];

    return <>
        <Nav
            title="자주 묻는 질문 카테고리"
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            tabItems={tabItems}
            errorMessagePair={props.errorMessageState} />
        <DefaultPage content={props.content} />
        <FaqPage
            content={props.content}
            faqs={props.faqs}
            selectedFaqState={props.selectedFaqState}
            setErrorMessage={setErrorMessage} />
    </>;
}

type DefaultPageProps = {
    content: Optional<FaqCategoryModal>;
};

function DefaultPage(props: DefaultPageProps) {
    const content = props.content;
    if (!content) {
        return <></>;
    }

    let parentIdContainer = <></>;
    if (content.parentId) {
        parentIdContainer = <div className="row cascade">
            <Card title="부모 카테고리 ID" value={content.parentId.toString()} />
        </div>;
    }

    return <div id={defaultPageId}>
        <div className="row cascade first">
            <Card title="ID" value={content.id.toString()} />
        </div>
        <div className="row cascade">
            <Card title="이름" value={content.name} />
        </div>
        {parentIdContainer}
    </div>;
}

type CardProps = {
    title: string;
    value?: Optional<string>;
    href?: string;
    smallBody?: boolean;
    columnWidth?: number;
    columnOffset?: number;
};

function Card(props: CardProps) {
    if (!props.value) {
        return <></>;
    }

    let className = "col";
    if (props.columnWidth !== undefined) {
        className += ` s${props.columnWidth}`;
    } else {
        className += ' s8';
    }
    if (props.columnOffset !== undefined) {
        className += ` offset-s${props.columnOffset}`;
    } else {
        className += ' offset-s2';
    }

    let anchor: JSX.Element;
    if (props.href) {
        anchor = <a href={props.href}><i className="material-icons right grey-text">open_in_new</i></a>;
    } else {
        anchor = <></>;
    }

    return <div className={className}>
        <div className="card">
            <div className="card-content">
                <p>{props.title}{anchor}</p>
                <span className={(props.smallBody === true) ? "" : "card-title"}>{props.value}</span>
            </div>
        </div>
    </div>;
}

type FaqPageProps = {
    content: Optional<FaqCategoryModal>;
    selectedFaqState: StatePair<Optional<FaqModal>>;
    faqs: Optional<FaqModal[]>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function FaqPage(props: FaqPageProps) {
    const [selectedFaq, selectFaq] = props.selectedFaqState;
    const rows = (props.faqs ?? []).map(content => <Row key={content.id} content={content} selectFaq={selectFaq} />);
    const addHref = (props.content) ? `/misc/faq/post?faqCategoryId=${props.content.id}` : `/misc/faq/post`;
    return <div id={faqPageId}>
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>제목</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="fixed-action-btn">
            <a className="btn-floating btn-large secondary" href={addHref}>
                <i className="large material-icons">add</i>
            </a>
        </div>
        <DeleteModal selectedContent={selectedFaq} setErrorMessage={props.setErrorMessage} />
    </div>;
}

type RowProps = {
    content: FaqModal;
    selectFaq: React.Dispatch<Optional<FaqModal>>;
};

function Row(props: RowProps) {
    const content = props.content;
    if (!content) {
        return <tr />;
    }

    return <tr>
        <td>{content.id}</td>
        <td>{content.title}</td>
        <td>
            <a href={`/misc/faq/${content.id}`} className="modal-trigger clickable">
                <i className="material-icons black-text">open_in_new</i>
            </a>
            <a href={`/misc/faq/${content.id}/edit`} className="modal-trigger clickable">
                <i className="material-icons black-text">edit</i>
            </a>
            <a onClick={() => props.selectFaq(content)} className="modal-trigger clickable">
                <i className="material-icons black-text">clear</i>
            </a>
        </td>
    </tr>;
}

type DeleteModalProps = {
    selectedContent: Optional<FaqModal>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function DeleteModal(props: DeleteModalProps) {
    const onDeleteClicked = () => {
        if (props.selectedContent) {
            OnDeleteConfirmClicked(props.selectedContent, props.setErrorMessage);
        }
    };

    return <div id={faqDeleteModalId} className="modal">
        <div className="modal-content">
            <h4>삭제</h4>
            <p>이 항목을 삭제하시겠습니까?</p>
        </div>
        <div className="modal-footer">
            <a onClick={onDeleteClicked} className="waves-effect waves-red btn-flat red-text">삭제</a>
            <a className="modal-close waves-effect btn-flat black-text">취소</a>
        </div>
    </div>;
}

function OnDeleteConfirmClicked(
    content: FaqModal,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
) {
    const onReady = (response: BaseResponse) => {
        window.alert('삭제되었습니다.');
        document.location = `/misc/faq/category/${content.categoryId}`;
    };

    MiscFaqIO.delete(
        content.id,
        onReady,
        setErrorMessage
    );
}