import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {ProductReviewLikeModal, ProductReviewModal, ProductReviewReportModal, ProductReviewStatus} from "../../../modal/Products";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {ProductReviewIO, ProductReviewLikeIO, ProductReviewReportIO} from "../../../io/Products";
import {getMaxDate, getMinDate} from "../../../util/Environments";
import {MaterialModal} from "../../Materials";
import {BaseResponse} from "../../../util/Reponses";

const pageId = 'product_review';
const deleteModalId = `${pageId}_delete`;

export default function Detail() {
    const params = useParams();
    const productReviewId = params.productReviewId?.toBigIntOrNull();
    const [content, setContent] = useState<Optional<ProductReviewModal>>(null);

    const [likeLock, setLikeLock] = useState<number>(0);
    const [likeCount, setLikeCount] = useState<Optional<number>>(null);
    const [likes, setLikes] = useState<Optional<ProductReviewLikeModal[]>>(null);
    const [likeAscending, setLikeAscending] = useState<boolean>(true);
    const [hasMoreLikes, setMoreLikes] = useState<boolean>(true);

    const [reportLock, setReportLock] = useState<number>(0);
    const [reportCount, setReportCount] = useState<Optional<number>>(null);
    const [reports, setReports] = useState<Optional<ProductReviewReportModal[]>>(null);
    const [reportAscending, setReportAscending] = useState<boolean>(true);
    const [hasMoreReports, setMoreReports] = useState<boolean>(true);

    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
    });

    useEffect(() => {
        if (productReviewId) {
            ProductReviewIO.get(productReviewId, setContent, setErrorMessage);
            ProductReviewLikeIO.countByProductReviewId(productReviewId, setLikeCount, setErrorMessage);
            PrepareLikes(productReviewId, [likes, setLikes], likeAscending, setMoreLikes, setErrorMessage);
            ProductReviewReportIO.countByProductReviewId(productReviewId, setReportCount, setErrorMessage);
            PrepareReviews(productReviewId, [reports, setReports], reportAscending, setMoreReports, setErrorMessage);
        }
    }, [productReviewId]);

    useEffect(() => {
        if (productReviewId) {
            PrepareLikes(productReviewId, [likes, setLikes], likeAscending, setMoreLikes, setErrorMessage);
        }
    }, [productReviewId, likeLock, likeAscending]);

    useEffect(() => {
        if (productReviewId) {
            PrepareReviews(productReviewId, [reports, setReports], reportAscending, setMoreReports, setErrorMessage);
        }
    }, [productReviewId, reportLock, reportAscending]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            content={content}

            likeLockState={[likeLock, setLikeLock]}
            likeCount={likeCount}
            likesState={[likes, setLikes]}
            likeAscendingState={[likeAscending, setLikeAscending]}
            hasMoreLikes={hasMoreLikes}

            reportLockState={[reportLock, setReportLock]}
            reportCount={reportCount}
            reportsState={[reports, setReports]}
            reportAscendingState={[reportAscending, setReportAscending]}
            hasMoreReports={hasMoreReports}

            errorMessageState={[errorMessage, setErrorMessage]} />
    </>;
}

function PrepareLikes(
    productReviewId: bigint,
    contentsState: StatePair<Optional<ProductReviewLikeModal[]>>,
    contentAscending: boolean,
    setMoreContents: React.Dispatch<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const [present, setContents] = contentsState;
    const last = present?.lastOrNull()?.likedAt;
    const onReady = (contents: ProductReviewLikeModal[]) => {
        setContents((present ?? []).appended(contents));
        setMoreContents(contents.length === 20);
    };
    if (contentAscending) {
        ProductReviewLikeIO.listByProductReviewIdAscending(productReviewId, last ?? getMinDate(), onReady, setErrorMessage);
    } else {
        ProductReviewLikeIO.listByProductReviewIdDescending(productReviewId, last ?? getMaxDate(), setContents, setErrorMessage);
    }
}

function PrepareReviews(
    productReviewId: bigint,
    contentsState: StatePair<Optional<ProductReviewReportModal[]>>,
    contentAscending: boolean,
    setMoreContents: React.Dispatch<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const [present, setContents] = contentsState;
    const last = present?.lastOrNull()?.reportedAt;
    const onReady = (contents: ProductReviewReportModal[]) => {
        setContents((present ?? []).appended(contents));
        setMoreContents(contents.length === 20);
    };
    if (contentAscending) {
        ProductReviewReportIO.listByProductReviewIdAscending(productReviewId, last ?? getMinDate(), onReady, setErrorMessage);
    } else {
        ProductReviewReportIO.listByProductReviewIdDescending(productReviewId, last ?? getMaxDate(), setContents, setErrorMessage);
    }
}

type PageWrapperProps = {
    content: Optional<ProductReviewModal>;

    likeLockState: StatePair<number>;
    likeCount: Optional<number>;
    likesState: StatePair<Optional<ProductReviewLikeModal[]>>;
    likeAscendingState: StatePair<boolean>;
    hasMoreLikes: boolean;

    reportLockState: StatePair<number>;
    reportCount: Optional<number>;
    reportsState: StatePair<Optional<ProductReviewReportModal[]>>;
    reportAscendingState: StatePair<boolean>;
    hasMoreReports: boolean;

    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    if (!props.content) {
        return <>
            <Nav
                title="광고 리뷰"
                titleIcon="chevron_left"
                titleOnClick={() => window.history.back()}
                errorMessagePair={props.errorMessageState} />
        </>;
    }

    const barItems: [string, NavBarItemAction][] = [];
    if (props.content.statusFlags.toBitMask(ProductReviewStatus.values).isDisabled(ProductReviewStatus.DELETED)) {
        barItems.push(["삭제", OnDeleteClicked]);
    }

    let modifiedContainer: JSX.Element;
    if (props.content.modifiedAt) {
        modifiedContainer = <div className="row cascproducte">
            <Card title="수정 일시" value={props.content.modifiedAt.toRowFormat(true, true, true, true)} />
        </div>;
    } else {
        modifiedContainer = <></>;
    }

    let deletedContainer: JSX.Element;
    if (props.content.deletedAt) {
        deletedContainer = <div className="row cascproducte">
            <Card title="삭제 일시" value={props.content.deletedAt.toRowFormat(true, true, true, true)} />
        </div>;
    } else {
        deletedContainer = <></>;
    }

    const [, setErrorMessage] = props.errorMessageState;

    return <>
        <Nav
            title="광고 리뷰"
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <div className="row cascproducte first">
            <Card title="ID" value={props.content.id.toString()} />
        </div>
        <div className="row cascproducte">
            <Card title="광고 ID" value={props.content.productId.toString()} href={`/product/${props.content.productId}`} columnWidth={4} />
            <Card title="사용자 ID" value={props.content.userId.toString()} href={`/user/${props.content.userId}`} columnWidth={4} columnOffset={0}/>
        </div>
        <div className="row cascproducte">
            <Card title="내용" value={props.content.body} smallBody={true} />
        </div>
        <div className="row cascproducte">
            <Card title="평점" value={props.content.rating.toString()} />
        </div>
        <div className="row cascproducte">
            <Card title="작성 일시" value={props.content.writtenAt.toRowFormat(true, true, true, true)} />
        </div>
        {modifiedContainer}
        {deletedContainer}
        <LikeArea
            lockState={props.likeLockState}
            count={props.likeCount}
            contentsState={props.likesState}
            ascendingState={props.likeAscendingState}
            hasMoreContents={props.hasMoreLikes} />
        <ReportArea
            lockState={props.reportLockState}
            count={props.reportCount}
            contentsState={props.reportsState}
            ascendingState={props.reportAscendingState}
            hasMoreContents={props.hasMoreReports} />
        <DeleteModal
            content={props.content}
            setErrorMessage={setErrorMessage} />
    </>;
}

function OnDeleteClicked() {
    MaterialModal.get(`#${deleteModalId}`).open();
}

type CardProps = {
    title: string;
    value?: Optional<string>;
    href?: string;
    smallBody?: boolean;
    columnWidth?: number;
    columnOffset?: number;
};

function Card(props: CardProps) {
    if (!props.value) {
        return <></>;
    }

    let className = "col";
    if (props.columnWidth !== undefined) {
        className += ` s${props.columnWidth}`;
    } else {
        className += ' s8';
    }
    if (props.columnOffset !== undefined) {
        className += ` offset-s${props.columnOffset}`;
    } else {
        className += ' offset-s2';
    }

    let anchor: JSX.Element;
    if (props.href) {
        anchor = <a href={props.href}><i className="material-icons right grey-text">open_in_new</i></a>;
    } else {
        anchor = <></>;
    }

    return <div className={className}>
        <div className="card">
            <div className="card-content">
                <p>{props.title}{anchor}</p>
                <span className={(props.smallBody === true) ? "" : "card-title"}>{props.value}</span>
            </div>
        </div>
    </div>;
}

type AreaProps<T> = {
    lockState: StatePair<number>;
    count: Optional<number>;
    contentsState: StatePair<Optional<T[]>>;
    ascendingState: StatePair<boolean>;
    hasMoreContents: boolean;
};

function LikeArea(props: AreaProps<ProductReviewLikeModal>) {
    const [contents, setContents] = props.contentsState;
    if (props.count === null || props.count === 0 || !contents) {
        return <></>;
    }

    const [ascending, setAscending] = props.ascendingState;
    const columns: JSX.Element[][] = [[], [], [], []];
    const Card = ({content}: {content: ProductReviewLikeModal}) => <>
        <div className="card">
            <div className="card-content">
                <a href={`/user/${content.userId}`}>사용자: {content.userId.toString()}</a>
                <p>날짜: {content.likedAt.toRowFormat(true, false, false, false)}</p>
            </div>
        </div>
    </>;

    contents.forEach((content, index) => columns[index % 4].push(<Card key={index} content={content} />));
    if (props.hasMoreContents) {
        columns[contents.length % 4].push(<CardMore key={contents.length} lockState={props.lockState} />);
    }

    const sortText = (ascending) ? "오름차순" : "내림차순";
    const onSortClicked = () => {
        setContents(null);
        setAscending(!ascending);
    };
    return <>
        <div className="row" style={{ marginBottom: 0 }}>
            <div className="divider col s8 offset-s2" />
            <h5 className="col offset-s2">좋아요 ({props.count})</h5>
            <h5 className="col primary-text" onClick={onSortClicked} style={{ cursor: "pointer" }}>{sortText}</h5>
        </div>
        <div className="row">
            <div className="col s2 offset-s2">{columns[0]}</div>
            <div className="col s2">{columns[1]}</div>
            <div className="col s2">{columns[2]}</div>
            <div className="col s2">{columns[3]}</div>
        </div>
    </>;
}

function ReportArea(props: AreaProps<ProductReviewReportModal>) {
    const [contents, setContents] = props.contentsState;
    if (props.count === null || props.count === 0 || !contents) {
        return <></>;
    }

    const [ascending, setAscending] = props.ascendingState;
    const columns: JSX.Element[][] = [[], [], [], []];
    const Card = ({content}: {content: ProductReviewReportModal}) => <>
        <div className="card">
            <div className="card-content">
                <a href={`/user/${content.userId}`}>사용자: {content.userId.toString()}</a>
                <a href={`/misc/product/review/report/cause/${content.causeId}`}>신고 사유: {content.causeId.toString()}</a>
                <p>날짜: {content.reportedAt.toRowFormat(true, false, false, false)}</p>
            </div>
        </div>
    </>;

    contents.forEach((content, index) => columns[index % 4].push(<Card key={index} content={content} />));
    if (props.hasMoreContents) {
        columns[contents.length % 4].push(<CardMore key={contents.length} lockState={props.lockState} />);
    }

    const sortText = (ascending) ? "오름차순" : "내림차순";
    const onSortClicked = () => {
        setContents(null);
        setAscending(!ascending);
    };
    return <>
        <div className="row" style={{ marginBottom: 0 }}>
            <div className="divider col s8 offset-s2" />
            <h5 className="col offset-s2">신고 ({props.count})</h5>
            <h5 className="col primary-text" onClick={onSortClicked} style={{ cursor: "pointer" }}>{sortText}</h5>
        </div>
        <div className="row">
            <div className="col s2 offset-s2">{columns[0]}</div>
            <div className="col s2">{columns[1]}</div>
            <div className="col s2">{columns[2]}</div>
            <div className="col s2">{columns[3]}</div>
        </div>
    </>;
}

type CardMoreProps = {
    lockState: StatePair<number>
};

function CardMore(props: CardMoreProps) {
    const [lock, setLock] = props.lockState;
    return <>
        <div className="card">
            <div className="card-content">
                <a>‎</a>
                <p>‎</p>
                <span className="material-symbols-rounded" onClick={() => setLock(lock + 1)} style={{
                    fontSize: "xxx-large",
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)" }}>add</span>
            </div>
        </div>
    </>
}

type DeleteModalProps = {
    content: Optional<ProductReviewModal>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function DeleteModal(props: DeleteModalProps) {
    const onResponse = (response: BaseResponse) => {
        window.alert("삭제되었습니다.");
        window.history.back();
    };

    const onError = (error: string) => {
        const modal = MaterialModal.get(`#${deleteModalId}`);
        modal.setCancelable(true);
        modal.enableFooterButtons();
        modal.close();
        props.setErrorMessage(error);
    };

    const onDeleteClicked = () => {
        if (!props.content) {
            return;
        }

        const modal = MaterialModal.get(`#${deleteModalId}`);
        modal.setCancelable(false);
        modal.disableFooterButtons();
        ProductReviewIO.delete(props.content.id, onResponse, onError);
    };

    return <>
        <div id={deleteModalId} className="modal">
            <div className="modal-content">
                <h4>삭제</h4>
                <p>리뷰를 삭제하시겠습니까?</p>
            </div>
            <div className="modal-footer">
                <div className="modal-footer">
                    <a className="waves-effect btn-flat primary-text" onClick={onDeleteClicked}>예</a>
                    <a className="modal-close waves-effect btn-flat primary-text">아니오</a>
                </div>
            </div>
        </div>
    </>;
}