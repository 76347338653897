import {Route, Routes} from "react-router-dom";
import List from "./List";
import Edit from "./Edit";
import Detail from "./Detail";
import ParticipationRouter from "./participation/ParticipationRouter";
import ReviewRouter from "./review/ReviewRouter";
import {AssertSignIn} from "../../App";
import AnnouncementRouter from "./announcement/AnnouncementRouter";
import TargetRouter from "./target/TargetRouter";

export default function AdRouter() {
    return <Routes>
        <Route path="/list" element={<AssertSignIn><List /></AssertSignIn>} />
        <Route path="/post" element={<AssertSignIn><Edit /></AssertSignIn>} />
        <Route path="/:adId" element={<AssertSignIn><Detail /></AssertSignIn>} />
        <Route path="/:adId/edit" element={<AssertSignIn><Edit /></AssertSignIn>} />
        <Route path="/:adId/target/*" element={<TargetRouter />} />

        <Route path="/announcement/*" element={<AnnouncementRouter />} />
        <Route path="/participation/*" element={<ParticipationRouter />} />
        <Route path="/review/*" element={<ReviewRouter />} />

    </Routes>;
}