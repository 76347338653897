import {parseIntOrNull} from "../../util/Types";

export type CardHorizontalProps = {
    title: string;
    value?: string | null;
    href?: string;
    smallBody?: boolean;
};

export function CardHorizontal(props: CardHorizontalProps) {
    if (!props.value) {
        return <></>;
    }

    let anchor: JSX.Element;
    if (props.href && parseIntOrNull(props.value)) {
        anchor = <a href={props.href}><i className="material-icons right grey-text">open_in_new</i></a>;
    } else {
        anchor = <></>;
    }

    const valueTokens = props.value.split("\n")
    const value: JSX.Element[] = []
    for (let i = 0; i < valueTokens.length; i++) {
        if (i === valueTokens.length - 1) {
            value.push(<>{valueTokens[i]}</>)
        } else {
            value.push(<>{valueTokens[i]}<br /></>)
        }
    }

    return <div className="card">
        <div className="card-content">
            <p>{props.title}{anchor}</p>
            <span className={(props.smallBody === true) ? "" : "card-title"}>{value}</span>
        </div>
    </div>;
}