import {Route, Routes} from "react-router-dom";
import AgeRangeRouter from "./ageRange/AgeRangeRouter";
import AgreementRouter from "./agreement/AgreementRouter";
import ApplicationRouter from "./application/ApplicationRouter";
import BankRouter from "./bank/BankRouter";
import EventRouter from "./event/EventRouter";
import FaqRouter from "./faq/FaqRouter";
import HomeRouter from "./home/HomeRouter";
import LanguageRouter from "./language/LanguageRouter";
import NoticeRouter from "./notice/NoticeRouter";
import RegionRouter from "./region/RegionRouter";
import AdRouter from "./ad/AdRouter";
import ProductRouter from "./product/ProductRouter";
import PointRouter from "./point/PointRouter";
import UserRouter from "./user/UserRouter";
import ConstructionRouter from "./construction/ConstructionRouter";

export default function MiscRouter() {
    return <Routes>
        <Route path="/ad/*" element={<AdRouter />} />
        <Route path="/ageRange/*" element={<AgeRangeRouter />} />
        <Route path="/agreement/*" element={<AgreementRouter />} />
        <Route path="/application/*" element={<ApplicationRouter />} />
        <Route path="/bank/*" element={<BankRouter />} />
        <Route path="/construction/*" element={<ConstructionRouter />} />
        <Route path="/event/*" element={<EventRouter />} />
        <Route path="/faq/*" element={<FaqRouter />} />
        <Route path="/home/*" element={<HomeRouter />} />
        <Route path="/language/*" element={<LanguageRouter />} />
        <Route path="/notice/*" element={<NoticeRouter />} />
        <Route path="/point/*" element={<PointRouter />} />
        <Route path="/product/*" element={<ProductRouter />} />
        <Route path="/region/*" element={<RegionRouter />} />
        <Route path="/user/*" element={<UserRouter />} />
    </Routes>;
}