import {AssertSignIn} from "../../../App";
import {Route, Routes} from "react-router-dom";
import List from "./List";
import Edit from "./Edit";

export default function BlockRouter() {
    return <Routes>
        <Route path="/list" element={<AssertSignIn><List /></AssertSignIn>} />
        <Route path="/post" element={<AssertSignIn><Edit /></AssertSignIn>} />
        <Route path="/:userBlockId/edit" element={<AssertSignIn><Edit /></AssertSignIn>} />
    </Routes>;
}