import {
    AgeRangeModal,
    AgreementModal,
    AgreementType, ApplicationVersionModal, ApplicationVersionType,
    BankModal, ConstructionModal,
    EventModal,
    FaqCategoryModal, FaqModal, HomeBannerModal, HomeBestModal,
    LanguageModal, NoticeCategoryModal, NoticeModal, RegionModal
} from "../modal/Miscs";
import {Requests} from "../util/Requests";
import {Environments, Logger, SERVER_URL} from "../util/Environments";
import {OnArrayResponse, OnBaseResponse, OnErrorResponse, OnObjectResponse} from "../util/Reponses";
import {Optional} from "../util/Types";
import {UserModal} from "../modal/Users";
import {AxiosRequestConfig} from "axios";

export class MiscAgeRangeIO {
    static post(
        fromInclusive: number,
        toExclusive: number,
        name: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            Logger.error('creatorId is null!');
            onError('사용자 정보가 존재하지 않습니다.');
            return;
        }

        Requests.postObject(
            'MiscAgeRangeIO.post',
            `${SERVER_URL}/misc/ageRange`,
            o => o,
            onReady,
            onError,
            {fromInclusive, toExclusive, name, creatorId},
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<AgeRangeModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscAgeRangeIO.get',
            `${SERVER_URL}/misc/ageRange/${id}`,
            o => new AgeRangeModal(o),
            onReady,
            onError,
        );
    }

    static listAll(
        onReady: OnArrayResponse<AgeRangeModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscAgeRangeIO.listAll',
            `${SERVER_URL}/misc/ageRange/list`,
            o => new AgeRangeModal(o),
            onReady,
            onError,
        );
    }

    static listByIds(
        ids: number[],
        onReady: OnArrayResponse<AgeRangeModal>,
        onError: OnErrorResponse
    ) {
        if (ids.isEmpty()) {
            onReady([]);
            return;
        }

        const config: AxiosRequestConfig = {
            params: new URLSearchParams(ids.map(id => ["ids", id.toString()]))
        };

        Requests.getArray(
            'MiscAgeRangeIO.listByIds',
            `${SERVER_URL}/misc/ageRange/list`,
            o => new AgeRangeModal(o),
            onReady,
            onError,
            config
        );
    }

    static update(
        id: number,
        fromInclusive: number,
        toExclusive: number,
        name: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'MiscAgeRangeIO.post',
            `${SERVER_URL}/misc/ageRange/${id}`,
            o => o,
            onReady,
            onError,
            {fromInclusive, toExclusive, name}
        );
    }

    static delete(
        sessionId: bigint,
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const config = {
            headers: { "x-vowing-session-id": sessionId.toString() },
        };
        Requests.delete(
            'MiscAgeRangeIO.delete',
            `${SERVER_URL}/misc/ageRange/${id}`,
            o => o,
            onReady,
            onError,
            config
        );
    }
}

export class MiscAgreementIO {
    static post(
        url: string,
        type: AgreementType,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'MiscAgreementIO.post',
            `${SERVER_URL}/misc/agreement`,
            o => o,
            onReady,
            onError,
            {url, type},
        );
    }

    static get(
        id: number,
        onReady: (agreement: AgreementModal) => void,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscAgreementIO.get',
            `${SERVER_URL}/misc/agreement/${id}`,
            o => new AgreementModal(o),
            onReady,
            onError,
        );
    }

    static listAll(
        onReady: (agreements: AgreementModal[]) => void,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscAgreementIO.listAll',
            `${SERVER_URL}/misc/agreement/list`,
            o => new AgreementModal(o),
            onReady,
            onError,
        );
    }
}

export class MiscApplicationVersionIO {
    static post(
        name: string,
        type: ApplicationVersionType,
        platform: string,
        expiredAt: Optional<Date>,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'MiscApplicationVersionIO.post',
            `${SERVER_URL}/misc/application/version`,
            o => o,
            onReady,
            onError,
            {name, type, platform, expiredAt: expiredAt?.toKSTString()},
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<ApplicationVersionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscApplicationVersionIO.get',
            `${SERVER_URL}/misc/application/version/${id}`,
            o => new ApplicationVersionModal(o),
            onReady,
            onError,
        );
    }

    static getByProperties(
        onReady: OnObjectResponse<ApplicationVersionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscApplicationVersionIO.get',
            `${SERVER_URL}/misc/application/version`,
            o => new ApplicationVersionModal(o),
            onReady,
            onError,
            {
                params: {
                    name: Environments.versionName,
                    platform: "admin",
                    type: ApplicationVersionType.current()
                }
            }
        );
    }

    static listAll(
        onReady: OnArrayResponse<ApplicationVersionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscApplicationVersionIO.listAll',
            `${SERVER_URL}/misc/application/version/list`,
            o => new ApplicationVersionModal(o),
            onReady,
            onError,
        );
    }

    static update(
        id: number,
        name: string,
        type: ApplicationVersionType,
        platform: string,
        expiredAt: Optional<Date>,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'MiscApplicationVersionIO.patch',
            `${SERVER_URL}/misc/application/version/${id}`,
            o => o,
            onReady,
            onError,
            {name, type, platform, expiredAt: expiredAt?.toKSTString()},
        );
    }
}

export class MiscBankIO {
    static post(
        name: string,
        onReady: OnObjectResponse<BankModal>,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            Logger.error('creatorId is null!');
            onError('사용자 정보가 존재하지 않습니다.');
            return;
        }

        Requests.postObject(
            'MiscBankIO.post',
            `${SERVER_URL}/misc/bank`,
            o => new BankModal(o),
            onReady,
            onError,
            {name, creatorId},
        );
    }

    static get(
        id: number,
        onReady: (bank: BankModal) => void,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscBankIO.get',
            `${SERVER_URL}/misc/bank/${id}`,
            o => new BankModal(o),
            onReady,
            onError,
        );
    }

    static listAll(
        onReady: (agreements: BankModal[]) => void,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscBankIO.listAll',
            `${SERVER_URL}/misc/bank/list`,
            o => new BankModal(o),
            onReady,
            onError,
        );
    }

    static update(
        id: number,
        name: string,
        onReady: OnObjectResponse<BankModal>,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'MiscBankIO.post',
            `${SERVER_URL}/misc/bank/${id}`,
            o => new BankModal(o),
            onReady,
            onError,
            {name},
        );
    }

    static delete(
        sessionId: bigint,
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const config = {
            headers: { "x-vowing-session-id": sessionId.toString() },
        };
        Requests.delete(
            'MiscBankIO.delete',
            `${SERVER_URL}/misc/bank/${id}`,
            o => o,
            onReady,
            onError,
            config
        );
    }
}

export class MiscConstructionIO {
    static get(
        onReady: OnObjectResponse<ConstructionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscConstructionIO.get',
            `${SERVER_URL}:8443`,
            o => new ConstructionModal(o),
            onReady,
            onError
        );
    }

    static update(
        startAt: Optional<Date>,
        endAt: Optional<Date>,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'MiscConstructionIO.update',
            `${SERVER_URL}/misc/construction`,
            o => o,
            onReady,
            onError,
            { startAt, endAt }
        );
    }
}

export class MiscEventIO {
    static post(
        title: string,
        body: string,
        descriptionImageCount: number,
        startAt: Date,
        endAt: Date,
        onReady: OnObjectResponse<EventModal>,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            Logger.error('creatorId is null!');
            onError('사용자 정보가 존재하지 않습니다.');
            return;
        }

        Requests.postObject(
            'MiscEventIO.post',
            `${SERVER_URL}/misc/event`,
            o => new EventModal(o),
            onReady,
            onError,
            {title, body, descriptionImageCount, startAt, endAt, creatorId},
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<EventModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscEventIO.get',
            `${SERVER_URL}/misc/event/${id}`,
            o => new EventModal(o),
            onReady,
            onError
        );
    }

    static listAll(
        onReady: (agreements: EventModal[]) => void,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscEventIO.listAll',
            `${SERVER_URL}/misc/event/list`,
            o => new EventModal(o),
            onReady,
            onError,
        );
    }

    static update(
        id: number,
        title: string,
        body: string,
        descriptionImageCount: number,
        startAt: Date,
        endAt: Date,
        onReady: OnObjectResponse<EventModal>,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'MiscEventIO.post',
            `${SERVER_URL}/misc/event/${id}`,
            o => new EventModal(o),
            onReady,
            onError,
            {title, body, descriptionImageCount, startAt, endAt},
        );
    }

    static delete(
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'MiscEventIO.delete',
            `${SERVER_URL}/misc/event/${id}`,
            o => o,
            onReady,
            onError,
        );
    }
}

export class MiscFaqCategoryIO {
    static post(
        name: string,
        parentId: Optional<number>,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            Logger.error('creatorId is null!');
            onError('사용자 정보가 존재하지 않습니다.');
            return;
        }

        Requests.postObject(
            'MiscFaqCategoryIO.post',
            `${SERVER_URL}/misc/faq/category`,
            o => o,
            onReady,
            onError,
            {name, parentId, creatorId},
        );
    }

    static get(
        id: number,
        onReady: (faqCategory: FaqCategoryModal) => void,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscFaqCategoryIO.get',
            `${SERVER_URL}/misc/faq/category/${id}`,
            o => new FaqCategoryModal(o),
            onReady,
            onError,
        );
    }

    static listAll(
        onReady: (agreements: FaqCategoryModal[]) => void,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscFaqCategoryIO.listAll',
            `${SERVER_URL}/misc/faq/category/list`,
            o => new FaqCategoryModal(o),
            onReady,
            onError,
        );
    }

    static update(
        id: number,
        name: string,
        parentId: Optional<number>,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'MiscFaqCategoryIO.post',
            `${SERVER_URL}/misc/faq/category/${id}`,
            o => o,
            onReady,
            onError,
            {name, parentId},
        );
    }

    static delete(
        sessionId: bigint,
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const config = {
            headers: { "x-vowing-session-id": sessionId.toString() },
        };
        Requests.delete(
            'MiscFaqCategoryIO.delete',
            `${SERVER_URL}/misc/faq/category/${id}`,
            o => o,
            onReady,
            onError,
            config
        );
    }
}

export class MiscFaqIO {
    static post(
        title: string,
        body: string,
        categoryId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            Logger.error('creatorId is null!');
            onError('사용자 정보가 존재하지 않습니다.');
            return;
        }

        Requests.postObject(
            'MiscFaqIO.post',
            `${SERVER_URL}/misc/faq`,
            o => o,
            onReady,
            onError,
            {title, body, categoryId, creatorId},
        );
    }

    static get(
        id: number,
        onReady: (faq: FaqModal) => void,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscFaqIO.get',
            `${SERVER_URL}/misc/faq/${id}`,
            o => new FaqModal(o),
            onReady,
            onError,
        );
    }

    static listAll(
        onReady: (agreements: FaqModal[]) => void,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscFaqIO.listAll',
            `${SERVER_URL}/misc/faq/list`,
            o => new FaqModal(o),
            onReady,
            onError,
        );
    }

    static listByCategoryId(
        categoryId: number,
        onReady: OnArrayResponse<FaqModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscFaqIO.listByCategoryId',
            `${SERVER_URL}/misc/faq/category/${categoryId}/list`,
            o => new FaqModal(o),
            onReady,
            onError,
        );
    }

    static update(
        id: number,
        title: string,
        body: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'MiscFaqIO.post',
            `${SERVER_URL}/misc/faq/${id}`,
            o => o,
            onReady,
            onError,
            {title, body},
        );
    }

    static delete(
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'MiscFaqIO.delete',
            `${SERVER_URL}/misc/faq/${id}`,
            o => o,
            onReady,
            onError,
        );
    }
}

export class MiscHomeBannerIO {
    static post(
        adId: Optional<number>,
        m10nId: Optional<number>,
        noticeId: Optional<number>,
        sortOrder: number,
        startAt: Date,
        endAt: Date,
        onReady: OnObjectResponse<HomeBannerModal>,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            Logger.error('creatorId is null!');
            onError('사용자 정보가 존재하지 않습니다.');
            return;
        }

        Requests.postObject(
            'MiscHomeBannerIO.post',
            `${SERVER_URL}/misc/home/banner`,
            o => new HomeBannerModal(o),
            onReady,
            onError,
            {adId, m10nId, noticeId, sortOrder, startAt, endAt, creatorId},
        );
    }

    static get(
        id: number,
        onReady: (homeBanner: HomeBannerModal) => void,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscHomeBannerIO.get',
            `${SERVER_URL}/misc/home/banner/${id}`,
            o => new HomeBannerModal(o),
            onReady,
            onError,
        );
    }

    static listAll(
        onReady: (homeBanners: HomeBannerModal[]) => void,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscHomeBannerIO.listAll',
            `${SERVER_URL}/misc/home/banner/list`,
            o => new HomeBannerModal(o),
            onReady,
            onError,
        );
    }

    static update(
        id: number,
        adId: Optional<number>,
        m10nId: Optional<number>,
        noticeId: Optional<number>,
        sortOrder: number,
        startAt: Date,
        endAt: Date,
        onReady: OnObjectResponse<HomeBannerModal>,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'MiscHomeBannerIO.post',
            `${SERVER_URL}/misc/home/banner/${id}`,
            o => new HomeBannerModal(o),
            onReady,
            onError,
            {adId, m10nId, noticeId, sortOrder, startAt, endAt},
        );
    }

    static delete(
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'MiscHomeBannerIO.delete',
            `${SERVER_URL}/misc/home/banner/${id}`,
            o => o,
            onReady,
            onError,
        );
    }
}

export class MiscHomeBestIO {
    static post(
        data: HomeBestModal.Post,
        onReady: OnObjectResponse<HomeBestModal>,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'MiscHomeBestIO.post',
            `${SERVER_URL}/misc/home/best`,
            o => new HomeBestModal(o),
            onReady,
            onError,
            data
        );
    }

    static put(
        id: number,
        data: HomeBestModal.Post,
        onReady: OnObjectResponse<HomeBestModal>,
        onError: OnErrorResponse
    ) {
        Requests.putObject(
            'MiscHomeBestIO.put',
            `${SERVER_URL}/misc/home/best/${id}`,
            o => new HomeBestModal(o),
            onReady,
            onError,
            data
        )
    }

    static delete(
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'MiscHomeBestIO.delete',
            `${SERVER_URL}/misc/home/best/${id}`,
            o => o,
            onReady,
            onError
        )
    }

    static getById(
        id: number,
        onReady: OnObjectResponse<HomeBestModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscHomeBestIO.getById',
            `${SERVER_URL}/misc/home/best/${id}`,
            o => new HomeBestModal(o),
            onReady,
            onError
        )
    }

    static listAll(
        onReady: OnArrayResponse<HomeBestModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscHomeBestIO.listAll',
            `${SERVER_URL}/misc/home/best/list`,
            o => new HomeBestModal(o),
            onReady,
            onError
        )
    }
}

export class MiscLanguageIO {
    static post(
        name: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            Logger.error('creatorId is null!');
            onError('사용자 정보가 존재하지 않습니다.');
            return;
        }

        Requests.postObject(
            'MiscLanguageIO.post',
            `${SERVER_URL}/misc/language`,
            o => o,
            onReady,
            onError,
            {name, creatorId},
        );
    }

    static get(
        id: number,
        onReady: (language: LanguageModal) => void,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscIO.getLanguage',
            `${SERVER_URL}/misc/language/${id}`,
            o => new LanguageModal(o),
            onReady,
            onError
        );
    }

    static listAll(
        onReady: (agreements: LanguageModal[]) => void,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscLanguageIO.listAll',
            `${SERVER_URL}/misc/language/list`,
            o => new LanguageModal(o),
            onReady,
            onError,
        );
    }

    static update(
        id: number,
        name: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'MiscLanguageIO.post',
            `${SERVER_URL}/misc/language/${id}`,
            o => o,
            onReady,
            onError,
            {name},
        );
    }

    static delete(
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'MiscLanguageIO.delete',
            `${SERVER_URL}/misc/language/${id}`,
            o => o,
            onReady,
            onError,
        );
    }
}

export class MiscNoticeCategoryIO {
    static post(
        name: string,
        parentId: Optional<number>,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            Logger.error('creatorId is null!');
            onError('사용자 정보가 존재하지 않습니다.');
            return;
        }

        Requests.postObject(
            'MiscNoticeCategoryIO.post',
            `${SERVER_URL}/misc/notice/category`,
            o => o,
            onReady,
            onError,
            {name, parentId, creatorId},
        );
    }

    static get(
        id: number,
        onReady: (noticeCategory: NoticeCategoryModal) => void,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscNoticeCategoryIO.get',
            `${SERVER_URL}/misc/notice/category/${id}`,
            o => new NoticeCategoryModal(o),
            onReady,
            onError,
        );
    }

    static listAll(
        onReady: (agreements: NoticeCategoryModal[]) => void,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscNoticeCategoryIO.listAll',
            `${SERVER_URL}/misc/notice/category/list`,
            o => new NoticeCategoryModal(o),
            onReady,
            onError,
        );
    }

    static update(
        id: number,
        name: string,
        parentId: Optional<number>,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'MiscNoticeCategoryIO.post',
            `${SERVER_URL}/misc/notice/category/${id}`,
            o => o,
            onReady,
            onError,
            {name, parentId},
        );
    }

    static delete(
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'MiscNoticeCategoryIO.delete',
            `${SERVER_URL}/misc/notice/category/${id}`,
            o => o,
            onReady,
            onError,
        );
    }
}

export class MiscNoticeIO {
    static post(
        title: string,
        body: string,
        categoryId: number,
        onReady: OnObjectResponse<NoticeModal>,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            Logger.error('creatorId is null!');
            onError('사용자 정보가 존재하지 않습니다.');
            return;
        }

        Requests.postObject(
            'MiscNoticeIO.post',
            `${SERVER_URL}/misc/notice`,
            o => new NoticeModal(o),
            onReady,
            onError,
            {title, body, categoryId, creatorId},
        );
    }

    static get(
        id: number,
        onReady: (notice: NoticeModal) => void,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscNoticeIO.get',
            `${SERVER_URL}/misc/notice/${id}`,
            o => new NoticeModal(o),
            onReady,
            onError,
        );
    }

    static listAll(
        onReady: (agreements: NoticeModal[]) => void,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscNoticeIO.listAll',
            `${SERVER_URL}/misc/notice/list`,
            o => new NoticeModal(o),
            onReady,
            onError,
        );
    }

    static listByCategoryId(
        categoryId: number,
        onReady: (agreements: NoticeModal[]) => void,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscNoticeIO.listByCategoryId',
            `${SERVER_URL}/misc/notice/category/${categoryId}/list`,
            o => new NoticeModal(o),
            onReady,
            onError,
        );
    }

    static update(
        id: number,
        title: string,
        body: string,
        onReady: OnObjectResponse<NoticeModal>,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'MiscNoticeIO.post',
            `${SERVER_URL}/misc/notice/${id}`,
            o => new NoticeModal(o),
            onReady,
            onError,
            {title, body},
        );
    }

    static delete(
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'MiscNoticeIO.delete',
            `${SERVER_URL}/misc/notice/${id}`,
            o => o,
            onReady,
            onError,
        );
    }
}

export class MiscRegionIO {
    static post(
        name: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'MiscRegionIO.post',
            `${SERVER_URL}/misc/region`,
            o => o,
            onReady,
            onError,
            {name},
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<RegionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'MiscRegionIO.listAll',
            `${SERVER_URL}/misc/region/${id}`,
            o => new RegionModal(o),
            onReady,
            onError,
        );
    }

    static listAll(
        onReady: OnArrayResponse<RegionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'MiscRegionIO.listAll',
            `${SERVER_URL}/misc/region/list`,
            o => new RegionModal(o),
            onReady,
            onError,
        );
    }

    static update(
        id: number,
        name: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'MiscRegionIO.post',
            `${SERVER_URL}/misc/region/${id}`,
            o => o,
            onReady,
            onError,
            {name},
        );
    }

    static delete(
        sessionId: bigint,
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const config = {
            headers: { "x-vowing-session-id": sessionId.toString() },
        };
        Requests.delete(
            'MiscRegionIO.delete',
            `${SERVER_URL}/misc/region/${id}`,
            o => o,
            onReady,
            onError,
            config
        );
    }
}