import {Route, Routes} from "react-router-dom";
import {AssertSignIn} from "../../../App";
import List from "./List";
import Detail from "./Detail";
import Post from "./Post";

export default function UsageRouter() {
    return <Routes>
        <Route path="/post" element={<AssertSignIn><Post /></AssertSignIn>} />
        <Route path="/list" element={<AssertSignIn><List /></AssertSignIn>} />
        <Route path="/:pointUsageId" element={<AssertSignIn><Detail /></AssertSignIn>} />
    </Routes>;
}