import {useParams} from "react-router-dom";
import {HomeBannerModal} from "../../../../modal/Miscs";
import { StorageIO } from "../../../../io/Services";

export default function Image() {
    const {homeBannerId} = useParams() as { homeBannerId?: number };

    if (homeBannerId === undefined) {
        window.alert('잘못된 요청입니다.');
        return <></>;
    }

    document.body.style.backgroundColor = "#000000";

    return <div className="page-center">
        <StorageIO.StorageImage
            objectKey={HomeBannerModal.imagePath(homeBannerId)}
            style={{ maxWidth: '50%', maxHeight: '50%' }} />
    </div>;
}