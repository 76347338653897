import {UserGender} from "./Users";
import {BitMaskFlag} from "../util/BitMask";
import {Optional} from "../util/Types";
import {StorageIO} from "../io/Services";
import {UniqueContent} from "./Modal";

export class AdAnnouncementStatus extends BitMaskFlag {
    static readonly USED = Object.freeze(new AdAnnouncementStatus("사용 완료", 1 << 0));
    static readonly values: ReadonlyArray<AdAnnouncementStatus> = [AdAnnouncementStatus.USED];
}

export enum AdDifficulty {
    VERY_EASY, EASY, NORMAL, HARD, EXPERT
}

export namespace AdDifficulty {
    export function toString(difficulty: AdDifficulty): string {
        switch (difficulty) {
            case AdDifficulty.VERY_EASY: return "매우 쉬움";
            case AdDifficulty.EASY: return "쉬움";
            case AdDifficulty.NORMAL: return "보통";
            case AdDifficulty.HARD: return "어려움";
            case AdDifficulty.EXPERT: return "매우 어려움";
        }
    }

    export function ordinal(s: string): number {
        return values().indexOf(s);
    }

    export function values(): string[] {
        return [
            toString(AdDifficulty.VERY_EASY),
            toString(AdDifficulty.EASY),
            toString(AdDifficulty.NORMAL),
            toString(AdDifficulty.HARD),
            toString(AdDifficulty.EXPERT)
        ]
    }
}

export class AdParticipationType extends BitMaskFlag {
    static readonly TYPE = Object.freeze(new AdParticipationType("타이핑", 1 << 0));
    static readonly SPEECH = Object.freeze(new AdParticipationType("읽기", 1 << 1));
    static readonly values: ReadonlyArray<AdParticipationType> = [
        AdParticipationType.TYPE,
        AdParticipationType.SPEECH
    ];
}

export class AdReviewStatus extends BitMaskFlag {
    static readonly MODIFIED = Object.freeze(new AdReviewStatus("수정됨", 1 << 0));
    static readonly DELETED = Object.freeze(new AdReviewStatus("삭제됨", 1 << 1));
    static readonly values: ReadonlyArray<AdReviewStatus> = [
        AdReviewStatus.MODIFIED,
        AdReviewStatus.DELETED
    ];
}

export class AdRewardType extends BitMaskFlag {
    static readonly POINT = Object.freeze(new AdRewardType("포인트", 1 << 0));
    static readonly PRODUCT = Object.freeze(new AdRewardType("상품", 1 << 1));
    static readonly values: ReadonlyArray<AdRewardType> = [
        AdRewardType.POINT,
        AdRewardType.PRODUCT
    ];
}

export class AdSearchFilter extends BitMaskFlag {
    static readonly ID_ASCENDING = Object.freeze(new AdSearchFilter("ID 오름차순", 1 << 0));
    static readonly ID_DESCENDING = Object.freeze(new AdSearchFilter("ID 내림차순", 1 << 1));
    static readonly POINT = Object.freeze(new AdSearchFilter("포인트 지급만", 1 << 2));
    static readonly PRODUCT = Object.freeze(new AdSearchFilter("상품 지급만", 1 << 3));
    static readonly DIFFICULTY_ASCENDING = Object.freeze(new AdSearchFilter("난이도 오름차순", 1 << 4));
    static readonly DIFFICULTY_DESCENDING = Object.freeze(new AdSearchFilter("난이도 내림차순", 1 << 5));
    static readonly AVAILABLE = Object.freeze(new AdSearchFilter("진행 중인 광고", 1 << 6));
    static readonly FINISHED = Object.freeze(new AdSearchFilter("마감된 광고", 1 << 7));
    static readonly POINT_DESCENDING = Object.freeze(new AdSearchFilter("포인트 내림차순", 1 << 8));
    static readonly values: ReadonlyArray<AdStatus> = [
        AdSearchFilter.ID_ASCENDING,
        AdSearchFilter.ID_DESCENDING,
        AdSearchFilter.POINT,
        AdSearchFilter.PRODUCT,
        AdSearchFilter.DIFFICULTY_ASCENDING,
        AdSearchFilter.DIFFICULTY_DESCENDING,
        AdSearchFilter.AVAILABLE,
        AdSearchFilter.FINISHED,
        AdSearchFilter.POINT_DESCENDING,
    ];
}

export class AdStatus extends BitMaskFlag {
    static readonly HIDDEN = Object.freeze(new AdStatus("숨김", 1 << 0));
    static readonly EXTERNAL_URL_WING = Object.freeze(new AdStatus("외부 연결 URL 클릭 시 날개 지급", 1 << 1));
    static readonly values: ReadonlyArray<AdStatus> = [
        AdStatus.HIDDEN,
        AdStatus.EXTERNAL_URL_WING
    ];
}

export class AdModal {
    id!: number;
    title!: string;
    description!: string;
    script!: string;
    externalUrl!: string;
    gradingMessage!: Optional<string>;
    amount!: number;
    point!: number;
    pointAdditionalHighAccuracy!: number;
    productId!: number | null;
    participationType!: number;
    rewardType!: number;
    headerImageCount!: number;
    descriptionImageCount!: number;
    difficulty!: AdDifficulty;
    minimumAccuracy!: number;
    statusFlags!: number;
    createdAt!: Date;
    startAt!: Date;
    endAt!: Date;
    exposedAt!: Date;
    announcedAt!: Date | null;

    constructor(json: object) {
        Object.assign(this, json);
    }

    static thumbnailImagePath(content: AdModal): string {
        return `ad/${content.id}/thumbnail.png`;
    }

    static headerImagePath(content: AdModal, index: number): string {
        return `ad/${content.id}/header/${index}.png`;
    }

    static descriptionImagePath(content: AdModal, index: number): string {
        return `ad/${content.id}/description/${index}.png`;
    }

    static contentVideoPath(content: AdModal): string {
        return `ad/${content.id}/content/video.mp4`;
    }
}

export class AdAnnouncementModal {
    id!: number;
    adId!: number;
    userId!: bigint;
    statusFlags!: number;
    createdAt!: Date;
    expiredAt!: Date;
    usedAt!: Optional<Date>;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class AdParticipationModal {
    static recordPath(adParticipation: AdParticipationModal): string | undefined {
        if (adParticipation.audioId !== null) {
            return StorageIO.createPath(`audio/${adParticipation.audioId}.aac`, 'vowing-1.3.0');
        } else if (adParticipation.accuracy >= 80) {
            return StorageIO.createPath(`ad/${adParticipation.adId}/recorded/${adParticipation.id}.wav`);
        } else {
            return undefined;
        }
    }

    id!: bigint;
    adId!: number;
    userId!: bigint;
    audioId!: Optional<bigint>;
    recordedText!: string;
    accuracy!: number;
    isPassed!: boolean;
    participatedAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class AdPopularModal {
    adId!: number;
    participationCount!: number;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class AdReviewLikeModal {
    adReviewId!: number;
    userId!: number;
    likedAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class AdReviewModal {
    id!: bigint;
    adId!: number;
    userId!: bigint;
    body!: string;
    rating!: number;
    imageCount!: number;
    statusFlags!: number;
    writtenAt!: Date;
    modifiedAt!: Optional<Date>;
    deletedAt!: Optional<Date>;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class AdReviewReportCauseModal {
    id!: number;
    description!: string;
    sortOrder!: number;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export class AdReviewReportModal {
    adReviewId!: number;
    userId!: number;
    causeId!: number;
    reportedAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export interface AdTargetModal<T> {
    adId: number;
    targetId: T;
}

export class AdTargetAgeModal implements AdTargetModal<number> {
    adId!: number;
    ageRangeId!: number;

    constructor(json: object) {
        Object.assign(this, json);
    }

    get targetId(): number {
        return this.ageRangeId;
    }
}

export class AdTargetGenderModal implements AdTargetModal<UserGender>, UniqueContent<UserGender> {
    id!: UserGender;
    readonly adId!: number;
    readonly gender!: UserGender;

    constructor(json: object) {
        Object.assign(this, json);
        this.id = this.gender;
    }

    get targetId(): UserGender {
        return this.gender;
    }
}

export class AdTargetRegionModal implements AdTargetModal<number> {
    adId!: number;
    regionId!: number;

    constructor(json: object) {
        Object.assign(this, json);
    }

    get targetId(): number {
        return this.regionId;
    }
}