import {useId} from "react";
import {appendClassName, IntrinsicProperties} from "../../util/Properties";
import {Optional} from "../../util/Types";

export type FileFieldProps = {
    formProps?: IntrinsicProperties.form
    fieldWrapperProps?: IntrinsicProperties.div
    buttonWrapperProps?: IntrinsicProperties.div
    buttonTitleProps?: IntrinsicProperties.span
    fileInputProps?: IntrinsicProperties.input
    pathWrapperProps?: IntrinsicProperties.div
    pathInputProps?: IntrinsicProperties.input

    title?: string
    accept?: string
    disabled?: boolean
    multiple?: boolean
    onChange?: (files: Optional<FileList>) => void
}

export function FileField(props: FileFieldProps) {
    return <>
        <form {...props.formProps}>
            <div
                {...props.fieldWrapperProps}
                className={appendClassName(props.fieldWrapperProps, 'file-field input-field')}>
                <div
                    {...props.buttonWrapperProps}
                    className={appendClassName(props.buttonWrapperProps, 'btn')}>
                    <span {...props.buttonTitleProps}>{props.title ?? '파일 선택'}</span>
                    <input
                        type={'file'}
                        accept={props.accept ?? props.fileInputProps?.accept}
                        disabled={props.disabled}
                        multiple={props.multiple ?? props.fileInputProps?.multiple}
                        onChange={event => props.onChange?.(event.target.files)}/>
                </div>
                <div
                    {...props.pathWrapperProps}
                    className={appendClassName(props.pathWrapperProps, 'file-path-wrapper')}>
                    <input
                        {...props.pathInputProps}
                        className={appendClassName(props.pathInputProps, 'file-path')}
                        type={props.pathInputProps?.type ?? 'text'}
                        disabled={props.disabled} />
                </div>
            </div>
        </form>
    </>
}