import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    ConditionalInfiniteContent,
    Optional,
    StatePair,
    useConditionalDateContent, useConditionalIntContent, useConditionalLongContent
} from "../../util/Types";
import {
    UserBlockModal,
    UserGender,
    UserModal,
    UserSessionModal,
    UserSignInModal,
    UserType
} from "../../modal/Users";
import {UserBlockIO, UserIO, UserSessionIO, UserSignInIO} from "../../io/Users";
import {BottomProgress, DetailCard, ErrorMessage, Nav, NavBarItemAction, NavTabItemProps} from "../Common";
import {MaterialModal} from "../Materials";
import {
    AdParticipationModal,
    AdReviewLikeModal,
    AdReviewModal,
    AdReviewReportModal
} from "../../modal/Ads";
import {PointUsageModal, PointUsageType, PointWithdrawalModal} from "../../modal/Points";
import {
    ProductDeliveryModal,
    ProductReviewLikeModal,
    ProductReviewModal,
    ProductReviewReportModal
} from "../../modal/Products";
import {WingUsageModal, WingUsageType} from "../../modal/Wings";
import {AdParticipationIO, AdReviewIO, AdReviewLikeIO, AdReviewReportIO} from "../../io/Ads";
import {Logger} from "../../util/Environments";
import {PointUsageIO, PointWithdrawalIO} from "../../io/Points";
import {ProductDeliveryIO, ProductReviewIO, ProductReviewLikeIO, ProductReviewReportIO} from "../../io/Products";
import {WingUsageIO} from "../../io/Wings";

const pageId = 'user_detail';
const defaultPageId = `${pageId}_default`;
const adParticipationPageId = `${pageId}_ad_participation`;
const adReviewPageId = `${pageId}_ad_review`;
const adReviewLikePageId = `${pageId}_ad_review_like`;
const adReviewReportPageId = `${pageId}_ad_review_report`;
const pointUsagePageId = `${pageId}_point_usage`;
const pointWithdrawalPageId = `${pageId}_point_withdrawal`;
const productDeliveryPageId = `${pageId}_product_delivery`;
const productReviewPageId = `${pageId}_product_review`;
const productReviewLikePageId = `${pageId}_product_review_like`;
const productReviewReportPageId = `${pageId}_product_review_report`;
const userBlockPageId = `${pageId}_user_block`;
const userSessionPageId = `${pageId}_user_session`;
const userSignInPageId = `${pageId}_user_sign_in`;
const wingUsagePageId = `${pageId}_wing_usage`;

let adParticipationStates: ConditionalInfiniteContent<bigint, AdParticipationModal, bigint>;
let adReviewStates: ConditionalInfiniteContent<bigint, AdReviewModal, bigint>;
let adReviewLikeStates: ConditionalInfiniteContent<bigint, AdReviewLikeModal, Date>;
let adReviewReportStates: ConditionalInfiniteContent<bigint, AdReviewReportModal, Date>;
let pointUsageStates: ConditionalInfiniteContent<bigint, PointUsageModal, bigint>;
let pointWithdrawalStates: ConditionalInfiniteContent<bigint, PointWithdrawalModal, bigint>;
let productDeliveryStates: ConditionalInfiniteContent<bigint, ProductDeliveryModal, bigint>;
let productReviewStates: ConditionalInfiniteContent<bigint, ProductReviewModal, bigint>;
let productReviewLikeStates: ConditionalInfiniteContent<bigint, ProductReviewLikeModal, Date>;
let productReviewReportStates: ConditionalInfiniteContent<bigint, ProductReviewReportModal, Date>;
let userBlockStates: ConditionalInfiniteContent<bigint, UserBlockModal, number>;
let userSessionStates: ConditionalInfiniteContent<bigint, UserSessionModal, bigint>;
let userSignInStates: ConditionalInfiniteContent<bigint, UserSignInModal, bigint>;
let wingUsageStates: ConditionalInfiniteContent<bigint, WingUsageModal, bigint>;

export default function Detail() {
    const {userId} = useParams() as { userId?: bigint };
    const [user, setUser] = useState<Optional<UserModal>>(null);
    const [lastSignIn, setLastSignIn] = useState<Optional<UserSignInModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    adParticipationStates = useConditionalLongContent(AdParticipationIO.listByUserIdAscending, AdParticipationIO.listByUserIdDescending);
    adReviewStates = useConditionalLongContent(AdReviewIO.listByUserIdAscending, AdReviewIO.listByUserIdDescending);
    adReviewLikeStates = useConditionalDateContent(AdReviewLikeIO.listByUserIdAscending, AdReviewLikeIO.listByUserIdDescending);
    adReviewReportStates = useConditionalDateContent(AdReviewReportIO.listByUserIdAscending, AdReviewReportIO.listByUserIdDescending);
    pointUsageStates = useConditionalLongContent(PointUsageIO.listByUserIdAscending, PointUsageIO.listByUserIdDescending);
    pointWithdrawalStates = useConditionalLongContent(PointWithdrawalIO.listByUserIdAscending, PointWithdrawalIO.listByUserIdDescending);
    productDeliveryStates = useConditionalLongContent(ProductDeliveryIO.listByUserIdAscending, ProductDeliveryIO.listByUserIdDescending);
    productReviewStates = useConditionalLongContent(ProductReviewIO.listByUserIdAscending, ProductReviewIO.listByUserIdDescending);
    productReviewLikeStates = useConditionalDateContent(ProductReviewLikeIO.listByUserIdAscending, ProductReviewLikeIO.listByUserIdDescending);
    productReviewReportStates = useConditionalDateContent(ProductReviewReportIO.listByUserIdAscending, ProductReviewReportIO.listByUserIdDescending);
    userBlockStates = useConditionalIntContent(UserBlockIO.listByUserIdAscending, UserBlockIO.listByUserIdDescending);
    userSessionStates = useConditionalLongContent(UserSessionIO.listByUserIdAscending, UserSessionIO.listByUserIdDescending);
    userSignInStates = useConditionalLongContent(UserSignInIO.listByUserIdAscending, UserSignInIO.listByUserIdDescending);
    wingUsageStates = useConditionalLongContent(WingUsageIO.listByUserIdAscending, WingUsageIO.listByUserIdDescending);

    useEffect(() => {
        M.AutoInit();
        M.Tabs.init(document.querySelectorAll('ul.tabs')).forEach(tab => {
            Logger.log(tab);
        });
    });

    useEffect(() => {
        if (userId) {
            UserIO.get(userId, setUser, setErrorMessage);
        }
    }, [userId]);

    useEffect(() => {
        if (userId) {
            UserSignInIO.getLastByUserId(userId, setLastSignIn, setErrorMessage);
        }
    }, [userId]);

    useEffect(() => {
        if (errorMessage) {
            const modal = MaterialModal.getOrNull('#error_modal');
            modal?.setOnDismiss(() => document.location = '/user/list');
            modal?.open();
        }
    }, [errorMessage]);

    return <PageWrapper
        user={user}
        lastSignIn={lastSignIn}
        errorMessageState={[errorMessage, setErrorMessage]} />;
}

type PageWrapperProps = {
    user: Optional<UserModal>;
    lastSignIn: Optional<UserSignInModal>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const user = props.user;
    if (!user) {
        return <></>;
    }

    const [, setErrorMessage] = props.errorMessageState;
    const barItems: [string, NavBarItemAction][] = [
        ["수정", `/user/${props.user?.id}/edit`],
        ["정지", `/user/block/post?userId=${props.user?.id}`]
    ];
    const tabItems: NavTabItemProps[] = [
        { text: "기본", href: `#${defaultPageId}` },
        { text: "참여한 광고", href: `#${adParticipationPageId}` },
        { text: "작성한 광고 리뷰", href: `#${adReviewPageId}` },
        { text: "좋아요한 광고 리뷰", href: `#${adReviewLikePageId}` },
        { text: "신고한 광고 리뷰", href: `#${adReviewReportPageId}` },
        { text: "포인트 사용", href: `#${pointUsagePageId}` },
        { text: "포인트 인출", href: `#${pointWithdrawalPageId}` },
        { text: "상품 배송", href: `#${productDeliveryPageId}` },
        { text: "작성한 상품 리뷰", href: `#${productReviewPageId}` },
        { text: "좋아요한 상품 리뷰", href: `#${productReviewLikePageId}` },
        { text: "신고한 상품 리뷰", href: `#${productReviewReportPageId}` },
        { text: "이용 정지 이력", href: `#${userBlockPageId}` },
        { text: "세션", href: `#${userSessionPageId}` },
        { text: "로그인", href: `#${userSignInPageId}` },
        { text: "날개 사용", href: `#${wingUsagePageId}` },
    ];

    return <>
        <Nav
            titleHref="/user/list"
            titleIcon="chevron_left"
            title="사용자"
            barItems={barItems}
            tabItems={tabItems}
            errorMessagePair={props.errorMessageState} />
        <DefaultPage user={props.user} lastSignIn={props.lastSignIn} />
        <ContentsPage
            userId={user.id}
            pageId={adParticipationPageId}
            tableHeaders={['ID', '광고 ID', '일치율', '통과', '참여 시각']}
            state={adParticipationStates}
            idSelector={content => content?.id ?? null}
            rowSelector={content => <AdParticipationRow key={content.id.toString()} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={adReviewPageId}
            tableHeaders={['광고 리뷰 ID', '광고 ID', '평점', '작성 시각']}
            state={adReviewStates}
            idSelector={content => content?.id ?? null}
            rowSelector={content => <AdReviewRow key={content.id.toString()} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={adReviewLikePageId}
            tableHeaders={['광고 리뷰 ID', '추가 시각']}
            state={adReviewLikeStates}
            idSelector={content => content?.likedAt ?? null}
            rowSelector={content => <AdReviewLikeRow key={content.likedAt.getTime()} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={adReviewReportPageId}
            tableHeaders={['광고 리뷰 ID', '신고 시각']}
            state={adReviewReportStates}
            idSelector={content => content?.reportedAt ?? null}
            rowSelector={content => <AdReviewReportRow key={content.reportedAt.getTime()} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={pointUsagePageId}
            tableHeaders={['ID', '증감', '마지막 누적 포인트', '구분', '광고 참여 ID', '날개 사용 ID', '생성 시각']}
            state={pointUsageStates}
            idSelector={content => content?.id ?? null}
            rowSelector={content => <PointUsageRow key={content.id.toString()} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={pointWithdrawalPageId}
            tableHeaders={['포인트 사용 ID', '은행 ID', '예금주', '계좌번호', '요청 시각', '취소 시각', '인출 시각', '거절 시각', '거절 사유 ID']}
            state={pointWithdrawalStates}
            idSelector={content => content?.pointUsageId ?? null}
            rowSelector={content => <PointWithdrawalRow key={content.pointUsageId.toString()} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={productDeliveryPageId}
            tableHeaders={['ID', '상품 ID', '수취인', '우편번호', '주소', '배송 요청사항', '요청 시각']}
            state={productDeliveryStates}
            idSelector={content => content?.id ?? null}
            rowSelector={content => <ProductDeliveryRow key={content.id.toString()} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={productReviewPageId}
            tableHeaders={['상품 리뷰 ID', '상품 ID', '평점', '작성 시각']}
            state={productReviewStates}
            idSelector={content => content?.id ?? null}
            rowSelector={content => <ProductReviewRow key={content.id.toString()} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={productReviewLikePageId}
            tableHeaders={['상품 리뷰 ID', '추가 시각']}
            state={productReviewLikeStates}
            idSelector={content => content?.likedAt ?? null}
            rowSelector={content => <ProductReviewLikeRow key={content.likedAt.getTime()} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={productReviewReportPageId}
            tableHeaders={['상품 리뷰 ID', '신고 시각']}
            state={productReviewReportStates}
            idSelector={content => content?.reportedAt ?? null}
            rowSelector={content => <ProductReviewReportRow key={content.reportedAt.getTime()} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={userBlockPageId}
            tableHeaders={['ID', '사유 ID', '시작 시각', '종료 시각']}
            state={userBlockStates}
            idSelector={content => content?.id ?? null}
            rowSelector={content => <UserBlockRow key={content.id} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={userSessionPageId}
            tableHeaders={['ID', '생성 시각', '만료 시각']}
            state={userSessionStates}
            idSelector={content => content?.id ?? null}
            rowSelector={content => <UserSessionRow key={content.id.toString()} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={userSignInPageId}
            tableHeaders={['ID', 'IPv4 주소', 'FCM 토큰', '장치 이름', '플랫폼 버전', '애플리케이션 버전 ID', '로그인 시각']}
            state={userSignInStates}
            idSelector={content => content?.id ?? null}
            rowSelector={content => <UserSignInRow key={content.id.toString()} content={content} />}
            setErrorMessage={setErrorMessage} />
        <ContentsPage
            userId={user.id}
            pageId={wingUsagePageId}
            tableHeaders={['ID', '증감', '마지막 누적 날개', '구분', '광고 참여 ID', '포인트 사용 ID', '생성 시각']}
            state={wingUsageStates}
            idSelector={content => content?.id ?? null}
            rowSelector={content => <WingUsageRow key={content.id.toString()} content={content} />}
            setErrorMessage={setErrorMessage} />
    </>;
}

type DefaultPageProps = {
    user: Optional<UserModal>;
    lastSignIn: Optional<UserSignInModal>;
};

function DefaultPage(props: DefaultPageProps) {
    const user = props.user;
    const lastSignIn = props.lastSignIn;
    if (!user || !lastSignIn) {
        return <></>;
    }

    return <div id={defaultPageId}>
        <div className="row cascade first">
            <DetailCard title="이메일" value={user.email} />
        </div>
        <div className="row cascade">
            <DetailCard title="전화번호" value={user.phone} />
        </div>
        <div className="row cascade">
            <DetailCard title="닉네임" value={user.nickname} />
        </div>
        <div className="row cascade">
            <DetailCard title="계정 이름(실명)" value={user.accountName} />
        </div>
        <div className="row cascade">
            <DetailCard title="추천인" value={user.recommenderId?.toString()} href={`/user/${user.recommenderId}`} />
        </div>
        <div className="row cascade">
            <DetailCard title="성별" value={UserGender.toString(user.gender)} />
        </div>
        <div className="row cascade">
            <DetailCard title="구분" value={UserType.toString(user.type)} />
        </div>
        <div className="row cascade">
            <DetailCard title="서비스 이용약관" value={user.serviceAgreementId.toString()} href="/misc/agreement/list" />
        </div>
        <div className="row cascade">
            <DetailCard title="위치정보서비스약관" value={user.locationAgreementId.toString()} href="/misc/agreement/list" />
        </div>
        <div className="row cascade">
            <DetailCard title="개인정보처리방침" value={user.privacyAgreementId.toString()} href="/misc/agreement/list" />
        </div>
        <div className="row cascade">
            <DetailCard title="이벤트 및 혜택 알림" value={user.commercialNotificationAgreementId?.toString() ?? "동의 안 함"} href="/misc/agreement/list" />
        </div>
        <div className="row cascade">
            <DetailCard title="마지막 로그인" value={lastSignIn.signedInAt.toRowFormat(true, true, true, true)} />
        </div>
        <div className="row cascade">
            <DetailCard title="가입 시각" value={user.signedUpAt.toRowFormat(true, true, true, true)} />
        </div>
        <div className="row cascade">
            <DetailCard title="휴면 전환" value={user.dormantAt?.toRowFormat(true, true, true, true)} />
        </div>
        <div className="row cascade">
            <DetailCard title="탈퇴 시각" value={user.withdrawnAt?.toRowFormat(true, true, true, true)} />
        </div>
        <div className="row cascade">
            <DetailCard title="탈퇴 사유" value={user.withdrawCauseId?.toString()} href="/misc/user/withdraw/cause/list" />
        </div>
    </div>;
}

type ContentsPageProps<T, K> = {
    userId: bigint;
    pageId: string;
    tableHeaders: ({ sort: string } | string)[];
    state: ConditionalInfiniteContent<bigint, T, K>;
    idSelector: (last: Optional<T>) => Optional<K>;
    rowSelector: (content: T) => JSX.Element;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function ContentsPage<T, K>(props: ContentsPageProps<T, K>) {
    const {
        contentAscending,
        contentCount,
        contents,
        defaultLastKeyOnAscending,
        defaultLastKeyOnDescending,
        hasMoreContents,
        isUpdating,
        supplierAscending,
        supplierDescending
    } = props.state;

    const onReady = (response: T[]) => {
        contents.set((contents.value ?? []).appended(response));
        hasMoreContents.set(response.length === 20);
        isUpdating.set(false);
    };

    const onError = (error: string) => {
        props.setErrorMessage(error);
        hasMoreContents.set(false);
        isUpdating.set(false);
    };

    const update = () => {
        isUpdating.set(true);

        const lastKey = props.idSelector(contents.value?.lastOrNull() ?? null);
        if (contentAscending.value) {
            supplierAscending(props.userId, lastKey ?? defaultLastKeyOnAscending, onReady, onError);
        } else {
            supplierDescending(props.userId, lastKey ?? defaultLastKeyOnDescending, onReady, onError);
        }
    };

    const onBottomReached = () => {
        if (isUpdating.value) {
            return;
        }

        update();
    };

    const onWindowScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            onBottomReached();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    });

    useEffect(() => {
        if (!contents.value) {
            onBottomReached();
        }
    }, [contents.value])

    const tableHeaders = props.tableHeaders.map(element => (typeof element !== 'string')
        ? <th key={element.sort} className="clickable" onClick={() => contentAscending.set(!contentAscending.value)}>{element.sort}</th>
        : <th key={element}>{element}</th>
    );
    const rows = (contents.value ?? []).map(props.rowSelector);

    return <div id={props.pageId}>
        <table className="centered highlight">
            <thead><tr>{tableHeaders}</tr></thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents.value} />
        </div>
    </div>;
}

// 광고 참여 ID, 광고 ID, 정확도, 통과, 참여 시각
function AdParticipationRow(props: { content: AdParticipationModal }) {
    const id = props.content.id.toString();
    const adId = props.content.adId;
    const isPassed = props.content.isPassed ? "O" : "X";
    return <tr>
        <td><a href={`/ad/participation/${id}`}>{id}</a></td>
        <td><a href={`/ad/${adId}`}>{adId}</a></td>
        <td>{props.content.accuracy + "%"}</td>
        <td>{isPassed}</td>
        <td>{props.content.participatedAt.toRowFormat(true, true)}</td>
    </tr>;
}

// 광고 리뷰 ID, 광고 ID, 평점, 작성 시각
function AdReviewRow(props: { content: AdReviewModal }) {
    const id = props.content.id.toString();
    const adId = props.content.adId;
    return <tr>
        <td><a href={`/ad/review/${id}`}>{id}</a></td>
        <td><a href={`/ad/${adId}`}>{adId}</a></td>
        <td>{props.content.rating / 2}</td>
        <td>{props.content.writtenAt.toRowFormat(true, true)}</td>
    </tr>;
}

// 광고 리뷰 ID, 추가 시각
function AdReviewLikeRow(props: { content: AdReviewLikeModal }) {
    const adReviewId = props.content.adReviewId.toString();
    return <tr>
        <td><a href={`/ad/review/${adReviewId}`}>{adReviewId}</a></td>
        <td>{props.content.likedAt.toRowFormat(true, true)}</td>
    </tr>;
}

// 광고 리뷰 ID, 신고 시각
function AdReviewReportRow(props: { content: AdReviewReportModal }) {
    const adReviewId = props.content.adReviewId.toString();
    return <tr>
        <td><a href={`/ad/review/${adReviewId}`}>{adReviewId}</a></td>
        <td><a href={`/misc/ad/review/report/cause`}>{props.content.causeId}</a></td>
        <td>{props.content.reportedAt.toRowFormat(true, true)}</td>
    </tr>;
}

// ID, 증감, 마지막 누적 포인트, 구분, 광고 참여 ID, 날개 사용 ID, 생성 시각
function PointUsageRow(props: { content: PointUsageModal }) {
    const id = props.content.id.toString();

    let adParticipation = <td />;
    if (props.content.adParticipationId) {
        const adParticipationId = props.content.adParticipationId.toString();
        adParticipation = <td><a href={`/ad/participation/${adParticipationId}`}>{adParticipationId}</a></td>;
    }

    let wingUsage = <td />;
    if (props.content.wingUsageId) {
        const wingUsageId = props.content.wingUsageId.toString();
        wingUsage = <td><a href={`/wing/usage/${wingUsageId}`}>{wingUsageId}</a></td>;
    }

    return <tr>
        <td><a href={`/point/usage/${id}`}>{id}</a></td>
        <td>{props.content.amount}</td>
        <td>{props.content.lastAmount}</td>
        <td>{PointUsageType.toString(props.content.type)}</td>
        {adParticipation}
        {wingUsage}
        <td>{props.content.createdAt.toRowFormat(true, true)}</td>
    </tr>;
}

// 포인트 사용 ID, 은행 ID, 예금주, 계좌번호, 요청 시각, 취소 시각, 인출 시각, 거절 시각, 거절 사유 ID
function PointWithdrawalRow(props: { content: PointWithdrawalModal }) {
    const pointUsageId = props.content.pointUsageId.toString();

    let canceledAt = <td />;
    if (props.content.canceledAt) {
        canceledAt = <td>{props.content.canceledAt.toRowFormat(true, true)}</td>;
    }

    let withdrawnAt = <td />;
    if (props.content.withdrawnAt) {
        withdrawnAt = <td>{props.content.withdrawnAt.toRowFormat(true, true)}</td>;
    }

    let rejectedAt = <td />;
    if (props.content.rejectedAt) {
        rejectedAt = <td>{props.content.rejectedAt.toRowFormat(true, true)}</td>;
    }

    let rejectedCause = <td />;
    if (props.content.rejectCauseId) {
        rejectedCause = <td>{props.content.rejectCauseId}</td>;
    }

    return <tr>
        <td><a href={`/point/usage/${pointUsageId}`}>{pointUsageId}</a></td>
        <td><a href={'/misc/bank/list'}>{props.content.bankId}</a></td>
        <td>{props.content.accountOwnerName}</td>
        <td>{props.content.accountNumber}</td>
        <td>{props.content.requestedAt.toRowFormat(true, true)}</td>
        {canceledAt}
        {withdrawnAt}
        {rejectedAt}
        {rejectedCause}
    </tr>;
}

// ID, 상품 ID, 수취인, 우편번호, 주소, 배송 요청사항, 요청 시각
function ProductDeliveryRow(props: { content: ProductDeliveryModal }) {
    return <tr>
        <td><a href={`/product/delivery/${props.content.id}`}>{props.content.id.toString()}</a></td>
        <td><a href={`/product/${props.content.productId}`}>{props.content.productId}</a></td>
        <td>{props.content.recipientName}</td>
        <td>{props.content.postcode}</td>
        <td>{props.content.addressPrefix + " " + props.content.addressDetail}</td>
        <td>{props.content.requestMessage}</td>
        <td>{props.content.requestedAt.toRowFormat(true, true)}</td>
    </tr>;
}

// 상품 리뷰 ID, 상품 ID, 평점, 작성 시각
function ProductReviewRow(props: { content: ProductReviewModal }) {
    const id = props.content.id.toString();
    const productId = props.content.productId;
    return <tr>
        <td><a href={`/product/review/${id}`}>{id}</a></td>
        <td><a href={`/product/${productId}`}>{productId}</a></td>
        <td>{props.content.rating / 2}</td>
        <td>{props.content.writtenAt.toRowFormat(true, true)}</td>
    </tr>;
}

// 상품 리뷰 ID, 추가 시각
function ProductReviewLikeRow(props: { content: ProductReviewLikeModal }) {
    const productReviewId = props.content.productReviewId.toString();
    return <tr>
        <td><a href={`/product/review/${productReviewId}`}>{productReviewId}</a></td>
        <td>{props.content.likedAt.toRowFormat(true, true)}</td>
    </tr>;
}

// 상품 리뷰 ID, 신고 시각
function ProductReviewReportRow(props: { content: ProductReviewReportModal }) {
    const productReviewId = props.content.productReviewId.toString();
    return <tr>
        <td><a href={`/product/review/${productReviewId}`}>{productReviewId}</a></td>
        <td><a href={`/misc/product/review/report/cause`}>{props.content.causeId}</a></td>
        <td>{props.content.reportedAt.toRowFormat(true, true)}</td>
    </tr>;
}

// ID, 사유 ID, 시작 시각, 종료 시각
function UserBlockRow(props: { content: UserBlockModal }) {
    return <tr>
        <td>{props.content.id}</td>
        <td><a href='/misc/user/block/cause/list'>{props.content.causeId}</a></td>
        <td>{props.content.blockedAt.toRowFormat(true, true)}</td>
        <td>{props.content.blockedUntil?.toRowFormat(true, true) ?? "무기한"}</td>
    </tr>;
}

// ID, 생성 시각, 만료 시각
function UserSessionRow(props: { content: UserSessionModal }) {
    return <tr>
        <td>{props.content.id.toString()}</td>
        <td>{props.content.createdAt.toRowFormat(true, true, true, true)}</td>
        <td>{props.content.expiredAt.toRowFormat(true, true, true, true)}</td>
    </tr>;
}

// ID, IPv4 주소, FCM 토큰, 장치 이름, 플랫폼 버전, 애플리케이션 버전 ID, 로그인 시각
function UserSignInRow(props: { content: UserSignInModal }) {
    return <tr>
        <td>{props.content.id.toString()}</td>
        <td>{props.content.remoteAddress}</td>
        <td>{props.content.messagingToken}</td>
        <td>{props.content.deviceName}</td>
        <td>{props.content.platformVersion}</td>
        <td><a href='/misc/application/version/list'>{props.content.applicationVersionId}</a></td>
        <td>{props.content.signedInAt.toRowFormat(true, true, true, true)}</td>
    </tr>;
}

// ID, 증감, 마지막 누적 날개, 구분, 광고 참여 ID, 포인트 사용 ID, 생성 시각
function WingUsageRow(props: { content: WingUsageModal }) {
    const id = props.content.id.toString();

    let adParticipation = <td />;
    if (props.content.adParticipationId) {
        const adParticipationId = props.content.adParticipationId.toString();
        adParticipation = <td><a href={`/ad/participation/${adParticipationId}`}>{adParticipationId}</a></td>;
    }

    let pointUsage = <td />;
    if (props.content.pointUsageId) {
        const wingUsageId = props.content.pointUsageId.toString();
        pointUsage = <td><a href={`/point/usage/${wingUsageId}`}>{wingUsageId}</a></td>;
    }

    return <tr>
        <td><a href={`/wing/usage/${id}`}>{id}</a></td>
        <td>{props.content.amount}</td>
        <td>{props.content.lastAmount}</td>
        <td>{WingUsageType.toString(props.content.type)}</td>
        {adParticipation}
        {pointUsage}
        <td>{props.content.createdAt.toRowFormat(true, true)}</td>
    </tr>;
}