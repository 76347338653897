import React, {useEffect, useState} from "react";
import {HomeBannerModal} from "../../../../modal/Miscs";
import {MiscHomeBannerIO} from "../../../../io/Miscs";
import {BaseResponse} from "../../../../util/Reponses";
import {ErrorMessage, Nav} from "../../../Common";
import {MaterialModal} from "../../../Materials";
import {Optional, StatePair} from "../../../../util/Types";

const pageId = 'home_banner_list';
const deleteModalId = `${pageId}_delete_modal`;

export default function List() {
    const [contents, setContents] = useState<Optional<HomeBannerModal[]>>(null);
    const [selectedContent, selectContent] = useState<Optional<HomeBannerModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => M.AutoInit());

    useEffect(() => MiscHomeBannerIO.listAll(response => setContents(response.sort((a, b) => b.id.compareTo(a.id))), setErrorMessage), []);

    useEffect(() => {
        if (selectedContent) {
            MaterialModal.getOrNull(`#${deleteModalId}`)?.open();
        } else {
            MaterialModal.getOrNull(`#${deleteModalId}`)?.close();
        }
    }, [selectedContent]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contents={contents}
            selectedContentState={[selectedContent, selectContent]}
            errorMessageState={[errorMessage, setErrorMessage]} />
    </>;
}

type PageWrapperProps = {
    contents: Optional<HomeBannerModal[]>;
    selectedContentState: StatePair<Optional<HomeBannerModal>>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const [selectedContent, selectContent] = props.selectedContentState;
    const [, setErrorMessage] = props.errorMessageState;
    const rows = (props.contents ?? []).map(content => <Row key={content.id} content={content} selectContent={selectContent} />);
    return <>
        <Nav
            title="홈 배너"
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            errorMessagePair={props.errorMessageState} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>광고 ID</th>
                <th>암기플러스 ID</th>
                <th>공지사항 ID</th>
                <th>표시 순서</th>
                <th>시작 일시</th>
                <th>종료 일시</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="fixed-action-btn">
            <a className="btn-floating btn-large secondary" href='/misc/home/banner/post'><i className="large material-icons">add</i></a>
        </div>
        <DeleteModal selectedContent={selectedContent} setErrorMessage={setErrorMessage} />
    </>;
}

type RowProps = {
    content: HomeBannerModal;
    selectContent: React.Dispatch<Optional<HomeBannerModal>>;
};

function Row(props: RowProps) {
    const content = props.content;
    if (!content) {
        return <tr />;
    }

    return <tr>
        <td>{content.id}</td>
        <td>{content.adId ? <a href={`/ad/${content.adId}`}>{content.adId.toString()}</a> : '-'}</td>
        <td>{content.m10nId ? <a href={`/m10n/${content.m10nId}`}>{content.m10nId.toString()}</a> : '-'}</td>
        <td>{content.noticeId ? <a href={`/misc/notice/${content.noticeId}`}>{content.noticeId.toString()}</a> : '-'}</td>
        <td>{content.sortOrder}</td>
        <td>{content.startAt.toRowFormat(true, true, false, false)}</td>
        <td>{content.endAt.toRowFormat(true, true, false, false)}</td>
        <td>
            <a href={`/misc/home/banner/${content.id}.png`} className="modal-trigger clickable">
                <i className="material-icons black-text">image</i>
            </a>
            <a href={`/misc/home/banner/${content.id}/edit`} className="modal-trigger clickable">
                <i className="material-icons black-text">edit</i>
            </a>
            <a onClick={() => props.selectContent(content)} className="modal-trigger clickable">
                <i className="material-icons black-text">clear</i>
            </a>
        </td>
    </tr>;
}

type DeleteModalProps = {
    selectedContent: Optional<HomeBannerModal>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function DeleteModal(props: DeleteModalProps) {
    const onDeleteClicked = () => {
        if (props.selectedContent) {
            OnDeleteConfirmClicked(props.selectedContent, props.setErrorMessage);
        }
    };

    return <div id={deleteModalId} className="modal">
        <div className="modal-content">
            <h4>삭제</h4>
            <p>이 항목을 삭제하시겠습니까?</p>
        </div>
        <div className="modal-footer">
            <a onClick={onDeleteClicked} className="waves-effect waves-red btn-flat red-text">삭제</a>
            <a className="modal-close waves-effect btn-flat black-text">취소</a>
        </div>
    </div>;
}

function OnDeleteConfirmClicked(
    content: HomeBannerModal,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
) {
    const onReady = (response: BaseResponse) => {
        window.alert('삭제되었습니다.');
        document.location = '/misc/home/banner/list';
    };

    MiscHomeBannerIO.delete(
        content.id,
        onReady,
        setErrorMessage
    );
}