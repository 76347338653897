import {useParams} from "react-router-dom";
import {StorageIO} from "../../io/Services";
import {ProductModal} from "../../modal/Products";
import {Optional} from "../../util/Types";

export default function Image() {
    const {productId, imageType, index} = useParams() as { productId?: number, imageType?: string, index?: number };
    const onError = () => {
       window.alert('잘못된 요청입니다.');
       return <></>;
    };

    if (productId === undefined) {
        return onError();
    }

    let objectKey: Optional<string> = null;
    switch (imageType) {
        case 'header':
            objectKey = ProductModal.headerImagePath(productId, index);
            break;
        case 'description':
            objectKey = ProductModal.descriptionImagePath(productId, index);
            break;
    }

    if (objectKey === null) {
        return onError();
    }

    document.body.style.backgroundColor = "#000000";

    return <div className="page-center">
        <StorageIO.StorageImage
            objectKey={objectKey}
            style={{ maxWidth: '50%', maxHeight: '50%' }} />
    </div>;
}