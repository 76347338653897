import {AgreementModal, AgreementType} from "../../../modal/Miscs";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {ErrorMessage, Nav} from "../../Common";
import {MiscAgreementIO} from "../../../io/Miscs";
import {MaterialModal} from "../../Materials";

export default function List() {
    const [contents, setContents] = useState<Optional<AgreementModal[]>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
    });

    useEffect(() => {
        MiscAgreementIO.listAll(setContents, setErrorMessage);
    }, []);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contents={contents}
            errorMessageState={[errorMessage, setErrorMessage]} />
    </>;
}

type PageWrapperProps = {
    contents: Optional<AgreementModal[]>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const rows = (props.contents ?? []).map(content => <Row key={content.id} content={content} />);
    return <>
        <Nav
            title="약관"
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            errorMessagePair={props.errorMessageState} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>URL</th>
                <th>구분</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="fixed-action-btn">
            <a className="btn-floating btn-large secondary" href='/misc/agreement/post'><i className="large material-icons">add</i></a>
        </div>
    </>;
}

type RowProps = {
    content: AgreementModal;
};

function Row(props: RowProps) {
    const content = props.content;
    if (!content) {
        return <tr />;
    }

    return <tr>
        <td>{content.id}</td>
        <td><a href={content.url}>{content.url}</a></td>
        <td>{AgreementType.toString(content.type)}</td>
    </tr>;
}