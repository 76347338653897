import {Optional} from "../util/Types";
import {UserModal} from "./Users";
import {BankModal} from "./Miscs";

export enum PointUsageType {
    WITHDRAW,
    AD_PARTICIPATION,
    WING_PURCHASE,
    RECOMMENDER,
    RECOMMENDEE,
    GIFTICON,
    ADMIN,
    SIGN_UP,
    ALARM,
    M10N_PARTICIPATION
}

export namespace PointUsageType {
    export function toString(type: PointUsageType): string {
        switch (type) {
            case PointUsageType.WITHDRAW: return "인출";
            case PointUsageType.AD_PARTICIPATION: return "광고 참여";
            case PointUsageType.WING_PURCHASE: return "날개 구매";
            case PointUsageType.RECOMMENDER: return "사용자 추천";
            case PointUsageType.RECOMMENDEE: return "추천인 입력";
            case PointUsageType.GIFTICON: return "기프티콘 구매";
            case PointUsageType.ADMIN: return "관리자 지급";
            case PointUsageType.SIGN_UP: return "회원가입";
            case PointUsageType.ALARM: return "알람 해제";
            case PointUsageType.M10N_PARTICIPATION: return "암기플러스 참여"
        }
    }

    export function values(): string[] {
        return [
            toString(PointUsageType.WITHDRAW),
            toString(PointUsageType.AD_PARTICIPATION),
            toString(PointUsageType.WING_PURCHASE),
            toString(PointUsageType.RECOMMENDER),
            toString(PointUsageType.RECOMMENDEE),
            toString(PointUsageType.GIFTICON),
            toString(PointUsageType.ADMIN),
            toString(PointUsageType.SIGN_UP),
            toString(PointUsageType.ALARM),
            toString(PointUsageType.M10N_PARTICIPATION)
        ];
    }
}

export enum PointWithdrawalStatus {
    WAITING,
    CANCELED,
    WITHDRAWN,
    REJECTED
}

export class PointUsageModal {
    id!: bigint;
    userId!: bigint;
    amount!: number;
    lastAmount!: number;
    type!: PointUsageType;
    adParticipationId!: Optional<bigint>;
    wingUsageId!: Optional<bigint>;
    gifticonPurchaseId!: Optional<bigint>;
    createdAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export type PointWithdrawalModal = {
    pointUsageId: bigint
    userId: bigint
    bankId: number
    accountOwnerName: string
    accountNumber: string
    statusFlags: PointWithdrawalStatus
    requestedAt: Date
    canceledAt: Optional<Date>
    withdrawnAt: Optional<Date>
    rejectedAt: Optional<Date>
    rejectCauseId: Optional<number>
}

export namespace PointWithdrawalModal {
    export function construct(o: any): PointWithdrawalModal {
        return o as PointWithdrawalModal
    }

    export type Qualified = PointWithdrawalModal & {
        pointUsage: PointUsageModal
        user: UserModal
        bank: BankModal
        rejectCause: Optional<PointWithdrawalRejectCauseModal>
    }

    export namespace Qualified {
        export function construct(o: any): PointWithdrawalModal.Qualified {
            return o as PointWithdrawalModal.Qualified
        }

        export function replace(
            present: PointWithdrawalModal.Qualified,
            to: PointWithdrawalModal,
            rejectCause?: PointWithdrawalRejectCauseModal
        ): PointWithdrawalModal.Qualified {
            return {
                pointUsage: present.pointUsage,
                user: present.user,
                bank: present.bank,
                rejectCause: rejectCause ?? present.rejectCause,
                ...to
            }
        }
    }
}

export class PointWithdrawalRejectCauseModal {
    id!: number;
    description!: string;

    constructor(json: object) {
        Object.assign(this, json);
    }
}