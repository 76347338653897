import {sha512} from 'js-sha512';

function applySalt(
    plainText: string,
    salt: string
): string {
    let composite = "";
    const min = Math.min(plainText.length, salt.length);
    for (let i = 0; i < min; i++) {
        composite += plainText[i];
        composite += salt[i];
    }

    composite += plainText.substring(min, plainText.length);
    composite += salt.substring(min, salt.length);

    return composite;
}

function generatePadding(
    length: number = 40,
    char: string = 'P'
): string {
    let padding = "";
    for (let i = 0; i < length; i++) {
        padding += char;
    }

    return padding;
}

function applyPadding(
    plainText: string,
    length: number = 40,
    char: string = 'P'
): string {
    return generatePadding(length - plainText.length, char) + plainText;
}

function getRandomInt(minInclusive: number, maxExclusive: number): number {
    minInclusive = Math.ceil(minInclusive);
    maxExclusive = Math.floor(maxExclusive);
    return Math.floor(Math.random() * (maxExclusive - minInclusive)) + minInclusive; //최댓값은 제외, 최솟값은 포함
}

export function generateSalt(): string {
    let salt = "";
    while (salt.length < 8) {
        const type = getRandomInt(0, 62);
        switch (type) {
            case 0:
                salt += String.fromCharCode(getRandomInt(48, 58));
                break;
            case 1:
                salt += String.fromCharCode(getRandomInt(65, 91));
                break;
            case 2:
                salt += String.fromCharCode(getRandomInt(97, 122));
                break;
        }
    }

    return salt;
}

export function encrypt(plainText: string, salt: string): string {
    const salted = applySalt(plainText, salt);
    const padded = applyPadding(salted);

    return sha512(padded);
}