import React, {useEffect, useState} from "react";
import {Optional, StateWrapper, wrapState} from "../../util/Types";
import {BottomProgress, ErrorMessage, Nav} from "../Common";
import {MaterialModal} from "../Materials";
import {GifticonModal} from "../../modal/Gifticons";
import {getMaxInt} from "../../util/Environments";
import {GifticonIO} from "../../io/Gifticons";

export default function List() {
    const lockPair = wrapState(useState<number>(0));
    const contentsPair = wrapState(useState<Optional<GifticonModal[]>>(null));
    const moreContentsPair = wrapState(useState<boolean>(true));
    const updatingPair = wrapState(useState<boolean>(false));
    const errorMessagePair = wrapState(useState<Optional<ErrorMessage>>(null));

    useEffect(() => {
        M.AutoInit();
        MaterialModal.init();
    });

    useEffect(() => PrepareContents(
        contentsPair,
        moreContentsPair.set,
        updatingPair.set,
        errorMessagePair.set
    ), [lockPair.value]);

    useEffect(() => {
        const onWindowScroll = () => OnWindowScroll(contentsPair, moreContentsPair, updatingPair, errorMessagePair.set);
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    });

    return <PageWrapper contents={contentsPair.value} hasMoreContents={moreContentsPair.value} />;
}

function PrepareContents(
    contentsPair: StateWrapper<Optional<GifticonModal[]>>,
    setMoreContents: React.Dispatch<boolean>,
    setUpdating: React.Dispatch<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const { value: present, set: setContents } = contentsPair;

    const onReady = (contents: GifticonModal[]) => {
        setContents(present?.appended(contents) ?? contents);
        setMoreContents(contents.length === 20);
        setUpdating(false);
    };

    const onError = (error: ErrorMessage) => {
        setErrorMessage(error);
        setMoreContents(false);
        setUpdating(false);
    };

    const lastId = present?.lastOrNull()?.id ?? getMaxInt();
    setUpdating(true);
    GifticonIO.listDescending(
        lastId,
        onReady,
        onError
    );
}

function OnWindowScroll(
    contentsPair: StateWrapper<Optional<GifticonModal[]>>,
    moreContentsPair: StateWrapper<boolean>,
    updatingPair: StateWrapper<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const { value: hasMoreContents, set: setMoreContents } = moreContentsPair;
    const { value: isUpdating, set: setUpdating } = updatingPair;
    if (hasMoreContents && !isUpdating && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        PrepareContents(contentsPair, setMoreContents, setUpdating, setErrorMessage);
    }
}

type PageWrapperProps = {
    contents: Optional<GifticonModal[]>,
    hasMoreContents: boolean
};

function PageWrapper(props: PageWrapperProps) {
    const rows = (props.contents ?? []).map(content => <Row key={content.id} content={content} />);
    return <>
        <Nav
            title="기프티콘"
            titleIcon="chevron_left"
            titleHref="/" />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>브랜드 ID</th>
                <th>제목</th>
                <th>포인트</th>
                <th>등록 수량</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="fixed-action-btn">
            <a className="btn-floating btn-large secondary" href='/gifticon/post'><i className="large material-icons">add</i></a>
        </div>
        <div className="row">
            <BottomProgress hasMoreContents={props.hasMoreContents} />
        </div>
    </>;
}

type RowProps = {
    content: GifticonModal
};

function Row(props: RowProps) {
    let title = props.content.title;
    if (title.length > 20) {
        title = title.substring(0, 17) + "...";
    }

    return <tr>
        <td>{props.content.id}</td>
        <td>{props.content.brandId}</td>
        <td>{title}</td>
        <td>{props.content.point}</td>
        <td>{props.content.amount}</td>
        <td>
            <a href={`/gifticon/${props.content.id}/edit`}><i className="material-icons">edit</i></a>
            <a href={`/gifticon/${props.content.id}/description/list`}><i className="material-icons">list</i></a>
        </td>
    </tr>;
}