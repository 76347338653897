import {Optional} from "../util/Types";

export enum WingUsageType {
    SCHEDULER,
    PURCHASE,
    AD_PARTICIPATION,
    SIGN_UP,
    ADMIN,
    AD_EXTERNAL_URL_VISIT,
    M10N_PARTICIPATION,
    M10N_EXTERNAL_URL_VISIT
}

export namespace WingUsageType {
    export function toString(type: WingUsageType): string {
        switch (type) {
            case WingUsageType.SCHEDULER: return "스케줄러";
            case WingUsageType.PURCHASE: return "날개 구매";
            case WingUsageType.AD_PARTICIPATION: return "광고 참여";
            case WingUsageType.SIGN_UP: return "회원가입";
            case WingUsageType.ADMIN: return "관리자 지급";
            case WingUsageType.AD_EXTERNAL_URL_VISIT: return "외부 연결 URL";
            case WingUsageType.M10N_PARTICIPATION: return "암기플러스 참여"
            case WingUsageType.M10N_EXTERNAL_URL_VISIT: return "암기플러스 외부 연결 URL"
        }
    }

    export function values(): string[] {
        return [
            toString(WingUsageType.SCHEDULER),
            toString(WingUsageType.PURCHASE),
            toString(WingUsageType.AD_PARTICIPATION),
            toString(WingUsageType.SIGN_UP),
            toString(WingUsageType.ADMIN),
            toString(WingUsageType.AD_EXTERNAL_URL_VISIT),
            toString(WingUsageType.M10N_PARTICIPATION),
            toString(WingUsageType.M10N_EXTERNAL_URL_VISIT)
        ];
    }
}

export class WingUsageModal {
    id!: bigint;
    userId!: bigint;
    amount!: number;
    lastAmount!: number;
    type!: WingUsageType;
    adParticipationId!: Optional<bigint>;
    pointUsageId!: Optional<bigint>;
    createdAt!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}
