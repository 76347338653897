import {Optional, wrapState} from "../../../../util/Types";
import React, {useEffect, useState} from "react";
import {ErrorMessage, Nav, NavBarItemAction} from "../../../Common";
import {Component, MaterialInput, MaterialModal} from "../../../Materials";
import {AdTargetRegionIO} from "../../../../io/Ads";
import {useParams} from "react-router-dom";

const pageId = 'ad_target_region_post';
const regionInputId = `${pageId}_region`;

export default function Post() {
    const {adId} = useParams() as { adId?: null };
    const errorMessageState = wrapState(useState<Optional<ErrorMessage>>(null));

    useEffect(() => {
        MaterialModal.init();
        MaterialInput.init();
    });

    useEffect(() => {
        if (errorMessageState.value) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    });

    if (adId === null || adId === undefined) {
        window.alert('잘못된 요청입니다.');
        return <></>;
    }

    const onSaveClick = () => {
        const regionId = MaterialInput.get(`#${regionInputId}`).getValue().toIntOrNull();
        if (regionId === null) {
            errorMessageState.set('ID를 입력해주세요');
            return;
        }

        AdTargetRegionIO.post(adId, regionId, onPosted, onPostError);
    };

    const onPosted = () => {
        window.alert('저장되었습니다.');
        document.location = `/ad/${adId}#ad_detail_target_region`;
    };

    const onPostError = (error: ErrorMessage) => {
        if (typeof error === 'string' && error.includes('Duplicate entry')) {
            errorMessageState.set('이미 등록된 지역입니다.');
        } else {
            errorMessageState.set(error);
        }
    };

    const barItems: [string, NavBarItemAction][] = [
        ['저장', onSaveClick],
        ['취소', () => window.history.back()]
    ];

    return <>
        <Nav
            titleHref='/'
            titleIcon='chevron_left'
            title='대상 지역 추가'
            barItems={barItems}
            errorMessageState={errorMessageState} />
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={regionInputId}
                inputType="number"
                inputPlaceHolder="지역 ID"
                label="지역 ID" />
        </div>
    </>;
}