import M from 'materialize-css';
import {Logger} from "../util/Environments";
import {
    find,
    firstIndexWith,
    IntRange,
    map,
    mapNotNull
} from "../util/Iterators";
import {OnArrayResponse, OnErrorResponse, OnObjectResponse} from "../util/Reponses";
import {Dates} from "../util/Dates";
import {DatePart, Optional, StringConvertable, TimePart} from "../util/Types";
import React, {ChangeEvent} from "react";
import {BitMask, BitMaskFlag} from "../util/BitMask";

interface EnableHolder {
    isEnabled(): boolean;
    enable: () => void;
    disable: () => void;
}

interface ValueHolder {
    getValue: () => string;
    setValue: (value: string) => void;
    clearValue: () => void;
}

export class MaterialComponent<E extends HTMLElement> {
    static enable(...components: Optional<EnableHolder>[]) {
        components.forEach(component => component?.enable());
    }

    static disable(...components: Optional<EnableHolder>[]) {
        components.forEach(component => component?.disable());
    }

    static clearValues(...components: ValueHolder[]) {
        components.forEach(component => component.clearValue());
    }

    protected readonly element: E

    protected constructor(element: E) {
        this.element = element;
    }
}

export class MaterialButton
    extends MaterialComponent<HTMLAnchorElement>
    implements EnableHolder
{
    static getOrNull(
        selectors: string,
        from: ParentNode = document
    ): MaterialButton | null {
        const element = from.querySelector<HTMLAnchorElement>(selectors);
        if (!element) {
            Logger.error(
                'MaterialButton.getOrNull', 'element is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element
            );
            return null;
        }

        return new MaterialButton(element);
    }

    static get(
        selectors: string,
        from: ParentNode = document
    ): MaterialButton {
        return this.getOrNull(selectors, from)!;
    }

    static listAll(
        selectors: string,
        from: ParentNode = document
    ): MaterialButton[] {
        const elements = from.querySelectorAll<HTMLAnchorElement>(selectors);
        return map(elements, element => new MaterialButton(element));
    }

    isEnabled(): boolean {
        return !this.element.classList.contains('disabled');
    }

    enable() {
        this.element.classList.remove('disabled');
    }

    disable() {
        this.element.classList.add('disabled');
    }
}

export class MaterialCheckbox
    extends MaterialComponent<HTMLInputElement>
    implements EnableHolder
{
    static getOrNull(
        selectors: string,
        from: ParentNode = document
    ): MaterialCheckbox | null {
        const element = from.querySelector<HTMLInputElement>(selectors);
        if (!element) {
            Logger.error(
                'MaterialCheckbox.getOrNull', 'element is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element
            );
            return null;
        }

        return new MaterialCheckbox(element);
    }

    static get(
        selectors: string,
        from: ParentNode = document
    ): MaterialCheckbox {
        return this.getOrNull(selectors, from)!
    }

    static listAll(
        selectors: string,
        from: ParentNode = document
    ): MaterialCheckbox[] {
        const elements = from.querySelectorAll<HTMLInputElement>(selectors);
        return map(elements, element => new MaterialCheckbox(element));
    }

    isEnabled(): boolean {
        return !this.element.disabled;
    }

    enable() {
        this.element.disabled = false;
    }

    disable() {
        this.element.disabled = true;
    }

    isChecked(): boolean {
        return this.element.checked;
    }

    check() {
        this.element.checked = true;
    }

    uncheck() {
        this.element.checked = false;
    }

    setCheck(check: boolean): boolean {
        const prevStatus = this.element.checked;
        this.element.checked = check;

        return prevStatus;
    }

    get labelText(): string {
        return this.element.nextElementSibling!.textContent!;
    }
}

export class MaterialCheckboxGroup
    extends MaterialComponent<HTMLFormElement>
    implements EnableHolder
{
    static getOrNull(
        selectors: string,
        from: ParentNode = document
    ): MaterialCheckboxGroup | null {
        const element = from.querySelector<HTMLFormElement>(selectors);
        if (!element) {
            Logger.error(
                'MaterialCheckboxGroup.getOrNull', 'element is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element
            );
            return null;
        }

        return new MaterialCheckboxGroup(element);
    }

    static get(
        selectors: string,
        from: ParentNode = document
    ): MaterialCheckboxGroup {
        return this.getOrNull(selectors, from)!
    }

    static listAll(
        selectors: string,
        from: ParentNode = document
    ): MaterialCheckboxGroup[] {
        const elements = from.querySelectorAll<HTMLFormElement>(selectors);
        return map(elements, element => new MaterialCheckboxGroup(element));
    }

    private readonly checkboxes = MaterialCheckbox.listAll('input', this.element);
    private alreadyDisabled?: MaterialCheckbox[];

    isEnabled(): boolean {
        return this.checkboxes.all(checkbox => checkbox.isEnabled());
    }

    enable(): void {
        const alreadyDisabled = this.alreadyDisabled ?? [];
        for (const checkbox of this.checkboxes) {
            if (!alreadyDisabled.contains(checkbox)) {
                checkbox.enable();
            }
        }
    }

    disable(): void {
        const alreadyDisabled: MaterialCheckbox[] = [];
        for (const checkbox of this.checkboxes) {
            if (!checkbox.isEnabled()) {
                alreadyDisabled.push(checkbox);
            } else {
                checkbox.disable();
            }
        }

        this.alreadyDisabled = alreadyDisabled;
    }

    setBits<E extends BitMaskFlag>(bitMask: BitMask<E> | number) {
        if (typeof bitMask === 'number') {
            this.checkboxes.forEach((checkbox, index) => {
                if ((bitMask & (1 << index)) !== 0) {
                    checkbox.check();
                }
            });
        } else {
            bitMask.forEachEnabled(element => {
                this.checkboxes.find(checkbox => checkbox.labelText === element.name)?.check();
            });
        }
    }

    getBits(): number {
        let bits = 0;
        this.checkboxes.forEach((checkbox, index) => {
            if (checkbox.isChecked()) {
                bits |= 1 << index;
            }
        })

        return bits;
    }
}

export class MaterialDatepicker
    extends MaterialComponent<HTMLInputElement>
    implements EnableHolder, ValueHolder
{
    static init(from: ParentNode = document) {
        const datepickers = from.querySelectorAll('.datepicker');
        const months = map(new IntRange(1, 13), i => `${i}월`);
        const weekdaysShort = ["일", "월", "화", "수", "목", "금", "토"];
        const weekdays = weekdaysShort.map(s => `${s}요일`);
        M.Datepicker.init(datepickers, {
            format: "yyyy-mm-dd",
            i18n: {
                cancel: "취소",
                clear: "지우기",
                done: "확인",
                months: months,
                monthsShort: months,
                weekdays: weekdays,
                weekdaysShort: weekdaysShort,
                weekdaysAbbrev: weekdaysShort
            }
        });
    }

    static getOrNull(
        selectors: string,
        from: ParentNode = document
    ): MaterialDatepicker | null {
        const element = from.querySelector<HTMLInputElement>(selectors);
        if (!element) {
            Logger.error(
                'MaterialDatepicker.getOrNull', 'element is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element
            );
            return null;
        }

        const datepickerInstance = M.Datepicker.getInstance(element);
        if (!datepickerInstance) {
            Logger.error(
                'MaterialDatepicker.getOrNull', 'datepickerInstance is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element,
                'datepickerInstance', datepickerInstance
            );
            return null;
        }

        return new MaterialDatepicker(element, datepickerInstance);
    }

    static get(
        selectors: string,
        from: ParentNode = document
    ): MaterialDatepicker {
        return this.getOrNull(selectors, from)!
    }

    static listAll(
        selectors: string,
        from: ParentNode = document
    ): MaterialDatepicker[] {
        const elements = from.querySelectorAll<HTMLInputElement>(selectors);
        return mapNotNull(elements, element => {
            const datepickerInstance = M.Datepicker.getInstance(element);
            if (!datepickerInstance) {
                Logger.error(
                    'MaterialDatepicker.listAll', 'datepickerInstance is null or undefined',
                    'from', from,
                    'selectors', selectors,
                    'element', element,
                    'datepickerInstance', datepickerInstance
                );
                return null;
            }

            return new MaterialDatepicker(element, datepickerInstance);
        });
    }

    private readonly datepickerInstance: M.Datepicker;

    constructor(element: HTMLInputElement, datepickerInstance: M.Datepicker) {
        super(element);
        this.datepickerInstance = datepickerInstance;
    }

    isEnabled(): boolean {
        return this.element.hasAttribute('disabled');
    }

    enable() {
        this.element.removeAttribute('disabled');
    }

    disable() {
        this.element.setAttribute('disabled', '');
    }

    getValue(): string {
        return this.element.value;
    }

    setValue(value: string, update?: boolean): void {
        this.datepickerInstance.setDate(new Date(value));
        this.datepickerInstance.setInputValue();
        if (update !== false) {
            M.updateTextFields();
        }
    }

    clearValue(): void {
        this.datepickerInstance.setDate(undefined);
        this.datepickerInstance.setInputValue();
    }

    getDate(): Date {
        return new Date(this.getValue());
    }

    getDateOrNull(): Optional<Date> {
        const value = this.getValue();
        if (value.isEmpty() || value.length !== 10) {
            return null;
        } else {
            return this.getDate();
        }
    }

    getDatePart(): [number, number, number] {
        return Dates.parseDateString(this.getValue());
    }

    getDatePartOrNull(requireDecreasedMonth?: boolean): Optional<DatePart> {
        return this.getDate().splitDatePartOrNull(requireDecreasedMonth);
    }
}

export class MaterialDropdown
    extends MaterialComponent<HTMLUListElement>
{
    static init(from: ParentNode = document): M.Dropdown[] {
        const dropdowns = from.querySelectorAll('ul.dropdown-content');
        return M.Dropdown.init(dropdowns);
    }

    static getOrNull(
        selectors: string,
        from: ParentNode = document
    ): MaterialDropdown | null {
        const element = from.querySelector<HTMLUListElement>(selectors);
        if (!element) {
            Logger.error(
                'MaterialDropdown.getOrNull', 'element is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element
            );
            return null;
        }

        const dropdownInstance = M.Dropdown.getInstance(element);
        if (!dropdownInstance) {
            Logger.error(
                'MaterialDropdown.getOrNull', 'dropdownInstance is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element,
                'dropdownInstance', dropdownInstance
            );
            return null;
        }

        return new MaterialDropdown(element, dropdownInstance);
    }

    static get(
        selectors: string,
        from: ParentNode = document
    ): MaterialDropdown {
        return this.getOrNull(selectors, from)!
    }

    static listAll(
        selectors: string,
        from: ParentNode = document
    ): MaterialDropdown[] {
        const elements = from.querySelectorAll<HTMLUListElement>(selectors);
        return mapNotNull(elements, element => {
            const dropdownInstance = M.Dropdown.getInstance(element);
            if (!dropdownInstance) {
                Logger.error(
                    'MaterialDropdown.listAll', 'dropdownInstance is null or undefined',
                    'from', from,
                    'selectors', selectors,
                    'element', element,
                    'dropdownInstance', dropdownInstance
                );
                return null;
            }

            return new MaterialDropdown(element, dropdownInstance);
        });
    }

    private readonly dropdownInstance: M.Dropdown;

    constructor(element: HTMLUListElement, dropdownInstance: M.Dropdown) {
        super(element);
        this.dropdownInstance = dropdownInstance;
    }
}

export class MaterialInput
    extends MaterialComponent<HTMLInputElement>
    implements EnableHolder, ValueHolder
{
    static init(from: ParentNode = document) {
        const elements = from.querySelectorAll<HTMLInputElement>('input[data-length]');
        M.CharacterCounter.init(elements);
    }

    static getOrNull(
        selectors: string,
        from: ParentNode = document
    ): MaterialInput | null {
        const element = from.querySelector<HTMLInputElement>(selectors);
        if (!element) {
            Logger.error(
                'MaterialInput.getOrNull', 'element is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element
            );
            return null;
        }

        return new MaterialInput(element);
    }

    static get(
        selectors: string,
        from: ParentNode = document
    ): MaterialInput {
        return this.getOrNull(selectors, from)!
    }

    static listAll(
        selectors: string,
        from: ParentNode = document
    ): MaterialInput[] {
        const elements = from.querySelectorAll<HTMLInputElement>(selectors);
        return map(elements, element => new MaterialInput(element));
    }

    isEnabled(): boolean {
        return this.element.hasAttribute('disabled');
    }

    setEnabled(enable: boolean) {
        if (enable) {
            this.enable();
        } else {
            this.disable();
        }
    }

    enable() {
        this.element.removeAttribute('disabled');
        MaterialButton.getOrNull('div.btn', this.element)?.enable();
    }

    disable() {
        this.element.setAttribute('disabled', '');
        this.element.classList.remove('valid');
        MaterialButton.getOrNull('div.btn', this.element)?.disable();
    }

    getValue(): string {
        return this.element.value;
    }

    setValue(value: string, update?: boolean, clearIfEmpty?: boolean) {
        if (value.isEmpty() && clearIfEmpty === true) {
            this.clearValue();
        } else {
            this.element.value = value;
        }
        if (update !== false) {
            M.updateTextFields();
        }
    }

    clearValue() {
        this.element.value = "";
        this.element.classList.remove('valid');

        const label = this.element.nextElementSibling;
        if (label) {
            label.classList.remove('active');
        }
    }

    getFileLength(): number {
        Logger.log('length', this.element.files?.length);
        return this.element.files?.length ?? 0;
    }

    readFileBuffer(
        onReady: OnObjectResponse<ArrayBuffer>,
        onError: OnErrorResponse
    ) {
        const files = this.element.files;
        if (!files || files.length === 0) {
            onError('선택된 파일이 없습니다.');
            return;
        }

        files[0].arrayBuffer()
            .then(onReady)
            .catch(reason => onError(`MaterialInput.readFileBuffer: ${reason}`));
    }

    readFileBuffers(
        onReady: OnArrayResponse<ArrayBuffer>,
        onError: OnErrorResponse
    ) {
        const files = this.element.files;
        if (!files) {
            onReady([]);
            return;
        }

        MaterialInput.readFileBuffersRecursive(files, 0, [], onReady, onError);
    }

    private static readFileBuffersRecursive(
        files: FileList,
        index: number,
        accumulator: ArrayBuffer[],
        onReady: OnArrayResponse<ArrayBuffer>,
        onError: OnErrorResponse
    ) {
        if (index === files.length) {
            onReady(accumulator);
            return;
        }

        files[index].arrayBuffer()
            .then(content => {
                accumulator.push(content);
                this.readFileBuffersRecursive(files, index + 1, accumulator, onReady, onError);
            })
            .catch(reason => onError(`MaterialInput.readFileBuffersRecursive: ${reason}`));
    }
}

export class MaterialModal
    extends MaterialComponent<HTMLDivElement>
{
    static init(from: ParentNode = document) {
        const modals = from.querySelectorAll('.modal');
        const instances = M.Modal.init(modals);
        Logger.log('MaterialModal.init',
            'modals', modals,
            'instances', instances
        );
    }

    static getOrNull(
        selectors: string,
        from: ParentNode = document
    ): MaterialModal | null {
        const element = from.querySelector<HTMLDivElement>(selectors);
        if (!element) {
            Logger.error(
                'MaterialModal.getOrNull', 'element is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element
            );
            return null;
        }

        const modalInstance = M.Modal.getInstance(element);
        if (!modalInstance) {
            Logger.error(
                'MaterialModal.getOrNull', 'modalInstance is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element,
                'modalInstance', modalInstance
            );
            return null;
        }

        return new MaterialModal(element, modalInstance);
    }

    static get(
        selectors: string,
        from: ParentNode = document
    ): MaterialModal {
        return this.getOrNull(selectors, from)!
    }

    static listAll(
        selectors: string,
        from: ParentNode = document
    ): MaterialModal[] {
        const elements = from.querySelectorAll<HTMLDivElement>(selectors);
        return mapNotNull(elements, element => {
            const modalInstance = M.Modal.getInstance(element);
            if (!modalInstance) {
                Logger.error(
                    'MaterialModal.listAll', 'modalInstance is null or undefined',
                    'from', from,
                    'selectors', selectors,
                    'element', element,
                    'modalInstance', modalInstance
                );
                return null;
            }

            return new MaterialModal(element, modalInstance);
        });
    }

    private readonly modalInstance: M.Modal;
    private readonly footerButtons: MaterialButton[];

    private constructor(element: HTMLDivElement, modalInstance: M.Modal) {
        super(element);
        this.modalInstance = modalInstance;
        this.footerButtons = MaterialButton.listAll('div.modal-footer > a', element);
    }

    open() {
        if (!this.modalInstance.isOpen) {
            this.modalInstance.open();
        }
    }

    close() {
        this.modalInstance.close();
    }

    setVisibility(visible: boolean) {
        if (visible) {
            this.open()
        } else {
            this.close()
        }
    }

    enableFooterButtons() {
        MaterialComponent.enable(...this.footerButtons);
    }

    disableFooterButtons() {
        MaterialComponent.disable(...this.footerButtons);
    }

    setOnDismiss(listener: () => void) {
        this.modalInstance.options.onCloseStart = listener;
    }

    setOnButtonClicked(text: string, listener: () => void) {
        const elements = this.element.querySelectorAll<HTMLAnchorElement>('.modal-footer > a');
        find(elements, element => element.innerText === text)?.addEventListener('click', listener);
    }

    isCancelable(): boolean {
        return this.modalInstance.options.dismissible;
    }

    setCancelable(cancelable: boolean) {
        this.modalInstance.options.dismissible = cancelable;
    }

    isPreventScrolling(): boolean {
        return this.modalInstance.options.preventScrolling;
    }

    setPreventScrolling(preventScrolling: boolean) {
        this.modalInstance.options.preventScrolling = preventScrolling;
    }
}

export class MaterialProgressBar
    extends MaterialComponent<HTMLDivElement>
{
    static getOrNull(
        selectors: string,
        from: ParentNode = document
    ): MaterialProgressBar | null {
        const element = from.querySelector<HTMLDivElement>(selectors);
        if (!element) {
            Logger.error(
                'MaterialProgressBar.getOrNull', 'element is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element
            );
            return null;
        }

        return new MaterialProgressBar(element);
    }

    static get(
        selectors: string,
        from: ParentNode = document
    ): MaterialProgressBar {
        return this.getOrNull(selectors, from)!;
    }

    static listAll(
        selectors: string,
        from: ParentNode = document
    ): MaterialProgressBar[] {
        const elements = from.querySelectorAll<HTMLDivElement>(selectors);
        return map(elements, element => new MaterialProgressBar(element));
    }

    show() {
        this.element.classList.remove('hide');
    }

    hide() {
        this.element.classList.add('hide');
    }
}

export class MaterialSelectWrapper
    extends MaterialComponent<HTMLDivElement>
    implements EnableHolder, ValueHolder
{
    static init(
        from: ParentNode = document
    ): M.FormSelect[] {
        const elements = from.querySelectorAll('select');
        return M.FormSelect.init(elements);
    }

    static getOrNull(
        selectors: string,
        from: ParentNode = document
    ): MaterialSelectWrapper | null {
        const element = from.querySelector<HTMLDivElement>(selectors);
        if (!element) {
            Logger.error(
                'MaterialSelectWrapper.getOrNull', 'element is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element
            );
            return null;
        }

        const selector = element.querySelector('select');
        if (!selector) {
            Logger.error(
                'MaterialSelectWrapper.getOrNull', 'selector is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element,
                'selector', selector
            );
            return null;
        }

        const selectInstance = M.FormSelect.getInstance(selector);
        if (!selectInstance) {
            Logger.error(
                'MaterialSelectWrapper.getOrNull', 'selectInstance is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element,
                'selector', selector,
                'selectInstance', selectInstance
            );
            return null;
        }

        return new MaterialSelectWrapper(element, selectInstance);
    }

    static get(
        selectors: string,
        from: ParentNode = document
    ): MaterialSelectWrapper {
        return this.getOrNull(selectors, from)!;
    }

    static listAll(
        selectors: string,
        from: ParentNode = document
    ): MaterialSelectWrapper[] {
        const elements = from.querySelectorAll<HTMLDivElement>(selectors);
        return mapNotNull(elements, element => {
            const selector = element.querySelector('select');
            if (!selector) {
                Logger.error(
                    'MaterialSelectWrapper.listAll', 'selector is null or undefined',
                    'from', from,
                    'selectors', selectors,
                    'element', element,
                    'selector', selector
                );
                return null;
            }

            const selectInstance = M.FormSelect.getInstance(selector);
            if (!selectInstance) {
                Logger.error(
                    'MaterialSelectWrapper.listAll', 'selectInstance is null or undefined',
                    'from', from,
                    'selectors', selectors,
                    'element', element,
                    'selector', selector,
                    'selectInstance', selectInstance
                );
                return null;
            }

            return new MaterialSelectWrapper(element, selectInstance);
        });
    }

    private selectInstance: M.FormSelect

    constructor(element: HTMLDivElement, selectInstance: M.FormSelect) {
        super(element);
        this.selectInstance = selectInstance;
    }

    getValue(): string {
        return this.getFirstSelection()!;
    }

    setValue(value: string) {
        this.clearValue(false);
        const listElements = this.element.querySelectorAll<HTMLLIElement>('ul.dropdown-content.select-dropdown > li')
        const optionElements = this.element.querySelectorAll<HTMLSpanElement>('ul.dropdown-content.select-dropdown li > span');
        const index = firstIndexWith(optionElements, element => element.innerText === value);
        if (index === -1) {
            return;
        }

        this.element.querySelectorAll('select option')[index].setAttribute('selected', '');
        listElements[index].classList.add('selected');
    }

    clearValue(setDefault?: boolean) {
        this.element
            .querySelectorAll('ul.dropdown-content.select-dropdown > li')
            .forEach(element => element.classList.remove('selected'));
        this.element
            .querySelectorAll('select option[selected]')
            .forEach(element => element.removeAttribute('selected'));
        if (setDefault === false) {
            return;
        }

        const defaultOptionElement = this.element.querySelector<HTMLOptionElement>('select > option[value=""]');
        if (defaultOptionElement) {
            this.element
                .querySelectorAll<HTMLLIElement>('ul.dropdown-content.select-dropdown > li')
                .item(defaultOptionElement.index)
                .classList
                .add('selected');
        }
    }

    getSelectedValues(): string[] {
        const selectedElements = this.element.querySelectorAll<HTMLSpanElement>('li.selected > span');
        return map(selectedElements, element => element.innerText);
    }

    getSelectedValuesAs<R>(transform: (innerText: string) => R): R[] {
        const selectedElements = this.element.querySelectorAll<HTMLSpanElement>('li.selected > span');
        return map(selectedElements, element => transform(element.innerText));
    }

    getFirstSelection(): string | null {
        const selectedValues = this.getSelectedValues();
        const selectedValue = selectedValues[0];
        if (!selectedValue) {
            Logger.error(
                'Materials.MaterialSelectWrapper.getFirstSelection', 'selectedValue is null or undefined',
                'selectedValues', selectedValues,
                'selectedValue', selectedValue
            );
            return null;
        }

        return selectedValue;
    }

    getFirstSelectionAs<R>(transform: (innerText: string) => R): R | null {
        const selectedValue = this.getFirstSelection();
        if (!selectedValue) {
            return null;
        }

        return transform(selectedValue);
    }

    isEnabled(): boolean {
        return this.element.hasAttribute('disabled');
    }

    enable() {
        this.element.removeAttribute('disabled');
    }

    disable() {
        this.element.setAttribute('disabled', '');
    }
}

export class MaterialSidenav
    extends MaterialComponent<HTMLUListElement>
{
    static getOrNull(
        selectors: string,
        from: ParentNode = document
    ): MaterialSidenav | null {
        const element = from.querySelector<HTMLUListElement>(selectors);
        if (!element) {
            Logger.error(
                'MaterialSidenav.getOrNull', 'element is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element
            );
            return null;
        }

        const sidenavInstance = M.Sidenav.getInstance(element);
        if (!sidenavInstance) {
            Logger.error(
                'MaterialSidenav.getOrNull', 'sidenavInstance is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element,
                'selectInstance', sidenavInstance
            );
            return null;
        }

        return new MaterialSidenav(element, sidenavInstance);
    }

    static get(
        selectors: string,
        from: ParentNode = document
    ): MaterialSidenav {
        return this.getOrNull(selectors, from)!;
    }

    static listAll(
        selectors: string,
        from: ParentNode = document
    ): MaterialSidenav[] {
        const elements = from.querySelectorAll<HTMLUListElement>(selectors);
        return mapNotNull(elements, element => {
            const selector = element.querySelector('select');
            if (!selector) {
                Logger.error(
                    'MaterialSidenav.listAll', 'selector is null or undefined',
                    'from', from,
                    'selectors', selectors,
                    'element', element,
                    'selector', selector
                );
                return null;
            }

            const sidenavInstance = M.Sidenav.getInstance(element);
            if (!sidenavInstance) {
                Logger.error(
                    'MaterialSidenav.listAll', 'sidenavInstance is null or undefined',
                    'from', from,
                    'selectors', selectors,
                    'element', element,
                    'selectInstance', sidenavInstance
                );
                return null;
            }

            return new MaterialSidenav(element, sidenavInstance);
        });
    }

    private readonly sidenavInstance: M.Sidenav;

    constructor(element: HTMLUListElement, sidenavInstance: M.Sidenav) {
        super(element);
        this.sidenavInstance = sidenavInstance;
    }

    open() {
        if (!this.sidenavInstance.isOpen) {
            this.sidenavInstance.open();
        }
    }

    close() {
        this.sidenavInstance.close();
    }
}

export class MaterialTextarea
    extends MaterialComponent<HTMLInputElement>
    implements EnableHolder, ValueHolder
{
    static init(from: ParentNode = document) {
        const elements = from.querySelectorAll<HTMLTextAreaElement>('textarea[data-length]');
        M.CharacterCounter.init(elements);
    }

    static getOrNull(
        selectors: string,
        from: ParentNode = document
    ): MaterialTextarea | null {
        const element = from.querySelector<HTMLInputElement>(selectors);
        if (!element) {
            Logger.error(
                'MaterialInput.getOrNull', 'element is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element
            );
            return null;
        }

        return new MaterialTextarea(element);
    }

    static get(
        selectors: string,
        from: ParentNode = document
    ): MaterialTextarea {
        return this.getOrNull(selectors, from)!
    }

    static listAll(
        selectors: string,
        from: ParentNode = document
    ): MaterialTextarea[] {
        const elements = from.querySelectorAll<HTMLInputElement>(selectors);
        return map(elements, element => new MaterialTextarea(element));
    }

    isEnabled(): boolean {
        return this.element.hasAttribute('disabled');
    }

    enable() {
        this.element.removeAttribute('disabled');
    }

    disable() {
        this.element.setAttribute('disabled', '');
    }

    getValue(): string {
        return this.element.value;
    }

    setValue(value: string) {
        this.element.value = value;
        M.textareaAutoResize(this.element);
    }

    clearValue() {
        this.element.value = "";
        this.element.classList.remove('valid');

        const label = this.element.nextElementSibling;
        if (label) {
            label.classList.remove('active');
        }
    }
}

export class MaterialTimepicker
    extends MaterialComponent<HTMLInputElement>
    implements EnableHolder, ValueHolder
{
    static init(from: ParentNode = document) {
        const timepickers = from.querySelectorAll('.timepicker');
        M.Timepicker.init(timepickers, { twelveHour: false });
    }

    static getOrNull(
        selectors: string,
        from: ParentNode = document
    ): MaterialTimepicker | null {
        const element = from.querySelector<HTMLInputElement>(selectors);
        if (!element) {
            Logger.error(
                'MaterialTimepicker.getOrNull', 'element is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element
            );
            return null;
        }

        const timepickerInstance = M.Timepicker.getInstance(element);
        if (!timepickerInstance) {
            Logger.error(
                'MaterialTimepicker.getOrNull', 'timepickerInstance is null or undefined',
                'from', from,
                'selectors', selectors,
                'element', element,
                'timepickerInstance', timepickerInstance
            );
            return null;
        }

        return new MaterialTimepicker(element, timepickerInstance);
    }

    static get(
        selectors: string,
        from: ParentNode = document
    ): MaterialTimepicker {
        return this.getOrNull(selectors, from)!
    }

    static listAll(
        selectors: string,
        from: ParentNode = document
    ): MaterialTimepicker[] {
        const elements = from.querySelectorAll<HTMLInputElement>(selectors);
        return mapNotNull(elements, element => {
            const timepickerInstance = M.Timepicker.getInstance(element);
            if (!timepickerInstance) {
                Logger.error(
                    'MaterialDatepicker.listAll', 'timepickerInstance is null or undefined',
                    'from', from,
                    'selectors', selectors,
                    'element', element,
                    'timepickerInstance', timepickerInstance
                );
                return null;
            }

            return new MaterialTimepicker(element, timepickerInstance);
        });
    }

    private readonly timepickerInstance: M.Timepicker;

    constructor(element: HTMLInputElement, timepickerInstance: M.Timepicker) {
        super(element);
        this.timepickerInstance = timepickerInstance;
    }

    isEnabled(): boolean {
        return this.element.hasAttribute('disabled');
    }

    enable() {
        this.element.removeAttribute('disabled');
    }

    disable() {
        this.element.setAttribute('disabled', '');
    }

    getValue(): string {
        return this.element.value;
    }

    setValue(value: string, update?: boolean): void {
        this.element.value = value;
        this.timepickerInstance.time = value;
        if (update !== false) {
            M.updateTextFields();
        }
    }

    clearValue(): void {
        this.timepickerInstance.time = "00:00";
    }

    getTimePart(): [number, number, number, number] {
        return Dates.parseTimeString(this.getValue());
    }

    getTimePartOrNull(): Optional<TimePart> {
        const value = this.getValue();
        if (value.isEmpty() || value.length !== 5 || value[2] !== ':') {
            return null;
        }

        const [hourString, minuteString] = value.split(":");
        const hour = hourString.toIntOrNull();
        const minute = minuteString.toIntOrNull();
        if (hour === null || minute === null) {
            return null
        } else {
            return { hour, minute, second: 0, millis: 0 };
        }
    }
}

export namespace Component {
    type CheckboxProps = {
        formClasses?: string;
        formRequired?: boolean;
        checked?: boolean;
        disabled?: boolean;
        inputId: string;
        onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
        text: string;
    };

    export function Checkbox(props: CheckboxProps) {
        const formChildren = <p>
            <label>
                <input
                    id={props.inputId}
                    type="checkbox"
                    // TODO 초깃값
                    // checked={props.checked === true}
                    disabled={props.disabled === true}
                    onChange={(event) => props.onChange?.(event)} />
                <span>{props.text}</span>
            </label>
        </p>;
        if (props.formRequired === false) {
            return formChildren;
        } else {
            return <form className={props.formClasses}>{formChildren}</form>;
        }
    }

    type CheckboxGroupProps<V extends StringConvertable> = {
        formId: string;
        formClasses?: string;
        values: ReadonlyArray<V>;
        checkedIndices?: number[];
        disabledIndices?: number[];
        onChanges?: ReadonlyMap<number, (event: ChangeEvent<HTMLInputElement>) => void>;
    };

    export function CheckboxGroup<V extends StringConvertable>(props: CheckboxGroupProps<V>) {
        const checkboxes = props.values.map((value, index) => {
            const valueString = value.toString();
            const id = `${props.formId}_${index}`
            const checked = (props.checkedIndices ?? []).contains(index, true);
            const disabled = (props.disabledIndices ?? []).contains(index, true);
            const onChange = props.onChanges?.get(index) ?? (() => {
                Logger.log('onChange is not set!');
            });
            return <>
                <Checkbox
                    key={index}
                    formRequired={false}
                    checked={checked}
                    disabled={disabled}
                    inputId={id}
                    text={valueString}
                    onChange={onChange} />
            </>;
        });

        return <form id={props.formId} className={"checkbox-group " + (props.formClasses ?? "")}>{checkboxes}</form>;
    }

    type DatepickerProps = {
        formClasses?: string;
        fieldClasses?: string;
        inputId: string;
        enabled?: boolean;
        label: string;
    };

    export function Datepicker(props: DatepickerProps) {
        return <>
            <Input
                formClasses={props.formClasses}
                fieldClasses={props.fieldClasses}
                inputId={props.inputId}
                inputClasses="datepicker"
                enabled={props.enabled}
                label={props.label} />
        </>;
    }

    type DropdownProps = {
        uListId: string;
        uListClasses?: string;
        items: { text?: string; icon?: string; action?: string | (() => void); divider?: boolean }[];
    };

    export function Dropdown(props: DropdownProps) {
        const listItems = props.items.map((item, index) => {
            if (item.divider === true) {
                return <li key={index} className="divider" tabIndex={-1}></li>;
            } else {
                const icon = (item.icon)
                    ? <i className="material-icons">{item.icon}</i>
                    : <></>;
                if (!item.action) {
                    return <li key={index}><a>{icon}{item.text}</a></li>;
                } else if (typeof item.action === 'string') {
                    return <li key={index}><a href={item.action}>{icon}{item.text}</a></li>;
                } else {
                    return <li key={index}><a onClick={item.action}>{icon}{item.text}</a></li>;
                }
            }
        });

        return <>
            <ul id={props.uListId} className={"dropdown-content " + ((props.uListClasses) ? props.uListClasses : "")}>
                {listItems}
            </ul>
        </>;
    }

    type FileInputProps = {
        formClasses?: string;
        fieldClasses?: string;
        buttonId?: string;
        inputId: string;
        inputLabel: string;
        enabled?: boolean;
        multiple?: boolean;
        accept: string;
    }

    export function FileInput(props: FileInputProps) {
        return <>
            <form className={props.formClasses}>
                <div className={"file-field input-field " + (props.fieldClasses ?? "")}>
                    <div id={props.buttonId} className={"btn " + ((props.enabled === false) ? "disabled" : "")} >
                        <span>{props.inputLabel}</span>
                        <input id={props.inputId} type="file" accept={props.accept} disabled={props.enabled === false} multiple={props.multiple === true}/>
                    </div>
                    <div className="file-path-wrapper">
                        <input className="file-path validate" type="text" disabled={props.enabled === false}/>
                    </div>
                </div>
            </form>
        </>;
    }

    type InputProps = {
        formClasses?: string;
        fieldClasses?: string;
        inputId: string;
        inputType?: string;
        inputClasses?: string;
        inputPlaceHolder?: string;
        inputMax?: number | string;
        inputDataLength?: number;
        value?: string;
        enabled?: boolean;
        label: string;
    };

    export function Input(props: InputProps) {
        let input: JSX.Element;
        if (props.enabled === false) {
            input = <>
                <input
                    id={props.inputId}
                    type={props.inputType}
                    className={"input-field " + (props.inputClasses ?? "")}
                    placeholder={props.inputPlaceHolder}
                    max={props.inputMax}
                    data-length={props.inputDataLength?.toString()}
                    maxLength={props.inputDataLength}
                    disabled
                    value={props.value} />
            </>;
        } else {
            input = <>
                <input
                    id={props.inputId}
                    type={props.inputType}
                    className={"input-field " + (props.inputClasses ?? "")}
                    placeholder={props.inputPlaceHolder}
                    max={props.inputMax}
                    data-length={props.inputDataLength?.toString()}
                    maxLength={props.inputDataLength}
                    value={props.value} />
            </>;
        }

        return <>
            <form className={props.formClasses}>
                <div className={props.fieldClasses}>
                    {input}
                    <label htmlFor={props.inputId}>{props.label}</label>
                </div>
            </form>
        </>;
    }

    type SelectProps = {
        values: string[];

        wrapperId: string;
        wrapperClasses?: string;

        selectId: string;
        requireDefault?: boolean;
        selectDefaultText?: string;

        label: string;
        onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
    };

    export function Select(props: SelectProps) {
        let defaultOption: JSX.Element;
        if (props.requireDefault === false) {
            defaultOption = <></>;
        } else {
            defaultOption = <option value="" disabled>{props.selectDefaultText ?? "선택"}</option>;
        }

        const options = props.values.map((element, index) => <option key={element} value={index}>{element}</option>);
        return <>
            <div id={props.wrapperId} className={"input-field " + (props.wrapperClasses?.toString() ?? "")}>
                <select id={props.selectId} defaultValue="" onChange={props.onChange}>
                    {defaultOption}
                    {options}
                </select>
                <label>{props.label}</label>
            </div>
        </>;
    }

    type TextAreaProps = {
        formClasses?: string;
        fieldClasses?: string;
        textareaId: string;
        textareaClasses?: string;
        textareaPlaceHolder?: string;
        textareaDataLength?: number;
        value?: string;
        enabled?: boolean;
        label: string;
    };

    export function Textarea(props: TextAreaProps) {
        let textarea: JSX.Element;
        if (props.enabled === false) {
            textarea = <>
                <textarea
                    id={props.textareaId}
                    className={"input-field materialize-textarea " + (props.textareaClasses ?? "")}
                    placeholder={props.textareaPlaceHolder}
                    data-length={props.textareaDataLength?.toString()}
                    maxLength={props.textareaDataLength}
                    disabled
                    value={props.value} />
            </>;
        } else {
            textarea = <>
                <textarea
                    id={props.textareaId}
                    className={"input-field materialize-textarea " + (props.textareaClasses ?? "")}
                    placeholder={props.textareaPlaceHolder}
                    data-length={props.textareaDataLength?.toString()}
                    maxLength={props.textareaDataLength}
                    value={props.value} />
            </>;
        }

        return <>
            <form className={props.formClasses}>
                <div className={props.fieldClasses}>
                    {textarea}
                    <label htmlFor={props.textareaId}>{props.label}</label>
                </div>
            </form>
        </>;
    }

    type TimepickerProps = {
        formClasses?: string;
        fieldClasses?: string;
        inputId: string;
        enabled?: boolean;
        label: string;
    };

    export function Timepicker(props: TimepickerProps) {
        return <>
            <Input
                formClasses={props.formClasses}
                fieldClasses={props.fieldClasses}
                inputId={props.inputId}
                inputClasses="timepicker"
                enabled={props.enabled}
                label={props.label} />
        </>;
    }
}