import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {UserModal} from "../../../modal/Users";
import {ErrorMessage, Nav} from "../../Common";
import {UserIO} from "../../../io/Users";
import {getMaxLong} from "../../../util/Environments";

export default function List() {
    const [users, setUsers] = useState<Optional<UserModal[]>>(null);
    const [hasMoreContents, setMoreContents] = useState<boolean>(true);
    const [isUpdating, setUpdating] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
    });

    useEffect(() => {
        const lastId = users?.lastOrNull()?.id ?? getMaxLong();
        UserIO.listWithdrawnDescending(lastId, setUsers, setErrorMessage);
    }, []);

    useEffect(() => {
        const onWindowScroll = () => OnWindowScroll(
            [users, setUsers],
            [hasMoreContents, setMoreContents],
            [isUpdating, setUpdating],
            setErrorMessage
        );
        window.addEventListener('scroll', onWindowScroll);
        return function clean() {
            window.removeEventListener('scroll', onWindowScroll);
        }
    });

    return <PageWrapper users={users} errorMessageState={[errorMessage, setErrorMessage]} />
}

function PrepareContents(
    contentsState: StatePair<Optional<UserModal[]>>,
    moreContentsState: StatePair<boolean>,
    updatingState: StatePair<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const [present, setContents] = contentsState;
    const [, setMoreContents] = moreContentsState;

    const onResponse = (contents: UserModal[]) => {
        setContents((present ?? []).appended(contents));
        setMoreContents(contents.length === 20);
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setMoreContents(false);
    };

    const lastId = present?.lastOrNull()?.id ?? getMaxLong();
    UserIO.listWithdrawnDescending(lastId, onResponse, onError);
}

function OnWindowScroll(
    contentsState: StatePair<Optional<UserModal[]>>,
    moreContentsState: StatePair<boolean>,
    updatingState: StatePair<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const [isUpdating] = updatingState;
    if (!isUpdating && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // 업데이트중이지 않고 바닥까지 스크롤되었을 때
        PrepareContents(contentsState, moreContentsState, updatingState, setErrorMessage);
    }
}

type PageWrapperProps = {
    users: Optional<UserModal[]>,
    errorMessageState: StatePair<Optional<ErrorMessage>>
};

function PageWrapper(props: PageWrapperProps) {
    const rows = props?.users?.map(user => <Row key={user.id.toString()} user={user} />) ?? [];

    return <>
        <Nav
            title="탈퇴"
            titleIcon={"chevron_left"}
            titleHref={"/user/list"}
            errorMessagePair={props.errorMessageState} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>이메일</th>
                <th>전화번호</th>
                <th>탈퇴 시각</th>
                <th>탈퇴 사유 ID</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>
    </>;
}

type RowProps = {
    user: UserModal;
};

function Row(props: RowProps) {
    const userId = props.user.id.toString()
    const withdrawnCauseId = props.user.withdrawCauseId!;
    return <tr>
        <td><a href={`/user/${userId}`}>{userId}</a></td>
        <td>{props.user.email}</td>
        <td>{props.user.phone}</td>
        <td>{props.user.withdrawnAt!.toRowFormat(true, true, true, false)}</td>
        <td><a href={`/misc/user/withdraw/cause/${withdrawnCauseId}`}>{withdrawnCauseId}</a></td>
        <td>
            <a href={`/user/${props.user.id}`} style={{ cursor: "pointer" }}>
                <i className="material-icons black-text">open_in_new</i>
            </a>
        </td>
    </tr>;
}