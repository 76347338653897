import {
    M10nModal,
    M10nParticipationModal,
    M10nReviewLikeModal,
    M10nReviewModal,
    M10nReviewReportCauseModal,
    M10nReviewReportModal
} from "../modal/M10ns";
import {OnArrayResponse, OnBaseResponse, OnErrorResponse, OnObjectResponse} from "../util/Reponses";
import {Requests, SequentialPostError, SequentialPostResponse} from "../util/Requests";
import {SERVER_URL} from "../util/Environments";
import {BitMask} from "../util/BitMask";
import {AxiosRequestConfig} from "axios";
import {UserModal} from "../modal/Users";
import PostSheet = M10nModal.PostSheet;

export const M10nIO = Object.freeze({
    post(
        data: M10nModal.Post,
        onReady: OnObjectResponse<M10nModal>,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'M10nIO.post',
            `${SERVER_URL}/m10n`,
            M10nModal.construct,
            onReady,
            onError,
            data
        )
    },
    postList(
        sheet: PostSheet[],
        onReady: OnArrayResponse<M10nModal>,
        onError: OnErrorResponse
    ) {
        if (sheet.isEmpty()) {
            onReady([])
            return
        }

        Requests.postArray(
            'M10nIO.postList',
            `${SERVER_URL}/m10n/list`,
            M10nModal.construct,
            onReady,
            onError,
            sheet
        )
    },
    postSheet(
        sheet: any[],
        onComplete: (responses: SequentialPostResponse<M10nModal & { hashTagIndex: number }>[], errors: SequentialPostError[]) => void
    ) {
        const responses: SequentialPostResponse<M10nModal & { hashTagIndex: number }>[] = []
        const errors: SequentialPostError[] = []
        const postElement = (index: number) => {
            if (index === sheet.length) {
                onComplete(responses, errors)
                return
            }

            this.post(
                M10nModal.Post.construct(sheet[index]),
                response => {
                    responses.push({ index, response: { ...response, hashTagIndex: sheet[index].hashTagIndex } })
                    postElement(index + 1)
                },
                error => {
                    errors.push({ index, error })
                    postElement(index + 1)
                }
            )
        }

        postElement(0)
    },
    patch(
        id: number,
        data: M10nModal.Post,
        onReady: OnObjectResponse<M10nModal>,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'M10nIO.patch',
            `${SERVER_URL}/m10n/${id}`,
            M10nModal.construct,
            onReady,
            onError,
            data
        )
    },
    getById(
        id: number,
        onReady: OnObjectResponse<M10nModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'M10nIO.getById',
            `${SERVER_URL}/m10n/${id}`,
            M10nModal.construct,
            onReady,
            onError
        )
    },
    listByIds(
        ids: number[],
        onReady: OnArrayResponse<M10nModal>,
        onError: OnErrorResponse
    ) {
        if (ids.isEmpty()) {
            onReady([])
            return
        }

        const config: AxiosRequestConfig = {
            params: new URLSearchParams(ids.map(id => ["ids", id.toString()]))
        };

        Requests.getArray(
            'M10nIO.listByIds',
            `${SERVER_URL}/m10n/list`,
            M10nModal.construct,
            onReady,
            onError,
            config
        )
    },
    listBySearchFilter(
        filter: BitMask<M10nModal.SearchFilter>,
        lastId: number,
        limit: number,
        onReady: OnArrayResponse<M10nModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, filter: filter.value, limit, userId: UserModal.idOrNull() }
        };

        Requests.getArray(
            'M10nIO.listBySearchFilter',
            `${SERVER_URL}/m10n/list`,
            M10nModal.construct,
            onReady,
            onError,
            config
        )
    },
    listByQueryAndSearchFilter(
        query: string,
        filter: BitMask<M10nModal.SearchFilter>,
        lastId: number,
        limit: number,
        onReady: OnArrayResponse<M10nModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, filter: filter.value, limit, query, userId: UserModal.idOrNull() }
        };

        Requests.getArray(
            'M10nIO.listBySearchFilter',
            `${SERVER_URL}/m10n/list`,
            M10nModal.construct,
            onReady,
            onError,
            config
        )
    }
})

export const M10nParticipationIO = Object.freeze({
    getById(
        id: bigint,
        onReady: OnObjectResponse<M10nParticipationModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            `M10nParticipationIO.getById`,
            `${SERVER_URL}/m10n/participation/${id}`,
            M10nParticipationModal.construct,
            onReady,
            onError
        )
    },
    listByIds(
        ids: number[],
        onReady: OnArrayResponse<M10nParticipationModal>,
        onError: OnErrorResponse
    ) {
        if (ids.isEmpty()) {
            onReady([])
            return
        }

        const config: AxiosRequestConfig = {
            params: new URLSearchParams(ids.map(id => ["ids", id.toString()]))
        };

        Requests.getArray(
            'M10nParticipationIO.listByIds',
            `${SERVER_URL}/m10n/participation/list`,
            M10nParticipationModal.construct,
            onReady,
            onError,
            config
        )
    },
    listAllDescending(
        lastId: bigint,
        onReady: OnArrayResponse<M10nParticipationModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'M10nParticipationIO.listAllDescending',
            `${SERVER_URL}/m10n/participation/list`,
            M10nParticipationModal.construct,
            onReady,
            onError,
            { params: { lastId, descending: '' } }
        )
    },
    listAllByM10nId(
        m10nId: number,
        onReady: OnArrayResponse<M10nParticipationModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'M10nParticipationIO.listAllByM10nId',
            `${SERVER_URL}/m10n/${m10nId}/participation/list`,
            M10nParticipationModal.construct,
            onReady,
            onError
        )
    },
    countByM10nId(
        m10nId: number,
        onReady: OnObjectResponse<bigint>,
        onError: OnErrorResponse
    ) {
        Requests.getObject<bigint, { count: bigint }>(
            'M10nParticipationIO.countByM10nId',
            `${SERVER_URL}/m10n/${m10nId}/participation/count`,
            o => o.count,
            onReady,
            onError
        )
    },
    countPassedByM10nId(
        m10nId: number,
        onReady: OnObjectResponse<bigint>,
        onError: OnErrorResponse
    ) {
        Requests.getObject<bigint, { count: bigint }>(
            'M10nParticipationIO.countPassedByM10nId',
            `${SERVER_URL}/m10n/${m10nId}/participation/passed/count`,
            o => o.count,
            onReady,
            onError
        )
    }
})

export const M10nReviewIO = Object.freeze({
    delete(
        id: bigint,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'M10nReviewIO.delete',
            `${SERVER_URL}/m10n/review/${id}`,
            o => o,
            onReady,
            onError
        )
    },
    getById(
        id: bigint,
        onReady: OnObjectResponse<M10nReviewModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'M10nReviewIO.getById',
            `${SERVER_URL}/m10n/review/${id}`,
            M10nReviewModal.construct,
            onReady,
            onError,
        )
    },
    listAllDescending(
        lastId: bigint,
        onReady: OnArrayResponse<M10nReviewModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'M10nReviewIO.listAllDescending',
            `${SERVER_URL}/m10n/review/list`,
            M10nReviewModal.construct,
            onReady,
            onError,
            { params: { lastId, descending: '' } }
        )
    },
    listAllByM10nId(
        m10nId: number,
        onReady: OnArrayResponse<M10nReviewModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'M10nReviewIO.listAllByM10nId',
            `${SERVER_URL}/m10n/${m10nId}/review/list`,
            M10nReviewModal.construct,
            onReady,
            onError
        )
    }
})

export const M10nReviewLikeIO = Object.freeze({
    listAllByM10nReviewId(
        m10nReviewId: bigint,
        onReady: OnArrayResponse<M10nReviewLikeModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'M10nLikeIO.listAllByM10nId',
            `${SERVER_URL}/m10n/review/${m10nReviewId}/like/list`,
            M10nReviewLikeModal.construct,
            onReady,
            onError
        )
    }
})

export const M10nReviewReportIO = Object.freeze({
    listAllByM10nReviewId(
        m10nReviewId: bigint,
        onReady: OnArrayResponse<M10nReviewReportModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'M10nReviewIO.listAllByM10nId',
            `${SERVER_URL}/m10n/review/${m10nReviewId}/report/list`,
            M10nReviewReportModal.construct,
            onReady,
            onError
        )
    }
})

export const M10nReviewReportCauseIO = Object.freeze({
    listAll(
        onReady: OnArrayResponse<M10nReviewReportCauseModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'M10nReviewReportCauseIO.listAll',
            `${SERVER_URL}/m10n/review/report/cause/list/all`,
            M10nReviewReportCauseModal.construct,
            onReady,
            onError
        )
    }
})