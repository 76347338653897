import {Optional} from "../util/Types";
import {BitMask} from "../util/BitMask";
import {UserGender, UserSessionModal} from "../modal/Users";
import {Requests} from "../util/Requests";
import {SERVER_URL} from "../util/Environments";
import {
    AdAnnouncementModal,
    AdDifficulty, AdModal,
    AdParticipationModal,
    AdParticipationType, AdReviewLikeModal, AdReviewModal, AdReviewReportCauseModal, AdReviewReportModal,
    AdRewardType, AdSearchFilter, AdTargetAgeModal, AdTargetGenderModal
} from "../modal/Ads";
import {OnArrayResponse, OnBaseResponse, OnErrorResponse, OnObjectResponse} from "../util/Reponses";
import {AxiosRequestConfig} from "axios";
import {RegionModal} from "../modal/Miscs";

export class AdIO {
    static post(
        title: string,
        description: string,
        script: string,
        externalUrl: string,
        gradingMessage: Optional<string>,
        amount: number,
        point: number,
        pointAdditionalHighAccuracy: number,
        productId: Optional<number>,
        participationType: BitMask<AdParticipationType>,
        rewardType: BitMask<AdRewardType>,
        headerImageCount: number,
        descriptionImageCount: number,
        difficulty: AdDifficulty,
        minimumAccuracy: number,
        externalUrlWing: boolean,
        startAt: Date,
        endAt: Date,
        exposedAt: Date,
        announcedAt: Optional<Date>,
        onReady: OnObjectResponse<AdModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            headers: { "x-vowing-session-id": UserSessionModal.id()!.toString() }
        };

        const data = {
            title, description, script, externalUrl, gradingMessage, amount, point, pointAdditionalHighAccuracy, productId,
            participationType: participationType.value, rewardType: rewardType.value, headerImageCount,
            descriptionImageCount, difficulty, minimumAccuracy, externalUrlWing, startAt, endAt, exposedAt, announcedAt
        };

        Requests.postObject(
            'AdIO.post',
            `${SERVER_URL}/ad`,
            o => new AdModal(o),
            onReady,
            onError,
            data,
            config
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<AdModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'AdIO.get',
            `${SERVER_URL}/ad/${id}`,
            o => new AdModal(o),
            onReady,
            onError
        );
    }

    static list(
        lastId: number,
        filter: BitMask<AdSearchFilter>,
        limit: number,
        onReady: OnArrayResponse<AdModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, filter: filter.value, limit }
        };

        Requests.getArray(
            'AdIO.list',
            `${SERVER_URL}/ad/list`,
            o => new AdModal(o),
            onReady,
            onError,
            config
        );
    }

    static search(
        query: string,
        lastId: number,
        onReady: OnArrayResponse<AdModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { query, lastId }
        };

        Requests.getArray(
            'AdIO.search',
            `${SERVER_URL}/ad/search`,
            o => new AdModal(o),
            onReady,
            onError,
            config
        );
    }

    static update(
        id: number,
        title: string,
        description: string,
        script: string,
        externalUrl: string,
        gradingMessage: Optional<string>,
        amount: number,
        point: number,
        pointAdditionalHighAccuracy: number,
        productId: Optional<number>,
        participationType: BitMask<AdParticipationType>,
        rewardType: BitMask<AdRewardType>,
        headerImageCount: number,
        descriptionImageCount: number,
        difficulty: AdDifficulty,
        minimumAccuracy: number,
        externalUrlWing: boolean,
        startAt: Date,
        endAt: Date,
        exposedAt: Date,
        announcedAt: Optional<Date>,
        onReady: OnObjectResponse<AdModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            headers: { "x-vowing-session-id": UserSessionModal.id()!.toString() }
        };

        const data = {
            title, description, script, externalUrl, gradingMessage, amount, point, pointAdditionalHighAccuracy, productId,
            participationType: participationType.value, rewardType: rewardType.value, headerImageCount,
            descriptionImageCount, difficulty, minimumAccuracy, externalUrlWing, startAt, endAt, exposedAt, announcedAt
        };

        Requests.patchObject(
            'AdIO.update',
            `${SERVER_URL}/ad/${id}`,
            o => new AdModal(o),
            onReady,
            onError,
            data,
            config
        );
    }
}

export class AdAnnouncementIO {
    static listDescending(
        lastId: number,
        onReady: OnArrayResponse<AdAnnouncementModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'AdAnnouncementIO.listDescending',
            `${SERVER_URL}/ad/announcement/list`,
            o => new AdAnnouncementModal(o),
            onReady,
            onError,
            { params: { lastId, descending: "" } }
        )
    }
}

export class AdParticipationIO {
    static get(
        id: bigint,
        onReady: OnObjectResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'AdParticipationIO.get',
            `${SERVER_URL}/ad/participation/${id}`,
            o => new AdParticipationModal(o),
            onReady,
            onError
        );
    }

    static countByAdId(
        adId: number,
        onReady: OnObjectResponse<bigint>,
        onError: OnErrorResponse
    ) {
        Requests.getObject<bigint, { count: bigint }>(
            'AdParticipationIO.countByAdId',
            `${SERVER_URL}/ad/${adId}/participation/count`,
            o => o.count,
            onReady,
            onError
        );
    }

    static countPassedByAdId(
        adId: number,
        onReady: OnObjectResponse<bigint>,
        onError: OnErrorResponse
    ) {
        Requests.getObject<bigint, { count: bigint }>(
            'AdParticipationIO.countPassedByAdId',
            `${SERVER_URL}/ad/${adId}/participation/passed/count`,
            o => o.count,
            onReady,
            onError
        );
    }

    static listAscending(
        lastId: bigint,
        onReady: OnArrayResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { lastId, ascending: "" }
        };
        Requests.getArray(
            'AdParticipation.listAscending',
            `${SERVER_URL}/ad/participation/list`,
            o => new AdParticipationModal(o),
            onReady,
            onError,
            config
        );
    }

    static listDescending(
        lastId: bigint,
        onReady: OnArrayResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { lastId, descending: "" }
        };
        Requests.getArray(
            'AdParticipation.listDescending',
            `${SERVER_URL}/ad/participation/list`,
            o => new AdParticipationModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByAdIdAscending(
        adId: number,
        lastId: bigint,
        onReady: OnArrayResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { lastId, ascending: "" }
        };
        Requests.getArray(
            'AdParticipation.listByAdIdAscending',
            `${SERVER_URL}/ad/${adId}/participation/list`,
            o => new AdParticipationModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByAdIdDescending(
        adId: number,
        lastId: bigint,
        onReady: OnArrayResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { lastId, descending: "" }
        };
        Requests.getArray(
            'AdParticipation.listByAdIdDescending',
            `${SERVER_URL}/ad/${adId}/participation/list`,
            o => new AdParticipationModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { userId, lastId, ascending: "" }
        };
        Requests.getArray(
            'AdParticipation.listByUserIdAscending',
            `${SERVER_URL}/ad/participation/list`,
            o => new AdParticipationModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { userId, lastId, descending: "" }
        };
        Requests.getArray(
            'AdParticipation.listByUserIdDescending',
            `${SERVER_URL}/ad/participation/list`,
            o => new AdParticipationModal(o),
            onReady,
            onError,
            config
        );
    }

    static search(
        query: string,
        lastId: bigint,
        onReady: OnArrayResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { query, lastId }
        };
        Requests.getArray(
            'AdParticipation.search',
            `${SERVER_URL}/ad/participation/search`,
            o => new AdParticipationModal(o),
            onReady,
            onError,
            config
        );
    }

    static listRecordDescending(
        lastId: bigint,
        onReady: OnArrayResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { lastId, descending: "" }
        };
        Requests.getArray(
            'AdParticipation.listRecordDescending',
            `${SERVER_URL}/ad/participation/record/list`,
            o => new AdParticipationModal(o),
            onReady,
            onError,
            config
        );
    }

    static listRecordByAdIdDescending(
        adId: number,
        lastId: bigint,
        onReady: OnArrayResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { adId, lastId, descending: "" }
        };
        Requests.getArray(
            'AdParticipation.listRecordByAdIdDescending',
            `${SERVER_URL}/ad/participation/record/list`,
            o => new AdParticipationModal(o),
            onReady,
            onError,
            config
        );
    }

    static listRecordByUserAgeDescending(
        userAge: number,
        lastId: bigint,
        onReady: OnArrayResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { userAge, lastId, descending: "" }
        };
        Requests.getArray(
            'AdParticipation.listByUserAgeDescending',
            `${SERVER_URL}/ad/participation/record/list`,
            o => new AdParticipationModal(o),
            onReady,
            onError,
            config
        );
    }

    static listRecordByUserGenderDescending(
        userGender: UserGender,
        lastId: bigint,
        onReady: OnArrayResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { userGender, lastId, descending: "" }
        };
        Requests.getArray(
            'AdParticipation.listByUserGenderDescending',
            `${SERVER_URL}/ad/participation/record/list`,
            o => new AdParticipationModal(o),
            onReady,
            onError,
            config
        );
    }

    static listRecordByUserRegionDescending(
        regionId: number,
        lastId: bigint,
        onReady: OnArrayResponse<AdParticipationModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { regionId, lastId, descending: "" }
        };
        Requests.getArray(
            'AdParticipation.listByUserRegionDescending',
            `${SERVER_URL}/ad/participation/record/list`,
            o => new AdParticipationModal(o),
            onReady,
            onError,
            config
        );
    }
}

export class AdReviewIO {
    static get(
        id: bigint,
        onReady: OnObjectResponse<AdReviewModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'AdReviewIO.get',
            `${SERVER_URL}/ad/review/${id}`,
            o => new AdReviewModal(o),
            onReady,
            onError
        );
    }

    static countByAdId(
        adId: number,
        onReady: OnObjectResponse<number>,
        onError: OnErrorResponse
    ) {
        Requests.getObject<number, { count: number }>(
            'AdReviewIO.countByAdId',
            `${SERVER_URL}/ad/${adId}/review/count`,
            o => o.count,
            onReady,
            onError
        );
    }

    static listAscending(
        lastId: bigint,
        onReady: OnArrayResponse<AdReviewModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, ascending: "" }
        };

        Requests.getArray(
            'AdReviewIO.listAscending',
            `${SERVER_URL}/ad/review/list`,
            o => new AdReviewModal(o),
            onReady,
            onError,
            config
        );
    }

    static listDescending(
        lastId: bigint,
        onReady: OnArrayResponse<AdReviewModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, descending: "" }
        };

        Requests.getArray(
            'AdReviewIO.listDescending',
            `${SERVER_URL}/ad/review/list`,
            o => new AdReviewModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByAdIdAscending(
        adId: number,
        lastId: bigint,
        onReady: OnArrayResponse<AdReviewModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, ascending: "" }
        };

        Requests.getArray(
            'AdReviewIO.listByAdIdAscending',
            `${SERVER_URL}/ad/${adId}/review/list`,
            o => new AdReviewModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByAdIdDescending(
        adId: number,
        lastId: bigint,
        onReady: OnArrayResponse<AdReviewModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, descending: "" }
        };

        Requests.getArray(
            'AdReviewIO.listByAdIdDescending',
            `${SERVER_URL}/ad/${adId}/review/list`,
            o => new AdReviewModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<AdReviewModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { userId, lastId, ascending: "" }
        };
        Requests.getArray(
            'AdReviewIO.listByUserIdAscending',
            `${SERVER_URL}/ad/review/list`,
            o => new AdReviewModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<AdReviewModal>,
        onError: OnErrorResponse
    ) {
        const config = {
            params: { userId, lastId, descending: "" }
        };
        Requests.getArray(
            'AdReviewIO.listByUserIdDescending',
            `${SERVER_URL}/ad/review/list`,
            o => new AdReviewModal(o),
            onReady,
            onError,
            config
        );
    }

    static search(
        query: string,
        lastId: bigint,
        onReady: OnArrayResponse<AdReviewModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { query, lastId }
        };

        Requests.getArray(
            'AdReviewIO.search',
            `${SERVER_URL}/ad/review/search`,
            o => new AdReviewModal(o),
            onReady,
            onError,
            config
        );
    }

    static update(
        id: bigint,
        body: number,
        rating: number,
        imageCount: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const data = { body, rating, imageCount };
        const config: AxiosRequestConfig = {
            headers: { "x-vowing-session-id": UserSessionModal.id()!.toString() },
        };

        Requests.patchObject(
            'AdReviewIO.update',
            `${SERVER_URL}/ad/review/${id}`,
            o => o,
            onReady,
            onError,
            data,
            config
        );
    }

    static delete(
        id: bigint,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            headers: { "x-vowing-session-id": UserSessionModal.id()!.toString() },
        };

        Requests.delete(
            'AdReviewIO.delete',
            `${SERVER_URL}/ad/review/${id}`,
            o => o,
            onReady,
            onError,
            config
        );
    }
}

export class AdReviewLikeIO {
    static countByAdReviewId(
        reviewId: bigint,
        onReady: OnObjectResponse<number>,
        onError: OnErrorResponse
    ) {
        Requests.getObject<number, { count: number }>(
            'AdReviewLikeIO.count',
            `${SERVER_URL}/ad/review/${reviewId}/like/count`,
            o => o.count,
            onReady,
            onError
        );
    }

    static listByAdReviewIdAscending(
        adReviewId: bigint,
        lastLikedAt: Date,
        onReady: OnArrayResponse<AdReviewLikeModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastLikedAt, ascending: "" }
        };

        Requests.getArray(
            'AdReviewIO.listByAdReviewIdAscending',
            `${SERVER_URL}/ad/review/${adReviewId}/like/list`,
            o => new AdReviewLikeModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByAdReviewIdDescending(
        adReviewId: bigint,
        lastLikedAt: Date,
        onReady: OnArrayResponse<AdReviewLikeModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastLikedAt, descending: "" }
        };

        Requests.getArray(
            'AdReviewIO.listByAdReviewIdDescending',
            `${SERVER_URL}/ad/review/${adReviewId}/like/list`,
            o => new AdReviewLikeModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastLikedAt: Date,
        onReady: OnArrayResponse<AdReviewLikeModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { userId, lastLikedAt, ascending: "" }
        };

        Requests.getArray(
            'AdReviewIO.listByUserIdAscending',
            `${SERVER_URL}/ad/review/like/list`,
            o => new AdReviewLikeModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastLikedAt: Date,
        onReady: OnArrayResponse<AdReviewLikeModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { userId, lastLikedAt, descending: "" }
        };

        Requests.getArray(
            'AdReviewIO.listByUserIdDescending',
            `${SERVER_URL}/ad/review/like/list`,
            o => new AdReviewLikeModal(o),
            onReady,
            onError,
            config
        );
    }
}

export class AdReviewReportIO {
    static countByAdReviewId(
        adReviewId: bigint,
        onReady: OnObjectResponse<number>,
        onError: OnErrorResponse
    ) {
        Requests.getObject<number, { count: number }>(
            'AdReviewReportIO.countByAdReviewId',
            `${SERVER_URL}/ad/review/${adReviewId}/report/count`,
            o => o.count,
            onReady,
            onError
        );
    }

    static listAscending(
        lastReportedAt: Date,
        onReady: OnArrayResponse<AdReviewReportModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            headers: { "x-vowing-session-id": UserSessionModal.id()!.toString() },
            params: { lastReportedAt, ascending: "" }
        };
        Requests.getArray(
            'AdReviewReportIO.listAscending',
            `${SERVER_URL}/ad/review/report/list`,
            o => new AdReviewReportModal(o),
            onReady,
            onError,
            config
        )
    }

    static listDescending(
        lastReportedAt: Date,
        onReady: OnArrayResponse<AdReviewReportModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastReportedAt, descending: "" }
        };
        Requests.getArray(
            'AdReviewReportIO.listDescending',
            `${SERVER_URL}/ad/review/report/list`,
            o => new AdReviewReportModal(o),
            onReady,
            onError,
            config
        )
    }

    static listByAdReviewIdAscending(
        adReviewId: bigint,
        lastReportedAt: Date,
        onReady: OnArrayResponse<AdReviewReportModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastReportedAt, ascending: "" }
        };
        Requests.getArray(
            'AdReviewReportIO.listByAdReviewIdAscending',
            `${SERVER_URL}/ad/review/${adReviewId}/report/list`,
            o => new AdReviewReportModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByAdReviewIdDescending(
        adReviewId: bigint,
        lastReportedAt: Date,
        onReady: OnArrayResponse<AdReviewReportModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastReportedAt, descending: "" }
        };
        Requests.getArray(
            'AdReviewReportIO.listByAdReviewIdDescending',
            `${SERVER_URL}/ad/review/${adReviewId}/report/list`,
            o => new AdReviewReportModal(o),
            onReady,
            onError,
            config
        )
    }

    static listByCauseIdDescending(
        causeId: number,
        lastReportedAt: Date,
        onReady: OnArrayResponse<AdReviewReportModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { causeId, lastReportedAt, descending: "" }
        };
        Requests.getArray(
            'AdReviewReportIO.listByCauseIdDescending',
            `${SERVER_URL}/ad/review/report/list`,
            o => new AdReviewReportModal(o),
            onReady,
            onError,
            config
        )
    }

    static listByReportedAtDescending(
        reportedAt: string,
        lastReportedAt: Date,
        onReady: OnArrayResponse<AdReviewReportModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { reportedAt, lastReportedAt, descending: "" }
        };
        Requests.getArray(
            'AdReviewReportIO.listByReportedAtDescending',
            `${SERVER_URL}/ad/review/report/list`,
            o => new AdReviewReportModal(o),
            onReady,
            onError,
            config
        )
    }

    static listByUserIdAscending(
        userId: bigint,
        lastReportedAt: Date,
        onReady: OnArrayResponse<AdReviewReportModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { userId, lastReportedAt, ascending: "" }
        };
        Requests.getArray(
            'AdReviewReportIO.listByUserIdAscending',
            `${SERVER_URL}/ad/review/report/list`,
            o => new AdReviewReportModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastReportedAt: Date,
        onReady: OnArrayResponse<AdReviewReportModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { userId, lastReportedAt, descending: "" }
        };
        Requests.getArray(
            'AdReviewReportIO.listByUserIdDescending',
            `${SERVER_URL}/ad/review/report/list`,
            o => new AdReviewReportModal(o),
            onReady,
            onError,
            config
        );
    }
}

export class AdReviewReportCauseIO {
    static post(
        description: string,
        sortOrder: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'AdReviewReportCauseIO.post',
            `${SERVER_URL}/misc/ad/review/report/cause`,
            o => o,
            onReady,
            onError,
            {description, sortOrder}
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<AdReviewReportCauseModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'AdReviewReportCauseIO.get',
            `${SERVER_URL}/misc/ad/review/report/cause/${id}`,
            o => new AdReviewReportCauseModal(o),
            onReady,
            onError
        );
    }

    static listAll(
        onReady: OnArrayResponse<AdReviewReportCauseModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'AdReviewReportCauseIO.listAll',
            `${SERVER_URL}/misc/ad/review/report/cause/list`,
            o => new AdReviewReportCauseModal(o),
            onReady,
            onError
        );
    }

    static update(
        id: number,
        description: string,
        sortOrder: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'AdReviewReportCauseIO.update',
            `${SERVER_URL}/misc/ad/review/report/cause/${id}`,
            o => o,
            onReady,
            onError,
            {description, sortOrder}
        );
    }

    static delete(
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'AdReviewReportCauseIO.delete',
            `${SERVER_URL}/misc/ad/review/report/cause/${id}`,
            o => o,
            onReady,
            onError,
        );
    }
}

export class AdTargetAgeRangeIO {
    static post(
        adId: number,
        ageRangeId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.post(
            'AdTargetAgeIO.post',
            `${SERVER_URL}/ad/${adId}/target/age/${ageRangeId}`,
            o => o,
            onReady,
            onError,
        );
    }

    static listRange(
        adId: number,
        onReady: OnArrayResponse<AdTargetAgeModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'AdTargetAgeIO.list',
            `${SERVER_URL}/ad/${adId}/target/age/list`,
            o => new AdTargetAgeModal(o),
            onReady,
            onError
        );
    }

    static delete(
        adId: number,
        ageRangeId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'AdTargetAgeIO.delete',
            `${SERVER_URL}/ad/${adId}/target/age/${ageRangeId}`,
            o => o,
            onReady,
            onError,
        );
    }
}

export class AdTargetGenderIO {
    static post(
        adId: number,
        gender: UserGender,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.post(
            'AdTargetGenderIO.post',
            `${SERVER_URL}/ad/${adId}/target/gender/${gender}`,
            o => o,
            onReady,
            onError,
        );
    }

    static list(
        adId: number,
        onReady: OnArrayResponse<AdTargetGenderModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'AdTargetGenderIO.list',
            `${SERVER_URL}/ad/${adId}/target/gender/list`,
            o => new AdTargetGenderModal(o),
            onReady,
            onError
        );
    }

    static delete(
        adId: number,
        gender: UserGender,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'AdTargetGenderIO.delete',
            `${SERVER_URL}/ad/${adId}/target/gender/${gender}`,
            o => o,
            onReady,
            onError,
        );
    }
}

export class AdTargetRegionIO {
    static post(
        adId: number,
        regionId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.post(
            'AdTargetRegionIO.post',
            `${SERVER_URL}/ad/${adId}/target/region/${regionId}`,
            o => o,
            onReady,
            onError,
        );
    }

    static list(
        adId: number,
        onReady: OnArrayResponse<RegionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'AdTargetRegionIO.list',
            `${SERVER_URL}/ad/${adId}/target/region/list`,
            o => new RegionModal(o),
            onReady,
            onError
        );
    }

    static delete(
        adId: number,
        regionId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'AdTargetRegionIO.delete',
            `${SERVER_URL}/ad/${adId}/target/region/${regionId}`,
            o => o,
            onReady,
            onError,
        );
    }
}