import {Optional, StatePair} from "../../../util/Types";
import {NoticeModal} from "../../../modal/Miscs";
import React, {useEffect, useState} from "react";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {useParams} from "react-router-dom";
import {MaterialModal} from "../../Materials";
import {MiscNoticeIO} from "../../../io/Miscs";

export default function Detail() {
    const {noticeId} = useParams() as { noticeId?: number };
    const [content, setContent] = useState<Optional<NoticeModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
    });

    useEffect(() => {
        if (noticeId) {
            MiscNoticeIO.get(noticeId, setContent, setErrorMessage);
        }
    }, [noticeId]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage]);

    return <PageWrapper content={content} errorMessageState={[errorMessage, setErrorMessage]} />;
}

type PageWrapperProps = {
    content: Optional<NoticeModal>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const content = props.content;
    if (!content) {
        return <></>;
    }

    const onEditClicked = () => {
        if (props.content) {
            document.location = `/misc/notice/${props.content.id}/edit`;
        }
    };

    const barItems: [string, NavBarItemAction][] = [
        ["수정", onEditClicked]
    ];

    return <>
        <Nav
            title="공지사항"
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <div className="row cascade first">
            <Card title="ID" value={content.id.toString()} />
        </div>
        <div className="row cascade">
            <Card title="카테고리 ID" value={content.categoryId.toString()} />
        </div>
        <div className="row cascade">
            <Card title="제목" value={content.title} />
        </div>
        <div className="row cascade">
            <Card title="내용" value={content.body} smallBody={true} />
        </div>
        <div className="row cascade">
            <Card title="작성 시각" value={content.createdAt.toRowFormat(true, true, true, true)} />
        </div>
    </>;
}

type CardProps = {
    title: string;
    value?: Optional<string>;
    href?: string;
    smallBody?: boolean;
    columnWidth?: number;
    columnOffset?: number;
};

function Card(props: CardProps) {
    if (!props.value) {
        return <></>;
    }

    let className = "col";
    if (props.columnWidth !== undefined) {
        className += ` s${props.columnWidth}`;
    } else {
        className += ' s8';
    }
    if (props.columnOffset !== undefined) {
        className += ` offset-s${props.columnOffset}`;
    } else {
        className += ' offset-s2';
    }

    let anchor: JSX.Element;
    if (props.href) {
        anchor = <a href={props.href}><i className="material-icons right grey-text">open_in_new</i></a>;
    } else {
        anchor = <></>;
    }

    return <div className={className}>
        <div className="card">
            <div className="card-content">
                <p>{props.title}{anchor}</p>
                <span className={(props.smallBody === true) ? "" : "card-title"}>{props.value}</span>
            </div>
        </div>
    </div>;
}