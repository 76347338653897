import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../../util/Types";
import {ProductOptionItemModal} from "../../../../modal/Products";
import {ErrorMessage, Nav, NavBarItemAction} from "../../../Common";
import {Component, MaterialComponent, MaterialInput, MaterialModal} from "../../../Materials";
import {ProductOptionItemIO} from "../../../../io/Products";

const pageId = 'product_description_edit';
const nameInputId = `${pageId}_name`;
const sortOrderInputId = `${pageId}_sort_order`;

type Params = {
    productOptionId?: string,
    productOptionItemId?: string,
}

export default function Edit() {
    const params = useParams<Params>();
    const productOptionId = params.productOptionId?.toIntOrNull() ?? null;
    const productOptionItemId = params.productOptionItemId?.toIntOrNull() ?? null;

    const [content, setContent] = useState<Optional<ProductOptionItemModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        MaterialModal.init();
        MaterialInput.init();
    });

    useEffect(() => {
        if (productOptionItemId !== null) {
            ProductOptionItemIO.get(productOptionItemId, setContent, setErrorMessage);
        }
    }, [productOptionItemId]);

    useEffect(() => {
        if (content) {
            MaterialInput.getOrNull(`#${nameInputId}`)?.setValue(content.name);
            MaterialInput.getOrNull(`#${sortOrderInputId}`)?.setValue(content.sortOrder.toString());
        }
    }, [content]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${nameInputId}`),
            MaterialInput.getOrNull(`#${sortOrderInputId}`),
        ];

        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage]);

    if (errorMessage !== null) {
        window.alert('오류가 발생했습니다.');
        setErrorMessage(null);
        setRootEnabled(true);
    }

    if (productOptionId === null) {
        window.alert('잘못된 요청입니다.');
        return <Link to="/" />;
    } else {
        return <PageWrapper
            productOptionId={productOptionId}
            contentState={[content, setContent]}
            errorMessageState={[errorMessage, setErrorMessage]}
            setRootEnabled={setRootEnabled} />;
    }
}

type PageWrapperProps = {
    productOptionId: number;
    contentState: StatePair<Optional<ProductOptionItemModal>>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [content] = props.contentState;
    const [, setErrorMessage] = props.errorMessageState;
    const onSaveClicked = () => OnSaveClicked(
        props.productOptionId,
        content,
        setErrorMessage,
        props.setRootEnabled
    );
    const onCancelClicked = () => window.history.back();
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", onCancelClicked]
    ];

    return <>
        <Nav
            title={"상품 옵션 항목 " + ((content) ? "수정" : "추가")}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <Editor />
    </>;
}

function OnSaveClicked(
    productOptionId: number,
    content: Optional<ProductOptionItemModal>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const name = MaterialInput.get(`#${nameInputId}`).getValue();
    if (name.length.notIn(0, 101)) {
        setErrorMessage("이름을 확인해주세요.");
        return;
    }

    const sortOrder = MaterialInput.get(`#${sortOrderInputId}`).getValue().toIntOrNull();
    if (sortOrder === null) {
        setErrorMessage("표시 순서를 확인해주세요.");
        return;
    }

    const onReady = () => {
        window.alert('저장되었습니다.');
        window.history.back();
    };

    const onError = (error: string) => {
        setRootEnabled(true);
        setErrorMessage(error);
    };

    if (content) {
        if (content.name === name &&
            content.sortOrder === sortOrder
        ) {
            onReady();
            return;
        }

        setRootEnabled(false);
        ProductOptionItemIO.update(content.id, name, sortOrder, onReady, onError);
    } else {
        setRootEnabled(false);
        ProductOptionItemIO.post(productOptionId, name, sortOrder, onReady, onError);
    }
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={nameInputId}
                inputClasses="validate"
                inputPlaceHolder="100자 이내"
                inputDataLength={100}
                label="이름" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={sortOrderInputId}
                inputType="number"
                inputClasses="validate"
                label="표시 순서" />
        </div>
    </>;
}