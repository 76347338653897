import {useParams} from "react-router-dom";
import {Optional, wrapState} from "../../../util/Types";
import React, {useEffect, useState} from "react";
import {PointUsageModal, PointUsageType} from "../../../modal/Points";
import {PointUsageIO} from "../../../io/Points";
import {DetailCard, ErrorMessage, Nav} from "../../Common";

export default function Detail() {
    const {pointUsageId} = useParams() as { pointUsageId?: bigint };
    const {value: pointUsage, set: setPointUsage} = wrapState(useState<Optional<PointUsageModal>>(null));
    const {value: errorMessage, set: setErrorMessage} = wrapState(useState<Optional<ErrorMessage>>(null));

    useEffect(() => {
        M.AutoInit();
    });
    useEffect(() => {
        if (pointUsageId !== undefined && pointUsageId !== null) {
            PointUsageIO.get(pointUsageId, setPointUsage, setErrorMessage);
        }
    }, [pointUsageId]);

    if (pointUsage === null) {
        return <></>;
    }

    const adParticipationCard = (pointUsage.adParticipationId === null) ? <></> :
        <div className="row cascade">
            <DetailCard title="광고 참여 ID" value={pointUsage.adParticipationId.toString()} href={`/ad/participation/${pointUsage.adParticipationId}`} />
        </div>;
    const wingUsageCard = (pointUsage.wingUsageId === null) ? <></> :
        <div className="row cascade">
            <DetailCard title="날개 사용 ID" value={pointUsage.wingUsageId.toString()} href={`/wing/usage/${pointUsage.wingUsageId}`} />
        </div>;
    const gifticonPurchaseCard = (pointUsage.gifticonPurchaseId === null) ? <></> :
        <div className="row cascade">
            <DetailCard title="기프티콘 구매 ID" value={pointUsage.gifticonPurchaseId.toString()} href={`/gitfticon/purchase/${pointUsage.gifticonPurchaseId}`} />
        </div>;

    return <>
        <Nav
            title="포인트 사용"
            titleIcon="chevron_left"
            titleHref="/point/usage/list"
            errorMessagePair={[errorMessage, setErrorMessage]} />
        <div className="row cascade first">
            <DetailCard title="ID" value={pointUsage.id.toString()} />
        </div>
        <div className="row cascade">
            <DetailCard title="사용자 ID" value={pointUsage.userId.toString()} href={`/user/${pointUsage.userId}`} />
        </div>
        <div className="row cascade">
            <DetailCard title="증감" value={pointUsage.amount.toString()} />
        </div>
        <div className="row cascade">
            <DetailCard title="마지막 보유량" value={pointUsage.lastAmount.toString()} />
        </div>
        <div className="row cascade">
            <DetailCard title="현재 보유량" value={(pointUsage.amount + pointUsage.lastAmount).toString()} />
        </div>
        <div className="row cascade">
            <DetailCard title="유형" value={PointUsageType.toString(pointUsage.type)} />
        </div>
        {adParticipationCard}
        {wingUsageCard}
        {gifticonPurchaseCard}
        <div className="row cascade">
            <DetailCard title="시각" value={pointUsage.createdAt.toRowFormat(true, true, true, true)} />
        </div>
    </>;
}
