import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {AgreementType} from "../../../modal/Miscs";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {MiscAgreementIO} from "../../../io/Miscs";
import {Component, MaterialComponent, MaterialInput, MaterialModal, MaterialSelectWrapper} from "../../Materials";

const pageId = 'agreement_edit';
const urlInputId = `${pageId}_url`;
const typeSelectId = `${pageId}_type`;
const typeWrapperId = `${pageId}_type_wrapper`;

export default function Edit() {
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        MaterialSelectWrapper.init();
    });

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${urlInputId}`),
            MaterialSelectWrapper.getOrNull(`#${typeWrapperId}`),
        ];

        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            errorMessageState={[errorMessage, setErrorMessage]}
            setRootEnabled={setRootEnabled} />
    </>;
}

type PageWrapperProps = {
    errorMessageState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [, setErrorMessage] = props.errorMessageState;
    const onSaveClicked = () => OnSaveClicked(setErrorMessage, props.setRootEnabled);
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", () => document.location = "/misc/agreement/list"]
    ];

    return <>
        <Nav
            title={"약관 추가"}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <Editor />
    </>;
}

function OnSaveClicked(
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const url = MaterialInput.get(`#${urlInputId}`).getValue();
    if (url.length.notIn(1, 1001) && !url.startsWith("http")) {
        setErrorMessage('URL을 확인해주세요.');
        return;
    }

    const type = MaterialSelectWrapper.get(`#${typeWrapperId}`).getFirstSelectionAs(AgreementType.ordinal);
    if (type === null) {
        setErrorMessage('구분을 확인해주세요.');
        return;
    }

    const onReady = () => {
        window.alert('저장되었습니다.');
        document.location = `/misc/agreement/list`;
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    setRootEnabled(false);
    MiscAgreementIO.post(url, type, onReady, onError);
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={urlInputId}
                inputType="url"
                inputClasses="validate"
                inputPlaceHolder="1000자 이내"
                inputDataLength={1000}
                label="URL" />
        </div>
        <div className="row cascade">
            <Component.Select
                values={AgreementType.values()}
                wrapperId={typeWrapperId}
                wrapperClasses="col s8 offset-s2"
                selectId={typeSelectId}
                label="구분" />
        </div>
    </>;
}