import {useParams} from "react-router-dom";
import {Optional, useWrapper} from "../../../util/Types";
import {M10nParticipationModal} from "../../../modal/M10ns";
import {ErrorMessage, Nav} from "../../Common";
import React, {useEffect} from "react";
import {useEffectOptional} from "../../../util/Hooks";
import {M10nParticipationIO} from "../../../io/M10n";
import {CardHorizontal} from "../../common/CardHorizontal";

export function Detail() {
    const { id } = useParams<{ id?: string }>()
    const content = useWrapper<Optional<M10nParticipationModal>>(null)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)

    useEffect(() => M.AutoInit())

    useEffectOptional(id => M10nParticipationIO.getById(
        BigInt(id),
        response => content.set(response),
        error => errorMessage.set(error)
    ), id)

    return <>
        <Nav
            title={'암기플러스 참여'}
            titleIcon={'chevron_left'}
            titleHref={'/m10n/participation/list'} />
        {content.value && <>
            <div className={'row cascade'}>
                <div className={'col s8 offset-s2'}>
                    <CardHorizontal title={"ID"} value={content.value.id.toString()} />
                    <CardHorizontal title={"암기플러스 ID"} value={content.value.m10nId.toString()} href={`/m10n/${content.value.m10nId}`} />
                    <CardHorizontal title={"사용자 ID"} value={content.value.userId.toString()} href={`/user/${content.value.userId}`} />
                    <CardHorizontal title={"녹음된 텍스트"} value={content.value.recordedText} href={M10nParticipationModal.audioPath(content.value)} />
                    <CardHorizontal title={"일치율"} value={content.value.accuracy.toString() + '%'} />
                    <CardHorizontal title={"합격 여부"} value={(content.value.isPassed) ? '예' : '아니오'} />
                    <CardHorizontal title={"참여 시각"} value={content.value.participatedAt.toRowFormat(true, true)} />
                </div>
            </div>
        </>}
    </>
}