import {OnArrayResponse, OnBaseResponse, OnNumberResponse, OnErrorResponse, OnObjectResponse} from "../util/Reponses";
import {
    ProductBrandModal,
    ProductDeliveryModal,
    ProductDescriptionModal,
    ProductModal,
    ProductOptionItemModal,
    ProductOptionModal,
    ProductReviewLikeModal,
    ProductReviewModal,
    ProductReviewReportCauseModal,
    ProductReviewReportModal
} from "../modal/Products";
import {Requests} from "../util/Requests";
import {SERVER_URL} from "../util/Environments";
import {AxiosRequestConfig} from "axios";
import {UserModal} from "../modal/Users";

export class ProductIO {
    static post(
        productBrandId: number,
        name: string,
        price: number,
        headerImageCount: number,
        descriptionImageCount: number,
        onReady: OnObjectResponse<ProductModal>,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            onError("사용자 정보가 없습니다.");
        }

        Requests.postObject(
            'ProductIO.post',
            `${SERVER_URL}/product`,
            o => new ProductModal(o),
            onReady,
            onError,
            { productBrandId, name, price, headerImageCount, descriptionImageCount, creatorId }
        );
    }

    static get(
        productId: number,
        onReady: OnObjectResponse<ProductModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'ProductIO.get',
            `${SERVER_URL}/product/${productId}`,
            o => new ProductModal(o),
            onReady,
            onError,
        );
    }

    static countByProductBrandId(
        productBrandId: number,
        onReady: OnNumberResponse,
        onError: OnErrorResponse
    ) {
        Requests.getCount(
            'ProductIO.countByProductBrandId',
            `${SERVER_URL}/product/count`,
            onReady,
            onError,
            { params: { productBrandId } }
        );
    }

    static listAscending(
        lastId: number,
        onReady: OnArrayResponse<ProductModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, ascending: "" }
        };
        Requests.getArray(
            'ProductIO.listAscending',
            `${SERVER_URL}/product/list`,
            o => new ProductModal(o),
            onReady,
            onError,
            config
        );
    }

    static listDescending(
        lastId: number,
        onReady: OnArrayResponse<ProductModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, descending: "" }
        };
        Requests.getArray(
            'ProductIO.listDescending',
            `${SERVER_URL}/product/list`,
            o => new ProductModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByProductBrandIdAscending(
        productBrandId: number,
        lastId: number,
        onReady: OnArrayResponse<ProductModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, ascending: "" }
        };
        Requests.getArray(
            'ProductIO.listByProductBrandIdAscending',
            `${SERVER_URL}/product/list`,
            o => new ProductModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByProductBrandIdDescending(
        productBrandId: number,
        lastId: number,
        onReady: OnArrayResponse<ProductModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, descending: "" }
        };
        Requests.getArray(
            'ProductIO.listByProductBrandIdDescending',
            `${SERVER_URL}/product/list`,
            o => new ProductModal(o),
            onReady,
            onError,
            config
        );
    }

    static search(
        query: string,
        lastId: number,
        onReady: OnArrayResponse<ProductModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { query, lastId }
        };
        Requests.getArray(
            'ProductIO.search',
            `${SERVER_URL}/product/search`,
            o => new ProductModal(o),
            onReady,
            onError,
            config
        );
    }

    static update(
        productId: number,
        productBrandId: number,
        name: string,
        price: number,
        headerImageCount: number,
        descriptionImageCount: number,
        onReady: OnObjectResponse<ProductModal>,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'ProductIO.update',
            `${SERVER_URL}/product/${productId}`,
            o => new ProductModal(o),
            onReady,
            onError,
            { productBrandId, name, price, headerImageCount, descriptionImageCount }
        );
    }

    static delete(
        productId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'ProductIO.delete',
            `${SERVER_URL}/product/${productId}`,
            o => o,
            onReady,
            onError
        );
    }
}

export class ProductBrandIO {
    static post(
        name: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            onError("사용자 정보가 없습니다.");
        }

        Requests.postObject(
            'ProductBrandIO.post',
            `${SERVER_URL}/product/brand`,
            o => o,
            onReady,
            onError,
            { name, creatorId }
        );
    }

    static get(
        productBrandId: number,
        onReady: OnObjectResponse<ProductBrandModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'ProductBrandIO.get',
            `${SERVER_URL}/product/brand/${productBrandId}`,
            o => new ProductBrandModal(o),
            onReady,
            onError
        );
    }

    static listByIds(
        productBrandIds: number[],
        onReady: OnArrayResponse<ProductBrandModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: new URLSearchParams(productBrandIds.map(id => ["ids", id.toString()]))
        };

        Requests.getArray(
            'ProductBrandIO.listByIds',
            `${SERVER_URL}/product/brand/list`,
            o => new ProductBrandModal(o),
            onReady,
            onError,
            config
        );
    }

    static listAscending(
        lastId: number,
        onReady: OnArrayResponse<ProductBrandModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, ascending: "" }
        };

        Requests.getArray(
            'ProductBrandIO.listAscending',
            `${SERVER_URL}/product/brand/list`,
            o => new ProductBrandModal(o),
            onReady,
            onError,
            config
        );
    }

    static listDescending(
        lastId: number,
        onReady: OnArrayResponse<ProductBrandModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastId, descending: "" }
        };

        Requests.getArray(
            'ProductBrandIO.listDescending',
            `${SERVER_URL}/product/brand/list`,
            o => new ProductBrandModal(o),
            onReady,
            onError,
            config
        );
    }

    static search(
        query: string,
        lastId: number,
        onReady: OnArrayResponse<ProductBrandModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { query, lastId }
        };

        Requests.getArray(
            'ProductBrandIO.search',
            `${SERVER_URL}/product/brand/search`,
            o => new ProductBrandModal(o),
            onReady,
            onError,
            config
        );
    }

    static update(
        productBrandId: number,
        name: string,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'ProductBrandIO.post',
            `${SERVER_URL}/product/brand/${productBrandId}`,
            o => o,
            onReady,
            onError,
            { name }
        );
    }

    static delete(
        productBrandId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'ProductBrandIO.delete',
            `${SERVER_URL}/product/brand/${productBrandId}`,
            o => o,
            onReady,
            onError
        );
    }
}

export class ProductDeliveryIO {
    static get(
        productDeliveryId: number,
        onReady: OnObjectResponse<ProductDeliveryModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'ProductDeliveryIO.get',
            `${SERVER_URL}/product/delivery/${productDeliveryId}`,
            o => new ProductDeliveryModal(o),
            onReady,
            onError
        );
    }

    static listAscending(
        lastId: bigint,
        onReady: OnArrayResponse<ProductDeliveryModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductDeliveryIO.listAscending',
            `${SERVER_URL}/product/delivery/list`,
            o => new ProductDeliveryModal(o),
            onReady,
            onError,
            { params: { lastId, ascending: "" } }
        );
    }

    static listDescending(
        lastId: bigint,
        onReady: OnArrayResponse<ProductDeliveryModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductDeliveryIO.listDescending',
            `${SERVER_URL}/product/delivery/list`,
            o => new ProductDeliveryModal(o),
            onReady,
            onError,
            { params: { lastId, descending: "" } }
        );
    }

    static listByProductIdAscending(
        productId: number,
        lastId: bigint,
        onReady: OnArrayResponse<ProductDeliveryModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductDeliveryIO.listByProductIdAscending',
            `${SERVER_URL}/product/delivery/list`,
            o => new ProductDeliveryModal(o),
            onReady,
            onError,
            { params: { lastId, productId, ascending: "" } }
        );
    }

    static listByProductIdDescending(
        productId: number,
        lastId: bigint,
        onReady: OnArrayResponse<ProductDeliveryModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductDeliveryIO.listByProductIdDescending',
            `${SERVER_URL}/product/delivery/list`,
            o => new ProductDeliveryModal(o),
            onReady,
            onError,
            { params: { lastId, productId, descending: "" } }
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<ProductDeliveryModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductDeliveryIO.listByUserIdAscending',
            `${SERVER_URL}/product/delivery/list`,
            o => new ProductDeliveryModal(o),
            onReady,
            onError,
            { params: { lastId, userId, ascending: "" } }
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<ProductDeliveryModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductDeliveryIO.listByUserIdDescending',
            `${SERVER_URL}/product/delivery/list`,
            o => new ProductDeliveryModal(o),
            onReady,
            onError,
            { params: { lastId, userId, descending: "" } }
        );
    }

    static search(
        query: string,
        lastId: bigint,
        onReady: OnArrayResponse<ProductDeliveryModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductDeliveryIO.listByUserIdDescending',
            `${SERVER_URL}/product/delivery/list`,
            o => new ProductDeliveryModal(o),
            onReady,
            onError,
            { params: { query, lastId } }
        );
    }

    // TODO listOptionItems
}

export class ProductDescriptionIO {
    static post(
        productId: number,
        title: string,
        body: string,
        sortOrder: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            onError("사용자 정보가 없습니다.");
            return;
        }

        Requests.postObject(
            'ProductDescriptionIO.post',
            `${SERVER_URL}/product/${productId}/description`,
            o => o,
            onReady,
            onError,
            { title, body, sortOrder, creatorId }
        );
    }

    static get(
        productId: number,
        productDescriptionId: number,
        onReady: OnObjectResponse<ProductDescriptionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'ProductDescriptionIO.get',
            `${SERVER_URL}/product/${productId}/description/${productDescriptionId}`,
            o => new ProductDescriptionModal(o),
            onReady,
            onError
        );
    }

    static listByProductId(
        productId: number,
        onReady: OnArrayResponse<ProductDescriptionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductDescriptionIO.listByProductId',
            `${SERVER_URL}/product/${productId}/description/list`,
            o => new ProductDescriptionModal(o),
            onReady,
            onError
        );
    }

    static update(
        productId: number,
        productDescriptionId: number,
        title: string,
        body: string,
        sortOrder: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'ProductDescriptionIO.update',
            `${SERVER_URL}/product/${productId}/description/${productDescriptionId}`,
            o => o,
            onReady,
            onError,
            { title, body, sortOrder }
        );
    }

    static delete(
        productId: number,
        productDescriptionId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'ProductDescriptionIO.update',
            `${SERVER_URL}/product/${productId}/description/${productDescriptionId}`,
            o => o,
            onReady,
            onError
        );
    }
}

export class ProductOptionIO {
    static post(
        productId: number,
        title: string,
        sortOrder: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            onError("사용자 정보가 없습니다.");
        }

        Requests.postObject(
            'ProductOptionIO.post',
            `${SERVER_URL}/product/${productId}/option`,
            o => o,
            onReady,
            onError,
            { title, sortOrder, creatorId }
        );
    }

    static get(
        productId: number,
        productOptionId: number,
        onReady: OnObjectResponse<ProductOptionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'ProductOptionIO.get',
            `${SERVER_URL}/product/${productId}/option/${productOptionId}`,
            o => new ProductOptionModal(o),
            onReady,
            onError
        );
    }

    static listByProductId(
        productId: number,
        onReady: OnArrayResponse<ProductOptionModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductOptionIO.listByProductId',
            `${SERVER_URL}/product/${productId}/option/list`,
            o => new ProductOptionModal(o),
            onReady,
            onError
        );
    }

    static update(
        productId: number,
        productOptionId: number,
        title: string,
        sortOrder: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'ProductOptionIO.update',
            `${SERVER_URL}/product/${productId}/option/${productOptionId}`,
            o => o,
            onReady,
            onError,
            { title, sortOrder }
        );
    }

    static delete(
        productId: number,
        productOptionId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'ProductOptionIO.update',
            `${SERVER_URL}/product/${productId}/option/${productOptionId}`,
            o => o,
            onReady,
            onError
        );
    }
}

export class ProductOptionItemIO {
    static post(
        productOptionId: number,
        name: string,
        sortOrder: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        const creatorId = UserModal.idOrNull();
        if (creatorId === null) {
            onError("사용자 정보가 없습니다.");
            return;
        }

        Requests.postObject(
            'ProductOptionItemIO.post',
            `${SERVER_URL}/product/option/${productOptionId}/item`,
            o => o,
            onReady,
            onError,
            { name, sortOrder, creatorId }
        );
    }

    static get(
        productOptionItemId: number,
        onReady: OnObjectResponse<ProductOptionItemModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'ProductOptionIO.get',
            `${SERVER_URL}/product/option/item/${productOptionItemId}`,
            o => new ProductOptionItemModal(o),
            onReady,
            onError
        );
    }

    static listByProductOptionId(
        productOptionId: number,
        onReady: OnArrayResponse<ProductOptionItemModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductOptionItemIO.listByProductOptionId',
            `${SERVER_URL}/product/option/${productOptionId}/item/list`,
            o => new ProductOptionItemModal(o),
            onReady,
            onError,
        );
    }

    static update(
        id: number,
        name: string,
        sortOrder: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'ProductOptionItemIO.update',
            `${SERVER_URL}/product/option/item/${id}`,
            o => o,
            onReady,
            onError,
            { name, sortOrder }
        );
    }

    static delete(
        id: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'ProductOptionItemIO.delete',
            `${SERVER_URL}/product/option/item/${id}`,
            o => o,
            onReady,
            onError
        );
    }
}

export class ProductReviewIO {
    static get(
        id: bigint,
        onReady: OnObjectResponse<ProductReviewModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'ProductReviewIO.get',
            `${SERVER_URL}/product/review/${id}`,
            o => new ProductReviewModal(o),
            onReady,
            onError
        );
    }

    static listAscending(
        lastId: bigint,
        onReady: OnArrayResponse<ProductReviewModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewIO.listAscending',
            `${SERVER_URL}/product/review/list`,
            o => new ProductReviewModal(o),
            onReady,
            onError,
            { params: { lastId, ascending: "" } }
        );
    }

    static listDescending(
        lastId: bigint,
        onReady: OnArrayResponse<ProductReviewModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewIO.listDescending',
            `${SERVER_URL}/product/review/list`,
            o => new ProductReviewModal(o),
            onReady,
            onError,
            { params: { lastId, descending: "" } }
        );
    }

    static listByProductIdAscending(
        productId: number,
        lastId: bigint,
        onReady: OnArrayResponse<ProductReviewModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewIO.listByProductIdAscending',
            `${SERVER_URL}/product/review/list`,
            o => new ProductReviewModal(o),
            onReady,
            onError,
            { params: { productId, lastId, ascending: "" } }
        );
    }

    static listByProductIdDescending(
        productId: number,
        lastId: bigint,
        onReady: OnArrayResponse<ProductReviewModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewIO.listByProductIdDescending',
            `${SERVER_URL}/product/review/list`,
            o => new ProductReviewModal(o),
            onReady,
            onError,
            { params: { productId, lastId, descending: "" } }
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<ProductReviewModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewIO.listByUserIdAscending',
            `${SERVER_URL}/product/review/list`,
            o => new ProductReviewModal(o),
            onReady,
            onError,
            { params: { userId, lastId, ascending: "" } }
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastId: bigint,
        onReady: OnArrayResponse<ProductReviewModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewIO.listByUserIdDescending',
            `${SERVER_URL}/product/review/list`,
            o => new ProductReviewModal(o),
            onReady,
            onError,
            { params: { userId, lastId, descending: "" } }
        );
    }

    static search(
        query: string,
        lastId: bigint,
        onReady: OnArrayResponse<ProductReviewModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewIO.search',
            `${SERVER_URL}/product/review/list`,
            o => new ProductReviewModal(o),
            onReady,
            onError,
            { params: { query, lastId } }
        );
    }

    static delete(
        productReviewId: bigint,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'ProductReviewIO.search',
            `${SERVER_URL}/product/review/${productReviewId}`,
            o => o,
            onReady,
            onError,
        );
    }
}

export class ProductReviewLikeIO {
    static countByProductReviewId(
        productReviewId: bigint,
        onReady: OnNumberResponse,
        onError: OnErrorResponse
    ) {
        Requests.getCount(
            'ProductReviewLikeIO.countByProductReviewId',
            `${SERVER_URL}/product/review/${productReviewId}/like/count`,
            onReady,
            onError
        );
    }

    static listByProductReviewIdAscending(
        productReviewId: bigint,
        lastLikedAt: Date,
        onReady: OnArrayResponse<ProductReviewLikeModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewLikeIO.listByProductReviewIdAscending',
            `${SERVER_URL}/product/review/${productReviewId}/like/list`,
            o => new ProductReviewLikeModal(o),
            onReady,
            onError,
            { params: { lastLikedAt: lastLikedAt.toKSTString(), ascending: "" } }
        );
    }

    static listByProductReviewIdDescending(
        productReviewId: bigint,
        lastLikedAt: Date,
        onReady: OnArrayResponse<ProductReviewLikeModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewLikeIO.listByProductReviewIdDescending',
            `${SERVER_URL}/product/review/${productReviewId}/like/list`,
            o => new ProductReviewLikeModal(o),
            onReady,
            onError,
            { params: { lastLikedAt: lastLikedAt.toKSTString(), descending: "" } }
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastLikedAt: Date,
        onReady: OnArrayResponse<ProductReviewLikeModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewLikeIO.listByUserIdAscending',
            `${SERVER_URL}/product/review/like/list`,
            o => new ProductReviewLikeModal(o),
            onReady,
            onError,
            { params: { userId, lastLikedAt: lastLikedAt.toKSTString(), ascending: "" } }
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastLikedAt: Date,
        onReady: OnArrayResponse<ProductReviewLikeModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewLikeIO.listByUserIdDescending',
            `${SERVER_URL}/product/review/like/list`,
            o => new ProductReviewLikeModal(o),
            onReady,
            onError,
            { params: { userId, lastLikedAt: lastLikedAt, descending: "" } }
        );
    }

    static delete(
        productId: number,
        productReviewId: bigint,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'ProductReviewLikeIO.delete',
            `${SERVER_URL}/product/${productId}/review/${productReviewId}/like/list`,
            o => o,
            onReady,
            onError,
        );
    }
}

export class ProductReviewReportIO {
    static countByProductReviewId(
        productReviewId: bigint,
        onReady: OnObjectResponse<number>,
        onError: OnErrorResponse
    ) {
        Requests.getObject<number, { count: number }>(
            'ProductReviewReportIO.countByProductReviewId',
            `${SERVER_URL}/product/review/${productReviewId}/report/count`,
            o => o.count,
            onReady,
            onError
        );
    }

    static listDescending(
        lastReportedAt: Date,
        onReady: OnArrayResponse<ProductReviewReportModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { lastReportedAt, descending: "" }
        };
        Requests.getArray(
            'ProductReviewReportIO.listDescending',
            `${SERVER_URL}/product/review/report/list`,
            o => new ProductReviewReportModal(o),
            onReady,
            onError,
            config
        )
    }

    static listByProductReviewIdAscending(
        productReviewId: bigint,
        lastReportedAt: Date,
        onReady: OnArrayResponse<ProductReviewReportModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewReportIO.listByProductReviewIdAscending',
            `${SERVER_URL}/product/review/${productReviewId}/report/list`,
            o => new ProductReviewReportModal(o),
            onReady,
            onError,
            { params: { lastReportedAt: lastReportedAt.toKSTString(), ascending: "" } }
        );
    }

    static listByProductReviewIdDescending(
        productReviewId: bigint,
        lastReportedAt: Date,
        onReady: OnArrayResponse<ProductReviewReportModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewReportIO.listByProductReviewIdDescending',
            `${SERVER_URL}/product/review/${productReviewId}/report/list`,
            o => new ProductReviewReportModal(o),
            onReady,
            onError,
            { params: { lastReportedAt: lastReportedAt.toKSTString(), descending: "" } }
        );
    }

    static listByCauseIdAscending(
        causeId: number,
        lastReportedAt: Date,
        onReady: OnArrayResponse<ProductReviewReportModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewReportIO.listByCauseIdAscending',
            `${SERVER_URL}/product/review/report/list`,
            o => new ProductReviewReportModal(o),
            onReady,
            onError,
            { params: { causeId, lastReportedAt: lastReportedAt.toKSTString(), ascending: "" } }
        );
    }

    static listByCauseIdDescending(
        causeId: number,
        lastReportedAt: Date,
        onReady: OnArrayResponse<ProductReviewReportModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewReportIO.listByCauseIdDescending',
            `${SERVER_URL}/product/review/report/list`,
            o => new ProductReviewReportModal(o),
            onReady,
            onError,
            { params: { causeId, lastReportedAt: lastReportedAt.toKSTString(), descending: "" } }
        );
    }

    static listByReportedAtDescending(
        reportedAt: string,
        lastReportedAt: Date,
        onReady: OnArrayResponse<ProductReviewReportModal>,
        onError: OnErrorResponse
    ) {
        const config: AxiosRequestConfig = {
            params: { reportedAt, lastReportedAt, descending: "" }
        };
        Requests.getArray(
            'ProductReviewReportIO.listByReportedAtDescending',
            `${SERVER_URL}/product/review/report/list`,
            o => new ProductReviewReportModal(o),
            onReady,
            onError,
            config
        );
    }

    static listByUserIdAscending(
        userId: bigint,
        lastReportedAt: Date,
        onReady: OnArrayResponse<ProductReviewReportModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewReportIO.listByUserIdAscending',
            `${SERVER_URL}/product/review/report/list`,
            o => new ProductReviewReportModal(o),
            onReady,
            onError,
            { params: { userId, lastReportedAt: lastReportedAt, ascending: "" } }
        );
    }

    static listByUserIdDescending(
        userId: bigint,
        lastReportedAt: Date,
        onReady: OnArrayResponse<ProductReviewReportModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewReportIO.listByUserIdAscending',
            `${SERVER_URL}/product/review/report/list`,
            o => new ProductReviewReportModal(o),
            onReady,
            onError,
            { params: { userId, lastReportedAt: lastReportedAt, ascending: "" } }
        );
    }
}

export class ProductReviewReportCauseIO {
    static post(
        description: string,
        sortOrder: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.postObject(
            'ProductReviewReportCauseIO.post',
            `${SERVER_URL}/misc/product/review/report/cause`,
            o => o,
            onReady,
            onError,
            {description, sortOrder}
        );
    }

    static get(
        id: number,
        onReady: OnObjectResponse<ProductReviewReportCauseModal>,
        onError: OnErrorResponse
    ) {
        Requests.getObject(
            'ProductReviewReportCauseIO.get',
            `${SERVER_URL}/misc/product/review/report/cause/${id}`,
            o => new ProductReviewReportCauseModal(o),
            onReady,
            onError
        );
    }

    static listAll(
        onReady: OnArrayResponse<ProductReviewReportCauseModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'ProductReviewReportCauseIO.listAll',
            `${SERVER_URL}/misc/product/review/report/cause/list`,
            o => new ProductReviewReportCauseModal(o),
            onReady,
            onError,
        );
    }

    static update(
        productReviewReportCauseId: number,
        description: string,
        sortOrder: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.patchObject(
            'ProductReviewReportCauseIO.update',
            `${SERVER_URL}/misc/product/review/report/cause/${productReviewReportCauseId}`,
            o => o,
            onReady,
            onError,
            {description, sortOrder}
        );
    }

    static delete(
        productReviewReportCauseId: number,
        onReady: OnBaseResponse,
        onError: OnErrorResponse
    ) {
        Requests.delete(
            'ProductReviewReportCauseIO.delete',
            `${SERVER_URL}/misc/product/review/report/cause/${productReviewReportCauseId}`,
            o => o,
            onReady,
            onError
        );
    }
}