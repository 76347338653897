import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../util/Types";
import {BottomProgress, ErrorMessage, ErrorModal, Nav} from "../Common";
import {UserModal, UserSessionModal} from "../../modal/Users";
import {UserIO} from "../../io/Users";
import {MaterialInput} from "../Materials";

const pageId = 'user_list';
const searchInputId = `${pageId}_page_all_search`;

export default function List() {
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    return <>
        <Nav
            title="사용자"
            titleHref="/"
            titleIcon="chevron_left"
            excludeErrorModal={true}
            errorMessagePair={[errorMessage, setErrorMessage]} />
        <ErrorModal errorMessagePair={[errorMessage, setErrorMessage]} />
        <PageAll setErrorMessage={setErrorMessage} />;
    </>;
}

export type PageProps = {
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function PageAll(props: PageProps) {
    const [lock, setLock] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [hasMoreContents, setMoreContents] = useState<boolean>(true);
    const [users, setUsers] = useState<Optional<UserModal[]>>(null);
    const [query, setQuery] = useState<Optional<string>>(null);
    const [isUpdating, setUpdating] = useState<boolean>(false);

    useEffect(() => M.AutoInit());

    useEffect(() => {
        if (!query) {
            PrepareUsers([page, setPage], setMoreContents, [users, setUsers], setUpdating, props.setErrorMessage);
        }
    }, [lock]);

    useEffect(() => {
        if (query) {
            SearchUsers(query, [page, setPage], setMoreContents, [users, setUsers], setUpdating, props.setErrorMessage);
        }
    }, [query]);

    useEffect(() => {
        const onWindowScroll = () => OnWindowScroll([page, setPage], setMoreContents, [users, setUsers], query, [isUpdating, setUpdating], props.setErrorMessage);
        window.addEventListener('scroll', onWindowScroll);
        return function clean() {
            window.removeEventListener('scroll', onWindowScroll);
        }
    });

    return <PageWrapper
        lock={lock}
        users={users ?? []}
        hasMoreContents={hasMoreContents}
        query={query}
        setError={props.setErrorMessage}
        setLock={setLock}
        setPage={setPage}
        setMoreContents={setMoreContents}
        setUsers={setUsers}
        setQuery={setQuery} />;
}

function PrepareUsers(
    pageState: StatePair<number>,
    setMoreContents: React.Dispatch<boolean>,
    usersState: StatePair<Optional<UserModal[]>>,
    setUpdating: React.Dispatch<boolean>,
    setError: React.Dispatch<Optional<string>>
) {
    const [page, setPage] = pageState;
    const [stateUsers, setUsers] = usersState;
    const appendableUsers = Array.of(...(stateUsers ?? []));
    setUpdating(true);
    UserIO.listAscending(
        UserSessionModal.loadFromStorage()!.id,
        page,
        users => {
            setMoreContents(users.length === 10);
            setPage(page + 1);
            appendableUsers.push(...users);
            setUsers(appendableUsers);
            setUpdating(false);
        },
        error => {
            setError(error);
            setMoreContents(false);
            setUpdating(false);
        }
    );
}

function SearchUsers(
    query: string,
    pageState: StatePair<number>,
    setMoreContents: React.Dispatch<boolean>,
    usersState: StatePair<Optional<UserModal[]>>,
    setUpdating: React.Dispatch<boolean>,
    setError: React.Dispatch<Optional<string>>
) {
    const [page, setPage] = pageState;
    const [stateUsers, setUsers] = usersState;
    const appendableUsers = Array.of(...(stateUsers ?? []));
    UserIO.search(
        UserSessionModal.loadFromStorage()!.id,
        query,
        page,
        users => {
            setMoreContents(users.length === 20);
            setPage(page + 1);
            appendableUsers.push(...users);
            setUsers(appendableUsers);
            setUpdating(false);
        },
        error => {
            setError(error);
            setMoreContents(false);
            setUpdating(false);
        }
    );
}

function OnWindowScroll(
    pageState: StatePair<number>,
    setMoreContents: React.Dispatch<boolean>,
    usersState: StatePair<Optional<UserModal[]>>,
    query: Optional<string>,
    updatingState: StatePair<boolean>,
    setError: React.Dispatch<Optional<string>>,
) {
    const [isUpdating, setUpdating] = updatingState;
    if (!isUpdating && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // 업데이트중이지 않고 바닥까지 스크롤되었을 때
        if (!query) {
            PrepareUsers(pageState, setMoreContents, usersState, setUpdating, setError);
        } else {
            SearchUsers(query, pageState, setMoreContents, usersState, setUpdating, setError);
        }
    }
}

type PageWrapperProps = {
    lock: number;
    users: UserModal[];
    hasMoreContents: boolean;
    query: Optional<string>;

    setError: React.Dispatch<Optional<string>>;
    setLock: React.Dispatch<number>;
    setPage: React.Dispatch<number>;
    setMoreContents: React.Dispatch<boolean>;
    setUsers: React.Dispatch<Optional<UserModal[]>>;
    setQuery: React.Dispatch<Optional<string>>;
};

function PageWrapper(props: PageWrapperProps) {
    const userRows = props.users.map(user => <Row key={user.id.toString()} user={user} />);
    return <>
        <SearchWrapper
            lock={props.lock}
            query={props.query}
            setLock={props.setLock}
            setPage={props.setPage}
            setUsers={props.setUsers}
            setQuery={props.setQuery} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>이메일</th>
                <th>닉네임</th>
                <th>이름</th>
                <th>전화번호</th>
                <th>생년월일</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{userRows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={props.hasMoreContents} />
        </div>
    </>;
}

type SearchWrapperProps = {
    lock: number;
    query: Optional<string>;

    setLock: React.Dispatch<number>;
    setPage: React.Dispatch<number>;
    setUsers: React.Dispatch<Optional<UserModal[]>>;
    setQuery: React.Dispatch<Optional<string>>;
};

function SearchWrapper(props: SearchWrapperProps) {
    let searchIcon: JSX.Element;
    if (props.query) {
        const onClicked = () => OnSearchClearClicked(props.lock, props.setLock, props.setPage, props.setUsers, props.setQuery);
        searchIcon = <i className="material-icons prefix" style={{ cursor: "pointer" }} onClick={onClicked}>clear</i>;
    } else {
        const onClicked = () => OnSearchClicked(props.setPage, props.setUsers, props.setQuery);
        searchIcon = <i className="material-icons prefix" style={{ cursor: "pointer" }} onClick={onClicked}>search</i>;
    }

    return <div className="row">
        <form className="col s6 offset-s3">
            <div className="input-field">
                {searchIcon}
                <input id={searchInputId} type="text" className="validate" />
                <label htmlFor={searchInputId}>검색</label>
            </div>
        </form>
    </div>;
}

function OnSearchClearClicked(
    lock: number,
    setLock: React.Dispatch<number>,
    setPage: React.Dispatch<number>,
    setUsers: React.Dispatch<Optional<UserModal[]>>,
    setQuery: React.Dispatch<Optional<string>>
) {
    setPage(0);
    setQuery(null);
    setLock(lock + 1);
    setUsers(null);
    MaterialInput.get(`#${searchInputId}`).clearValue();
}

function OnSearchClicked(
    setPage: React.Dispatch<number>,
    setUsers: React.Dispatch<Optional<UserModal[]>>,
    setQuery: React.Dispatch<Optional<string>>
) {
    setPage(0);
    setUsers(null);
    setQuery(MaterialInput.get(`#${searchInputId}`).getValue());
}

type RowProps = {
    user: UserModal;
};

function Row(props: RowProps) {
    return <tr>
        <td>{props.user.id.toString()}</td>
        <td>{props.user.email}</td>
        <td>{props.user.nickname}</td>
        <td>{props.user.accountName}</td>
        <td>{props.user.phone}</td>
        <td>{props.user.birth}</td>
        <td><RowAction href={`/user/${props.user.id}`} icon={'open_in_new'} /></td>
    </tr>;
}

type RowActionProps = {
    href: string;
    icon: string;
};

function RowAction(props: RowActionProps) {
    return <a href={props.href} className="modal-trigger clickable">
        <i className="material-icons black-text">{props.icon}</i>
    </a>;
}