import {Route, Routes} from "react-router-dom";
import List from "./List";
import Detail from "./Detail";
import {AssertSignIn} from "../../../App";
import RecordRouter from "./record/RecordRouter";

export default function ParticipationRouter() {
    return <Routes>
        <Route path="/list" element={<AssertSignIn><List /></AssertSignIn>} />
        <Route path="/:adParticipationId" element={<AssertSignIn><Detail /></AssertSignIn>} />
        <Route path="/record/*" element={<AssertSignIn><RecordRouter /></AssertSignIn>} />
    </Routes>;
}