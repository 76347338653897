import {Route, Routes} from "react-router-dom";
import {List} from "./List";
import {Detail} from "./Detail";
import {Post} from "./Post";
import {Edit} from "./Edit";

export default function BestRouter() {
    return <>
        <Routes>
            <Route path={'/post'} element={<Post />} />
            <Route path={'/list'} element={<List />} />
            <Route path={'/:id'} element={<Detail />} />
            <Route path={'/:id/edit'} element={<Edit />} />
        </Routes>
    </>
}