import {ApplicationVersionModal, ApplicationVersionStatus, ApplicationVersionType} from "../../../../modal/Miscs";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../../util/Types";
import {ErrorMessage, Nav} from "../../../Common";
import {MiscApplicationVersionIO} from "../../../../io/Miscs";
import {MaterialModal} from "../../../Materials";

export default function List() {
    const [contents, setContents] = useState<Optional<ApplicationVersionModal[]>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
    });

    useEffect(() => {
        MiscApplicationVersionIO.listAll(setContents, setErrorMessage);
    }, []);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contents={contents}
            errorMessageState={[errorMessage, setErrorMessage]} />
    </>;
}

type PageWrapperProps = {
    contents: Optional<ApplicationVersionModal[]>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const rows = (props.contents ?? []).map(content => <Row key={content.id} content={content} />);
    return <>
        <Nav
            title="애플리케이션 버전"
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            errorMessagePair={props.errorMessageState} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>이름</th>
                <th>구분</th>
                <th>플랫폼</th>
                <th>만료</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="fixed-action-btn">
            <a className="btn-floating btn-large secondary" href='/misc/application/version/post'><i className="large material-icons">add</i></a>
        </div>
    </>;
}

type RowProps = {
    content: ApplicationVersionModal;
};

function Row(props: RowProps) {
    const content = props.content;
    if (!content) {
        return <tr />;
    }

    const expired = (ApplicationVersionStatus.EXPIRED.isEnabled(content.statusFlags)) ? "O" : "";

    return <tr>
        <td>{content.id}</td>
        <td>{content.name}</td>
        <td>{ApplicationVersionType.toString(content.type)}</td>
        <td>{content.platform}</td>
        <td>{expired}</td>
        <td>
            <a href={`/misc/application/version/${content.id}/edit`} className="modal-trigger clickable">
                <i className="material-icons black-text">edit</i>
            </a>
        </td>
    </tr>;
}