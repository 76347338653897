import {Component, MaterialComponent, MaterialInput, MaterialModal, MaterialSelectWrapper} from "../../Materials";
import {Optional, StateWrapper, wrapState} from "../../../util/Types";
import {map} from "../../../util/Iterators";
import React, {ChangeEvent, useEffect, useState} from "react";
import {UserNotificationType} from "../../../modal/Users";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {UserNotification} from "../../../io/Users";
import {Logger} from "../../../util/Environments";

const pageId = 'user_block_edit';
const userIdInputId = `${pageId}_user`;
const userIdIndicatorId = `${pageId}_user_indicator`;
const titleInputId = `${pageId}_title`;
const bodyInputId = `${pageId}_body`;
const typeSelectId = `${pageId}_type`;
const typeWrapperId = `${pageId}_type_wrapper`;
const adIdInputId = `${pageId}_ad_id`;
const productIdInputId = `${pageId}_product_id`;

export default function Post() {
    const userIdsPair = wrapState(useState<Set<bigint>>(new Set<bigint>()));
    const typePair = wrapState(useState<UserNotificationType>(UserNotificationType.NOTICE));
    const errorMessagePair = wrapState(useState<Optional<ErrorMessage>>(null));
    const rootEnabledPair = wrapState(useState<boolean>(true));

    useEffect(() => {
        MaterialSelectWrapper.init();
        MaterialModal.init();
        MaterialInput.init();
    });

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${userIdInputId}`),
            MaterialInput.getOrNull(`#${titleInputId}`),
            MaterialInput.getOrNull(`#${bodyInputId}`),
            MaterialInput.getOrNull(`#${typeWrapperId}`),
            MaterialInput.getOrNull(`#${adIdInputId}`),
            MaterialInput.getOrNull(`#${productIdInputId}`)
        ];

        if (rootEnabledPair.value) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabledPair.value]);

    useEffect(() => {
        if (errorMessagePair.value) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessagePair.value]);

    return <>
        <PageWrapper
            userIdsPair={userIdsPair}
            typePair={typePair}
            setErrorMessage={errorMessagePair.set}
            setRootEnabled={rootEnabledPair.set} />
    </>;
}

type PageWrapperProps = {
    userIdsPair: StateWrapper<Set<bigint>>,
    typePair: StateWrapper<UserNotificationType>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
};

function PageWrapper(props: PageWrapperProps) {
    const onSaveClicked = () => OnSaveClicked(
        props.userIdsPair.value,
        props.typePair.value,
        props.setErrorMessage,
        props.setRootEnabled
    );
    const onCancelClicked = () => window.history.back();
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", onCancelClicked]
    ];

    return <>
        <Nav
            titleHref='/'
            titleIcon="chevron_left"
            title="알림"
            barItems={barItems} />
        <Editor
            userIdsPair={props.userIdsPair}
            typePair={props.typePair} />
    </>;
}

function OnSaveClicked(
    userIds: Set<bigint>,
    type: UserNotificationType,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    if (userIds.size === 0) {
        setErrorMessage("사용자 ID를 입력해 주세요.");
        return
    }

    const title = MaterialInput.get(`#${titleInputId}`).getValue();
    if (title.isEmpty()) {
        setErrorMessage("제목을 입력해 주세요.");
        return;
    }

    const body = MaterialInput.get(`#${bodyInputId}`).getValue();
    if (body.isEmpty()) {
        setErrorMessage("내용을 입력해 주세요.");
        return;
    }

    let adId: Optional<number> = null;
    if (type === UserNotificationType.ANNOUNCEMENT) {
        adId = MaterialInput.get(`#${adIdInputId}`).getValue().toIntOrNull();
        if (adId === null) {
            setErrorMessage("광고 ID를 확인해 주세요.");
            return;
        }
    }

    let productId: Optional<number> = null;
    if (type === UserNotificationType.PRODUCT_REVIEW) {
        productId = MaterialInput.get(`#${productIdInputId}`).getValue().toIntOrNull();
        if (productId === null) {
            setErrorMessage("상품 ID를 확인해 주세요.");
            return;
        }
    }

    const onReady = () => {
        window.alert("알림을 발송했습니다.");
        window.history.back();
    };

    setRootEnabled(false);
    UserNotification.postByUserIds(
        userIds,
        title,
        body,
        type,
        adId,
        productId,
        onReady,
        setErrorMessage
    );
}

type EditorProps = {
    userIdsPair: StateWrapper<Set<bigint>>,
    typePair: StateWrapper<UserNotificationType>,
};

function Editor(props: EditorProps) {
    const { value: userIds, set: setUserIds } = props.userIdsPair;
    const { value: type, set: setType } = props.typePair;

    const onUserIdDeleteClicked = (userId: bigint) => {
        const set = new Set<bigint>(userIds);
        set.delete(userId);
        setUserIds(set);
    };

    const userIdRows = map(userIds, userId => <>
        <a key={userId.toString()} className="collection-item">
            <a>{userId.toString()}</a>
            <a className="secondary-content clickable" onClick={() => onUserIdDeleteClicked(userId)}>
                <i className="material-icons">delete</i>
            </a>
        </a>
    </>);

    const onUserIdAddClicked = () => {
        Logger.log(userIds);
        const input = MaterialInput.get(`#${userIdInputId}`);
        const userId = input.getValue().toBigIntOrNull();
        if (userId !== null && !userIds.has(userId)) {
            setUserIds(new Set<bigint>(userIds).add(userId));
            input.clearValue();
        }
        Logger.log(userIds);
    };

    const onTypeSelected = (event: ChangeEvent<HTMLSelectElement>) => {
        switch (event.target.selectedIndex) {
            case 0: setType(UserNotificationType.NOTICE); break;
            case 1: setType(UserNotificationType.EVENT); break;
            case 2: setType(UserNotificationType.ANNOUNCEMENT); break;
            case 3: setType(UserNotificationType.PRODUCT_REVIEW); break;
            case 4: setType(UserNotificationType.POINT_WITHDRAW_COMPLETE); break;
            case 5: setType(UserNotificationType.POINT_ADMIN); break;
        }
    };

    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s6 offset-s2"
                inputId={userIdInputId}
                inputType="number"
                inputPlaceHolder="사용자 ID"
                inputDataLength={19}
                label="사용자 ID" />
            <a className="col s2 waves-effect waves-light btn" onClick={onUserIdAddClicked}>
                <i className="material-icons">add</i>추가
            </a>
        </div>
        <div className="row cascade">
            <div id={userIdIndicatorId} className="col s8 offset-s2 collection" style={{ display: (userIds.size === 0) ? "none" : "inherit" }}>
                {userIdRows}
            </div>
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={titleInputId}
                inputPlaceHolder="최대 40자"
                inputDataLength={40}
                label="제목" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={bodyInputId}
                inputPlaceHolder="최대 80자"
                inputDataLength={80}
                label="내용" />
        </div>
        <div className="row cascade">
            <Component.Select
                values={UserNotificationType.values()}
                wrapperId={typeWrapperId}
                wrapperClasses="input-field col s8 offset-s2"
                selectId={typeSelectId}
                requireDefault={false}
                selectDefaultText={UserNotificationType.toString(UserNotificationType.NOTICE)}
                label="알림 유형"
                onChange={onTypeSelected} />
        </div>
        <div className="row cascade" style={{ display: (type === UserNotificationType.ANNOUNCEMENT) ? "inherit" : "none" }}>
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={adIdInputId}
                inputType="number"
                inputPlaceHolder="광고 ID"
                inputDataLength={19}
                label="광고 ID" />
        </div>
        <div className="row cascade" style={{ display: (type === UserNotificationType.PRODUCT_REVIEW) ? "inherit" : "none" }}>
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={productIdInputId}
                inputType="number"
                inputPlaceHolder="상품 ID"
                inputDataLength={19}
                label="상품 ID" />
        </div>
    </>;
}






