import {Optional} from "./Types";

type DatePart = [number, number, number];
type TimePart = [number, number, number, number];
const locale = 'ko-KR';
const formatGenerator: (length: number) => Intl.NumberFormatOptions = (length: number) => ({useGrouping: false, minimumIntegerDigits: length});

export class Dates {
    static isValidDate(value: string): boolean {
        return /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(?:\.\d*)?$/.test(value);
    }

    static parseObjectDates(body: any) {
        if (body === null || body === undefined || typeof body !== "object")
            return body;

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (value && typeof value === "string" && Dates.isValidDate(value)) {
                body[key] = new Date(value);
            } else if (value && typeof value === "object") {
                Dates.parseObjectDates(value);
            }
        }
    }

    private static format(original: number, length: number): string {
        return original.toLocaleString(locale, formatGenerator(length));
    }

    static formatDatePart(input: Date): DatePart {
        const year = input.getFullYear();
        const month = input.getMonth();
        const date = input.getDate();

        return [year, month, date];
    }

    static formatDateString(input: Date): string {
        const [year, month, date] = this.formatDatePart(input);

        return `${this.format(year, 4)}-${this.format(month, 2)}-${this.format(date, 2)}`;
    }

    static parseDateString(input: string): DatePart {
        return this.formatDatePart(new Date(input));
    }

    static formatTimePart(input: Date): TimePart {
        const hour = input.getHours();
        const minute = input.getMinutes();
        const second = input.getSeconds();
        const millis = input.getMilliseconds();

        return [hour, minute, second, millis];
    }

    static parseTimeString(input: string): TimePart {
        const [hour, minute, secondPart] = input.split(":");
        if (secondPart) {
            // hh:mm:ss OR hh:mm:ss.ZZZ
            const [second, millis] = secondPart.split(".");
            if (millis) {
                // hh:mm:ss.ZZZ
                return [parseInt(hour), parseInt(minute), parseInt(second), parseInt(millis)];
            } else {
                // hh:mm:ss
                return [parseInt(hour), parseInt(minute), parseInt(second), 0];
            }
        } else {
            // hh:mm
            return [parseInt(hour), parseInt(minute), 0, 0];
        }
    }

    static formatTimeString(input: Date, includeSecond?: boolean, includeMillis?: boolean) {
        const [hour, minute, second, millis] = this.formatTimePart(input);
        let time = `${this.format(hour, 2)}:${this.format(minute, 2)}`;
        if (includeSecond === true) {
            time += `:${this.format(second, 2)}`;
            if (includeMillis === true) {
                time += `.${this.format(millis, 3)}`;
            }
        }

        return time;
    }

    static formatTableRow(input: Date): string {
        const [year, month, date] = this.formatDatePart(input);
        const [hour, minute, second, millis] = this.formatTimePart(input);

        return `${year}년 ${month}월 ${date}일 ${hour}시 ${minute}분 ${second}초 ${millis}`;
    }

    static formatTableRowOrNull(input: Optional<Date>): Optional<string> {
        if (!input) {
            return null;
        }

        const [datePart, timePart] = input.toISOString().split("T");
        const [year, month, date] = datePart.split("-");
        const [hour, minute, secondPart] = timePart.split(":");
        const [second, millisPart] = secondPart.split(".")
        const [millis] = millisPart.split("Z");

        return `${year}년 ${month}월 ${date}일 ${hour}시 ${minute}분 ${second}초 ${millis}`;
    }
}