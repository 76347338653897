import {Route, Routes} from "react-router-dom";
import {AssertSignIn} from "../../../App";
import Edit from "./Edit";
import ItemRouter from "./item/ItemRouter";

export default function OptionRouter() {
    return <Routes>
        <Route path="/post" element={<AssertSignIn><Edit /></AssertSignIn>} />
        <Route path="/:productOptionId/edit" element={<AssertSignIn><Edit /></AssertSignIn>} />

        <Route path="/item/*" element={<AssertSignIn><ItemRouter /></AssertSignIn>} />
        <Route path="/:productOptionId/item/*" element={<AssertSignIn><ItemRouter /></AssertSignIn>} />
    </Routes>;
}