import {Route, Routes} from "react-router-dom";
import {AssertSignIn} from "../../App";
import WithdrawalRouter from "./withdrawal/WithdrawalRouter";
import UsageRouter from "./usage/UsageRouter";

export default function PointRouter() {
    return <Routes>
        <Route path="/usage/*" element={<AssertSignIn><UsageRouter /></AssertSignIn>} />
        <Route path="/withdrawal/*" element={<AssertSignIn><WithdrawalRouter /></AssertSignIn>} />
    </Routes>;
}