import {Comparator, Optional, Comparable} from "./Types";

declare global {
    interface Array<T> {
        isEmpty(): boolean;
        isNotEmpty(): boolean;

        binarySearch(what: T, comparator: Comparator<T>): number;

        insert(element: T, index?: number): number;

        all(predicate: (element: T) => boolean): boolean;
        any(predicate: (element: T) => boolean): boolean;
        contains(what: T, sorted?: boolean, comparator?: Comparator<T>): boolean;
        fold<R>(initial: R, operation: (acc: R, element: T) => R): R;
        foldIndexed<R>(initial: R, operation: (index: number, acc: R, element: T) => R): R;

        first(): T;
        firstOrNull(): Optional<T>;
        firstOrElse(defaultValue: () => T): T;

        last(): T;
        lastOrNull(): Optional<T>;
        lastOrElse(defaultValue: () => T): T;

        copy(this: T[]): T[];
        appended(this: T[], other: T[]): T[];
    }
}

Array.prototype.isEmpty = function () {
    return this.length === 0;
}

Array.prototype.isNotEmpty = function () {
    return this.length !== 0;
}

Array.prototype.binarySearch = function<T> (this: T[], what: T, comparator: Comparator<T>) {
    let lo = 0;
    let hi = this.length - 1;
    while (lo <= hi) {
        let mid = (hi + lo) >> 1;
        let compare = comparator(what, this[mid]);
        if (compare > 0) {
            lo = mid + 1;
        } else if (compare < 0) {
            hi = mid - 1;
        } else {
            return mid;
        }
    }

    return -lo - 1;
}

Array.prototype.insert = function<T> (this: T[], element: T, index?: number): number {
    if (index === undefined) {
        return this.push(element);
    }

    this.push(element);
    for (let i = this.length - 1; i >= 0; i--) {
        this[i] = this[i - 1];
    }

    this[0] = element;
    return 1;
}

Array.prototype.all = function<T> (predicate: (element: T) => boolean): boolean {
    for (const element of this) {
        if (!predicate(element)) {
            return false;
        }
    }

    return true;
}

Array.prototype.any = function<T> (predicate: (element: T) => boolean): boolean {
    for (const element of this) {
        if (predicate(element)) {
            return true;
        }
    }

    return false;
}

Array.prototype.contains = function<T extends Comparable<T>> (
    this: T[],
    what: T,
    sorted?: boolean
): boolean {
    if (this.isEmpty()) {
        return false;
    }

    if (sorted === true) {
        return this.binarySearch(what, (o1, o2) => o1.compareTo(o2)) >= 0;
    }

    for (const element of this) {
        if (element === what) {
            return true;
        }
    }

    return false;
}

Array.prototype.fold = function<T, R> (this: T[], initial: R, operation: (acc: R, element: T) => R): R {
    let accumulate = initial;
    for (const element of this) {
        accumulate = operation(accumulate, element);
    }

    return accumulate;
}

Array.prototype.foldIndexed = function<T, R> (this: T[], initial: R, operation: (index: number, acc: R, element: T) => R): R {
    let accumulate = initial;
    let index = 0;
    for (const element of this) {
        accumulate = operation(index++, accumulate, element);
    }

    return accumulate;
}

Array.prototype.first = function () {
    return this[0];
}

Array.prototype.firstOrNull = function () {
    if (this.isEmpty()) {
        return null;
    } else {
        return this.first();
    }
}

Array.prototype.firstOrElse = function<T> (defaultValue: () => T): T {
    return this.firstOrNull() ?? defaultValue();
}

Array.prototype.last = function () {
    return this[this.length - 1];
}

Array.prototype.lastOrNull = function() {
    if (this.isEmpty()) {
        return null;
    } else {
        return this.last();
    }
}

Array.prototype.lastOrElse = function<T> (defaultValue: () => T): T {
    return this.lastOrNull() ?? defaultValue();
}

Array.prototype.copy = function<T> (this: T[]): T[] {
    const copied: T[] = [];
    copied.push(...this);

    return copied;
}

Array.prototype.appended = function<T> (this: T[], other: T[]): T[] {
    const appended: T[] = [];
    appended.push(...this);
    appended.push(...other);

    return appended;
}

export function _util_array() {}

export function createArray<T>(size: number, init: (index: number) => T): T[] {
    return size.map(init);
}