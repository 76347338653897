import {useLocation, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {EventModal, NoticeModal} from "../../../modal/Miscs";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {MiscNoticeIO} from "../../../io/Miscs";
import {
    Component,
    MaterialComponent,
    MaterialDatepicker,
    MaterialInput,
    MaterialModal, MaterialTextarea, MaterialTimepicker
} from "../../Materials";
import qs from "qs";
import {StringBuilder} from "../../../util/StringBuilder";
import {StorageIO} from "../../../io/Services";

const pageId = 'notice_edit';
const categoryInputId = `${pageId}_category`;
const titleInputId = `${pageId}_title`;
const bodyTextareaId = `${pageId}_body`;
const descriptionImageInputId = `${pageId}_description_image`;

export default function Edit() {
    const {noticeId} = useParams() as { noticeId?: number };
    const {noticeCategoryId} = qs.parse(
        useLocation().search,
        { ignoreQueryPrefix: true }
    ) as { noticeCategoryId?: number };

    const [content, setContent] = useState<Optional<NoticeModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [errorRecover, setErrorRecover] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        MaterialInput.init();
        MaterialDatepicker.init();
        MaterialTimepicker.init();
    });

    useEffect(() => {
        if (noticeId) {
            MiscNoticeIO.get(noticeId, setContent, setErrorMessage);
            MaterialInput.getOrNull(`#${categoryInputId}`)?.disable();
        }
    }, [noticeId]);

    useEffect(() => {
        const categoryInput = (noticeId) ? null : MaterialInput.getOrNull(`#${categoryInputId}`);
        const inputs = [
            categoryInput,
            MaterialInput.getOrNull(`#${titleInputId}`),
            MaterialTextarea.getOrNull(`#${bodyTextareaId}`),
            MaterialInput.getOrNull(`#${descriptionImageInputId}`)
        ];
        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [noticeId, rootEnabled]);

    useEffect(() => {
        if (noticeCategoryId) {
            MaterialInput.getOrNull(`#${categoryInputId}`)?.setValue(noticeCategoryId.toString());
        }
    }, [noticeCategoryId]);

    useEffect(() => {
        if (content) {
            MaterialInput.getOrNull(`#${categoryInputId}`)?.setValue(content.categoryId.toString());
            MaterialInput.getOrNull(`#${titleInputId}`)?.setValue(content.title);
            MaterialTextarea.getOrNull(`#${bodyTextareaId}`)?.setValue(content.body);
        }
    }, [content]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contentState={[content, setContent]}
            errorMessageState={[errorMessage, setErrorMessage]}
            errorRecoverState={[errorRecover, setErrorRecover]}
            setRootEnabled={setRootEnabled} />
    </>;
}

type PageWrapperProps = {
    contentState: StatePair<Optional<NoticeModal>>;

    errorMessageState: StatePair<Optional<ErrorMessage>>;
    errorRecoverState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [content] = props.contentState;
    const [, setErrorMessage] = props.errorMessageState;
    const [, setErrorRecover] = props.errorRecoverState;
    const onSaveClicked = () => OnSaveClicked(content, setErrorMessage, setErrorRecover, props.setRootEnabled);
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", window.history.back]
    ];

    return <>
        <Nav
            title={"공지사항 " + ((content) ? "수정" : "추가")}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <Editor content={content} />
    </>;
}

function OnSaveClicked(
    content: Optional<NoticeModal>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const noticeCategoryId = MaterialInput.get(`#${categoryInputId}`).getValue().toIntOrNull();
    if (noticeCategoryId === null) {
        setErrorMessage('카테고리 ID를 확인해주세요.');
        return;
    }

    const title = MaterialInput.get(`#${titleInputId}`).getValue();
    if (title.length.notIn(1, 101)) {
        setErrorMessage('제목을 확인해주세요.');
        return;
    }

    const body = MaterialTextarea.get(`#${bodyTextareaId}`).getValue();
    if (body.length.notIn(1, 1001)) {
        setErrorMessage('내용을 확인해주세요.');
        return;
    }

    const descriptionImageInput = MaterialInput.get(`#${descriptionImageInputId}`);

    const onSucceed = () => {
        window.alert('저장되었습니다.');
        document.location = `/misc/notice/category/${noticeCategoryId}`;
    };

    const onUploadError = (content: NoticeModal, succeeded: string[], error: string) => {
        MaterialModal.getOrNull('#error_modal')?.setOnDismiss(() => document.location = "/misc/notice/list");
        const succeededLine = (succeeded.isNotEmpty()) ? StringBuilder.joinToString(succeeded, ", ", "성공한 항목: ") : "";
        setErrorRecover([
            'AWS에 접속하여 오류가 발생한 이미지 또는 비디오를 추가해야 합니다. 필요하다면 폴더를 생성해야 할 수 있습니다.',
            succeededLine,
            StorageIO.createPath(`misc/notice/${content.id}`)
        ]);
        setErrorMessage(error);
    };

    const onReady = (content: NoticeModal) => {
        StorageIO.uploadMaterialFiles(content, [
            { input: descriptionImageInput, path: NoticeModal.descriptionImagePath, onSucceedText: '설명 이미지' }
        ], onSucceed, (succeeded, error) => onUploadError(content, succeeded, error));
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    if (!content) {
        setRootEnabled(false);
        MiscNoticeIO.post(title, body, noticeCategoryId, onReady, onError);
    } else {
        if (content.title === title &&
            content.body === body) {
            onReady(content);
            return;
        }

        setRootEnabled(false);
        MiscNoticeIO.update(content.id, title, body, onReady, onError);
    }
}

type EditorProps = {
    content: Optional<NoticeModal>,
};

function Editor(props: EditorProps) {
    const descriptionImageContainer = (props.content !== null) ?
        <div className="row cascade">
            <StorageIO.StorageImage
                className="col s8 offset-s2"
                objectKey={NoticeModal.descriptionImagePath(props.content)} />
        </div> : <></>;
        
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={categoryInputId}
                inputType="number"
                inputClasses="validate"
                label="카테고리 ID" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={titleInputId}
                inputClasses="validate"
                inputPlaceHolder="100자 이내"
                inputDataLength={100}
                label="제목" />
        </div>
        <div className="row cascade">
            <Component.Textarea
                formClasses="col s8 offset-s2"
                textareaId={bodyTextareaId}
                textareaClasses="validate"
                textareaPlaceHolder="1,000자 이내"
                textareaDataLength={1000}
                label="내용" />
        </div>
        <div className="row cascade">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                inputId={descriptionImageInputId}
                inputLabel="설명 이미지 선택"
                multiple={true}
                accept="image/png" />
        </div>
        {descriptionImageContainer}
    </>;
}