import {Optional, StatePair} from "../../util/Types";
import {UserGender, UserModal, UserSessionModal, UserType} from "../../modal/Users";
import React, {useEffect, useState} from "react";
import {Component, MaterialComponent, MaterialDatepicker, MaterialInput, MaterialSelectWrapper} from "../Materials";
import {StorageIO} from "../../io/Services";
import {ErrorMessage, Nav, NavBarItemAction} from "../Common";
import {useParams} from "react-router-dom";
import {UserIO} from "../../io/Users";
import {S3} from "aws-sdk";
import {S3_BUCKET_NAME} from "../../util/Environments";
import {BaseResponse, BaseResponseMetadata} from "../../util/Reponses";

const pageId = 'user_editor'
const emailInputId = `${pageId}_email`;
const phoneInputId = `${pageId}_phone`;
const nicknameInputId = `${pageId}_nickname`;
const accountNameInputId = `${pageId}_account_name`;
const birthInputId = `${pageId}_birth`;
const genderSelectId = `${pageId}_gender`;
const genderWrapperId = `${pageId}_gender_wrapper`;
const typeSelectorId = `${pageId}_type`;
const typeWrapperId = `${pageId}_type_wrapper`;
const profileImageInputId = `${pageId}_profile_image`;
const profileImageButtonId = `${pageId}_profile_image_button`;

export default function Edit() {
    const {userId} = useParams();
    const [user, setUser] = useState<Optional<UserModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [errorRecover, setErrorRecover] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        M.AutoInit();
        MaterialDatepicker.init();
    });

    useEffect(() => {
        if (userId) {
            PrepareUser(BigInt(userId), setUser, setErrorMessage);
        }
    }, [userId]);

    useEffect(() => {
        if (user) {
            MaterialInput.getOrNull(`#${emailInputId}`)?.setValue(user.email);
            MaterialInput.getOrNull(`#${phoneInputId}`)?.setValue(user.phone);
            MaterialInput.getOrNull(`#${nicknameInputId}`)?.setValue(user.nickname);
            MaterialInput.getOrNull(`#${accountNameInputId}`)?.setValue(user.accountName);
            MaterialDatepicker.getOrNull(`#${birthInputId}`)?.setValue(user.birth);
            MaterialSelectWrapper.getOrNull(`#${genderWrapperId}`)?.setValue(UserGender.toString(user.gender));
            MaterialSelectWrapper.getOrNull(`#${typeWrapperId}`)?.setValue(UserType.toString(user.type));
        }
    }, [user]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${emailInputId}`),
            MaterialInput.getOrNull(`#${phoneInputId}`),
            MaterialInput.getOrNull(`#${nicknameInputId}`),
            MaterialInput.getOrNull(`#${accountNameInputId}`),
            MaterialDatepicker.getOrNull(`#${birthInputId}`),
            MaterialSelectWrapper.getOrNull(`#${genderWrapperId}`),
            MaterialSelectWrapper.getOrNull(`#${typeWrapperId}`),
        ];

        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled])

    return <PageWrapper
        user={user}
        errorMessageState={[errorMessage, setErrorMessage]}
        errorRecoverState={[errorRecover, setErrorRecover]}
        setRootEnabled={setRootEnabled} />
}

function PrepareUser(
    userId: bigint,
    setUser: React.Dispatch<Optional<UserModal>>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    UserIO.get(userId, setUser, setErrorMessage);
}

type PageWrapperProps = {
    user: Optional<UserModal>;

    errorMessageState: StatePair<Optional<ErrorMessage>>;
    errorRecoverState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const onSaveClicked = () => {
        if (props.user) {
            OnSaveClicked(
                props.user,
                props.errorMessageState[1],
                props.errorRecoverState[1],
                props.setRootEnabled
            );
        }
    };
    const onCancelClicked = () => {
        if (props.user) {
            OnCancelClick(props.user);
        }
    }
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", onCancelClicked]
    ];

    return <>
        <Nav
            titleHref={`/user/${props.user?.id}`}
            titleIcon={"chevron_left"}
            title={"사용자 수정"}
            barItems={barItems}
            errorMessagePair={props.errorMessageState}
            errorRecoverPair={props.errorRecoverState}
        />
        <Editor
            user={props.user} />
    </>;
}

function OnSaveClicked(
    user: UserModal,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const email = MaterialInput.get(`#${emailInputId}`).getValue();
    const phone = MaterialInput.get(`#${phoneInputId}`).getValue();
    const nickname = MaterialInput.get(`#${nicknameInputId}`).getValue();
    const accountName = MaterialInput.get(`#${accountNameInputId}`).getValue();
    const birth = MaterialDatepicker.get(`#${birthInputId}`).getValue();
    const gender = MaterialSelectWrapper.get(`#${genderWrapperId}`).getFirstSelectionAs(s => UserGender.values().indexOf(s) as UserGender)!;
    const type = MaterialSelectWrapper.get(`#${typeWrapperId}`).getFirstSelectionAs(s => UserType.values().indexOf(s) as UserType)!;
    const profileImageInput = MaterialInput.get(`#${profileImageInputId}`);
    const profileImageCount = profileImageInput.getFileLength();

    const onUploadResponse = (responses: S3.ManagedUpload.SendData) => {
        window.alert('수정되었습니다.');
        document.location = `/user/${user.id}`;
    };

    const onUploadError = (error: string) => {
        setErrorRecover([
            'AWS에 접속하여 오류가 발생한 이미지를 추가해야 합니다. 필요하다면 폴더를 생성해야 할 수 있습니다.',
            `https://s3.console.aws.amazon.com/s3/buckets/${S3_BUCKET_NAME}?region=ap-northeast-2&prefix=user/${user.id}`
        ]);
        setErrorMessage(error);
        setRootEnabled(true);
    };

    const onImageRead = (image: ArrayBuffer) => {
        StorageIO.upload(
            user.profileImagePath(),
            image,
            onUploadResponse,
            error => onUploadError(error)
        );
    };

    const onImageReadError = (error: string) => {
        setErrorRecover([
            'AWS에 접속하여 오류가 발생한 이미지를 추가해야 합니다. 필요하다면 폴더를 생성해야 할 수 있습니다.',
            `https://s3.console.aws.amazon.com/s3/buckets/${S3_BUCKET_NAME}?region=ap-northeast-2&prefix=user/${user.id}`
        ]);
        setErrorMessage(error);
        setRootEnabled(true);
    };

    const onReady = (baseResponse: BaseResponse) => {
        if (profileImageCount !== 0) {
            profileImageInput.readFileBuffer(
                image => onImageRead(image),
                error => onImageReadError(error)
            );
            return;
        }

        window.alert('수정되었습니다.');
        document.location = `/user/${user.id}`;
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    if (user.email === email &&
        user.phone === phone &&
        user.nickname === nickname &&
        user.accountName === accountName &&
        user.birth === birth &&
        user.gender === gender &&
        user.type === type) {
        onReady(BaseResponse.defaultValue());
        return;
    }

    setRootEnabled(false);
    UserIO.update(
        UserSessionModal.loadFromStorage()!.id,
        user.id,
        email,
        phone,
        nickname,
        accountName,
        birth,
        gender,
        type,
        onReady,
        onError
    );
}

function OnCancelClick(user: UserModal) {
    document.location = `/user/${user.id}`;
}

type EditorProps = {
    user: Optional<UserModal>
};

function Editor(props: EditorProps) {
    let profileImage: JSX.Element;
    if (props.user) {
        profileImage = <>
            <div className="row">
                <div className="col s8 offset-s2">
                    <StorageIO.StorageImage objectKey={props.user.profileImagePath()} style={{ maxWidth: '100%' }} />
                </div>
            </div>
        </>;
    } else {
        profileImage = <></>;
    }

    return <>
        <div className="row" style={{ marginTop: "2rem" }}>
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={emailInputId}
                inputType="email"
                inputClasses="validate"
                inputPlaceHolder="100자 이내"
                inputDataLength={100}
                label="이메일" />
        </div>
        <div className="row">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={phoneInputId}
                inputType="number"
                inputClasses="validate"
                inputPlaceHolder="11자 이내"
                inputDataLength={11}
                label="전화번호" />
        </div>
        <div className="row">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={nicknameInputId}
                inputClasses="validate"
                inputPlaceHolder="10자 이내"
                inputDataLength={10}
                label="닉네임" />
        </div>
        <div className="row">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={accountNameInputId}
                inputClasses="validate"
                inputPlaceHolder="10자 이내"
                inputDataLength={10}
                label="계정 이름(실명)" />
        </div>
        <div className="row">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={birthInputId}
                inputClasses="datepicker"
                inputPlaceHolder="yyyy-MM-dd"
                label="생년월일" />
        </div>
        <div className="row">
            <Component.Select
                values={UserGender.values()}
                wrapperId={genderWrapperId}
                wrapperClasses="input-field col s8 offset-s2"
                selectId={genderSelectId}
                label="성별" />
        </div>
        <div className="row">
            <Component.Select
                values={UserType.values()}
                wrapperId={typeWrapperId}
                wrapperClasses="col s8 offset-s2"
                selectId={typeSelectorId}
                label="구분" />
        </div>
        <div className="row">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                buttonId={profileImageButtonId}
                inputId={profileImageInputId}
                inputLabel="이미지 선택"
                accept="image/png" />
        </div>
        {profileImage}
    </>;
}