import {OnArrayResponse, OnErrorResponse, OnObjectResponse} from "../util/Reponses";
import {AdminHomeCountModal, UserPerfectModal} from "../modal/Admins";
import {Requests} from "../util/Requests";
import {SERVER_URL} from "../util/Environments";
import {AxiosRequestConfig} from "axios";

export class AdminIO {
    static get(
        onReady: OnObjectResponse<AdminHomeCountModal>,
        onError: OnErrorResponse
    ) {
        const duration = process.env.REACT_APP_ADMIN_HOME_COUNT_DURATION
        if (!duration) {
            onError("REACT_APP_ADMIN_HOME_COUNT_DURATION is not set!");
            return;
        }

        const config: AxiosRequestConfig = {
            params: { duration }
        };
        Requests.getObject<AdminHomeCountModal, AdminHomeCountModal>(
            'AdminIO.get',
            `${SERVER_URL}/admin/home/count`,
            o => o,
            onReady,
            onError,
            config
        );
    }
}

export const UserPerfectIO = Object.freeze({
    listAscendingByAdAll(
        lastUserId: bigint,
        lastAdAll: bigint,
        accuracy: number,
        onReady: OnArrayResponse<UserPerfectModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserPerfectIO.listAscendingByAdAll',
            `${SERVER_URL}/user/perfect/list`,
            UserPerfectModal.construct,
            onReady,
            onError,
            { params: { lastUserId, lastAdAll, accuracy, ascending: '' } }
        )
    },
    listAscendingByAdMonth(
        lastUserId: bigint,
        lastAdMonth: bigint,
        accuracy: number,
        onReady: OnArrayResponse<UserPerfectModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserPerfectIO.listAscendingByAdMonth',
            `${SERVER_URL}/user/perfect/list`,
            UserPerfectModal.construct,
            onReady,
            onError,
            { params: { lastUserId, lastAdMonth, accuracy, ascending: '' } }
        )
    },
    listAscendingByM10nAll(
        lastUserId: bigint,
        lastM10nAll: bigint,
        accuracy: number,
        onReady: OnArrayResponse<UserPerfectModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserPerfectIO.listAscendingByM10nAll',
            `${SERVER_URL}/user/perfect/list`,
            UserPerfectModal.construct,
            onReady,
            onError,
            { params: { lastUserId, lastM10nAll, accuracy, ascending: '' } }
        )
    },
    listAscendingByM10nMonth(
        lastUserId: bigint,
        lastM10nMonth: bigint,
        accuracy: number,
        onReady: OnArrayResponse<UserPerfectModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserPerfectIO.listAscendingByM10nMonth',
            `${SERVER_URL}/user/perfect/list`,
            UserPerfectModal.construct,
            onReady,
            onError,
            { params: { lastUserId, lastM10nMonth, accuracy, ascending: '' } }
        )
    },
    listAscendingByTotalAll(
        lastUserId: bigint,
        lastTotalAll: bigint,
        accuracy: number,
        onReady: OnArrayResponse<UserPerfectModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserPerfectIO.listAscendingByTotalAll',
            `${SERVER_URL}/user/perfect/list`,
            UserPerfectModal.construct,
            onReady,
            onError,
            { params: { lastUserId, lastTotalAll, accuracy, ascending: '' } }
        )
    },
    listAscendingByTotalMonth(
        lastUserId: bigint,
        lastTotalMonth: bigint,
        accuracy: number,
        onReady: OnArrayResponse<UserPerfectModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserPerfectIO.listAscendingByTotalMonth',
            `${SERVER_URL}/user/perfect/list`,
            UserPerfectModal.construct,
            onReady,
            onError,
            { params: { lastUserId, lastTotalMonth, accuracy, ascending: '' } }
        )
    },
    listDescendingByAdAll(
        lastUserId: bigint,
        lastAdAll: bigint,
        accuracy: number,
        onReady: OnArrayResponse<UserPerfectModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserPerfectIODelistAscendingByAdAll',
            `${SERVER_URL}/user/perfect/list`,
            UserPerfectModal.construct,
            onReady,
            onError,
            { params: { lastUserId, lastAdAll, accuracy, descending: '' } }
        )
    },
    listDescendingByAdMonth(
        lastUserId: bigint,
        lastAdMonth: bigint,
        accuracy: number,
        onReady: OnArrayResponse<UserPerfectModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserPerfectIO.lDestAscendingByAdMonth',
            `${SERVER_URL}/user/perfect/list`,
            UserPerfectModal.construct,
            onReady,
            onError,
            { params: { lastUserId, lastAdMonth, accuracy, descending: '' } }
        )
    },
    listDescendingByM10nAll(
        lastUserId: bigint,
        lastM10nAll: bigint,
        accuracy: number,
        onReady: OnArrayResponse<UserPerfectModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserPerfectIO.lDestAscendingByM10nAll',
            `${SERVER_URL}/user/perfect/list`,
            UserPerfectModal.construct,
            onReady,
            onError,
            { params: { lastUserId, lastM10nAll, accuracy, descending: '' } }
        )
    },
    listDescendingByM10nMonth(
        lastUserId: bigint,
        lastM10nMonth: bigint,
        accuracy: number,
        onReady: OnArrayResponse<UserPerfectModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserPerfectIO.lisDeAscendingByM10nMonth',
            `${SERVER_URL}/user/perfect/list`,
            UserPerfectModal.construct,
            onReady,
            onError,
            { params: { lastUserId, lastM10nMonth, accuracy, descending: '' } }
        )
    },
    listDescendingByTotalAll(
        lastUserId: bigint,
        lastTotalAll: bigint,
        accuracy: number,
        onReady: OnArrayResponse<UserPerfectModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserPerfectIO.liDetAscendingByTotalAll',
            `${SERVER_URL}/user/perfect/list`,
            UserPerfectModal.construct,
            onReady,
            onError,
            { params: { lastUserId, lastTotalAll, accuracy, descending: '' } }
        )
    },
    listDescendingByTotalMonth(
        lastUserId: bigint,
        lastTotalMonth: bigint,
        accuracy: number,
        onReady: OnArrayResponse<UserPerfectModal>,
        onError: OnErrorResponse
    ) {
        Requests.getArray(
            'UserPerfectIO.listDescendingByTotalMonth',
            `${SERVER_URL}/user/perfect/list`,
            UserPerfectModal.construct,
            onReady,
            onError,
            { params: { lastUserId, lastTotalMonth, accuracy, descending: '' } }
        )
    },
})