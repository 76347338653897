import React, {CSSProperties, DetailedHTMLProps, HTMLAttributes} from "react";

export namespace IntrinsicProperties {
    export type a = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
    export type abbr = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type address = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type area = React.DetailedHTMLProps<React.AreaHTMLAttributes<HTMLAreaElement>, HTMLAreaElement>;
    export type article = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type aside = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type audio = React.DetailedHTMLProps<React.AudioHTMLAttributes<HTMLAudioElement>, HTMLAudioElement>;
    export type b = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type base = React.DetailedHTMLProps<React.BaseHTMLAttributes<HTMLBaseElement>, HTMLBaseElement>;
    export type bdi = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type bdo = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type big = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type blockquote = React.DetailedHTMLProps<React.BlockquoteHTMLAttributes<HTMLQuoteElement>, HTMLQuoteElement>;
    export type body = React.DetailedHTMLProps<React.HTMLAttributes<HTMLBodyElement>, HTMLBodyElement>;
    export type br = React.DetailedHTMLProps<React.HTMLAttributes<HTMLBRElement>, HTMLBRElement>;
    export type button = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
    export type canvas = React.DetailedHTMLProps<React.CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement>;
    export type caption = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type cite = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type code = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type col = React.DetailedHTMLProps<React.ColHTMLAttributes<HTMLTableColElement>, HTMLTableColElement>;
    export type colgroup = React.DetailedHTMLProps<React.ColgroupHTMLAttributes<HTMLTableColElement>, HTMLTableColElement>;
    export type data = React.DetailedHTMLProps<React.DataHTMLAttributes<HTMLDataElement>, HTMLDataElement>;
    export type datalist = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDataListElement>, HTMLDataListElement>;
    export type dd = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type del = React.DetailedHTMLProps<React.DelHTMLAttributes<HTMLModElement>, HTMLModElement>;
    export type details = React.DetailedHTMLProps<React.DetailsHTMLAttributes<HTMLDetailsElement>, HTMLDetailsElement>;
    export type dfn = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type dialog = React.DetailedHTMLProps<React.DialogHTMLAttributes<HTMLDialogElement>, HTMLDialogElement>;
    export type div = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    export type dl = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDListElement>, HTMLDListElement>;
    export type dt = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type em = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type embed = React.DetailedHTMLProps<React.EmbedHTMLAttributes<HTMLEmbedElement>, HTMLEmbedElement>;
    export type fieldset = React.DetailedHTMLProps<React.FieldsetHTMLAttributes<HTMLFieldSetElement>, HTMLFieldSetElement>;
    export type figcaption = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type figure = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type footer = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type form = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;
    export type h1 = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
    export type h2 = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
    export type h3 = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
    export type h4 = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
    export type h5 = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
    export type h6 = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
    export type head = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadElement>, HTMLHeadElement>;
    export type header = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type hgroup = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type hr = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>;
    export type html = React.DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLHtmlElement>, HTMLHtmlElement>;
    export type i = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type iframe = React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>;
    export type img = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
    export type input = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    export type ins = React.DetailedHTMLProps<React.InsHTMLAttributes<HTMLModElement>, HTMLModElement>;
    export type kbd = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type keygen = React.DetailedHTMLProps<React.KeygenHTMLAttributes<HTMLElement>, HTMLElement>;
    export type label = React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;
    export type legend = React.DetailedHTMLProps<React.HTMLAttributes<HTMLLegendElement>, HTMLLegendElement>;
    export type li = React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>;
    export type link = React.DetailedHTMLProps<React.LinkHTMLAttributes<HTMLLinkElement>, HTMLLinkElement>;
    export type main = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type map = React.DetailedHTMLProps<React.MapHTMLAttributes<HTMLMapElement>, HTMLMapElement>;
    export type mark = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type menu = React.DetailedHTMLProps<React.MenuHTMLAttributes<HTMLElement>, HTMLElement>;
    export type menuitem = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type meta = React.DetailedHTMLProps<React.MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>;
    export type meter = React.DetailedHTMLProps<React.MeterHTMLAttributes<HTMLMeterElement>, HTMLMeterElement>;
    export type nav = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type noindex = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type noscript = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    // export type object = React.DetailedHTMLProps<React.ObjectHTMLAttributes<HTMLObjectElement>, HTMLObjectElement>;
    export type ol = React.DetailedHTMLProps<React.OlHTMLAttributes<HTMLOListElement>, HTMLOListElement>;
    export type optgroup = React.DetailedHTMLProps<React.OptgroupHTMLAttributes<HTMLOptGroupElement>, HTMLOptGroupElement>;
    export type option = React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>;
    export type output = React.DetailedHTMLProps<React.OutputHTMLAttributes<HTMLOutputElement>, HTMLOutputElement>;
    export type p = React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>;
    export type param = React.DetailedHTMLProps<React.ParamHTMLAttributes<HTMLParamElement>, HTMLParamElement>;
    export type picture = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type pre = React.DetailedHTMLProps<React.HTMLAttributes<HTMLPreElement>, HTMLPreElement>;
    export type progress = React.DetailedHTMLProps<React.ProgressHTMLAttributes<HTMLProgressElement>, HTMLProgressElement>;
    export type q = React.DetailedHTMLProps<React.QuoteHTMLAttributes<HTMLQuoteElement>, HTMLQuoteElement>;
    export type rp = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type rt = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type ruby = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type s = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type samp = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type slot = React.DetailedHTMLProps<React.SlotHTMLAttributes<HTMLSlotElement>, HTMLSlotElement>;
    export type script = React.DetailedHTMLProps<React.ScriptHTMLAttributes<HTMLScriptElement>, HTMLScriptElement>;
    export type section = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type select = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;
    export type small = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type source = React.DetailedHTMLProps<React.SourceHTMLAttributes<HTMLSourceElement>, HTMLSourceElement>;
    export type span = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
    export type strong = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type style = React.DetailedHTMLProps<React.StyleHTMLAttributes<HTMLStyleElement>, HTMLStyleElement>;
    export type sub = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type summary = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type sup = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type table = React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>;
    export type template = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTemplateElement>, HTMLTemplateElement>;
    export type tbody = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>;
    export type td = React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>;
    export type textarea = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
    export type tfoot = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>;
    export type th = React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement>;
    export type thead = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>;
    export type time = React.DetailedHTMLProps<React.TimeHTMLAttributes<HTMLTimeElement>, HTMLTimeElement>;
    export type title = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTitleElement>, HTMLTitleElement>;
    export type tr = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;
    export type track = React.DetailedHTMLProps<React.TrackHTMLAttributes<HTMLTrackElement>, HTMLTrackElement>;
    export type u = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type ul = React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>;
    // export type var = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type video = React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>;
    export type wbr = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    export type webview = React.DetailedHTMLProps<React.WebViewHTMLAttributes<HTMLWebViewElement>, HTMLWebViewElement>;
}

export function appendStyle<E extends HTMLAttributes<T>, T>(
    props: DetailedHTMLProps<E, T> | undefined,
    what: CSSProperties
): CSSProperties {
    const present = props?.style
    if (present === undefined) {
        return what
    } else {
        return { ...present, ...what }
    }
}

export function appendClassName<E extends HTMLAttributes<T>, T>(
    props: DetailedHTMLProps<E, T> | undefined,
    what: string
): string {
    const present = props?.className
    if (present === undefined || present.isEmpty()) {
        return what
    } else if (present.includes(what)) {
        return present
    } else {
        return present + ` ${what}`
    }
}