import React from "react";
import {UserSessionIO, UserSignInIO} from "../io/Users";
import {UserModal, UserSessionModal} from "../modal/Users";
import {encrypt, generateSalt} from "../util/Encryptions";
import {MaterialProgressBar} from "./Materials";
import {ApplicationVersionModal} from "../modal/Miscs";

function OnSignInClicked() {
    const emailField = document.querySelector('#sign_in_email');
    const passwordField = document.querySelector('#sign_in_password');
    if (!emailField || !passwordField) {
        return;
    }

    const email = (emailField as HTMLInputElement).value;
    const password = (passwordField as HTMLInputElement).value;

    if (email.length === 0) {
        M.toast({html: "이메일을 입력해주세요."});
        return;
    }

    if (password.length === 0) {
        M.toast({html: "비밀번호를 입력해주세요."});
        return;
    }

    MaterialProgressBar.get('#sign_in_progress').show();

    UserSignInIO.getSalt(
        email,
        salt => onSaltReady(email, password, salt),
        onSignInError
    );
}

function onSaltReady(
    email: string,
    password: string,
    salt: string
) {
    const encryptedPassword = encrypt(password, salt);
    const nextPasswordSalt = generateSalt();
    const nextPassword = encrypt(password, nextPasswordSalt);
    UserSignInIO.signIn(
        email,
        encryptedPassword,
        nextPassword,
        nextPasswordSalt,
        ApplicationVersionModal.instance.value.id,
        user => onUserReady(user, email, password, nextPassword),
        onSignInError
    );
}

function onUserReady(
    user: UserModal,
    email: string,
    password: string,
    encryptedPassword: string
) {
    user.saveStorage();
    const nextPasswordSalt = generateSalt();
    const nextPassword = encrypt(password, nextPasswordSalt);
    UserSessionIO.create(
        user.id,
        encryptedPassword,
        nextPassword,
        nextPasswordSalt,
        onSessionIdReady,
        onSessionIdError
    );
}

function onSignInError(error: string) {
    MaterialProgressBar.get('#sign_in_progress').hide();
    M.toast({html: "오류가 발생했습니다."});
}

function onSessionIdReady(sessionId: bigint) {
    UserSessionIO.get(
        sessionId,
        onUserSessionReady,
        onUserSessionError
    );
}

function onSessionIdError(error: string) {
    MaterialProgressBar.get('#sign_in_progress').hide();
    UserModal.clearStorage();
    M.toast({html: "오류가 발생했습니다."});
}

function onUserSessionReady(userSession: UserSessionModal) {
    userSession.saveStorage();

    document.location = "/";
}

function onUserSessionError(error: string) {
    MaterialProgressBar.get('#sign_in_progress').hide();
    UserModal.clearStorage();
    M.toast({html: "오류가 발생했습니다."});
}

export function SignIn() {
    return <div className="container">
        <div className="row centered center-row page-center">
            <div className="card col s6" style={{ marginLeft: 0, marginRight: 0 }}>
                <div id="sign_in_progress" className="progress hide">
                    <div className="indeterminate"/>
                </div>
                <div className="card-content">
                    {/*<span className="card-title">관리자 로그인</span>*/}
                    <img src="/res/logo_primary_horizontal_icon.svg" style={{ width: '10vw' }} />
                    <div className="input-field" style={{ marginTop: '2rem' }}>
                        <input id="sign_in_email" type="email" className="validate"/>
                        <label htmlFor="sign_in_email">이메일</label>
                    </div>
                    <div className="input-field">
                        <input id="sign_in_password" type="password" className="validate"/>
                        <label htmlFor="sign_in_password">비밀번호</label>
                    </div>
                    <div className="card-action">
                        <a id="sign_in_done" href="#" onClick={OnSignInClicked}>로그인</a>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
