import React, {useEffect} from "react";
import {BooleanWrapper, Optional, useBoolean, useWrapper} from "../../../util/Types";
import {BottomProgress, ErrorMessage, Nav} from "../../Common";
import {getMaxLong} from "../../../util/Environments";
import {PointWithdrawalModal, PointWithdrawalRejectCauseModal, PointWithdrawalStatus} from "../../../modal/Points";
import {PointWithdrawalIO, PointWithdrawalRejectCauseIO} from "../../../io/Points";
import {Icon} from "../../common/Icon";
import {MaterialModal} from "../../Materials";

export function List() {
    const hasMoreContents = useBoolean(true)

    useEffect(() => M.AutoInit())

    return <>
        <Nav
            title={'포인트 인출 요청'}
            titleHref={'/'}
            titleIcon={'chevron_left'} />
        <table className={'centered highlight'}>
            <thead>
            <tr>
                <th>포인트 사용 ID</th>
                <th>사용자 ID</th>
                <th>은행</th>
                <th>계좌번호</th>
                <th>예금주</th>
                <th>요청 금액</th>
                <th>닉네임</th>
                <th>계정 이름</th>
                <th>요청 시각</th>
                <th>인출 시각</th>
                <th>거절 시각</th>
                <th>거절 사유</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody><Rows hasMoreContents={hasMoreContents} /></tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents.value} />
        </div>
    </>
}

type RowsProps = {
    hasMoreContents: BooleanWrapper
}

function Rows(props: RowsProps) {
    const contents = useWrapper<PointWithdrawalModal.Qualified[]>([])
    const isFetching = useBoolean(false)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)
    const rejectCauses = useWrapper<PointWithdrawalRejectCauseModal[]>([])
    const rows = useWrapper<JSX.Element[]>([])

    useEffect(() => fetchContents(), [])
    useEffect(() => PointWithdrawalRejectCauseIO.listAll(rejectCauses.set, errorMessage.set), [])
    useEffect(() => MaterialModal.getOrNull('#error_modal')?.setVisibility(errorMessage.value !== null), [errorMessage.value])
    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    })

    const fetchContents = () => {
        isFetching.setTrue()
        PointWithdrawalIO.listQualifiedDescending(
            contents.value.lastOrNull()?.pointUsageId ?? getMaxLong(),
            response => {
                contents.set(contents.value.appended(response))
                rows.set(rows.value.appended(response.map((content, index) => <>
                    <Row
                        key={content.pointUsageId.toString()}
                        index={index}
                        content={content} />
                </>)))
                props.hasMoreContents.set(response.length >= 20)
                isFetching.setFalse()
            },
            error => {
                errorMessage.set(error)
                props.hasMoreContents.setFalse()
                isFetching.setFalse()
            }
        )
    }

    const onWindowScroll = () => {
        if (
            !isFetching.value &&
            props.hasMoreContents.value &&
            (window.innerHeight + window.scrollY) >= document.body.offsetHeight
        ) {
            fetchContents()
        }
    }

    function Row({index, content}: { index: number, content: PointWithdrawalModal.Qualified }) {
        const row = useWrapper<JSX.Element>(<></>)
        const {value, set: setValue} = useWrapper(content)
        useEffect(() => {
            row.set(<>
                <tr>
                    <td><a href={`/point/usage/${value.pointUsageId}`}>{value.pointUsageId.toString()}</a></td>
                    <td><a href={`/user/${value.userId}`}>{value.userId.toString()}</a></td>
                    <td>{value.bank.name}</td>
                    <td>{value.accountNumber}</td>
                    <td>{value.accountOwnerName}</td>
                    <td>{value.pointUsage.amount * -1}</td>
                    <td>{value.user.nickname}</td>
                    <td>{value.user.accountName}</td>
                    <td>{value.requestedAt.toRowFormat(true, true)}</td>
                    <td>{value.withdrawnAt?.toRowFormat(true, true) ?? '-'}</td>
                    <td>{value.rejectedAt?.toRowFormat(true, true) ?? '-'}</td>
                    <td>{value.rejectCause?.description ?? '-'}</td>
                    <td><Actions /></td>
                </tr>
            </>)
        }, [value])

        function Actions() {
            if (value.statusFlags === PointWithdrawalStatus.WAITING) {
                const dropdownId = `reject-dropdown-${content.pointUsageId}`
                const selections = rejectCauses.value.map(content => <>
                    <li
                        key={content.id}
                        style={{ width: '240px' }}>
                        <a onClick={() => onRejectCauseClick(content.id)}>{content.description}</a>
                    </li>
                </>)

                return <>
                    <a
                        style={{ cursor: 'pointer' }}
                        onClick={onWithdrawClick}>
                        <Icon iconName={'done'} />
                    </a>
                    <a
                        style={{ cursor: 'pointer' }}
                        className={'dropdown-trigger'}
                        data-target={dropdownId}
                        onClick={onRejectClick}>
                        <Icon iconName={'clear'} />
                    </a>
                    <ul
                        id={dropdownId}
                        className={'dropdown-content'}
                        style={{ width: '240px' }}>
                        {selections}
                    </ul>
                </>
            } else {
                return <></>
            }
        }

        const onWithdrawClick = () => PointWithdrawalIO.withdraw(
            value.pointUsageId,
            onUpdated,
            errorMessage.set
        )

        const onRejectClick = () => {
            const dropdownId = `reject-dropdown-${content.pointUsageId}`
            const dropdown = M.Dropdown
                .init(document.querySelectorAll('.dropdown-trigger'))
                .find(dropdown => dropdown.id === dropdownId)
            if (dropdown === undefined) {
                return
            }

            dropdown.options.constrainWidth = true
            dropdown.options.onOpenEnd = () => {
                const style = (dropdown.dropdownEl as HTMLElement).style
                const currentWidth = parseFloat(style.width.substring(0, style.width.length - 2))
                const widthDiff = 240 - currentWidth
                style.width = '240px'
                style.left = `${parseFloat(style.left.substring(0, style.left.length - 2)) - widthDiff}px`
            }
            dropdown.open()
        }

        const onRejectCauseClick = (causeId: number) => PointWithdrawalIO.reject(
            value.pointUsageId,
            causeId,
            onUpdated,
            errorMessage.set
        )

        const onUpdated = (response: PointWithdrawalModal) => {
            const nextContent = PointWithdrawalModal.Qualified.replace(
                value,
                response,
                rejectCauses.value.find(cause => cause.id === response.rejectCauseId)
            )
            setValue(nextContent)

            const nextContents = contents.value
            nextContents[index] = nextContent
            contents.set(nextContents)
        }

        return row.value
    }

    return <>{rows.value}</>
}