import {Optional, StateWrapper, unwrap, useBoolean, useWrapper} from "../../../../util/Types";
import {useEffect} from "react";
import {MaterialModal} from "../../../Materials";
import {ErrorMessage, Nav, NavBarItemAction} from "../../../Common";
import {TextField} from "../../../common/TextField";
import {FileField} from "../../../common/FileField";
import {MiscHomeBestIO} from "../../../../io/Miscs";
import {HomeBestModal} from "../../../../modal/Miscs";
import {StorageIO} from "../../../../io/Services";

export function Post() {
    const adId = useWrapper<Optional<number>>(null)
    const m10nId = useWrapper<Optional<number>>(null)
    const noticeId = useWrapper<Optional<number>>(null)
    const sortOrder = useWrapper<number>(1)
    const image = useWrapper<Optional<File>>(null)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)
    const disabled = useBoolean(false)

    useEffect(() => M.AutoInit())
    useEffect(() => {
        if (errorMessage.value) {
            MaterialModal.getOrNull('#error_modal')?.open();
        } else {
            MaterialModal.getOrNull('#error_modal')?.close();
        }
    }, [errorMessage.value]);

    const getId = (state: StateWrapper<Optional<number>>) => (state.value === null || state.value === 0) ? null : state.value

    const onSaveClick = () => {
        let enteredIds = 0
        enteredIds += (getId(adId) === null) ? 0 : 1
        enteredIds += (getId(m10nId) === null) ? 0 : 1
        enteredIds += (getId(noticeId) === null) ? 0 : 1
        if (enteredIds >= 2) {
            errorMessage.set('대상 ID는 모두 입력하지 않거나 하나만 입력해주세요.')
            return
        }
        if (sortOrder.value < 1 || sortOrder.value > 3) {
            errorMessage.set('표시 순서를 확인해주세요.')
            return
        }
        if (image.value === null) {
            errorMessage.set('이미지를 선택해주세요.')
            return
        }

        disabled.setTrue()
        post()
    }

    const post = () => MiscHomeBestIO.post({
        adId: getId(adId),
        m10nId: getId(m10nId),
        noticeId: getId(noticeId),
        sortOrder: sortOrder.value
    }, onPosted, onPostError)

    const onPosted = (response: HomeBestModal) => StorageIO.uploadFileSync(HomeBestModal.imagePath(response), image.value!)
        .then(onUploaded)
        .catch(onUploadError)

    const onPostError = (error: ErrorMessage) => {
        disabled.setFalse()
        errorMessage.set(error)
    }

    const onUploaded = () => {
        alert('추가되었습니다.')
        document.location = '/misc/home/best/list'
    }

    const onUploadError = (error: ErrorMessage) => {
        disabled.setFalse()
        errorMessage.set(error)
    }

    const onCancelClick = () => document.location = '/misc/home/best/list'

    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClick],
        ["취소", onCancelClick]
    ];

    return <>
        <Nav
            title={'홈 베스트 추가'}
            titleIcon={'chevron_left'}
            titleHref={`/misc/home/best/list`}
            barItems={barItems}
            errorMessageState={errorMessage} />
        <div className={'row cascade'} style={{ marginTop: "2rem" }}>
            <TextField
                label={'광고 ID 또는 0을 입력하여 무시'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: adId.value?.toString() ?? '', disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => adId.set(Number(value))} />
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'암기플러스 ID 또는 0을 입력하여 무시'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: m10nId.value?.toString() ?? '', disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => m10nId.set(Number(value))} />
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'공지사항 ID 또는 0을 입력하여 무시'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: noticeId.value?.toString() ?? '', disabled: disabled.value, type: 'number' }}
                onValueChange={(event, value) => noticeId.set(Number(value))} />
        </div>
        <div className={'row cascade'}>
            <TextField
                label={'표시 순서(1이상, 3이하)'}
                formProps={{ className: 'col s8 offset-s2' }}
                inputProps={{ value: sortOrder.value.toString() ?? '', disabled: disabled.value, type: 'number', min: 1, max: 3 }}
                onValueChange={(event, value) => sortOrder.set(Number(value))} />
        </div>
        <div className={'row cascade'}>
            <FileField
                formProps={{ className: 'col s8 offset-s2' }}
                title={'이미지'}
                accept={'image/png'}
                multiple={false}
                onChange={files => unwrap(files, files => unwrap(files?.item(0), image.set))} />
        </div>
    </>
}