import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {BottomProgress, ErrorMessage, Nav} from "../../Common";
import {MaterialModal} from "../../Materials";
import {getMaxLong} from "../../../util/Environments";
import {WingUsageModal, WingUsageType} from "../../../modal/Wings";
import {WingUsageIO} from "../../../io/Wings";

export default function List() {
    const [contents, setContents] = useState<Optional<WingUsageModal[]>>(null);
    const [hasMoreContents, setMoreContents] = useState<boolean>(true);
    const [isUpdating, setUpdating] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
        MaterialModal.init();
    });

    useEffect(() => {
        PrepareContents(
            [contents, setContents],
            [hasMoreContents, setMoreContents],
            [isUpdating, setUpdating],
            [errorMessage, setErrorMessage]
        );
    }, []);

    useEffect(() => {
        const onWindowScroll = () => OnWindowScroll(
            [contents, setContents],
            [hasMoreContents, setMoreContents],
            [isUpdating, setUpdating],
            [errorMessage, setErrorMessage]
        );
        window.addEventListener('scroll', onWindowScroll);
        return function clean() {
            window.removeEventListener('scroll', onWindowScroll);
        }
    });

    return <>
        <PageWrapper
            contentsState={[contents, setContents]}
            moreContentsState={[hasMoreContents, setMoreContents]}
            errorMessageState={[errorMessage, setErrorMessage]} />
    </>;
}

function PrepareContents(
    contentsState: StatePair<Optional<WingUsageModal[]>>,
    moreContentsState: StatePair<boolean>,
    updatingState: StatePair<boolean>,
    errorMessageState: StatePair<Optional<ErrorMessage>>
) {
    const [present, setContents] = contentsState;
    const [, setErrorMessage] = errorMessageState;
    const [, setMoreContents] = moreContentsState;

    const onResponse = (contents: WingUsageModal[]) => {
        setContents((present ?? []).appended(contents));
        setMoreContents(contents.length === 20);
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setMoreContents(false);
    };

    const lastId = present?.lastOrNull()?.id ?? getMaxLong();
    WingUsageIO.listDescending(lastId, onResponse, onError);
}

function OnWindowScroll(
    contentsState: StatePair<Optional<WingUsageModal[]>>,
    moreContentsState: StatePair<boolean>,
    updatingState: StatePair<boolean>,
    setErrorMessage: StatePair<Optional<ErrorMessage>>
) {
    const [isUpdating] = updatingState;
    if (!isUpdating && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // 업데이트중이지 않고 바닥까지 스크롤되었을 때
        PrepareContents(contentsState, moreContentsState, updatingState, setErrorMessage);
    }
}

type PageWrapperProps = {
    contentsState: StatePair<Optional<WingUsageModal[]>>;
    moreContentsState: StatePair<boolean>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const [contents] = props.contentsState;
    const [hasMoreContents] = props.moreContentsState;
    const rows = (contents ?? []).map(content =>
        <Row
            key={content.id.toString()}
            content={content} />
    );

    return <>
        <Nav
            title="날개 사용"
            titleHref="/"
            titleIcon="chevron_left"
            excludeErrorModal={true} />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>사용자 ID</th>
                <th>증감</th>
                <th>마지막 누적 날개</th>
                <th>구분</th>
                <th>광고 참여 ID</th>
                <th>포인트 사용 ID</th>
                <th>생성 시각</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents} />
        </div>
    </>;
}

type RowProps = {
    content: WingUsageModal;
};

function Row(props: RowProps) {
    let adParticipation = <td />;
    if (props.content.adParticipationId) {
        const adParticipationId = props.content.adParticipationId.toString();
        adParticipation = <td><a href={`/ad/participation/${adParticipationId}`}>{adParticipationId}</a></td>;
    }

    let pointUsage = <td />;
    if (props.content.pointUsageId) {
        const pointUsageId = props.content.pointUsageId.toString();
        pointUsage = <td><a href={`/point/usage/${pointUsageId}`}>{pointUsageId}</a></td>;
    }

    return <tr>
        <td><a href={`/wing/usage/${props.content.id}`}>{props.content.id.toString()}</a></td>
        <td><a href={`/user/${props.content.userId}`}>{props.content.userId.toString()}</a></td>
        <td>{props.content.amount}</td>
        <td>{props.content.lastAmount}</td>
        <td>{WingUsageType.toString(props.content.type)}</td>
        {adParticipation}
        {pointUsage}
        <td>{props.content.createdAt.toRowFormat(true, true)}</td>
        <td>
            <a href={`/point/usage/${props.content.id}`} style={{ cursor: "pointer" }}>
                <i className="material-icons black-text">open_in_new</i>
            </a>
        </td>
    </tr>;
}