import React, {useId} from "react";
import {appendClassName, appendStyle, IntrinsicProperties} from "../../util/Properties";
import {Icon, IconProps} from "./Icon";

export type TextFieldProps = {
    label: string
    onValueChange?(event: React.ChangeEvent<HTMLInputElement>, value: string): void

    formProps?: IntrinsicProperties.form
    iconProps?: IconProps
    inputProps?: IntrinsicProperties.input
    labelProps?: IntrinsicProperties.label
}

export function TextField(props: TextFieldProps) {
    const id = useId()
    const { label, onValueChange, formProps, iconProps, inputProps, labelProps } = props
    const icon = (iconProps)
        ? <>
            <Icon
                {...iconProps}
                style={appendStyle(iconProps, { cursor: 'pointer' })}
                className={appendClassName(iconProps, 'prefix')} />
        </>
        : <></>

    return <>
        <form {...formProps}>
            {icon}
            <input
                {...inputProps}
                id={id}
                onChange={event => onValueChange?.(event, event.target.value)}
                className={appendClassName(inputProps, 'input-field')} />
            <label
                {...labelProps}
                htmlFor={id}>
                {label}
            </label>
        </form>
    </>
}