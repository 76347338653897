import {Optional, StatePair} from "../../util/Types";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    ProductDeliveryModal,
    ProductDescriptionModal,
    ProductModal,
    ProductOptionModal,
    ProductReviewModal
} from "../../modal/Products";
import {BottomProgress, DetailCard, ErrorMessage, Nav, NavBarItemAction, NavTabItemProps} from "../Common";
import {ProductDeliveryIO, ProductDescriptionIO, ProductIO, ProductOptionIO, ProductReviewIO} from "../../io/Products";
import {getMaxLong, getMinLong} from "../../util/Environments";
import {MaterialModal} from "../Materials";
import { StorageIO } from "../../io/Services";

const pageId = 'product_detail';
const defaultPageId = `${pageId}_default`;
const descriptionPageId = `${pageId}_description`;
const optionPageId = `${pageId}_option`;
const reviewPageId = `${pageId}_review`;
const deliveryPageId = `${pageId}_delivery`;

type ContentStates<T> = {
    contentCount: StatePair<Optional<number>>;
    contents: StatePair<Optional<T[]>>;
    contentAscending: StatePair<boolean>;
    hasMoreContents: StatePair<boolean>;
    isUpdating: StatePair<boolean>;
};

type RowProps<T> = {
    content: T
};

export default function Detail() {
    const {productId} = useParams() as { productId?: number };
    const [content, setContent] = useState<Optional<ProductModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    const descriptionStates: ContentStates<ProductDescriptionModal> = {
        contentCount: useState<Optional<number>>(null),
        contents: useState<Optional<ProductDescriptionModal[]>>(null),
        contentAscending: useState(true),
        hasMoreContents: useState(true),
        isUpdating: useState(false)
    };

    const [descriptions, setDescriptions] = descriptionStates.contents;
    const [descriptionAscending] = descriptionStates.contentAscending;

    const optionStates: ContentStates<ProductOptionModal> = {
        contentCount: useState<Optional<number>>(null),
        contents: useState<Optional<ProductOptionModal[]>>(null),
        contentAscending: useState(true),
        hasMoreContents: useState(true),
        isUpdating: useState(false)
    };

    const [options, setOptions] = optionStates.contents;
    const [optionAscending] = optionStates.contentAscending;

    const reviewStates: ContentStates<ProductReviewModal> = {
        contentCount: useState<Optional<number>>(null),
        contents: useState<Optional<ProductReviewModal[]>>(null),
        contentAscending: useState(true),
        hasMoreContents: useState(true),
        isUpdating: useState(false)
    };

    const [reviews, setReviews] = reviewStates.contents;
    const [reviewAscending] = reviewStates.contentAscending;
    const [, setMoreReviews] = reviewStates.hasMoreContents;

    const deliveryStates: ContentStates<ProductDeliveryModal> = {
        contentCount: useState<Optional<number>>(null),
        contents: useState<Optional<ProductDeliveryModal[]>>(null),
        contentAscending: useState(true),
        hasMoreContents: useState(true),
        isUpdating: useState(false)
    };

    const [deliveries, setDeliveries] = deliveryStates.contents;
    const [deliveryAscending] = deliveryStates.contentAscending;
    const [, setMoreDeliveries] = deliveryStates.hasMoreContents;

    useEffect(() => {
        M.AutoInit();
        M.Tabs.init(document.querySelectorAll('.tabs'));
        M.Carousel.init(document.querySelectorAll('.carousel.carousel-slider'), { fullWidth: true, indicators: true });
    });

    useEffect(() => {
        if (productId) {
            ProductIO.get(productId, setContent, setErrorMessage);
            ProductDescriptionIO.listByProductId(productId, setDescriptions, setErrorMessage);
            ProductOptionIO.listByProductId(productId, setOptions, setErrorMessage);
            ProductReviewIO.listByProductIdAscending(productId, (reviews ?? []).lastOrNull()?.id ?? getMinLong(), setReviews, setErrorMessage);
        }
    }, [productId]);

    useEffect(() => {
        if (!descriptions) {
            return;
        }

        const copied = descriptions.copy();
        if (descriptionAscending) {
            copied.sort((a, b) => a.sortOrder - b.sortOrder);
        } else {
            copied.sort((a, b) => b.sortOrder - a.sortOrder);
        }

        setDescriptions(copied);
    }, [descriptionAscending]);

    useEffect(() => {
        if (!options) {
            return;
        }

        const copied = options.copy();
        if (optionAscending) {
            copied.sort((a, b) => a.sortOrder - b.sortOrder);
        } else {
            copied.sort((a, b) => b.sortOrder - a.sortOrder);
        }

        setOptions(copied);
    }, [optionAscending]);

    useEffect(() => {
        if (productId) {
            PrepareReviews(productId, reviewStates.contents, reviewAscending, setMoreReviews, reviewStates.isUpdating, setErrorMessage);
        }
    }, [productId, reviewAscending]);

    useEffect(() => {
        if (productId) {
            PrepareDeliveries(productId, deliveryStates.contents, deliveryAscending, setMoreDeliveries, deliveryStates.isUpdating, setErrorMessage);
        }
    }, [productId, deliveryAscending]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull(`#error_modal`)?.open();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            content={content}
            descriptionStates={descriptionStates}
            optionStates={optionStates}
            reviewStates={reviewStates}
            deliveryStates={deliveryStates}
            setErrorMessage={setErrorMessage} />
    </>;
}

type PageWrapperProps = {
    content: Optional<ProductModal>;
    descriptionStates: ContentStates<ProductDescriptionModal>;
    optionStates: ContentStates<ProductOptionModal>;
    reviewStates: ContentStates<ProductReviewModal>;
    deliveryStates: ContentStates<ProductDeliveryModal>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const barItems: [string, NavBarItemAction][] = [
        ["수정", () => { document.location = `/product/${props.content?.id}/edit` }]
    ];

    const tabItems: ReadonlyArray<NavTabItemProps> = [
        { text: "자세히", href: `#${defaultPageId}` },
        { text: "설명", href: `#${descriptionPageId}` },
        { text: "옵션", href: `#${optionPageId}` },
        { text: "리뷰", href: `#${reviewPageId}` },
        { text: "배송", href: `#${deliveryPageId}` },
    ];

    return <>
        <Nav
            title="상품"
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            tabItems={tabItems} />
        <DefaultPage content={props.content} />
        <DescriptionPage content={props.content} states={props.descriptionStates} />
        <OptionPage content={props.content} states={props.optionStates} />
        <ReviewPage content={props.content} states={props.reviewStates} setErrorMessage={props.setErrorMessage} />
        <DeliveryPage content={props.content} states={props.deliveryStates} setErrorMessage={props.setErrorMessage} />
    </>;
}

type DefaultPageProps = {
    content: Optional<ProductModal>;
};

function DefaultPage(props: DefaultPageProps) {
    const content = props.content;
    if (!content) {
        return <></>;
    }

    const headerImages = content.headerImageCount.map(index =>
        <div key={index} className="carousel-item">
            <StorageIO.StorageImage objectKey={ProductModal.headerImagePath(content, index)} style={{ width: "intrinsic" }} />
        </div>
    );

    const headerImageContainer: JSX.Element = <div className="col s8 offset-s2">
        <div className="card">
            <div className="card-image">
                <div className="carousel carousel-slider center">
                    {headerImages}
                </div>
            </div>
            <div className="card-content">
                <span className="card-title">상단 이미지 ({content.headerImageCount})</span>
            </div>
        </div>
    </div>;

    const descriptionImages = content.descriptionImageCount.map(index =>
        <div key={index} className="carousel-item">
            <StorageIO.StorageImage objectKey={ProductModal.descriptionImagePath(content, index)} style={{ width: "intrinsic" }} />
        </div>
    );

    const descriptionImageContainer = <div className="col s8 offset-s2">
        <div className="card">
            <div className="card-image">
                <div className="carousel carousel-slider center">
                    {descriptionImages}
                </div>
            </div>
            <div className="card-content">
                <span className="card-title">설명 이미지 ({content.descriptionImageCount})</span>
            </div>
        </div>
    </div>;

    return <div id={defaultPageId}>
        <div className="row cascade first">
            <DetailCard title="ID" value={content.id.toString()} />
        </div>
        <div className="row cascade">
            <DetailCard title="상품 브랜드 ID" value={content.productBrandId.toString()} />
        </div>
        <div className="row cascade">
            <DetailCard title="이름" value={content.name} />
        </div>
        <div className="row cascade">
            <DetailCard title="가격" value={content.price + "원"} />
        </div>
        <div className="row cascade">
            <DetailCard title="생성 일시" value={content.createdAt.toRowFormat(true, true, true, true)} />
        </div>
        <div className="row cascade">
            {headerImageContainer}
        </div>
        <div className="row cascade">
            {descriptionImageContainer}
        </div>
    </div>
}

type DescriptionPageProps = {
    content: Optional<ProductModal>;
    states: ContentStates<ProductDescriptionModal>;
};

function DescriptionPage(props: DescriptionPageProps) {
    const [contents] = props.states.contents;
    const [contentAscending, setContentAscending] = props.states.contentAscending;
    const rows = (contents ?? []).map(content => <DescriptionRow key={content.id} content={content}/>);

    return <div id={descriptionPageId}>
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>제목</th>
                <th>내용</th>
                <th className="clickable" onClick={() => setContentAscending(!contentAscending)}>표시 순서</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="fixed-action-btn">
            <a className="btn-floating btn-large secondary" href={`/product/${props.content?.id}/description/post`}>
                <i className="large material-icons">add</i>
            </a>
        </div>
    </div>;
}

function DescriptionRow(props: RowProps<ProductDescriptionModal>) {
    return <tr>
        <td>{props.content.id}</td>
        <td>{props.content.title}</td>
        <td>{props.content.body}</td>
        <td>{props.content.sortOrder}</td>
        <td>
            <a href={`/product/${props.content.id}/description/${props.content.id}/edit`} style={{ cursor: "pointer" }}>
                <i className="material-icons black-text">edit</i>
            </a>
        </td>
    </tr>;
}

type OptionPageProp = {
    content: Optional<ProductModal>;
    states: ContentStates<ProductOptionModal>;
};

function OptionPage(props: OptionPageProp) {
    const [contents] = props.states.contents;
    const [contentAscending, setContentAscending] = props.states.contentAscending;
    const rows = (contents ?? []).map(content => <OptionRow key={content.id} content={content}/>);

    return <div id={optionPageId}>
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>제목</th>
                <th className="clickable" onClick={() => setContentAscending(!contentAscending)}>표시 순서</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="fixed-action-btn">
            <a className="btn-floating btn-large secondary" href={`/product/${props.content?.id}/option/post`}>
                <i className="large material-icons">add</i>
            </a>
        </div>
    </div>;
}

function OptionRow(props: RowProps<ProductOptionModal>) {
    return <tr>
        <td>{props.content.id}</td>
        <td>{props.content.title}</td>
        <td>{props.content.sortOrder}</td>
        <td>
            <a href={`/product/option/${props.content.id}/item/list`} className="clickable">
                <i className="material-icons black-text">list</i>
            </a>
            <a href={`/product/${props.content.id}/option/${props.content.id}/edit`} className="clickable">
                <i className="material-icons black-text">edit</i>
            </a>
        </td>
    </tr>;
}

type ReviewPageProps = {
    content: Optional<ProductModal>;
    states: ContentStates<ProductReviewModal>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function ReviewPage(props: ReviewPageProps) {
    const [contents] = props.states.contents;
    const [contentAscending, setContentAscending] = props.states.contentAscending;
    const [hasMoreContents, setMoreContents] = props.states.hasMoreContents;

    useEffect(() => {
        const content = props.content;
        if (!content) {
            return () => {};
        }

        const clean = () => window.removeEventListener('scroll', onWindowScroll);
        const onWindowScroll = () => OnReviewWindowScroll(
            content.id,
            props.states.contents,
            contentAscending,
            setMoreContents,
            props.states.isUpdating,
            props.setErrorMessage
        );

        window.addEventListener('scroll', onWindowScroll);
        return clean;
    });

    const rows = (contents ?? []).map(content => <ReviewRow key={content.id.toString()} content={content}/>);
    return <div id={reviewPageId}>
        <table className="centered highlight">
            <thead>
            <tr>
                <th className="clickable" onClick={() => setContentAscending(!contentAscending)}>ID</th>
                <th>사용자 ID</th>
                <th>내용</th>
                <th>평점</th>
                <th>작성 일시</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents} />
        </div>
    </div>;
}

function PrepareReviews(
    productId: number,
    contentsState: StatePair<Optional<ProductReviewModal[]>>,
    contentAscending: boolean,
    setMoreContents: React.Dispatch<boolean>,
    updatingState: StatePair<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const [isUpdating, setUpdating] = updatingState;
    if (isUpdating) {
        return;
    }

    setUpdating(true);
    const [present, setContents] = contentsState;
    const onReady = (contents: ProductReviewModal[]) => {
        setContents((present ?? []).appended(contents));
        setMoreContents(contents.length === 20);
        setUpdating(false);
    };
    const onError = (error: string) => {
        setErrorMessage(error);
        setMoreContents(false);
        setUpdating(false);
    };

    const lastId = (present ?? []).lastOrNull()?.id;
    if (contentAscending) {
        ProductReviewIO.listByProductIdAscending(productId, lastId ?? getMinLong(), onReady, onError);
    } else {
        ProductReviewIO.listByProductIdDescending(productId, lastId ?? getMaxLong(), onReady, onError);
    }
}

function OnReviewWindowScroll(
    productId: number,
    contentsState: StatePair<Optional<ProductReviewModal[]>>,
    contentAscending: boolean,
    setMoreContents: React.Dispatch<boolean>,
    updatingState: StatePair<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // 업데이트중이지 않고 바닥까지 스크롤되었을 때
        PrepareReviews(productId, contentsState, contentAscending, setMoreContents, updatingState, setErrorMessage);
    }
}

function ReviewRow(props: RowProps<ProductReviewModal>) {
    return <tr>
        <td>{props.content.id.toString()}</td>
        <td><a href={`/user/${props.content.userId}`}>{props.content.userId.toString()}</a></td>
        <td>{props.content.body}</td>
        <td>{props.content.rating / 2}</td>
        <td>{props.content.writtenAt.toRowFormat(true, true, false, false)}</td>
        <td>
            <a href={`/product/${props.content.id}/review/${props.content.id}`} style={{ cursor: "pointer" }}>
                <i className="material-icons black-text">open_in_new</i>
            </a>
        </td>
    </tr>;
}

type DeliveryPageProps = {
    content: Optional<ProductModal>;
    states: ContentStates<ProductDeliveryModal>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
};

function DeliveryPage(props: DeliveryPageProps) {
    const [contents] = props.states.contents;
    const [contentAscending, setContentAscending] = props.states.contentAscending;
    const [hasMoreContents, setMoreContents] = props.states.hasMoreContents;

    useEffect(() => {
        const content = props.content;
        if (!content) {
            return () => {};
        }

        const clean = () => window.removeEventListener('scroll', onWindowScroll);
        const onWindowScroll = () => OnDeliveryWindowScroll(
            content.id,
            props.states.contents,
            contentAscending,
            setMoreContents,
            props.states.isUpdating,
            props.setErrorMessage
        );

        window.addEventListener('scroll', onWindowScroll);
        return clean;
    });

    const rows = (contents ?? []).map(content => <DeliveryRow key={content.id.toString()} content={content}/>);
    return <div id={deliveryPageId}>
        <table className="centered highlight">
            <thead>
            <tr>
                <th className="clickable" onClick={() => setContentAscending(!contentAscending)}>ID</th>
                <th>사용자 ID</th>
                <th>수취인 이름</th>
                <th>우편번호</th>
                <th>기본 주소</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents} />
        </div>
    </div>;
}

function PrepareDeliveries(
    productId: number,
    contentsState: StatePair<Optional<ProductDeliveryModal[]>>,
    contentAscending: boolean,
    setMoreContents: React.Dispatch<boolean>,
    updatingState: StatePair<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const [isUpdating, setUpdating] = updatingState;
    if (isUpdating) {
        return;
    }

    setUpdating(true);
    const [present, setContents] = contentsState;
    const onReady = (contents: ProductDeliveryModal[]) => {
        setContents((present ?? []).appended(contents));
        setMoreContents(contents.length === 20);
        setUpdating(false);
    };
    const onError = (error: string) => {
        setErrorMessage(error);
        setMoreContents(false);
        setUpdating(false);
    };

    const lastId = (present ?? []).lastOrNull()?.id;
    if (contentAscending) {
        ProductDeliveryIO.listByProductIdAscending(productId, lastId ?? getMinLong(), onReady, onError);
    } else {
        ProductDeliveryIO.listByProductIdDescending(productId, lastId ?? getMaxLong(), onReady, onError);
    }
}

function OnDeliveryWindowScroll(
    productId: number,
    contentsState: StatePair<Optional<ProductDeliveryModal[]>>,
    contentAscending: boolean,
    setMoreContents: React.Dispatch<boolean>,
    updatingState: StatePair<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // 업데이트중이지 않고 바닥까지 스크롤되었을 때
        PrepareDeliveries(productId, contentsState, contentAscending, setMoreContents, updatingState, setErrorMessage);
    }
}

function DeliveryRow(props: RowProps<ProductDeliveryModal>) {
    const id = props.content.id.toString();
    const productId = props.content.productId.toString();
    const requesterId = props.content.requesterId.toString();
    return <tr>
        <td><a href={`/product/${productId}/delivery/${id}`}>{id}</a></td>
        <td><a href={`/user/${requesterId}`}>{requesterId}</a></td>
        <td>{props.content.recipientName}</td>
        <td>{props.content.postcode}</td>
        <td>{props.content.addressPrefix}</td>
        <td>
            <a href={`/product/${props.content.id}/delivery/${props.content.id}`} style={{ cursor: "pointer" }}>
                <i className="material-icons black-text">open_in_new</i>
            </a>
        </td>
    </tr>;
}