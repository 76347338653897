import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../../util/Types";
import {FaqCategoryModal} from "../../../../modal/Miscs";
import {ErrorMessage, Nav, NavBarItemAction} from "../../../Common";
import {MiscFaqCategoryIO} from "../../../../io/Miscs";
import {Component, MaterialComponent, MaterialInput, MaterialModal} from "../../../Materials";

const pageId = 'faq_category_edit';
const nameInputId = `${pageId}_name`;
const parentInputId = `${pageId}_parent`;

export default function Edit() {
    const {faqCategoryId} = useParams() as { faqCategoryId?: number };
    const [content, setContent] = useState<Optional<FaqCategoryModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        if (faqCategoryId) {
            MiscFaqCategoryIO.get(faqCategoryId, setContent, setErrorMessage);
        }
    }, [faqCategoryId]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${nameInputId}`),
            MaterialInput.getOrNull(`#${parentInputId}`),
        ];
        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled]);

    useEffect(() => {
        if (content) {
            MaterialInput.getOrNull(`#${nameInputId}`)?.setValue(content.name);

            if (content.parentId) {
                MaterialInput.getOrNull(`#${parentInputId}`)?.setValue(content.parentId.toString());
            }
        }
    }, [content]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contentState={[content, setContent]}
            errorMessageState={[errorMessage, setErrorMessage]}
            setRootEnabled={setRootEnabled} />
    </>;
}

type PageWrapperProps = {
    contentState: StatePair<Optional<FaqCategoryModal>>;

    errorMessageState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [content] = props.contentState;
    const [, setErrorMessage] = props.errorMessageState;
    const onSaveClicked = () => OnSaveClicked(content, setErrorMessage, props.setRootEnabled);
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", () => document.location = "/misc/faq/category/list"]
    ];

    return <>
        <Nav
            title={"자주 묻는 질문 카테고리 " + ((content) ? "수정" : "추가")}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <Editor />
    </>;
}

function OnSaveClicked(
    content: Optional<FaqCategoryModal>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const name = MaterialInput.get(`#${nameInputId}`).getValue();
    if (name.length.notIn(1, 41)) {
        setErrorMessage('이름을 확인해주세요.');
        return;
    }

    const parentId = MaterialInput.get(`#${parentInputId}`).getValue().toIntOrNull();

    const onReady = () => {
        window.alert('저장되었습니다.');
        document.location = `/misc/faq/category/list`;
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    if (!content) {
        setRootEnabled(false);
        MiscFaqCategoryIO.post(name, parentId, onReady, onError);
    } else {
        if (content &&
            content.name === name &&
            content.parentId === parentId) {
            onReady();
            return;
        }

        setRootEnabled(false);
        MiscFaqCategoryIO.update(content.id, name, parentId, onReady, onError);
    }
}

function Editor() {
    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={nameInputId}
                inputClasses="validate"
                inputPlaceHolder="40자 이내"
                inputDataLength={40}
                label="이름" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={parentInputId}
                inputType="number"
                inputClasses="validate"
                label="부모 카테고리 ID (선택)" />
        </div>
    </>;
}