import {Route, Routes} from "react-router-dom";
import AgeRouter from "./age/AgeRouter";
import GenderRouter from "./gender/GenderRouter";
import RegionRouter from "./region/RegionRouter";

export default function TargetRouter() {
    return <Routes>
        <Route path='/age/*' element={<AgeRouter />} />
        <Route path='/gender/*' element={<GenderRouter />} />
        <Route path='/region/*' element={<RegionRouter />} />
    </Routes>;
}