import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {EventModal} from "../../../modal/Miscs";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {MiscEventIO} from "../../../io/Miscs";
import {
    Component,
    MaterialComponent,
    MaterialDatepicker,
    MaterialInput,
    MaterialModal, MaterialTextarea, MaterialTimepicker
} from "../../Materials";
import S3 from "aws-sdk/clients/s3";
import {StorageIO} from "../../../io/Services";
import {fold, IntRange} from "../../../util/Iterators";
import {OnErrorResponse, OnObjectResponse} from "../../../util/Reponses";
import {StringBuilder} from "../../../util/StringBuilder";

const pageId = 'event_edit';
const titleInputId = `${pageId}_title`;
const bodyTextareaId = `${pageId}_body`;
const startDateId = `${pageId}_start_date`;
const startTimeId = `${pageId}_start_time`;
const endDateId = `${pageId}_end_date`;
const endTimeId = `${pageId}_end_time`;
const descriptionImageInputId = `${pageId}_description_image`;

export default function Edit() {
    const {eventId} = useParams() as { eventId?: number };
    const [content, setContent] = useState<Optional<EventModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [errorRecover, setErrorRecover] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        MaterialInput.init();
        MaterialDatepicker.init();
        MaterialTimepicker.init();
    });

    useEffect(() => {
        if (eventId) {
            MiscEventIO.get(eventId, setContent, setErrorMessage);
        }
    }, [eventId]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${titleInputId}`),
            MaterialTextarea.getOrNull(`#${bodyTextareaId}`),
            MaterialDatepicker.getOrNull(`#${startDateId}`),
            MaterialTimepicker.getOrNull(`#${startTimeId}`),
            MaterialDatepicker.getOrNull(`#${endDateId}`),
            MaterialTimepicker.getOrNull(`#${endTimeId}`),
            MaterialInput.getOrNull(`#${descriptionImageInputId}`)
        ];
        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled]);

    useEffect(() => {
        if (content) {
            MaterialInput.getOrNull(`#${titleInputId}`)?.setValue(content.title);
            MaterialTextarea.getOrNull(`#${bodyTextareaId}`)?.setValue(content.body);
            MaterialDatepicker.getOrNull(`#${startDateId}`)?.setValue(content.startAt.toDatePartString());
            MaterialTimepicker.getOrNull(`#${startTimeId}`)?.setValue(content.startAt.toTimePartString(false, false));
            MaterialDatepicker.getOrNull(`#${endDateId}`)?.setValue(content.endAt.toDatePartString());
            MaterialTimepicker.getOrNull(`#${endTimeId}`)?.setValue(content.endAt.toTimePartString(false, false));
        }
    }, [content]);

    useEffect(() => {
        if (errorMessage) {
            MaterialModal.getOrNull('#error_modal')?.open();
        }
    }, [errorMessage]);

    return <>
        <PageWrapper
            contentState={[content, setContent]}
            errorMessageState={[errorMessage, setErrorMessage]}
            errorRecoverState={[errorRecover, setErrorRecover]}
            setRootEnabled={setRootEnabled} />
    </>;
}

type PageWrapperProps = {
    contentState: StatePair<Optional<EventModal>>;

    errorMessageState: StatePair<Optional<ErrorMessage>>;
    errorRecoverState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [content] = props.contentState;
    const [, setErrorMessage] = props.errorMessageState;
    const [, setErrorRecover] = props.errorRecoverState;
    const onSaveClicked = () => OnSaveClicked(content, setErrorMessage, setErrorRecover, props.setRootEnabled);
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", () => document.location = "/misc/event/list"]
    ];

    return <>
        <Nav
            title={"이벤트 " + ((content) ? "수정" : "추가")}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <Editor
            contentState={props.contentState}
            setErrorMessage={setErrorMessage}
            setErrorRecover={setErrorRecover}
            setRootEnabled={props.setRootEnabled} />
    </>;
}

function OnSaveClicked(
    content: Optional<EventModal>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const title = MaterialInput.get(`#${titleInputId}`).getValue();
    if (title.length.notIn(1, 101)) {
        setErrorMessage('제목을 확인해주세요.');
        return;
    }

    const body = MaterialTextarea.get(`#${bodyTextareaId}`).getValue();
    if (body.length.notIn(1, 1001)) {
        setErrorMessage('내용을 확인해주세요.');
        return;
    }

    const startDatePart = MaterialDatepicker.get(`#${startDateId}`).getDatePartOrNull();
    if (startDatePart === null) {
        setErrorMessage('시작 날짜를 확인해주세요.');
        return;
    }

    const startTimePart = MaterialTimepicker.get(`#${startTimeId}`).getTimePartOrNull();
    if (startTimePart === null) {
        setErrorMessage('시작 시각을 확인해주세요.')
        return;
    }

    const startAt = new Date(startDatePart.year, startDatePart.month - 1, startDatePart.date, startTimePart.hour, startTimePart.minute);

    const endDatePart = MaterialDatepicker.get(`#${endDateId}`).getDatePartOrNull();
    if (endDatePart === null) {
        setErrorMessage('종료 날짜를 확인해주세요.');
        return;
    }

    const endTimePart = MaterialTimepicker.get(`#${endTimeId}`).getTimePartOrNull();
    if (endTimePart === null) {
        setErrorMessage('종료 시각을 확인해주세요.');
        return;
    }

    const endAt = new Date(endDatePart.year, endDatePart.month - 1, endDatePart.date, endTimePart.hour, endTimePart.minute);

    const descriptionImageInput = MaterialInput.get(`#${descriptionImageInputId}`);
    const descriptionImageCount = descriptionImageInput.getFileLength();

    const onSucceed = () => {
        window.alert('저장되었습니다.');
        document.location = '/misc/event/list';
    };

    const onUploadError = (content: EventModal, succeeded: string[], error: string) => {
        MaterialModal.getOrNull('#error_modal')?.setOnDismiss(() => document.location = "/misc/event/list");
        const succeededLine = (succeeded.isNotEmpty()) ? StringBuilder.joinToString(succeeded, ", ", "성공한 항목: ") : "";
        setErrorRecover([
            'AWS에 접속하여 오류가 발생한 이미지 또는 비디오를 추가해야 합니다. 필요하다면 폴더를 생성해야 할 수 있습니다.',
            succeededLine,
            StorageIO.createPath(`misc/event/${content.id}`)
        ]);
        setErrorMessage(error);
    };

    const onReady = (content: EventModal) => {
        StorageIO.uploadMaterialFiles(content, [
            { input: descriptionImageInput, path: EventModal.descriptionImagePath, onSucceedText: '설명 이미지' }
        ], onSucceed, (succeeded, error) => onUploadError(content, succeeded, error));
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    if (!content) {
        setRootEnabled(false);
        MiscEventIO.post(title, body, descriptionImageCount, startAt, endAt, onReady, onError);
    } else {
        const updating = Update(
            content, title, body, content.descriptionImageCount + descriptionImageCount, startAt, endAt, onReady, onError
        );

        setRootEnabled(!updating);
    }
}

function Update(
    content: EventModal,
    title: string,
    body: string,
    descriptionImageCount: number,
    startAt: Date,
    endAt: Date,
    onReady: OnObjectResponse<EventModal>,
    onError: OnErrorResponse,
): boolean {
    if (content.title === title &&
        content.body === body &&
        content.descriptionImageCount === descriptionImageCount &&
        content.startAt === startAt &&
        content.endAt === endAt) {
        onReady(content);
        return false;
    }

    MiscEventIO.update(content.id, title, body, descriptionImageCount, startAt, endAt, onReady, onError);
    return true;
}

type EditorProps = {
    contentState: StatePair<Optional<EventModal>>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function Editor(props: EditorProps) {
    const [content] = props.contentState;
    let descriptionImageContainer: JSX.Element;
    if (content && content.descriptionImageCount) {
        const images = content.descriptionImageCount.map(index => {
            const imagePath = EventModal.descriptionImagePath(content, index);
            const onDeleteClick = () => OnDescriptionImageDeleteClicked(
                props.contentState,
                index,
                props.setErrorMessage,
                props.setErrorRecover,
                props.setRootEnabled
            );

            return <a key={index} className="collection-item">
                <a href={`/${imagePath}`}>
                    {imagePath}
                </a>
                <a className="secondary-content clickable" onClick={onDeleteClick}>
                    <i className="material-icons">delete</i>
                </a>
            </a>;
        });

        descriptionImageContainer = <div className="col s8 offset-s2 collection">{images}</div>;
    } else {
        descriptionImageContainer = <></>;
    }


    return <>
        <div className="row cascade first">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={titleInputId}
                inputClasses="validate"
                inputPlaceHolder="100자 이내"
                inputDataLength={100}
                label="제목" />
        </div>
        <div className="row cascade">
            <Component.Textarea
                formClasses="col s8 offset-s2"
                textareaId={bodyTextareaId}
                textareaClasses="validate"
                textareaPlaceHolder="1,000자 이내"
                textareaDataLength={1000}
                label="내용" />
        </div>
        <div className="row cascade">
            <Component.Datepicker
                formClasses="col s4 offset-s2"
                inputId={startDateId}
                label="시작 날짜" />
            <Component.Timepicker
                formClasses="col s4"
                inputId={startTimeId}
                label="시작 시각" />
        </div>
        <div className="row cascade">
            <Component.Datepicker
                formClasses="col s4 offset-s2"
                inputId={endDateId}
                label="종료 날짜" />
            <Component.Timepicker
                formClasses="col s4"
                inputId={endTimeId}
                label="종료 시각" />
        </div>
        <div className="row cascade">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                inputId={descriptionImageInputId}
                inputLabel="설명 이미지 선택"
                multiple={true}
                accept="image/png" />
        </div>
        <div className="row cascade">
            {descriptionImageContainer}
        </div>
    </>;
}

function OnDescriptionImageDeleteClicked(
    contentState: StatePair<Optional<EventModal>>,
    index: number,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const [content, setContent] = contentState;
    if (!content) {
        return;
    }

    const onUpdateResponse = (content: EventModal) => {
        setContent(null);
        setContent(content);
        setRootEnabled(true);
    };

    const onUpdateError = (error: string) => {
        setErrorRecover([
            '데이터베이스에 다음 SQL을 실행해야 합니다: ',
            `UPDATE product SET description_image_count=${content.descriptionImageCount - 1} WHERE _id=${content.id}`
        ]);
        setErrorMessage([
            error,
            "AWS에서 파일 삭제를 성공했으나 데이터베이스 수정을 실패했습니다."
        ]);
        setRootEnabled(true);
    };

    const onCopyResponse = (responses: S3.CopyObjectOutput[]) => Update(
        content,
        content.title,
        content.body,
        content.descriptionImageCount - 1,
        content.startAt,
        content.endAt,
        onUpdateResponse,
        onUpdateError
    );

    const onCopyError = (error: string) => {
        setErrorRecover([
            'AWS에 접속하여 오류가 발생한 이미지를 수정해야 합니다. 필요하다면 폴더를 생성해야 할 수 있습니다.',
            StorageIO.createPath(EventModal.descriptionImagePath(content, index))
        ]);
        setErrorMessage(error);
        setRootEnabled(true);
    };

    setRootEnabled(false);
    const map = fold(new IntRange(index, content.descriptionImageCount - 1), new Map<string, string>(), (acc, element) => {
        acc.set(EventModal.descriptionImagePath(content, element + 1), EventModal.descriptionImagePath(content, element));
        return acc;
    });

    StorageIO.copyObjects(map, onCopyResponse, onCopyError);
}