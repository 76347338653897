import {Requests} from "./Requests";
import {BaseResponse} from "./Reponses";
import {Optional} from "./Types";

export const DEBUG = false;
export const SERVER_URL = (DEBUG) ? "https://backend-test.vowingadmin2.com" : "https://backend.vowingadmin2.com";
export const S3_BUCKET_NAME = (DEBUG) ? "vowing-test" : "vowing";
export const getMaxInt = () => 2147483647;
export const getMinInt = () => -2147483648;
export const getMaxLong = () => BigInt("9223372036854775807");
export const getMinLong = () => BigInt("-9223372036854775808");
export const getMaxDate = () => new Date(253402268399999);
export const getMinDate = () => new Date(-62135596800000);

export namespace Environments {
    export const versionName = process.env.REACT_APP_VERSION_NAME!!
}

export module Logger {
    export function log(message?: any, ...optionalParams: any[]) {
        if (DEBUG) {
            console.log(message, ...optionalParams);
        }
    }

    export function warn(message?: any, ...optionalParams: any[]) {
        if (DEBUG) {
            console.warn(message, ...optionalParams);
        }
    }

    export function error(message?: any, ...optionalParams: any[]) {
        if (DEBUG) {
            console.error(message, ...optionalParams);
        }
    }
}

export module AwsKeyManager {
    type KeyPair = {
        accessKeyId: string;
        secretAccessKey: string;
    };

    type KeyPairResponse = KeyPair & BaseResponse

    let accessKeyId: Optional<string> = null;
    let secretAccessKey: Optional<string> = null;

    function initialize(
        onReady: (accessKeyId: string, secretAccessKey: string) => void
    ) {
        const onKeyPairReady = (response: KeyPair) => {
            accessKeyId = response.accessKeyId;
            secretAccessKey = response.secretAccessKey;
            onReady(accessKeyId, secretAccessKey);
        }

        initializeSync()
            .then(onKeyPairReady)
            .catch(error => Logger.error('AwsKeyManager.initialize', error))
    }

    async function initializeSync() {
        return await Requests.getObjectSync(
            'AwsKeyManager.initialize',
            `${SERVER_URL}/misc/key/aws`,
            o => (o as KeyPairResponse)
        )
    }

    export function getKeyPair(onReady: (accessKeyId: string, secretAccessKey: string) => void) {
        if (accessKeyId !== null && secretAccessKey !== null) {
            onReady(accessKeyId, secretAccessKey);
            return;
        }

        initialize(onReady);
    }

    export async function getKeyPairSync(): Promise<KeyPair> {
        if (accessKeyId !== null && secretAccessKey !== null) {
            return { accessKeyId, secretAccessKey }
        } else {
            return await initializeSync()
        }
    }
}