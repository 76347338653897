import React, {useEffect} from "react";
import {M10nModal} from "../../modal/M10ns";
import {Optional, useBoolean, useWrapper} from "../../util/Types";
import {BottomProgress, ErrorMessage, Nav, NavBarItemAction} from "../Common";
import {M10nIO} from "../../io/M10n";
import {BitMask} from "../../util/BitMask";
import {getMaxInt} from "../../util/Environments";
import {SearchField} from "../common/SearchField";
import {FloatingActionButton} from "../common/FloatingActionButton";

const searchFilter = new BitMask<M10nModal.SearchFilter>(
    M10nModal.SearchFilter.ID_DESCENDING.value,
    M10nModal.SearchFilter.values
)

export function List() {
    const contents = useWrapper<M10nModal[]>([])
    const query = useWrapper<string>('')
    const lastQuery = useWrapper<string>('')
    const hasMoreContents = useBoolean(true)
    const isFetching = useBoolean(false)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)

    useEffect(() => M.AutoInit())

    useEffect(() => fetchContents(), [query.value])

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return function () {
            window.removeEventListener('scroll', onWindowScroll);
        }
    })

    const fetchContents = () => {
        const lastId = (lastQuery.value === query.value) ? (contents.value.lastOrNull()?.id ?? getMaxInt()) : getMaxInt()
        if (query.value.isEmpty()) {
            fetchContentsWithoutQuery(lastId)
        } else {
            fetchContentsWithQuery(lastId)
        }
    }

    const fetchContentsWithoutQuery = (lastId: number) => {
        isFetching.setTrue()
        M10nIO.listBySearchFilter(
            searchFilter,
            lastId,
            20,
            response => {
                if (lastQuery.value.isEmpty()) {
                    // 마지막 질의가 비었다면 이번 질의도 비었으므로 이전 내용에 추가
                    contents.set(contents.value.appended(response))
                } else {
                    // 마지막 질의가 비어있지 않다면 이전 내용이 질의 내용이므로 모두 삭제
                    contents.set(response)
                }
                hasMoreContents.set(response.length >= 20)
                lastQuery.set(query.value)
                isFetching.setFalse()
            },
            error => {
                errorMessage.set(error)
                hasMoreContents.setFalse()
                isFetching.setFalse()
            }
        )
    }

    const fetchContentsWithQuery = (lastId: number) => {
        isFetching.setTrue()
        M10nIO.listByQueryAndSearchFilter(
            query.value,
            searchFilter,
            lastId,
            20,
            response => {
                if (lastQuery.value !== query.value) {
                    // 마지막 질의와 이번 질의가 다르면 이전 내용을 모두 삭제
                    contents.set(response)
                } else {
                    contents.set(contents.value.appended(response))
                }
                hasMoreContents.set(response.length >= 20)
                lastQuery.set(query.value)
                isFetching.setFalse()
            },
            error => {
                errorMessage.set(error)
                hasMoreContents.setFalse()
                isFetching.setFalse()
            }
        )
    }

    const onWindowScroll = () => {
        if (!isFetching.value && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            fetchContents()
        }
    }

    const rows = contents.value.map(value => <>
        <Row
            key={value.id.toString()}
            content={value} />
    </>)

    const onUploadClick = () => document.location = '/m10n/post/sheet'

    const barItems: [string, NavBarItemAction][] = [
        ["업로드", onUploadClick]
    ]

    return <>
        <Nav
            title={'암기플러스'}
            titleHref={'/'}
            titleIcon={'chevron_left'}
            barItems={barItems}
            excludeErrorModal={true} />
        <SearchField query={query} />
        <table className={'centered highlight'}>
            <thead>
            <tr>
                <th>ID</th>
                <th>제목</th>
                <th>수량</th>
                <th>포인트</th>
                <th>난이도</th>
                <th>생성</th>
                <th>시작</th>
                <th>종료</th>
                <th>노출</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents.value} />
        </div>
        <FloatingActionButton
            href={'/m10n/post'}
            iconProps={{ iconName: 'add' }} />
    </>
}

type RowProps = {
    content: M10nModal
}

function Row(props: RowProps) {
    return <>
        <tr>
            <td>{props.content.id}</td>
            <td>{props.content.title}</td>
            <td>{props.content.amount}</td>
            <td>{props.content.point}</td>
            <td>{M10nModal.Difficulty.toString(props.content.difficulty)}</td>
            <td>{props.content.createdAt.toRowFormat(true, true, true)}</td>
            <td>{props.content.startAt.toRowFormat(true, true, true)}</td>
            <td>{props.content.endAt.toRowFormat(true, true, true)}</td>
            <td>{props.content.exposedAt.toRowFormat(true, true, true)}</td>
            <td>
                <a href={`/m10n/${props.content.id}`} style={{ cursor: "pointer" }}>
                    <i className="material-icons black-text">open_in_new</i>
                </a>
                <a href={`/m10n/${props.content.id}/edit`} style={{ cursor: "pointer" }}>
                    <i className="material-icons black-text">edit</i>
                </a>
            </td>
        </tr>
    </>
}