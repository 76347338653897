import {Optional, StateWrapper, wrapState} from "../../../util/Types";
import React, {useEffect, useState} from "react";
import {AdAnnouncementModal} from "../../../modal/Ads";
import {BottomProgress, ErrorMessage, Nav} from "../../Common";
import {MaterialModal} from "../../Materials";
import {getMaxInt} from "../../../util/Environments";
import {AdAnnouncementIO} from "../../../io/Ads";

export default function List() {
    const lockStates = wrapState(useState<number>(0));
    const contentStates = wrapState(useState<AdAnnouncementModal[]>([]));
    const moreContentStates = wrapState(useState<boolean>(true));
    const updatingStates = wrapState(useState<boolean>(false));
    const errorMessageStates = wrapState(useState<Optional<ErrorMessage>>(null));

    useEffect(() => {
        M.AutoInit();
        MaterialModal.init();
    });

    useEffect(() => prepareContents(
        contentStates,
        moreContentStates.set,
        updatingStates.set,
        errorMessageStates.set
    ), [lockStates.value]);

    useEffect(() => {
        const onWindowScrollListener = () => onWindowScroll(
            contentStates,
            moreContentStates,
            updatingStates,
            errorMessageStates.set
        );

        window.addEventListener('scroll', onWindowScrollListener);
        return () => window.removeEventListener('scroll', onWindowScrollListener);
    });

    return <PageWrapper contents={contentStates.value} hasMoreContents={moreContentStates.value} />;
}

function prepareContents(
    contentStates: StateWrapper<AdAnnouncementModal[]>,
    setMoreContents: React.Dispatch<boolean>,
    setUpdating: React.Dispatch<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const { value: present, set: setContents } = contentStates;

    const onReady = (contents: AdAnnouncementModal[]) => {
        setContents(present.appended(contents));
        setMoreContents(contents.length === 20);
        setUpdating(false);
    };

    const onError = (error: ErrorMessage) => {
        setErrorMessage(error);
        setMoreContents(false);
        setUpdating(false);
    };

    const lastId = present?.lastOrNull()?.id ?? getMaxInt();
    setUpdating(true);
    AdAnnouncementIO.listDescending(lastId, onReady, onError);
}

function onWindowScroll(
    contentStates: StateWrapper<AdAnnouncementModal[]>,
    moreContentStates: StateWrapper<boolean>,
    updatingStates: StateWrapper<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const { value: hasMoreContents, set: setMoreContents } = moreContentStates;
    const { value: isUpdating, set: setUpdating } = updatingStates;
    if (hasMoreContents && !isUpdating && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        prepareContents(contentStates, setMoreContents, setUpdating, setErrorMessage);
    }
}

type PageWrapperProps = {
    contents: AdAnnouncementModal[],
    hasMoreContents: boolean
};

function PageWrapper(props: PageWrapperProps) {
    const rows = (props.contents ?? []).map(content => <Row key={content.id} content={content} />);
    return <>
        <Nav
            title="광고 당첨"
            titleIcon="chevron_left"
            titleHref="/" />
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>광고 ID</th>
                <th>사용자 ID</th>
                <th>생성 시각</th>
                <th>만료 시각</th>
                <th>사용 시각</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="fixed-action-btn">
            <a className="btn-floating btn-large secondary" href='/gifticon/post'><i className="large material-icons">add</i></a>
        </div>
        <div className="row">
            <BottomProgress hasMoreContents={props.hasMoreContents} />
        </div>
    </>;
}

type RowProps = {
    content: AdAnnouncementModal
};

function Row(props: RowProps) {
    return <tr>
        <td>{props.content.id}</td>
        <td><a href={`/ad/${props.content.adId}`}>{props.content.adId}</a></td>
        <td><a href={`/user/${props.content.userId}`}>{props.content.userId.toString()}</a></td>
        <td>{props.content.createdAt.toRowFormat(true, true)}</td>
        <td>{props.content.expiredAt.toRowFormat(true, true)}</td>
        <td>{props.content.usedAt?.toRowFormat(true, true) ?? "-"}</td>
    </tr>;
}