export type BaseResponse = {
    metadata: BaseResponseMetadata;
};

export namespace BaseResponse {
    export function defaultValue(): BaseResponse {
        return {
            metadata: {
                acceptedAt: BigInt(0),
                respondAt: BigInt(0)
            }
        }
    }
}

export type BaseResponseMetadata = {
    acceptedAt: bigint;
    respondAt: bigint;
};

export class ErrorResponse {
    error!: string;
    message!: string;
    path!: string;
    status!: number;
    timestamp!: Date;

    constructor(json: object) {
        Object.assign(this, json);
    }
}

export type OnBaseResponse = OnObjectResponse<BaseResponse>;

export type OnObjectResponse<T> = (modal: T) => void;

export type OnArrayResponse<T> = OnObjectResponse<T[]>;

export type OnNumberResponse = OnObjectResponse<number>;

export type OnErrorResponse = OnObjectResponse<string>;
