import {useLocation, useParams} from "react-router-dom";
import qs from "qs";
import {Optional, StatePair} from "../../../util/Types";
import React, {useEffect, useState} from "react";
import {UserBlockCauseModal, UserBlockModal} from "../../../modal/Users";
import {UserBlockCauseIO, UserBlockIO} from "../../../io/Users";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {Component, MaterialComponent, MaterialDatepicker, MaterialInput, MaterialSelectWrapper} from "../../Materials";

const pageId = 'user_block_edit';
const userInputId = `${pageId}_user`;
const causeSelectId = `${pageId}_cause`;
const causeWrapperId = `${pageId}_cause_wrapper`;
const blockDateInputId = `${pageId}_block_date`;
const untilDateInputId = `${pageId}_until_date`;

export default function Edit() {
    const {userId} = qs.parse(
        useLocation().search, { ignoreQueryPrefix: true }
    ) as { userId?: bigint };

    const params = useParams();
    const userBlockId = (params.userBlockId) ? parseInt(params.userBlockId) : null;

    const [content, setContent] = useState<Optional<UserBlockModal>>(null);
    const [causes, setCauses] = useState<Optional<UserBlockCauseModal[]>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [isRootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        M.AutoInit();
        MaterialDatepicker.init();
    });

    useEffect(() => {
        if (userBlockId) {
            UserBlockIO.get(userBlockId, setContent, setErrorMessage);
        }
    }, [userBlockId]);

    useEffect(() => {
        UserBlockCauseIO.listAll(setCauses, setErrorMessage);
    }, []);

    useEffect(() => {
        if (userId !== undefined) {
            const userInput = MaterialInput.getOrNull(`#${userInputId}`);
            userInput?.setValue(userId.toString());
            userInput?.disable();
        }
    }, [userId]);

    useEffect(() => {
        InitializeView(content, causes);
    }, [content, causes]);

    useEffect(() => {
        const inputs = [
            (userId === undefined) ? MaterialInput.getOrNull(`#${userInputId}`) : null,
            MaterialSelectWrapper.getOrNull(`#${causeWrapperId}`),
            MaterialDatepicker.getOrNull(`#${blockDateInputId}`),
            MaterialDatepicker.getOrNull(`#${untilDateInputId}`)
        ];
        if (isRootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [isRootEnabled]);

    return <>
        <PageWrapper
            content={content}
            causes={causes}
            errorMessageState={[errorMessage, setErrorMessage]}
            setRootEnabled={setRootEnabled} />
    </>;
}

function InitializeView(content: Optional<UserBlockModal>, causes: Optional<UserBlockCauseModal[]>) {
    if (!content || !causes) {
        return;
    }

    const option = causes.find(cause => cause.id === content.causeId)?.description;
    if (option) {
        MaterialSelectWrapper.getOrNull(`#${causeWrapperId}`)?.setValue(option);
    }
    MaterialDatepicker.getOrNull(`#${blockDateInputId}`)?.setValue(content.blockedAt.toISOString());
    if (content.blockedUntil) {
        MaterialDatepicker.getOrNull(`#${untilDateInputId}`)?.setValue(content.blockedUntil.toISOString());
    }
}

type PageWrapperProps = {
    content: Optional<UserBlockModal>,
    causes: Optional<UserBlockCauseModal[]>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [, setErrorMessage] = props.errorMessageState;
    const barItems: [string, NavBarItemAction][] = [
        ["저장", () => OnSaveClicked(props.content, props.causes ?? [], setErrorMessage, props.setRootEnabled)]
    ];

    return <>
        <Nav
            title={"이용 정지"}
            titleHref={"/user/block/list"}
            titleIcon={"chevron_left"}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <div className="row" style={{ marginTop: "2rem" }}>
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={userInputId}
                inputType="number"
                inputClasses="validate"
                value={props.content?.userId?.toString()}
                enabled={props.content === null}
                label="사용자 ID" />
        </div>
        <div className="row">
            <Component.Select
                values={(props.causes ?? []).map(cause => cause.description)}
                wrapperId={causeWrapperId}
                wrapperClasses="col s8 offset-s2"
                selectId={causeSelectId}
                label="이용 정지 사유" />
        </div>
        <div className="row">
            <Component.Datepicker
                formClasses="col s4 offset-s2"
                inputId={blockDateInputId}
                label={"시작 날짜"} />
            <Component.Datepicker
                formClasses="col s4"
                inputId={untilDateInputId}
                label={"종료 날짜"} />
        </div>
    </>;
}

function OnSaveClicked(
    content: Optional<UserBlockModal>,
    causes: UserBlockCauseModal[],
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const userId = MaterialInput.get(`#${userInputId}`).getValue().toBigInt();
    const cause = MaterialSelectWrapper.get(`#${causeWrapperId}`).getFirstSelectionAs(s => causes.find(cause => cause.description === s))!;
    const blockDate = MaterialDatepicker.get(`#${blockDateInputId}`).getDate();
    const untilDate = MaterialDatepicker.get(`#${untilDateInputId}`).getDateOrNull();

    const onReady = () => {
        window.alert('저장되었습니다.');
        document.location = '/user/block/list';
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    if (content &&
        content.causeId === cause.id &&
        content.blockedAt === blockDate &&
        content.blockedUntil === untilDate) {
        onReady();
        return;
    }

    setRootEnabled(false);
    UserBlockIO.post(
        userId,
        cause.id,
        blockDate,
        untilDate,
        onReady,
        onError
    );
}