import {UserModal} from "./Users";

export type AdminHomeCount = {
    past: number;
    current: number;
};

type AdminHomeCountUser = {
    signedUp: AdminHomeCount;
    signedIn: AdminHomeCount;
    dormant: AdminHomeCount;
    withdrawn: AdminHomeCount;
};

type AdminHomeCountPoint = {
    positive: AdminHomeCount;
    negative: AdminHomeCount;
    withdrawRequest: AdminHomeCount;
};

type AdminHomeCountAd = {
    participation: AdminHomeCount;
    review: AdminHomeCount;
    reportedReview: AdminHomeCount;
};

type AdminHomeCountProduct = {
    deliveryRequest: AdminHomeCount;
    review: AdminHomeCount;
    reportedReview: AdminHomeCount;
};

export type AdminHomeCountModal = {
    user: AdminHomeCountUser;
    point: AdminHomeCountPoint;
    ad: AdminHomeCountAd;
    product: AdminHomeCountProduct;
};

export type UserPerfectModal = {
    user: UserModal
    adAll: bigint
    adMonth: bigint
    m10nAll: bigint
    m10nMonth: bigint
    totalAll: bigint
    totalMonth: bigint
}

export namespace UserPerfectModal {
    export function construct(o: any): UserPerfectModal {
        return o as UserPerfectModal
    }
}