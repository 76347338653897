import {Route, Routes} from "react-router-dom";
import {AssertSignIn} from "../../App";
import List from "./List";
import Edit from "./Edit";
import BrandRouter from "./brand/BrandRouter";
import DescriptionRouter from "./description/DescriptionRouter";
import PurchaseRouter from "./purchase/PurchaseRouter";

export default function GifticonRouter() {
    return <Routes>
        <Route path="/post" element={<AssertSignIn><Edit /></AssertSignIn>} />
        <Route path="/list" element={<AssertSignIn><List /></AssertSignIn>} />
        <Route path="/:id/edit" element={<AssertSignIn><Edit /></AssertSignIn>} />

        <Route path="/brand/*" element={<AssertSignIn><BrandRouter /></AssertSignIn>} />
        <Route path="/description/*" element={<AssertSignIn><DescriptionRouter /></AssertSignIn>} />
        <Route path="/:gifticonId/description/*" element={<AssertSignIn><DescriptionRouter /></AssertSignIn>} />
        <Route path="/purchase/*" element={<AssertSignIn><PurchaseRouter /></AssertSignIn>} />
    </Routes>;
}