import {
    M10nReviewLikeModal,
    M10nReviewModal,
    M10nReviewReportCauseModal,
    M10nReviewReportModal
} from "../../../modal/M10ns";
import {Optional, StateWrapper, useWrapper} from "../../../util/Types";
import {ErrorMessage, Nav, NavBarItemAction, NavTabItemProps} from "../../Common";
import {CardHorizontal} from "../../common/CardHorizontal";
import React, {useEffect} from "react";
import {M10nReviewIO, M10nReviewLikeIO, M10nReviewReportCauseIO, M10nReviewReportIO} from "../../../io/M10n";
import {useParams} from "react-router-dom";
import {useEffectOptional} from "../../../util/Hooks";

export function Detail() {
    const { id } = useParams<{ id?: string }>()
    const content = useWrapper<Optional<M10nReviewModal>>(null)
    const errorMessage = useWrapper<Optional<ErrorMessage>>(null)

    useEffect(() => M.AutoInit())

    useEffectOptional(id => M10nReviewIO.getById(BigInt(id), content.set, errorMessage.set), id)

    return <>
        {content.value && <>
            <PageWrapper
                content={content.value}
                errorMessage={errorMessage} />
        </>}
    </>
}

const defaultPageId = 'm10n_review_page_default'
const likePageId = 'm10n_review_page_like'
const reportPageId = 'm10n_review_page_report'

type PageWrapperProps = {
    content: M10nReviewModal
    errorMessage: StateWrapper<Optional<ErrorMessage>>
}

function PageWrapper(props: PageWrapperProps) {
    const barItems: [string, NavBarItemAction][] = (M10nReviewModal.Status.DELETED.isDisabled(props.content.statusFlags))
        ? [['삭제', () => onDeleteClick()]]
        : [];

    const tabItems: NavTabItemProps[] = [
        { text: '기본', href: `#${defaultPageId}` },
        { text: '좋아요', href: `#${likePageId}` },
        { text: '신고', href: `#${reportPageId}` },
    ]

    const onDeleteClick = () => M10nReviewIO.delete(
        props.content.id,
        () => {
            alert('삭제되었습니다.')
            document.location.reload()
        },
        props.errorMessage.set
    )

    return <>
        <Nav
            title={'암기플러스 리뷰'}
            titleIcon={'chevron_left'}
            titleHref={'/m10n/review/list'}
            barItems={barItems}
            tabItems={tabItems} />
        <DefaultPage {...props} />
        <LikePage {...props} />
        <ReportPage {...props} />
    </>
}

type DefaultPageProps = PageWrapperProps & {}

function DefaultPage(props: DefaultPageProps) {
    return <>
        <div id={defaultPageId} className={'row cascade'}>
            <div className={'col s8 offset-s2'}>
                <CardHorizontal title={'ID'} value={props.content.id.toString()} />
                <CardHorizontal title={'암기플러스 ID'} value={props.content.m10nId.toString()} href={`/m10n/${props.content.m10nId}`} />
                <CardHorizontal title={'사용자 ID'} value={props.content.userId.toString()} href={`/user/${props.content.userId}`} />
                <CardHorizontal title={'본문'} value={props.content.body} />
                <CardHorizontal title={'작성 시각'} value={props.content.writtenAt.toRowFormat(true, true)} />
                <CardHorizontal title={'수정 시각'} value={props.content.modifiedAt?.toRowFormat(true, true) ?? '(없음)'} />
                <CardHorizontal title={'삭제 시각'} value={props.content.deletedAt?.toRowFormat(true, true) ?? '(없음)'} />
            </div>
        </div>
    </>
}

type LikePageProps = DefaultPageProps & {}

function LikePage(props: LikePageProps) {
    const contents = useWrapper<M10nReviewLikeModal[]>([])
    const rows = useWrapper<JSX.Element[]>([])
    useEffect(() => M10nReviewLikeIO.listAllByM10nReviewId(props.content.id, contents.set, props.errorMessage.set), [])
    useEffect(() => {
        rows.set(contents.value.map(content => <>
            <tr key={content.userId.toString()}>
                <td><a href={`/user/${content.userId.toString()}`}>{content.userId.toString()}</a></td>
                <td>{content.likedAt.toRowFormat(true, true)}</td>
            </tr>
        </>))
    }, [contents.value])

    return <>
        <div id={likePageId} className={'row cascade'}>
            <table className={'centered highlight'}>
                <thead>
                <tr>
                    <th>사용자 ID</th>
                    <th>추가 시각</th>
                </tr>
                </thead>
                <tbody>
                {rows.value}
                </tbody>
            </table>
        </div>
    </>
}

type ReportPageProps = DefaultPageProps & {}

function ReportPage(props: ReportPageProps) {
    const contents = useWrapper<M10nReviewReportModal[]>([])
    const causes = useWrapper<M10nReviewReportCauseModal[]>([])
    const rows = useWrapper<JSX.Element[]>([])
    useEffect(() => M10nReviewReportIO.listAllByM10nReviewId(props.content.id, contents.set, props.errorMessage.set), [])
    useEffect(() => M10nReviewReportCauseIO.listAll(causes.set, props.errorMessage.set), [])
    useEffect(() => {
        rows.set(contents.value.map(content => <>
            <tr key={content.userId.toString()}>
                <td><a href={`/user/${content.userId.toString()}`}>{content.userId.toString()}</a></td>
                <td>{causes.value.find(o => o.id === content.causeId)?.description}</td>
                <td>{content.reportedAt.toRowFormat(true, true)}</td>
            </tr>
        </>))
    }, [contents.value])

    return <>
        <div id={reportPageId} className={'row cascade'}>
            <table className={'centered highlight'}>
                <thead>
                <tr>
                    <th>사용자 ID</th>
                    <th>사유</th>
                    <th>신고 시각</th>
                </tr>
                </thead>
                <tbody>
                {rows.value}
                </tbody>
            </table>
        </div>
    </>
}