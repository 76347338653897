import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ErrorMessage, Nav, NavBarItemAction} from "../../../Common";
import {StringBuilder} from "../../../../util/StringBuilder";
import {
    Component,
    MaterialComponent,
    MaterialDatepicker,
    MaterialInput,
    MaterialModal, MaterialTimepicker
} from "../../../Materials";
import {StorageIO} from "../../../../io/Services";
import {HomeBannerModal} from "../../../../modal/Miscs";
import {MiscHomeBannerIO} from "../../../../io/Miscs";
import {Optional, StatePair} from "../../../../util/Types";

const pageId = 'home_banner_edit';
const adIdInputId = `${pageId}_ad_id`
const m10nIdInputId = `${pageId}_m10n_id`
const noticeIdInputId = `${pageId}_notice_id`
const sortOrderInputId = `${pageId}_sort_order`;
const startDateId = `${pageId}_start_date`;
const startTimeId = `${pageId}_start_time`;
const endDateId = `${pageId}_end_date`;
const endTimeId = `${pageId}_end_time`;
const imageInputId = `${pageId}_image`;

export default function Edit() {
    const {homeBannerId} = useParams() as { homeBannerId?: number };
    const [content, setContent] = useState<Optional<HomeBannerModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);
    const [errorRecover, setErrorRecover] = useState<Optional<ErrorMessage>>(null);
    const [rootEnabled, setRootEnabled] = useState<boolean>(true);

    useEffect(() => {
        MaterialInput.init();
        MaterialDatepicker.init();
        MaterialTimepicker.init();
    });

    useEffect(() => {
        if (homeBannerId) {
            MiscHomeBannerIO.get(homeBannerId, setContent, setErrorMessage);
        }
    }, [homeBannerId]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${sortOrderInputId}`),
            MaterialInput.getOrNull(`#${adIdInputId}`),
            MaterialInput.getOrNull(`#${m10nIdInputId}`),
            MaterialInput.getOrNull(`#${noticeIdInputId}`),
            MaterialDatepicker.getOrNull(`#${startDateId}`),
            MaterialTimepicker.getOrNull(`#${startTimeId}`),
            MaterialDatepicker.getOrNull(`#${endDateId}`),
            MaterialTimepicker.getOrNull(`#${endTimeId}`),
            MaterialInput.getOrNull(`#${imageInputId}`)
        ];
        if (rootEnabled) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabled]);

    useEffect(() => {
        if (content) {
            MaterialInput.getOrNull(`#${sortOrderInputId}`)?.setValue(content.sortOrder.toString());
            MaterialInput.getOrNull(`#${adIdInputId}`)?.setValue(content.adId?.toString() ?? '');
            MaterialInput.getOrNull(`#${m10nIdInputId}`)?.setValue(content.m10nId?.toString() ?? '');
            MaterialInput.getOrNull(`#${noticeIdInputId}`)?.setValue(content.noticeId?.toString() ?? '');
            MaterialDatepicker.getOrNull(`#${startDateId}`)?.setValue(content.startAt.toDatePartString());
            MaterialTimepicker.getOrNull(`#${startTimeId}`)?.setValue(content.startAt.toTimePartString(false, false));
            MaterialDatepicker.getOrNull(`#${endDateId}`)?.setValue(content.endAt.toDatePartString());
            MaterialTimepicker.getOrNull(`#${endTimeId}`)?.setValue(content.endAt.toTimePartString(false, false));
        }
    }, [content]);

    useEffect(() => MaterialModal.getOrNull('#error_modal')?.setVisibility(errorMessage !== null), [errorMessage]);

    return <>
        <PageWrapper
            contentState={[content, setContent]}
            errorMessageState={[errorMessage, setErrorMessage]}
            errorRecoverState={[errorRecover, setErrorRecover]}
            setRootEnabled={setRootEnabled} />
    </>;
}

type PageWrapperProps = {
    contentState: StatePair<Optional<HomeBannerModal>>;

    errorMessageState: StatePair<Optional<ErrorMessage>>;
    errorRecoverState: StatePair<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function PageWrapper(props: PageWrapperProps) {
    const [content] = props.contentState;
    const [, setErrorMessage] = props.errorMessageState;
    const [, setErrorRecover] = props.errorRecoverState;
    const onSaveClicked = () => OnSaveClicked(content, setErrorMessage, setErrorRecover, props.setRootEnabled);
    const barItems: [string, NavBarItemAction][] = [
        ["저장", onSaveClicked],
        ["취소", () => document.location = "/misc/home/banner/list"]
    ];

    return <>
        <Nav
            title={"홈 배너 " + ((content) ? "수정" : "추가")}
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={props.errorMessageState} />
        <Editor
            contentState={props.contentState}
            setErrorMessage={setErrorMessage}
            setErrorRecover={setErrorRecover}
            setRootEnabled={props.setRootEnabled} />
    </>;
}

function OnSaveClicked(
    content: Optional<HomeBannerModal>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>,
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>,
    setRootEnabled: React.Dispatch<boolean>
) {
    const adId = MaterialInput.get(`#${adIdInputId}`).getValue().toIntOrNull()
    const m10nId = MaterialInput.get(`#${m10nIdInputId}`).getValue().toIntOrNull()
    const noticeId = MaterialInput.get(`#${noticeIdInputId}`).getValue().toIntOrNull()
    let enteredIds = 0
    enteredIds += (adId === null || adId === 0) ? 0 : 1
    enteredIds += (m10nId === null || m10nId === 0) ? 0 : 1
    enteredIds += (noticeId === null || noticeId === 0) ? 0 : 1
    if (enteredIds >= 2) {
        setErrorMessage('대상 ID는 모두 입력하지 않거나 하나만 입력해주세요.')
        return;
    }

    const sortOrder = MaterialInput.get(`#${sortOrderInputId}`).getValue().toIntOrNull();
    if (sortOrder === null) {
        setErrorMessage('표시 순서를 확인해주세요.');
        return;
    }

    const startDatePart = MaterialDatepicker.get(`#${startDateId}`).getDatePartOrNull();
    if (startDatePart === null) {
        setErrorMessage('시작 날짜를 확인해주세요.');
        return;
    }

    const startTimePart = MaterialTimepicker.get(`#${startTimeId}`).getTimePartOrNull();
    if (startTimePart === null) {
        setErrorMessage('시작 시각을 확인해주세요.')
        return;
    }

    const startAt = new Date(startDatePart.year, startDatePart.month - 1, startDatePart.date, startTimePart.hour, startTimePart.minute);

    const endDatePart = MaterialDatepicker.get(`#${endDateId}`).getDatePartOrNull();
    if (endDatePart === null) {
        setErrorMessage('종료 날짜를 확인해주세요.');
        return;
    }

    const endTimePart = MaterialTimepicker.get(`#${endTimeId}`).getTimePartOrNull();
    if (endTimePart === null) {
        setErrorMessage('종료 시각을 확인해주세요.');
        return;
    }

    const endAt = new Date(endDatePart.year, endDatePart.month - 1, endDatePart.date, endTimePart.hour, endTimePart.minute);

    const imageInput = MaterialInput.get(`#${imageInputId}`);
    const imageCount = imageInput.getFileLength();
    if (!content && imageCount === 0) {
        setErrorMessage('이미지를 선택해주세요.');
        return;
    }

    const onSucceed = () => {
        window.alert('저장되었습니다.');
        document.location = '/misc/home/banner/list';
    };

    const onUploadError = (content: HomeBannerModal, succeeded: string[], error: string) => {
        MaterialModal.getOrNull('#error_modal')?.setOnDismiss(() => document.location = "/misc/home/banner/list");
        const succeededLine = (succeeded.isNotEmpty()) ? StringBuilder.joinToString(succeeded, ", ", "성공한 항목: ") : "";
        setErrorRecover([
            'AWS에 접속하여 오류가 발생한 이미지 또는 비디오를 추가해야 합니다. 필요하다면 폴더를 생성해야 할 수 있습니다.',
            succeededLine,
            StorageIO.createPath(content.imagePath())
        ]);
        setErrorMessage(error);
    };

    const onReady = (content: HomeBannerModal) => {
        StorageIO.uploadMaterialFiles(content, [
            { input: imageInput, path: HomeBannerModal.imagePath, onSucceedText: '설명 이미지' }
        ], onSucceed, (succeeded, error) => onUploadError(content, succeeded, error));
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setRootEnabled(true);
    };

    if (!content) {
        setRootEnabled(false);
        MiscHomeBannerIO.post(adId, m10nId, noticeId, sortOrder, startAt, endAt, onReady, onError);
    } else {
        if (
            content.adId === adId &&
            content.m10nId === m10nId &&
            content.noticeId === noticeId &&
            content.sortOrder === sortOrder &&
            content.startAt.getTime() === startAt.getTime() &&
            content.endAt.getTime() === endAt.getTime()
        ) {
            onReady(content);
            return;
        }

        setRootEnabled(false);
        MiscHomeBannerIO.update(content.id, adId, m10nId, noticeId, sortOrder, startAt, endAt, onReady, onError);
    }
}

type EditorProps = {
    contentState: StatePair<Optional<HomeBannerModal>>;
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>;
    setErrorRecover: React.Dispatch<Optional<ErrorMessage>>;
    setRootEnabled: React.Dispatch<boolean>;
};

function Editor(props: EditorProps) {
    const [content] = props.contentState;
    let imageContainer: JSX.Element = <></>;
    if (content) {
        imageContainer = <div className="row cascade">
            <div className="col s8 offset-s2">
                <StorageIO.StorageImage objectKey={content.imagePath()} style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
        </div>;
    }

    return <>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={adIdInputId}
                inputType="number"
                label="광고 ID 또는 0을 입력하여 무시" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={m10nIdInputId}
                inputType="number"
                label="암기플러스 ID 또는 0을 입력하여 무시" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={noticeIdInputId}
                inputType="number"
                label="공지사항 ID 또는 0을 입력하여 무시" />
        </div>
        <div className="row cascade">
            <Component.Input
                formClasses="col s8 offset-s2"
                inputId={sortOrderInputId}
                inputType="number"
                label="표시 순서" />
        </div>
        <div className="row cascade">
            <Component.Datepicker
                formClasses="col s4 offset-s2"
                inputId={startDateId}
                label="시작 날짜" />
            <Component.Timepicker
                formClasses="col s4"
                inputId={startTimeId}
                label="시작 시각" />
        </div>
        <div className="row cascade">
            <Component.Datepicker
                formClasses="col s4 offset-s2"
                inputId={endDateId}
                label="종료 날짜" />
            <Component.Timepicker
                formClasses="col s4"
                inputId={endTimeId}
                label="종료 시각" />
        </div>
        <div className="row cascade">
            <Component.FileInput
                formClasses="col s8 offset-s2"
                inputId={imageInputId}
                inputLabel="이미지 선택"
                multiple={true}
                accept="image/png" />
        </div>
        {imageContainer}
    </>;
}