import React, {ChangeEvent} from "react";
import {appendClassName, IntrinsicProperties} from "../../util/Properties";
import {unwrap} from "../../util/Types";

export type SelectOptionKey = (string | number | ReadonlyArray<string> | undefined)

export type SelectOptionData = string | { toString(): string }

export type SelectOption<T extends SelectOptionData> = {
    key: SelectOptionKey
    data: T
}

export type SelectProps<O extends SelectOption<T>, T extends SelectOptionData> = {
    wrapperProps?: IntrinsicProperties.div
    selectProps?: IntrinsicProperties.select
    labelProps?: IntrinsicProperties.label
    optionProps?: IntrinsicProperties.option

    options: O[]
    optionIndex?: number
    onOptionSelected?: (index: number, option: O) => void

    placeholder?: string
    label?: string
}

export function Select<O extends SelectOption<T>, T extends SelectOptionData>(props: SelectProps<O, T>) {
    const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
        let index = event.target.selectedIndex
        if (props.placeholder !== undefined) {
            index--
        }

        props.onOptionSelected?.(index, props.options[index])
    }

    const placeholder = (props.placeholder !== undefined) ? <>
        <option disabled>{props.placeholder}</option>
    </> : <></>
    const options = props.options.map((option, index) => <>
        <option
            key={index.toString()}
            value={props.options[index].key}>
            {props.options[index].data.toString()}
        </option>
    </>)
    const label = (props.label !== undefined) ? <>
        <label {...props.labelProps}>{props.label}</label>
    </> : <></>

    return <>
        <div
            {...props.wrapperProps}
            className={appendClassName(props.wrapperProps, 'input-field')}>
            <select
                {...props.selectProps}
                defaultValue={unwrap(props.optionIndex, index => props.options[index]?.key)}
                onChange={onChange}>
                {placeholder}
                {options}
            </select>
            {label}
        </div>
    </>
}