import React from "react";
import {Icon, IconProps} from "./Icon";

export type FloatingActionButtonProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    href: string
    anchorProps?: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
    iconProps?: IconProps
}

export function FloatingActionButton(props: FloatingActionButtonProps) {
    const { href, iconProps, anchorProps, ...divProps } = props
    const divClassName = (divProps.className ?? '') + ' fixed-action-btn'
    const anchorClassName = (anchorProps?.className ?? '') + ' btn-floating btn-large'
    const iconClassName = (iconProps?.className ?? '') + ' large'
    return <>
        <div
            {...divProps}
            className={divClassName}>
            <a
                {...anchorProps}
                className={anchorClassName}
                href={href}>
                <Icon
                    {...iconProps}
                    className={iconClassName}
                    iconName={props.iconProps?.iconName ?? ''} />
            </a>
        </div>
    </>
}