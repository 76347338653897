import {Optional, StateWrapper, unwrapState, wrapState} from "../../../util/Types";
import React, {useEffect, useState} from "react";
import {ConstructionModal} from "../../../modal/Miscs";
import {
    Component,
    MaterialComponent,
    MaterialDatepicker,
    MaterialInput,
    MaterialModal,
    MaterialTimepicker
} from "../../Materials";
import {ErrorMessage, Nav, NavBarItemAction} from "../../Common";
import {LoadBalancerIO} from "../../../io/Services";
import {Logger} from "../../../util/Environments";

const pageId = 'misc_construction';
const startDateId = `${pageId}_start_date`;
const startTimeId = `${pageId}_start_time`;
const endDateId = `${pageId}_end_date`;
const endTimeId = `${pageId}_end_time`;

export default function Edit() {
    const contentPair = wrapState(useState<Optional<ConstructionModal>>(null));
    const rootEnabledPair = wrapState(useState<boolean>(true));
    const errorMessagePair = wrapState(useState<Optional<ErrorMessage>>(null));

    useEffect(() => {
        M.AutoInit();
        MaterialDatepicker.init();
        MaterialTimepicker.init();
    });

    useEffect(() => LoadBalancerIO.get(
        contentPair.set,
        () => {}
    ), []);

    useEffect(() => {
        const content = contentPair.value;
        Logger.log("content", content);
        if (content === null) {
            return;
        }

        MaterialDatepicker.getOrNull(`#${startDateId}`)?.setValue(content.startAt?.toDatePartString() ?? "");
        MaterialTimepicker.getOrNull(`#${startTimeId}`)?.setValue(content.startAt?.toTimePartString(false, false) ?? "");
        MaterialDatepicker.getOrNull(`#${endDateId}`)?.setValue(content.endAt?.toDatePartString() ?? "");
        MaterialTimepicker.getOrNull(`#${endTimeId}`)?.setValue(content.endAt?.toTimePartString(false, false) ?? "");
    }, [contentPair.value]);

    useEffect(() => {
        const inputs = [
            MaterialInput.getOrNull(`#${startDateId}`),
            MaterialInput.getOrNull(`#${startTimeId}`),
            MaterialInput.getOrNull(`#${endDateId}`),
            MaterialInput.getOrNull(`#${endTimeId}`),
        ];

        if (rootEnabledPair) {
            MaterialComponent.enable(...inputs);
        } else {
            MaterialComponent.disable(...inputs);
        }
    }, [rootEnabledPair]);

    useEffect(() => {
        if (errorMessagePair.value) {
            MaterialModal.getOrNull('#error_modal')?.open();
        }
    }, [errorMessagePair.value]);

    return <PageWrapper setRootMessage={rootEnabledPair.set} errorMessagePair={errorMessagePair} />;
}

type PageWrapperProps = {
    setRootMessage: React.Dispatch<boolean>,
    errorMessagePair: StateWrapper<Optional<ErrorMessage>>
};

function PageWrapper(props: PageWrapperProps) {
    const onApplyClicked = () => Apply(props.setRootMessage, props.errorMessagePair.set);
    const onDeleteClicked = () => Delete(props.setRootMessage, props.errorMessagePair.set);
    const onCancelClicked = () => window.history.back();
    const barItems: [string, NavBarItemAction][] = [
        ["적용", onApplyClicked],
        ["삭제", onDeleteClicked],
        ["취소", onCancelClicked],
    ];

    return <>
        <Nav
            title="서버 점검"
            titleIcon="chevron_left"
            titleOnClick={() => window.history.back()}
            barItems={barItems}
            errorMessagePair={unwrapState(props.errorMessagePair)} />
        <div className="row cascade first">
            <Component.Datepicker
                formClasses="col s4 offset-s2"
                inputId={startDateId}
                label="시작 날짜" />
            <Component.Timepicker
                formClasses="col s4"
                inputId={startTimeId}
                label="시작 시각" />
        </div>
        <div className="row cascade first">
            <Component.Datepicker
                formClasses="col s4 offset-s2"
                inputId={endDateId}
                label="종료 날짜" />
            <Component.Timepicker
                formClasses="col s4"
                inputId={endTimeId}
                label="종료 시각" />
        </div>
    </>;
}

function Apply(
    setRootEnabled: React.Dispatch<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    const startDate = MaterialDatepicker.get(`#${startDateId}`).getDatePartOrNull(true);
    if (startDate === null) {
        setErrorMessage("시작 날짜를 확인해주세요.");
        return
    }

    const startTime = MaterialTimepicker.get(`#${startTimeId}`).getTimePartOrNull();
    if (startTime === null) {
        setErrorMessage("시작 시각을 확인해주세요.");
        return;
    }

    const endDate = MaterialDatepicker.get(`#${endDateId}`).getDatePartOrNull(true);
    if (endDate === null) {
        setErrorMessage("종료 날짜를 확인해주세요.");
        return
    }

    const endTime = MaterialTimepicker.get(`#${endTimeId}`).getTimePartOrNull();
    if (endTime === null) {
        setErrorMessage("종료 시각을 확인해주세요.");
        return;
    }

    const startAt = new Date().apply(startDate, startTime);
    const endAt = new Date().apply(endDate, endTime);
    Update(startAt, endAt, setRootEnabled, setErrorMessage);
}

function Delete(
    setRootEnabled: React.Dispatch<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    Update(null, null, setRootEnabled, setErrorMessage);
}

function Update(
    startAt: Optional<Date>,
    endAt: Optional<Date>,
    setRootEnabled: React.Dispatch<boolean>,
    setErrorMessage: React.Dispatch<Optional<ErrorMessage>>
) {
    setRootEnabled(false);
    const onReady = () => {
        window.alert("적용되었습니다. 실제 반영까지 최대 1분이 소요될 수 있습니다.");
        window.history.back();
    };
    const onError = (error: ErrorMessage) => {
        setRootEnabled(true);
        setErrorMessage(error);
    };

    LoadBalancerIO.update(
        startAt,
        endAt,
        onReady,
        onError
    );
}