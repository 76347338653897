import './Home.css';
import {useEffect, useState} from "react";
import {Optional, StatePair} from "../util/Types";
import {ErrorMessage, Nav} from "./Common";
import {AdminHomeCount, AdminHomeCountModal} from "../modal/Admins";
import {AdminIO} from "../io/Admins";
import {MaterialSidenav} from "./Materials";

const pageId = 'home';
const sidenavId = `${pageId}_sidenav`;

export function Home() {
    const [counts, setCounts] = useState<Optional<AdminHomeCountModal>>(null);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => M.AutoInit());

    useEffect(() => AdminIO.get(setCounts, setErrorMessage), []);

    return <>
        <PageWrapper
            counts={counts}
            errorMessageState={[errorMessage, setErrorMessage]} />
    </>;
}

type PageWrapperProps = {
    counts: Optional<AdminHomeCountModal>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
    children?: JSX.Element | JSX.Element[];
}

function PageWrapper(props: PageWrapperProps) {
    const userCardProps: CardProps[] = (props.counts) ? [
        { title: "회원가입", count: props.counts.user.signedUp, openHref: "/user/list" },
        { title: "로그인", count: props.counts.user.signedIn, openHref: "/user/signIn/list" },
        { title: "휴면", count: props.counts.user.dormant, openHref: "/user/dormant/list" },
        { title: "탈퇴", count: props.counts.user.withdrawn, openHref: "/user/withdrawn/list" },
    ] : [];
    const pointCardProps: CardProps[] = (props.counts) ? [
        { title: "획득", count: props.counts.point.positive, openHref: "/point/usage/list?query=positive" },
        { title: "사용", count: props.counts.point.negative, openHref: "/point/usage/list?query=negative" },
        { title: "인출 요청", count: props.counts.point.withdrawRequest, openHref: "/point/withdrawal/list" },
    ] : [];
    const adCardProps: CardProps[] = (props.counts) ? [
        { title: "참여", count: props.counts.ad.participation, openHref: "/ad/participation/list" },
        { title: "리뷰", count: props.counts.ad.review, openHref: "/ad/review/list" },
        { title: "리뷰 신고", count: props.counts.ad.reportedReview, openHref: "/ad/review/report/list" },
    ] : [];
    const productCardProps: CardProps[] = (props.counts) ? [
        { title: "배송 요청", count: props.counts.product.deliveryRequest, openHref: "/product/delivery/list" },
        { title: "리뷰", count: props.counts.product.review, openHref: "/product/review/list" },
        { title: "리뷰 신고", count: props.counts.product.reportedReview, openHref: "/product/review/report/list" },
    ] : [];

    return <>
        <Nav
            titleImage="logo_white_horizontal_icon.svg"
            titleOnClick={() => MaterialSidenav.getOrNull(`#${sidenavId}`)?.open()}
            showHomeBar={false}
            errorMessagePair={props.errorMessageState} />
        <ul id={sidenavId} className="sidenav">
            <li><a className="subheader">사용자</a></li>
            <li><a className="waves-effect" href="/user/list">전체</a></li>
            <li><a className="waves-effect" href="/user/block/list">이용 정지</a></li>
            <li><a className="waves-effect" href="/user/signIn/list">로그인 기록</a></li>
            <li><a className="waves-effect" href="/user/perfect/list">발음왕</a></li>
            <li><a className="waves-effect" href="/user/dormant/list">휴면</a></li>
            <li><a className="waves-effect" href="/user/withdrawn/list">탈퇴</a></li>
            <li><a className="waves-effect" href="/user/notification/post">알림 발송</a></li>
            <li><div className="divider"></div></li>
            <li><a className="subheader">광고</a></li>
            <li><a className="waves-effect" href="/ad/list">전체</a></li>
            <li><a className="waves-effect" href="/ad/participation/list">참여</a></li>
            <li><a className="waves-effect" href="/ad/announcement/list">당첨</a></li>
            <li><a className="waves-effect" href="/ad/review/list">리뷰</a></li>
            <li><a className="waves-effect" href="/ad/review/report/list">신고</a></li>
            <li><div className="divider"></div></li>
            <li><a className="subheader">암기플러스</a></li>
            <li><a className="waves-effect" href="/m10n/list">전체</a></li>
            <li><a className="waves-effect" href="/m10n/participation/list">참여</a></li>
            <li><a className="waves-effect" href="/m10n/review/list">리뷰</a></li>
            <li><div className="divider"></div></li>
            <li><a className="subheader">포인트</a></li>
            <li><a className="waves-effect" href="/point/usage/post">지급 및 차감</a></li>
            <li><a className="waves-effect" href="/point/usage/list">사용</a></li>
            <li><a className="waves-effect" href="/point/withdrawal/list">인출 요청</a></li>
            <li><div className="divider"></div></li>
            <li><a className="subheader">상품</a></li>
            <li><a className="waves-effect" href="/product/list">전체</a></li>
            <li><a className="waves-effect" href="/product/brand/list">브랜드</a></li>
            <li><a className="waves-effect" href="/product/delivery/list">배송</a></li>
            <li><a className="waves-effect" href="/product/review/list">리뷰</a></li>
            <li><div className="divider"></div></li>
            <li><a className="subheader">날개</a></li>
            <li><a className="waves-effect" href="/wing/usage/post">지급 및 차감</a></li>
            <li><a className="waves-effect" href="/wing/usage/list">사용</a></li>
            <li><div className="divider"></div></li>
            <li><a className="subheader">기프티콘</a></li>
            <li><a className="waves-effect" href="/gifticon/list">목록</a></li>
            <li><a className="waves-effect" href="/gifticon/brand/list">브랜드 목록</a></li>
            <li><a className="waves-effect" href="/gifticon/purchase/list">구매 목록</a></li>
            <li><div className="divider"></div></li>
            <li><a className="subheader">환경 데이터</a></li>
            <li><a className="waves-effect" href="/misc/ageRange/list">연령대</a></li>
            <li><a className="waves-effect" href="/misc/agreement/list">약관</a></li>
            <li><a className="waves-effect" href="/misc/application/version/list">애플리케이션 버전</a></li>
            <li><a className="waves-effect" href="/misc/bank/list">은행</a></li>
            <li><a className="waves-effect" href="/misc/construction/edit">서버 점검</a></li>
            <li><a className="waves-effect" href="/misc/event/list">이벤트</a></li>
            <li><a className="waves-effect" href="/misc/faq/category/list">자주 묻는 질문 카테고리</a></li>
            <li><a className="waves-effect" href="/misc/home/banner/list">홈 배너</a></li>
            <li><a className="waves-effect" href="/misc/home/best/list">홈 베스트</a></li>
            <li><a className="waves-effect" href="/misc/language/list">언어</a></li>
            <li><a className="waves-effect" href="/misc/notice/category/list">공지사항 카테고리</a></li>
            <li><a className="waves-effect" href="/misc/region/list">지역</a></li>
            <li><a className="waves-effect" href="/misc/ad/review/report/cause/list">광고 리뷰 신고 사유</a></li>
            <li><a className="waves-effect" href="/misc/point/withdrawal/reject/cause/list">포인트 인출 거절 사유</a></li>
            <li><a className="waves-effect" href="/misc/product/review/report/cause/list">상품 리뷰 신고 사유</a></li>
            <li><a className="waves-effect" href="/misc/user/block/cause/list">사용자 이용 정지 사유</a></li>
            <li><a className="waves-effect" href="/misc/user/withdraw/cause/list">사용자 탈퇴 사유</a></li>
        </ul>
        <Section title={"사용자"} cardProps={userCardProps} />
        <Section title={"포인트"} cardProps={pointCardProps} />
        <Section title={"광고"} cardProps={adCardProps} />
        <Section title={"상품"} cardProps={productCardProps} />
    </>;
}

type SectionProps = {
    title: string;
    cardProps: CardProps[];
};

function Section(props: SectionProps) {
    const cards = props.cardProps.map(props =>
        <Card
            key={props.title}
            title={props.title}
            count={props.count}
            openHref={props.openHref} />
    );

    return <>
        <div className="row separator">
            <h4>{props.title}</h4>
        </div>
        <div className="row">
            {cards}
        </div>
    </>;
}

type CardProps = {
    title: string;
    count: AdminHomeCount;
    openHref: string;
};

function Card(props: CardProps) {
    return <div className="col s3">
        <div className="card">
            <div className="card-content">
                <span className="card-title">{props.title}<a href={props.openHref}><i className="material-icons right secondary">open_in_new</i></a></span>
                <div className="value-container">
                    <h1 className="value-current">{props.count.current}</h1>
                    <ValueDifference valueDifference={props.count.current - props.count.past} />
                </div>
            </div>
        </div>
    </div>;
}

type ValueDifferenceProps = {
    valueDifference: number;
};

function ValueDifference(props: ValueDifferenceProps) {
    if (props.valueDifference > 0) {
        return <h4 className="value-difference light-green-text">{'+' + props.valueDifference.toString()}</h4>
    } else if (props.valueDifference < 0) {
        return <h4 className="value-difference red-text">{props.valueDifference.toString()}</h4>
    } else {
        return <h4 className="value-difference">변화없음</h4>
    }
}

type ChipProps = {
    text: string;
    href: string;
};

function Chip(props: ChipProps) {
    return <>
        <div className={"chip"}>
            <a href={props.href}>{props.text}</a>
        </div>
    </>;
}