import {useLocation} from "react-router-dom";
import qs from "qs";
import React, {useEffect, useState} from "react";
import {Optional, StatePair} from "../../../util/Types";
import {ProductReviewModal} from "../../../modal/Products";
import {BottomProgress, ErrorMessage, Nav} from "../../Common";
import {MaterialInput, MaterialModal} from "../../Materials";
import {getMaxLong} from "../../../util/Environments";
import {ProductReviewIO} from "../../../io/Products";

const pageId = 'product_participation_list';
const searchInputId = `${pageId}_search`;

type PageParams = {
    productId?: string;
};

export default function List() {
    const {search} = useLocation();
    const parsedParams = qs.parse(search, { ignoreQueryPrefix: true }) as PageParams;
    const productId = parsedParams.productId?.toIntOrNull() ?? null;

    const [lock, setLock] = useState<number>(0);
    const [contents, setContents] = useState<Optional<ProductReviewModal[]>>(null);
    const [query, setQuery] = useState<Optional<string>>(null);
    const [hasMoreContents, setMoreContents] = useState<boolean>(true);
    const [isUpdating, setUpdating] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<Optional<ErrorMessage>>(null);

    useEffect(() => {
        M.AutoInit();
        MaterialModal.init();
    });

    useEffect(() => {
        const onWindowScroll = () => OnWindowScroll(
            [contents, setContents],
            [hasMoreContents, setMoreContents],
            query,
            productId,
            [isUpdating, setUpdating],
            [errorMessage, setErrorMessage]
        );
        window.addEventListener('scroll', onWindowScroll);
        return function clean() {
            window.removeEventListener('scroll', onWindowScroll);
        }
    });

    useEffect(() => PrepareReviews(
        [contents, setContents],
        [hasMoreContents, setMoreContents],
        query,
        productId,
        [isUpdating, setUpdating],
        [errorMessage, setErrorMessage]
    ), [lock, query]);

    return <>
        <PageWrapper
            lockState={[lock, setLock]}
            contentsState={[contents, setContents]}
            queryState={[query, setQuery]}
            productId={productId}
            moreContentsState={[hasMoreContents, setMoreContents]}
            errorMessageState={[errorMessage, setErrorMessage]} />
    </>;
}

function OnWindowScroll(
    contentsState: StatePair<Optional<ProductReviewModal[]>>,
    moreContentsState: StatePair<boolean>,
    query: Optional<string>,
    productId: Optional<number>,
    updatingState: StatePair<boolean>,
    setErrorMessage: StatePair<Optional<ErrorMessage>>
) {
    const [isUpdating] = updatingState;
    if (!isUpdating && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // 업데이트중이지 않고 바닥까지 스크롤되었을 때
        PrepareReviews(contentsState, moreContentsState, query, productId, updatingState, setErrorMessage);
    }
}

function PrepareReviews(
    contentsState: StatePair<Optional<ProductReviewModal[]>>,
    moreContentsState: StatePair<boolean>,
    query: Optional<string>,
    productId: Optional<number>,
    updatingState: StatePair<boolean>,
    errorMessageState: StatePair<Optional<ErrorMessage>>
) {
    const [present, setContents] = contentsState;
    const [, setErrorMessage] = errorMessageState;
    const [, setMoreContents] = moreContentsState;

    const onResponse = (contents: ProductReviewModal[]) => {
        setContents((present ?? []).appended(contents));
        setMoreContents(contents.length === 20);
    };

    const onError = (error: string) => {
        setErrorMessage(error);
        setMoreContents(false);
    };

    const lastId = present?.lastOrNull()?.id ?? getMaxLong();
    if (query) {
        ProductReviewIO.search(query, lastId, onResponse, onError)
    } else if (productId !== null) {
        ProductReviewIO.listByProductIdDescending(productId, lastId, onResponse, onError);
    } else {
        ProductReviewIO.listDescending(lastId, onResponse, onError);
    }
}

type PageWrapperProps = {
    lockState: StatePair<number>,
    contentsState: StatePair<Optional<ProductReviewModal[]>>;
    queryState: StatePair<Optional<string>>;
    productId: Optional<number>;
    moreContentsState: StatePair<boolean>;
    errorMessageState: StatePair<Optional<ErrorMessage>>;
};

function PageWrapper(props: PageWrapperProps) {
    const [contents, setContents] = props.contentsState;
    const [hasMoreContents] = props.moreContentsState;
    const searchWrapper = (props.productId === null)
        ? <SearchWrapper setContents={setContents} queryStates={props.queryState} />
        : <></>;

    const rows = (contents ?? []).map(content =>
        <Row
            key={content.id.toString()}
            content={content} />
    );

    return <>
        <Nav
            title="상품 리뷰"
            titleHref="/"
            titleIcon="chevron_left"
            excludeErrorModal={true} />
        {searchWrapper}
        <table className="centered highlight">
            <thead>
            <tr>
                <th>ID</th>
                <th>상품 ID</th>
                <th>사용자 ID</th>
                <th>평점</th>
                <th>작성 일시</th>
                <th>동작</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
        <div className="row">
            <BottomProgress hasMoreContents={hasMoreContents} />
        </div>
    </>;
}

type RowProps = {
    content: ProductReviewModal;
};

function Row(props: RowProps) {
    return <tr>
        <td>{props.content.id.toString()}</td>
        <td>{props.content.productId}</td>
        <td>{props.content.userId.toString()}</td>
        <td>{props.content.rating / 2}</td>
        <td>{props.content.writtenAt.toRowFormat(true, true, true, true)}</td>
        <td>
            <a href={`/product/review/${props.content.id}`} style={{ cursor: "pointer" }}>
                <i className="material-icons black-text">open_in_new</i>
            </a>
        </td>
    </tr>;
}

type SearchWrapperProps = {
    setContents: React.Dispatch<Optional<ProductReviewModal[]>>;
    queryStates: StatePair<Optional<string>>;
};

function SearchWrapper(props: SearchWrapperProps) {
    const [query, setQuery] = props.queryStates;

    let searchIcon: JSX.Element;
    if (query) {
        const onClicked = () => OnSearchClearClicked(props.setContents, setQuery);
        searchIcon = <i className="material-icons prefix" style={{ cursor: "pointer" }} onClick={onClicked}>clear</i>;
    } else {
        const onClicked = () => OnSearchClicked(props.setContents, setQuery);
        searchIcon = <i className="material-icons prefix" style={{ cursor: "pointer" }} onClick={onClicked}>search</i>;
    }

    return <div className="row">
        <form className="col s6 offset-s3">
            <div className="input-field">
                {searchIcon}
                <input id={searchInputId} type="text" className="validate" />
                <label htmlFor={searchInputId}>검색</label>
            </div>
        </form>
    </div>;
}

function OnSearchClearClicked(
    setContents: React.Dispatch<Optional<ProductReviewModal[]>>,
    setQuery: React.Dispatch<Optional<string>>
) {
    setQuery(null);
    setContents(null);
    MaterialInput.get(`#${searchInputId}`).clearValue();
}

function OnSearchClicked(
    setContents: React.Dispatch<Optional<ProductReviewModal[]>>,
    setQuery: React.Dispatch<Optional<string>>
) {
    setContents(null);
    setQuery(MaterialInput.get(`#${searchInputId}`).getValue());
}