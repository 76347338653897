import {Route, Routes} from "react-router-dom";
import {AssertSignIn} from "../../../App";
import {List} from "./List";
import Detail from "./Detail";

export default function WithdrawalRouter() {
    return <Routes>
        <Route path="/list" element={<AssertSignIn><List /></AssertSignIn>} />
        <Route path="/:pointWithdrawalId" element={<AssertSignIn><Detail /></AssertSignIn>} />
    </Routes>;
}