import {Route, Routes} from "react-router-dom";
import {AssertSignIn} from "../../../../App";
import Edit from "./Edit";
import List from "./List";
import Detail from "./Detail";

export default function CategoryRouter() {
    return <Routes>
        <Route path="/list" element={<AssertSignIn><List /></AssertSignIn>} />
        <Route path="/post" element={<AssertSignIn><Edit /></AssertSignIn>} />
        <Route path="/:faqCategoryId" element={<AssertSignIn><Detail /></AssertSignIn>} />
        <Route path="/:faqCategoryId/edit" element={<AssertSignIn><Edit /></AssertSignIn>} />
    </Routes>;
}